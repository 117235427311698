import { Injectable } from '@angular/core';
import { specialCharReplaceRegexp } from '../framework/constants/spend.constants';
import { FiscalService } from './fiscal.service';
import { Cashflow, CashflowFilters } from '../framework/constants/cashflow.constants';
import { RestRequestService } from '../restApi/rest-request.service';
import { REST_CASHFLOW_BASE } from '../restApi/RestRoutes';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CashFlowService {
  constructor(
    private fiscalService: FiscalService,
    private rest: RestRequestService,
  ) {}

  getCashFlowData(filters: CashflowFilters, projectStatusIds: number[]): Observable<Cashflow> {
    const extendedFilters: CashflowFilters = { ...filters, project_status_ids: projectStatusIds };
    const params = this.convertArrayParams(extendedFilters);
    return this.rest.getWithObservable(REST_CASHFLOW_BASE, {}, params);
  }

  private convertArrayParams(filters: CashflowFilters) {
    const convertedFilters = { ...filters };
    if (filters?.project_ids) {
      convertedFilters['project_ids[]'] = filters.project_ids;
      delete convertedFilters.project_ids;
    }
    if (filters?.property_ids) {
      convertedFilters['property_ids[]'] = filters.property_ids;
      delete convertedFilters.property_ids;
    }
    if (filters?.project_status_ids) {
      convertedFilters['project_status_ids[]'] = filters.project_status_ids;
      delete convertedFilters.project_status_ids;
    }

    if (!filters?.searchText) {
      delete convertedFilters.searchText;
    }
    return convertedFilters;
  }

  getFiscalYearFilterOptions$(): Observable<{ value: number; label: string }[]> {
    return this.fiscalService.fiscalYear$.pipe(
      filter((fiscalYear) => !!fiscalYear),
      map((currentFiscalYear) => {
        const fiscalYearOptions = [];
        for (let year = currentFiscalYear - 10; year <= currentFiscalYear + 10; year++) {
          fiscalYearOptions.push({ value: year, label: `FY ${year}` });
        }
        console.log('fiscalYearOptions', fiscalYearOptions, currentFiscalYear);
        return fiscalYearOptions;
      }),
    );
  }

  // todo types when it's refactored
  /**
   * sorts projects/line items by 'name' field ignoring special characters
   */
  sortFunction = (a: any, b: any) => {
    const aField = a.name.replace(specialCharReplaceRegexp, '');
    const bField = b.name.replace(specialCharReplaceRegexp, '');
    return aField.localeCompare(bField);
  };
}
