<div class="shared-prop-proj-wrapper px-8 pt-8 pb-2">
  <div class="pb-2">
    @if (currentPage === TEAM_PROP_PROJ_SIDEBAR_PAGES.PROPERTIES) {
      <div class="text-shade_2 font-semibold text-sm">Shared Properties</div>
    } @else {
      <app-arrow-button (click)="currentPage = TEAM_PROP_PROJ_SIDEBAR_PAGES.PROPERTIES">
        <div class="text-shade_2 font-semibold text-sm">Shared Projects</div>
      </app-arrow-button>
    }
  </div>
  @if (isLoading) {
    <app-page-loading></app-page-loading>
  }
  <div *ngIf="!isLoading">
    <app-search-input class="block" (searchText)="filterData($event)"></app-search-input>
  </div>
  <ng-scrollbar *ngIf="!isLoading" class="std-scrollbar" appearance="native" orientation="vertical">
    @if (currentPage === TEAM_PROP_PROJ_SIDEBAR_PAGES.PROPERTIES) {
      @for (property of propertiesFiltered; track property.id) {
        <div
          (mouseover)="propertyHovered[property.id] = true"
          (mouseleave)="propertyHovered[property.id] = false"
          (click)="setSelectedProperty(property); propertyHovered[property.id] = false"
          class="sidebar-item flex justify-between"
        >
          <div class="flex flex-col">
            <span class="text-size_xl text-color_primary font-semibold">{{ property.name }}</span>
            <span class="text-size_m text-shade_1 font-normal">{{ property.formatted_address }}</span>
          </div>
          <app-arrow-button [hovered]="propertyHovered[property.id]" class="rotate-180" [filled]="true">
          </app-arrow-button>
        </div>
      }
      @if (!propertiesFiltered.length) {
        <div class="mt-4 text-shade_2 text-size_xxl text-center">No shared properties to show</div>
      }
    } @else {
      @for (project of projectsFiltered; track project.id) {
        <div (click)="setSelectedProject(project)" class="sidebar-item flex justify-between">
          <div class="flex flex-col">
            <span class="text-size_xl cursor-pointer font-semibold project-title">{{ project.title }}</span>
          </div>
        </div>
      }
      @if (!projectsFiltered.length) {
        <div class="mt-4 text-shade_2 text-size_xxl text-center">No shared projects to show</div>
      }
    }
  </ng-scrollbar>
</div>
