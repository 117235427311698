import { Injectable } from '@angular/core';
import { RestRequestService } from '../restApi/rest-request.service';
import { REST_ACTIVITIES } from '../restApi/RestRoutes';
import {
  ACTIVITY_VIEW_TYPES,
  ActivityFilters,
  ActivityViewTypes,
  ISidebarActivity,
  ISidebarActivityPayload,
  NO_PROJECT_ID,
} from '../store/activities/activities.constants';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesApiServiceService {
  constructor(private rest: RestRequestService) {}

  getAllActivities() {
    return this.rest.getWithObservable(REST_ACTIVITIES);
  }

  getActivityById(activityId: number) {
    return this.rest.getWithObservable(`${REST_ACTIVITIES}/${activityId}`);
  }

  getActivitiesByCategory(category: ActivityViewTypes, page = 1, filters: ActivityFilters = {}) {
    // delete null / undefined filters, as they would be sent to the backend like {search: 'undefined'}
    if (category === ACTIVITY_VIEW_TYPES.OVERDUE_PROGRESS) {
      filters = { ...filters, 'types[]': ['tracking', 'daily'], overdue: 1 };
    }
    if (category === ACTIVITY_VIEW_TYPES.OPEN_INVOICES) {
      filters = { ...filters, 'types[]': ['invoice', 'misc'], completed: 0 };
    }
    if (category === ACTIVITY_VIEW_TYPES.OPEN_CONTRACTS) {
      filters = { ...filters, 'types[]': ['contract', 'change'], completed: 0 };
    }

    const filtersCopy = this.cleanFilters({ ...filters });

    return this.rest.getWithObservable(REST_ACTIVITIES, {}, { perPage: 20, page, ...filtersCopy });
  }

  getAllActivitiesByPageAndFilters(filters: ActivityFilters = {}, page = 1, perPage = 20) {
    const filtersCopy = this.cleanFilters({ ...filters });
    return this.rest.getWithObservable(REST_ACTIVITIES, {}, { perPage, page, ...filtersCopy });
  }

  cleanFilters = (filters: {}) => {
    const filtersCopy = { ...filters };
    for (const key of Object.keys(filtersCopy)) {
      if (!filtersCopy[key] && filtersCopy[key] !== 0) {
        delete filtersCopy[key];
      }
    }
    return filtersCopy;
  };

  assignTeammateToActivity(activityId: number, assignee_id: number | null) {
    return this.rest.patchWithObservable(`${REST_ACTIVITIES}/${activityId}`, {
      assignee_id,
    });
  }

  addDailyActivity(activity: ISidebarActivity) {
    const payload = this.dailyActivityDTO(activity);
    return this.rest.postWithObservable(`${REST_ACTIVITIES}`, {
      ...payload,
    });
  }

  updateActivity(activity: ISidebarActivity, activityId: number) {
    const payload = this.dailyActivityDTO(activity);
    return this.rest.putWithObservable(`${REST_ACTIVITIES}/${activityId}`, {
      ...payload,
    });
  }

  deleteDailyActivity(activityId: number) {
    return this.rest.delWithObservable(`${REST_ACTIVITIES}/${activityId}`);
  }

  dailyActivityDTO = (activity: ISidebarActivity): ISidebarActivityPayload => {
    const payload = {
      ...activity,
    };

    if (payload.project_id === NO_PROJECT_ID) {
      payload.project_id = null;
    }

    return {
      ...payload,
    };
  };
}
