import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppState } from '../../../store/app-state';
import { Store } from '@ngrx/store';
import {
  IMessage,
  IMessageThread,
  IMessagingUser,
} from '../../../store/messages/messages.interfaces';
import { filter, take, takeUntil } from 'rxjs/operators';
import { MessagingFooterService } from '../../../services/messaging-footer.service';
import { DeepCopyService } from '../../../services/deep-copy.service';
import {
  loadSingleProfilePicture,
  messagesActions,
} from '../../../store/messages/messages.actions';
import {
  getMessagingLoading,
  getThreadDraft,
  getThreadMessages,
} from '../../../store/messages/messages.selectors';
import { DomSanitizer } from '@angular/platform-browser';
import { NgScrollbar } from 'ngx-scrollbar';
import moment from 'moment/moment';
import { DiscussionViewCommonComponent } from '../discussion-view-common/discussion-view-common.component';
import { CustomOverlayService } from '../../../services/custom-overlay.service';
import { CurrentUserService } from '../../../services/current-user.service';
import { RichTextEditorComponent } from '../../inputs/rich-text-editor/rich-text-editor.component';
import { PresignedFileUploadService } from '../../../services/presigned-file-upload.service';
import { UploadDropDirective } from '../../../directives/upload-drop.directive';
import { MessageItemComponent } from '../message-item/message-item.component';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-discussion-thread-view',
  templateUrl: './discussion-thread-view.component.html',
  styleUrls: ['./discussion-thread-view.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgScrollbar,
    MessageItemComponent,
    NgFor,
    UploadDropDirective,
    RichTextEditorComponent,
    AsyncPipe,
    NgClass,
  ],
})
export class DiscussionThreadViewComponent
  extends DiscussionViewCommonComponent
  implements OnInit, OnDestroy
{
  @ViewChild('richTextEditorComponent') editorComponent: RichTextEditorComponent; // it overwrites the base component's variable
  @ViewChild('scrollbar') scrollbar: NgScrollbar;
  threadMessages: IMessageThread;
  isLoading$ = this.store.select(getMessagingLoading);

  constructor(
    protected domSanitizer: DomSanitizer,
    protected footerService: MessagingFooterService,
    protected overlayService: CustomOverlayService,
    protected store: Store<AppState>,
    protected userService: CurrentUserService,
    protected presignedFileUploadService: PresignedFileUploadService,
  ) {
    super(
      overlayService,
      footerService,
      store,
      userService,
      domSanitizer,
      presignedFileUploadService,
    );
  }

  ngOnInit(): void {
    this.handleLoadThreadMessages();
    this.getDraft();
    this.handleUpload();
    this.handleSaveEvent();
    this.handleChatMembers();
    this.setReadStatus();
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.setReadStatus();
  }

  private handleLoadThreadMessages() {
    this.store
      .select(getThreadMessages)
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((data) => !!data?.replies || !!data?.mainMessage),
      )
      .subscribe((threadMessages) => {
        const threadMessagesCopy = DeepCopyService.deepCopy(threadMessages);
        this.sortMessages(threadMessagesCopy);
        threadMessagesCopy.replies = threadMessagesCopy.replies.reverse();
        this.threadMessages = threadMessagesCopy;
        this.handleProfilePictures();
        if (this.shouldScrollToBottom) {
          this.shouldScrollToBottom = false;
          setTimeout(() => this.scrollToBottom(300), 100);
        }
      });
  }

  private getDraft() {
    this.store
      .select(getThreadDraft)
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((data) => !!data),
        take(1),
      )
      .subscribe((draft) => {
        if (this.editorOutput !== '') {
          return;
        }
        this.editorInput = draft.body;
      });
  }

  private sortMessages(messages: IMessageThread) {
    messages.replies.sort((a, b) => {
      return moment(a.updated_at).isAfter(b.updated_at) ? -1 : 1;
    });
    return messages;
  }

  private scrollToBottom(duration: number = 200) {
    this.scrollbar?.scrollTo({ bottom: 0, duration });
    this.scrollbar?.update();
  }

  /**
   * Check if there are users which do not have a profile picture loaded and load if needed.
   * @param loadedUserIds
   * @private
   */
  private loadMissingProfilePictures(loadedUserIds: number[]) {
    // these users we have after adding more messages
    const updatedUserIds = [
      ...new Set(this.threadMessages.replies.map((message) => message.user?.id)),
    ];
    if (loadedUserIds.length !== updatedUserIds.length) {
      // load profile pictures not loaded yet.
      const diff = updatedUserIds.filter(
        (updatedUserId) => !loadedUserIds.find((loadedUserId) => loadedUserId === updatedUserId),
      );
      // if diff is not empty that means there are new users (which were not included in this.allMessages)
      // so we dispatch an action to load missing profile pics
      diff.forEach((userId) => {
        const user = this.threadMessages.replies.find((m) => m.user?.id === userId)?.user;
        this.store.dispatch(loadSingleProfilePicture({ user: user as IMessagingUser }));
      });
    }
  }

  private handleProfilePictures() {
    // these users were loaded in the past, and they have all the user data (with profile pic)
    const userIds = this.threadMessages?.replies?.map((m) => m.user?.id);
    if (!userIds?.length) {
      return;
    }
    userIds.push(this.threadMessages?.mainMessage?.id);
    const loadedUserIds = [...new Set(userIds)];
    this.loadMissingProfilePictures(loadedUserIds);
  }

  /**
   * set main message to read when thread view is opened or closed.
   * @private
   */
  private setReadStatus() {
    if (this.threadMessages?.mainMessage?.thread?.length > 0) {
      this.store.dispatch(
        messagesActions.updateMessageReadStatus({
          messageId: this.threadMessages.mainMessage.id,
          isRead: true,
        }),
      );
    }
  }

  trackByFn = (index: number, item: IMessage) => item.id;
}
