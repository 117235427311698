import { SPEND_TYPES } from '../../../../../framework/constants/budget.constants';
import { ILineItemExtended } from '../../../../../store/spend/spend.interfaces';
import { defaultMonthlyData } from '../../../../../framework/constants/spend.constants';

/**
 * This function will rebuild the object that needs to be sent to the spreadsheet component
 * It will add missing years to all line items with default 0 monthly data
 * @param items - line items
 * @param allYears - all years from budget
 * @param budgetType - budget type
 */
export function getSpreadSheetData(
  items: ILineItemExtended[],
  allYears: number[],
  budgetType: SPEND_TYPES,
) {
  items.forEach((item) => {
    if (budgetType === SPEND_TYPES.COMMITMENTS) {
      item.committed_items.forEach((committedItem) => {
        allYears.forEach((year) => {
          const budIndex = committedItem.budget.findIndex((bud) => bud.year === year);
          if (budIndex === -1) {
            committedItem.budget.push({
              year,
              monthly_forecast: { ...defaultMonthlyData },
            });
          }
        });
      });
    } else {
      allYears.forEach((year) => {
        const budIndex = item.budget.findIndex((bud) => bud.year === year);
        if (budIndex === -1) {
          item.budget.push({
            year,
            monthly_budget: { ...defaultMonthlyData },
          });
        }
      });
    }
  });
  return items;
}
