import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DateCustomPipe, UTC_DATE } from '../../pipes/framework/date-custom.pipe';
import { Router } from '@angular/router';
import moment from 'moment/moment';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import {
  PROJECT_ATTRIBUTES,
  PROJECT_ATTRIBUTES_OBJ,
  projectsTableHeaders,
} from '../constants/project.constants';
import { ProjectApiService } from '../../services/project-api.service';
import { NotificationsService } from '../../services/notifications.service';
import { ProjectFilterService, ProjectSearchCriteria } from '../../services/project-filter.service';
import { PROJECTS_TABLE_VIEW, ProjectStatus } from '../../pages/webapp/projects/projects.interface';
import { CurrentUserService } from '../../services/current-user.service';
import { ProjectStateService } from '../../services/project-state.service';
import { MoneyPipe } from '../../pipes/framework/money-short.pipe';
import { ProjectBubbleComponent } from '../project-bubble/project-bubble.component';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { SortArrowComponent } from '../sort-arrow/sort-arrow.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField } from '@angular/material/form-field';
import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Store } from '@ngrx/store';
import {
  getProjectStatusesByKey,
  getProjectStatusesExcept,
} from '../../store/projects/projects.selectors';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

/**
 * this component is somehow configurable to show any data, but needs some fine-tuning to do so
 * @deprecated - this component is kept only for legacy reasons, it should not be used for new features.
 * Use the dynamic projected table instead.
 */
@Component({
  selector: 'app-projects-table',
  templateUrl: './projects-table.component.html',
  styleUrls: ['./projects-table.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    MatFormField,
    MatSelect,
    FormsModule,
    ReactiveFormsModule,
    MatOption,
    MatTooltip,
    SortArrowComponent,
    NgSwitchDefault,
    NgIf,
    NgScrollbar,
    ProgressBarComponent,
    ProjectBubbleComponent,
    MoneyPipe,
    DateCustomPipe,
  ],
})
export class ProjectsTableComponent implements OnInit, AfterViewInit, OnDestroy {
  private PROJECTS = []; // filtered projects before transformed for table
  private ALL_PROJECTS = [];

  @Input() set allProjects(value) {
    if (value) {
      this.ALL_PROJECTS = value;
      this.tableData = [...value];
      this.filter();
    }
  }
  get allProjects() {
    return this.ALL_PROJECTS;
  }

  set projects(value) {
    if (value) {
      this.PROJECTS = value;
      this.tableData = [...value];
      this.updateTotals(this.tableData);
      this.sortBy(this.lastSortedBy, false);
      this.tableData.sort(this.projectFilter.sortDateDesc);
    }
  }
  get projects() {
    return this.PROJECTS;
  }

  @Input() showTotals = true;

  @Input() set filterByText(value) {
    if (value) {
      this.filterCriteria.text = value;
    } else if (value === '') {
      this.filterCriteria.text = null;
    }
    this.filter();
  }

  @Input() set filterByProperty(value) {
    if (value) {
      this.filterCriteria.property = value;
    } else if (value === 0) {
      // all properties selected
      this.filterCriteria.property = null;
    }
    this.filter();
  }

  private _dataKeys = [
    'title',
    'start_date',
    'current_budget',
    'total_committed',
    'forecasts_to_complete',
    'actuals',
    'complete',
    'attributes',
    'statusId',
  ];
  @Input() set dataKeys(keys) {
    this._dataKeys = keys;
    this.headers = this.headers.filter((header) => this._dataKeys.includes(header.key));
  }
  get dataKeys() {
    return this._dataKeys;
  }

  private _viewType: PROJECTS_TABLE_VIEW;
  @Input() set viewType(viewType: PROJECTS_TABLE_VIEW) {
    this._viewType = viewType;
    if (viewType === PROJECTS_TABLE_VIEW.CONTRACTOR) {
      this.store
        .select(getProjectStatusesByKey(['invited', 'bidding', 'in_progress']))
        .pipe(
          filter((statuses) => statuses.length > 0),
          take(1),
        )
        .subscribe((statuses) => {
          this.statusControl.setValue(statuses.map((status) => status.id));
          this.filterCriteria.status = this.statusControl.value;
        });
    }
  }
  get viewType() {
    return this._viewType;
  }

  isLoading = false;

  attributesControl = new UntypedFormControl(['all']);
  statusControl = new UntypedFormControl([]);

  // all possible project statuses
  allStatuses: ProjectStatus[] = [];
  filterCriteria: ProjectSearchCriteria = {
    // it does not filter for null values
    text: null,
    property: null,
    attributes: null,
    status: this.statusControl.value,
  };

  VIEWS = PROJECTS_TABLE_VIEW;
  PROJECT_ATTRIBUTES = PROJECT_ATTRIBUTES;

  // data displayed in table
  tableData = [];
  // if dataKeys is an input, this could be generated
  headers = projectsTableHeaders;
  // this array contains the last sort order for the headers
  lastSortedAscending = Array(this.dataKeys.length).fill(false);
  // last sorted by (header index); -1 => no sort applied
  lastSortedBy = -1;
  // the totals object should be generated if needed
  totals = {
    numberOfProjects: 0,
    current_budget: 0,
    total_committed: 0,
    forecasts_to_complete: 0,
    actuals: 0,
    complete: 0,
    proposal_sum: 0,
  };

  UTC_DATE = UTC_DATE;
  isDestroyed$ = new Subject();

  constructor(
    private router: Router,
    private projectService: ProjectApiService,
    private notif: NotificationsService,
    private projectFilter: ProjectFilterService,
    public user: CurrentUserService,
    private store: Store,
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.store
      .select(getProjectStatusesExcept(['deleted']))
      .pipe(
        filter((statuses) => statuses?.length > 0),
        take(1),
        takeUntil(this.isDestroyed$),
      )
      .subscribe((statuses) => {
        this.allStatuses = statuses;
        this.filter();
        this.isLoading = false;
      });
  }

  ngAfterViewInit() {
    this.attributesControl.valueChanges.subscribe((attr: any[]) => {
      if (attr.includes('all')) {
        this.filterCriteria.attributes = null;
      } else {
        this.filterCriteria.attributes = {
          opex: attr.includes(PROJECT_ATTRIBUTES_OBJ.opex),
          capex: attr.includes(PROJECT_ATTRIBUTES_OBJ.capex),
          priority: attr.includes(PROJECT_ATTRIBUTES_OBJ.priority),
          approved: attr.includes(PROJECT_ATTRIBUTES_OBJ.approved),
        };
      }
      this.filter();
    });

    this.statusControl.valueChanges.subscribe((statuses) => {
      this.filterCriteria.status = statuses;
      this.filter();
    });
  }

  navigateToProject(projectId: number, statusId: number) {
    this.store
      .select(getProjectStatusesByKey(['deleted']))
      .pipe(take(1), takeUntil(this.isDestroyed$))
      .subscribe((statuses) => {
        const deletedStatus = statuses[0];
        if (statusId !== deletedStatus.id) {
          this.router.navigate(['webapp', 'projects', projectId]);
          return;
        }

        this.notif
          .showPopup("Deleted projects aren't available. Do you want to restore?")
          .then((resp) => {
            if (resp) {
              const newStatus: number = this.allStatuses.find(
                (status) => status.key === 'invited',
              ).id;

              this.projectService.updateProjectStatus(projectId, newStatus).then(
                () => {
                  this.notif.showSuccess('Project restored!');
                  this.router.navigate(['webapp', 'projects', projectId]);
                },
                (err) => {
                  this.notif.showError(err);
                },
              );
            }
          });
      });
  }

  isNumber(value) {
    // needed in template
    return typeof value === 'number';
  }

  private filter() {
    this.projects = this.projectFilter.filter(this.allProjects, this.filterCriteria);
  }

  updateTotals(projects) {
    Object.keys(this.totals).forEach((key) => (this.totals[key] = 0)); // reset to 0
    projects.forEach((project) => {
      this.totals.numberOfProjects += 1;
      this.totals.total_committed += project.total_committed;
      this.totals.actuals += project.actuals;
      this.totals.current_budget += project.current_budget;
      this.totals.forecasts_to_complete += project.forecasts_to_complete;
      this.totals.proposal_sum += project.proposal;
    });
    this.totals.complete = this.projectFilter.calculateComplete(this.totals);
  }

  updateProjectStatus(update: { projectId: number; newStatus: number | string }) {
    this.projectService.getProjects().then((projects) => {
      this.allProjects = projects;
      this.notif.close();
      console.log('updateProjectStatus got projects', performance.now());
    });
    ProjectStateService.updateContractorStats.next();
  }

  // the index from headers array
  sortBy(index: number, changeNextSortOrder = true) {
    if (index < 0) {
      return;
    }
    const key = this.dataKeys[index];
    if (key === 'attributes' || key === 'status_name') {
      // don't sort by attributes and status
      return;
    }
    this.lastSortedBy = index;
    let sortDescending;
    if (changeNextSortOrder) {
      sortDescending = this.lastSortedAscending[index];
      this.lastSortedAscending[index] = !this.lastSortedAscending[index];
    } else {
      sortDescending = !this.lastSortedAscending[index];
    }

    let sortFunc;
    let order = +1;
    if (sortDescending) {
      order = -1;
    }
    if (key === 'start_date') {
      // different sort function on by type
      sortFunc = (a, b) => {
        if (moment(a[key]).isBefore(moment(b[key]))) {
          return -1 * order;
        }
        return +1 * order;
      };
    } else {
      sortFunc = (a, b) => {
        if (a[key] < b[key]) {
          return -1 * order;
        }
        return +1 * order;
      };
    }

    this.tableData.sort(sortFunc);
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
