import { Injectable } from '@angular/core';
import { CommitmentsService } from './commitments.service';
import { CAPEX_ID, OPEX_ID } from '../framework/constants/general.constants';
import { Project } from '../pages/webapp/projects/projects.interface';

export interface ProjectSearchCriteria {
  text?: string;
  property?: number;
  attributes?: {
    opex: boolean;
    capex: boolean;
    approved: boolean;
    priority: boolean;
  };
  status?: number[];
}

@Injectable({
  providedIn: 'root',
})
export class ProjectFilterService {
  constructor(private commitmentsService: CommitmentsService) {}

  // this method should not exist, but the cashflow projects have a whole different structure
  filterForCashflow(projects: any[], criteria: ProjectSearchCriteria) {
    if (!projects) {
      return [];
    }
    let filtered = projects.map((p) => p.all_data);
    if (criteria.text) {
      const text = criteria.text.toLowerCase();
      filtered = this.filterByText(filtered, text);
    }
    if (criteria.property) {
      filtered = this.filterByProperty(filtered, criteria.property);
    }
    const selectedIDs = filtered.map((f) => f.id);
    return projects.filter((project) => {
      return selectedIDs.includes(project.id);
    });
  }

  filter(projects: any[], criteria: ProjectSearchCriteria) {
    let filtered = projects;
    if (criteria.attributes) {
      filtered = this.filterByAttributes(filtered, criteria.attributes);
    }
    if (criteria.status) {
      filtered = this.filterByStatus(filtered, criteria.status);
    }
    if (criteria.property) {
      filtered = this.filterByProperty(filtered, criteria.property);
    }
    if (criteria.text) {
      const text = criteria.text.toLowerCase();
      filtered = this.filterByText(filtered, text);
    }
    return filtered;
  }

  sortDateDesc = (a, b) => {
    return new Date(a.start_date).getTime() > new Date(b.start_date).getTime()
      ? -1
      : new Date(a.start_date).getTime() === new Date(b.start_date).getTime()
        ? 0
        : 1;
  };

  calculateComplete(project) {
    if (project.actuals === 0 || project.total_committed === 0) {
      return 0;
    }
    const complete = project.actuals / project.total_committed;
    return Math.round(complete * 100 * 100) / 100;
  }

  private filterByText(projects, text: string) {
    return projects.filter((project) => {
      if (project.title.toLowerCase().includes(text)) {
        return true;
      }
      if (project.project_property.formatted_address.toLowerCase().includes(text)) {
        return true;
      }
      if (project.project_property.name.toLowerCase().includes(text)) {
        return true;
      }
      if (project.project_status.name.toLowerCase().includes(text)) {
        return true;
      }
      return false;
    });
  }

  private filterByProperty(projects, propertyId) {
    return projects.filter((project) => {
      return project.project_property.id === propertyId;
    });
  }

  private filterByAttributes(projects, attributes) {
    return projects.filter((project) => {
      // if (attributes.opex && project.spend_category !== OPEX_ID) {
      //   return false;
      // }
      if (!attributes.opex && project.spend_category === OPEX_ID) {
        return false;
      }

      // if (attributes.capex && project.spend_category !== CAPEX_ID) {
      //   return false;
      // }
      if (!attributes.capex && project.spend_category === CAPEX_ID) {
        return false;
      }

      if (project.approved !== attributes.approved) {
        return false;
      }
      if (project.priority !== attributes.priority) {
        return false;
      }
      return true;
    });
  }

  private filterByStatus(projects: Project[], status: (number | string)[]) {
    return projects.filter((project) => {
      if (project.project_status) {
        return status.includes(project.project_status.id);
      }
      return status.includes(project.status);
    });
  }

  private setProjectAttributes(project) {
    const attributes = {
      capex: false,
      opex: false,
      priority: project.priority,
      approved: project.approved,
    };
    if (project.spend_category === CAPEX_ID) {
      attributes.capex = true;
      attributes.opex = false;
    } else {
      attributes.capex = false;
      attributes.opex = true;
    }
    return attributes;
  }
}
