import { EventEmitter, Injectable } from '@angular/core';
import { RestRequestService } from '../restApi/rest-request.service';
import {
  REST_BASE,
  REST_COMMITMENTS,
  REST_LINE_ITEMS_BY_PROJECT,
  REST_PROJECTS,
} from '../restApi/RestRoutes';
import { ErrorHandlerRest } from '../restApi/errorHandler-rest';
import { CurrentUserService } from './current-user.service';
import { Router } from '@angular/router';
import { DeepCopyService } from './deep-copy.service';

import {
  AllCommitmentsResponse,
  AllCommitmentsSummaryResponse,
  Commitment,
  ContractSummaryResponse,
  DirectCostsSummaryResponse,
  ICommitmentBudgetLineItem,
  ISidebarChangeOrder,
  ISidebarChangeOrderPayload,
  ISidebarCommitmentItem,
  ISidebarCommitmentItemsDTO,
  ISidebarContract,
  ISidebarContractPayload,
  ISidebarCost,
  ISidebarDirectCost,
  ISidebarDirectCostPayload,
  ISidebarInvoice,
  ISidebarInvoicePayload,
} from '../store/commitments/commitments.types';

@Injectable({
  providedIn: 'root',
})
export class CommitmentsService extends ErrorHandlerRest {
  constructor(
    private rest: RestRequestService,
    protected user: CurrentUserService,
    protected router: Router,
  ) {
    super(user, router);
  }
  updateData = new EventEmitter();

  // todo: remove 'Simple' in name and add return type
  async getAllCommitments(projectId: number): Promise<AllCommitmentsResponse> {
    try {
      return this.rest.get(`${REST_PROJECTS}/${projectId}/${REST_COMMITMENTS}`);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getCommitmentItems(projectId: number): Promise<ICommitmentBudgetLineItem[]> {
    try {
      return this.rest.get(
        `${REST_LINE_ITEMS_BY_PROJECT}/${projectId}?fiscal_view=1`,
        {},
        {
          view: 'summary',
        },
      );
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getAllCommitmentsSummary(projectId: number): Promise<AllCommitmentsSummaryResponse> {
    try {
      return this.rest.get(`${REST_PROJECTS}/${projectId}/${REST_COMMITMENTS}/summary`);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getDirectCostsSummary(projectId: number): Promise<DirectCostsSummaryResponse> {
    try {
      return this.rest.get(`${REST_PROJECTS}/${projectId}/direct-costs/summary`);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getContractSummary(contractId: number): Promise<DirectCostsSummaryResponse> {
    try {
      return this.rest.get(`${REST_PROJECTS}/${REST_COMMITMENTS}/contracts/${contractId}`);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getCommitmentById(id: number): Promise<Commitment> {
    try {
      return this.rest.get(`${REST_BASE}${REST_COMMITMENTS}/${id}`);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getContractById(id: number): Promise<ContractSummaryResponse> {
    try {
      return this.rest.get(`${REST_BASE}${REST_COMMITMENTS}/contracts/${id}`);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  addNewContract(contractForm: ISidebarContract) {
    const body: Partial<ISidebarContractPayload> = this.transformToContractDTO(contractForm);
    return this.addNewCommitment(body);
  }

  addNewChangeOrder(changeOrderData: ISidebarChangeOrder) {
    const body: Partial<ISidebarContractPayload> = this.transformToChangeOrderDTO(changeOrderData);
    return this.addNewCommitment(body);
  }

  addNewInvoice(invoiceData: ISidebarInvoice) {
    const body: Partial<ISidebarInvoicePayload> = this.transformToInvoiceDTO(invoiceData);
    return this.addNewCommitment(body);
  }

  addNewDirectCost(directCostForm: ISidebarDirectCost) {
    const body: Partial<ISidebarContractPayload> = this.transformToDirectCostDTO(directCostForm);
    return this.addNewCommitment(body);
  }

  addNewCommitment(body: Partial<ISidebarContractPayload> | Partial<ISidebarChangeOrderPayload>) {
    return new Promise<any>((res, rej) => {
      this.rest.post(`${REST_BASE}${REST_COMMITMENTS}`, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  modifyCommitment(body: Partial<ISidebarContractPayload> | Partial<ISidebarChangeOrderPayload>) {
    return new Promise<any>((res, rej) => {
      this.rest.put(`${REST_BASE}${REST_COMMITMENTS}/${body.id}`, body).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  modifyExistingContract(contractForm: ISidebarContract) {
    const body: Partial<ISidebarContractPayload> = this.transformToContractDTO(contractForm);

    return this.modifyCommitment(body);
  }

  modifyExistingChangeOrder(changeOrderData: ISidebarChangeOrder) {
    const body: Partial<ISidebarContractPayload> = this.transformToChangeOrderDTO(changeOrderData);
    return this.modifyCommitment(body);
  }

  modifyExistingInvoice(invoiceForm: ISidebarInvoice) {
    const body: Partial<ISidebarInvoicePayload> = this.transformToInvoiceDTO(invoiceForm);

    return this.modifyCommitment(body);
  }

  modifyExistingDirectCost(directCost: ISidebarDirectCost) {
    const body: Partial<ISidebarDirectCostPayload> = this.transformToDirectCostDTO(directCost);

    return this.modifyCommitment(body);
  }

  deleteCommitment(id: number) {
    return new Promise<any>((res, rej) => {
      this.rest.del(`${REST_BASE}${REST_COMMITMENTS}/${id}`).then(
        (dataRes) => {
          res(dataRes);
        },
        (err) => {
          rej(this.handleError(err));
        },
      );
    });
  }

  refreshData() {
    setTimeout(() => {
      this.updateData.emit(true);
    }, 500);
  }

  /**
   * Add common fields to DTO, which are used in all commitment types
   * @param commitmentData
   */
  commonDTOFields(
    commitmentData: ISidebarContract | ISidebarChangeOrder | ISidebarInvoice | ISidebarDirectCost,
  ) {
    const body:
      | Partial<ISidebarContractPayload>
      | Partial<ISidebarChangeOrderPayload>
      | Partial<ISidebarInvoicePayload> = {};
    if (commitmentData.id) {
      body.id = commitmentData.id;
    }
    if (commitmentData.service_provider?.temporary_service_provider_id) {
      body.temporary_service_provider_id =
        commitmentData.service_provider.temporary_service_provider_id;
    }
    if (commitmentData.service_provider?.service_provider_user_id) {
      body.service_provider_user_id = commitmentData.service_provider.service_provider_user_id;
    }
    if (
      commitmentData.service_provider?.name &&
      !commitmentData.service_provider?.service_provider_user_id &&
      !commitmentData.service_provider?.temporary_service_provider_id
    ) {
      body.tmp_sp_name = commitmentData.service_provider.name;
    }

    return body;
  }

  transformToContractDTO(contractForm: ISidebarContract) {
    const body: ISidebarContractPayload = {
      project_id: contractForm.project_id,
      type: contractForm.type,
      title: contractForm.title,
      description: contractForm.description,
      added_at: contractForm.added_at,
      approval_status: contractForm.approval_status,
      commitment_items: [
        ...this.transformCommitmentItems(contractForm.commitment_items),
        ...this.transformCommitmentItems(contractForm?.deleted_commitment_items),
      ],
    };

    if (!body.description) {
      delete body.description;
    }

    return {
      ...body,
      ...this.commonDTOFields(contractForm),
    };
  }

  transformToChangeOrderDTO(changeOrderData: ISidebarChangeOrder) {
    const body: ISidebarChangeOrderPayload = {
      project_id: changeOrderData.project_id,
      type: changeOrderData.type,
      change_order_type: changeOrderData.change_order_type,
      title: changeOrderData.title,
      description: changeOrderData.description,
      added_at: changeOrderData.added_at,
      approval_status: changeOrderData.approval_status,
      contract_id: changeOrderData.contract_id,
      commitment_items: [
        ...this.transformCommitmentItems(changeOrderData.commitment_items),
        ...this.transformCommitmentItems(changeOrderData.deleted_commitment_items),
      ],
    };

    if (!body.description) {
      delete body.description;
    }

    return {
      ...body,
      ...this.commonDTOFields(changeOrderData),
    };
  }

  transformToInvoiceDTO(invoiceData: ISidebarInvoice) {
    const body: ISidebarInvoicePayload = {
      project_id: invoiceData.project_id,
      type: invoiceData.type,
      invoice_number: invoiceData?.invoice_number ?? invoiceData?.title,
      description: invoiceData.description,
      added_at: invoiceData.added_at,
      paid_date: invoiceData.paid_date,
      paid_status: invoiceData.paid_status,
      contract_id: invoiceData.contract_id,
      costs: [
        ...this.transformCommitmentCosts(invoiceData.costs),
        ...this.transformCommitmentCosts(invoiceData.deleted_costs),
      ],
    };

    if (!body.paid_status) {
      delete body.paid_date;
    }

    if (!body.description) {
      delete body.description;
    }

    return {
      ...body,
      ...this.commonDTOFields(invoiceData),
    };
  }

  transformToDirectCostDTO(directCost: ISidebarDirectCost) {
    const body: ISidebarDirectCostPayload = {
      project_id: directCost.project_id,
      vendor: directCost.vendor,
      type: directCost.type,
      title: directCost.title,
      description: directCost.description,
      added_at: directCost.added_at,
      paid_date: directCost.paid_date,
      paid_status: directCost.paid_status,
      commitment_items: [
        ...this.transformCommitmentItems(directCost.commitment_items),
        ...this.transformCommitmentItems(directCost.deleted_commitment_items),
      ],
    };

    if (!body.paid_status) {
      delete body.paid_date;
    }

    if (!body.description) {
      delete body.description;
    }

    return {
      ...body,
      ...this.commonDTOFields(directCost),
    };
  }

  transformCommitmentItems(commitmentItems: Partial<ISidebarCommitmentItem>[]) {
    if (!commitmentItems?.length) {
      return [];
    }
    return commitmentItems.map((commitmentItem: ISidebarCommitmentItem) => {
      const transformed: ISidebarCommitmentItemsDTO = {
        item_id: commitmentItem.item.id,
        start_date: commitmentItem?.start_date ?? null,
        duration: commitmentItem.duration ?? null,
        budget_tag_id: commitmentItem.budget_tag.id,
        budget_tag_name: commitmentItem.budget_tag.name,
        costs: [...commitmentItem.costs, ...(commitmentItem?.deleted_costs ?? [])],
        deleted: !!commitmentItem?.deleted,
      };

      if (!commitmentItem.start_date) {
        delete transformed.start_date;
      }

      if (commitmentItem.duration === null) {
        delete transformed.duration;
      }

      if (commitmentItem.id) {
        transformed.id = commitmentItem.id;
      }
      return transformed;
    });
  }

  transformCommitmentCosts = (costs: ISidebarCost[]) => {
    const transformedCosts: ISidebarCost[] = DeepCopyService.deepCopy(costs);
    if (!transformedCosts?.length) {
      return [];
    }
    return transformedCosts.map((cost) => {
      const transformed: ISidebarCost = {
        id: cost.id ?? null,
        name: cost.name,
        value: cost.value,
        parent_id: cost.parent_id,
        deleted: cost?.deleted ?? false,
      };
      if (!cost.id) {
        delete transformed.id;
      }
      return transformed;
    });
  };
}
