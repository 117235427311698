import { AppState } from '../app-state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import {
  discardMessagingGroupCache,
  loadFirstPageOfGroups,
  loadMessages,
  messagesActions,
  setGroupFilters,
  setGroups,
  setMessageFilters,
  setMessagingView,
  setSelectedGroupId,
} from './messages.actions';
import {
  catchError,
  debounceTime,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { MessagesApiService } from '../../services/messages-api.service';
import {
  IMessageFilters,
  IMessagingGroup,
  IMessagingGroupApiFilters,
  IMessagingGroupFilters,
  IMessagingMember,
  MESSAGING_VIEWS,
} from './messages.interfaces';
import {
  getAreAllGroupsLoaded,
  messageSelectors,
  messagingFeatureSelector,
} from './messages.selectors';
import { of } from 'rxjs';
import {
  DISCUSSION_FILTER_TYPE,
  DISCUSSION_TYPES,
  discussionFilterTypeToDiscussionType,
  IMessagingHeaderFilters,
  MESSAGES_FIRST_PAGE_LENGTH,
  MESSAGES_NOT_FIRST_PAGE_LENGTH,
} from '../../framework/constants/messages.constants';
import { NotificationsService } from '../../services/notifications.service';
import { MessagesStateService } from '../../services/messages-state.service';

@Injectable()
export class MessagesGroupEffects {
  constructor(
    private actions: Actions,
    private store: Store<AppState>,
    private messagesApiService: MessagesApiService,
    private notif: NotificationsService,
    private messagesStateService: MessagesStateService,
  ) {}

  loadFirstPageOfGroups$ = createEffect(() =>
    this.actions.pipe(
      ofType(messagesActions.loadFirstPageOfGroups),
      tap((action) => {
        if (action.clearOldGroups) {
          this.store.dispatch(setGroups({ groups: [] }));
        }
        this.store.dispatch(
          setGroupFilters({
            filters: {
              discussionType: null,
              search: null,
              perPage: MESSAGES_FIRST_PAGE_LENGTH, // on first load it is set to 30, then defaults to 30
              page: 0, // page 0 means that the next page should be 1, the first
            },
          }),
        );
      }),
      map((action) => messagesActions.loadMessagingGroupsNextPage({})),
    ),
  );

  loadMessagingGroups$ = createEffect(() =>
    this.actions.pipe(
      ofType(messagesActions.loadMessagingGroupsNextPage),
      debounceTime(200),
      withLatestFrom(
        this.store.select(messageSelectors.getGroupFilters),
        this.store.select(getAreAllGroupsLoaded),
      ),
      tap(([action, filters, areAllGroupsLoaded]) => {
        if (!areAllGroupsLoaded) {
          this.store.dispatch(messagesActions.setIsLoading({ isLoading: true }));
        }
      }),
      mergeMap(([action, storeFilters, areAllGroupsLoaded]) => {
        if (areAllGroupsLoaded) {
          return of(messagesActions.cancelMessages());
        }
        let apiFilters;
        if (action.filters) {
          apiFilters = getGroupFilters(action.filters);
        } else {
          apiFilters = getGroupFilters(storeFilters);
        }

        return this.messagesApiService
          .getMessagingGroups(apiFilters)
          .pipe(
            map((groups) => messagesActions.paginatedGroupsLoaded({ groups, filters: apiFilters })),
          );
      }),
    ),
  );

  loadMessagingUsers$ = createEffect(() =>
    this.actions.pipe(
      ofType(messagesActions.loadSearchedMessagingUsers),
      tap(() => {
        this.store.dispatch(
          messagesActions.setIsSearchedUsersLoading({ isSearchedUsersLoading: true }),
        );
      }),
      mergeMap((action) =>
        this.messagesApiService.getMessagingUsers(action.search).pipe(
          map((users: IMessagingMember[]) => {
            return messagesActions.setSearchedMessagingMembers({ members: users });
          }),
          catchError((err) => {
            console.warn('loadMessagingUsers$ err handled', err);
            return of(messagesActions.cancel());
          }),
        ),
      ),
    ),
  );

  createGroup$ = createEffect(() => {
    return this.actions.pipe(
      ofType(messagesActions.createGroup),
      withLatestFrom(this.store.select(messagingFeatureSelector)),
      exhaustMap(([_, state]) => {
        const body = {
          members: [...state.newGroupData.discussionMembers],
          message: { body: state.newGroupData.message },
          name: state.newGroupData.name,
        };

        if (!body?.message?.body || !body?.members?.length || !body?.name) {
          this.notif.showError('Please fill all fields!');
          return of(messagesActions.cancel());
        }

        // no files to upload then set view to discussion list and discard changes
        if (!state.files.length) {
          this.store.dispatch(setMessagingView({ view: MESSAGING_VIEWS.DISCUSSION_LIST }));
          this.store.dispatch(discardMessagingGroupCache());
        }

        // after group creation, reload groups
        return this.messagesApiService.createGroup(body).pipe(
          tap((data) => {
            this.store.dispatch(loadFirstPageOfGroups({}));
          }),
          catchError((err) => {
            console.warn('createGroup$ err', err);
            this.notif.showError(
              'An error occurred: ' + (err?.error?.message || 'could not create group.'),
            );
            return of(messagesActions.cancel());
          }),
        );
      }),
      exhaustMap((groupData: IMessagingGroup) => {
        if (!groupData?.id) {
          return of(messagesActions.cancel());
        }
        return this.messagesApiService.getGroupMessages(groupData.id).pipe(
          map((groupMessages) => {
            // set last non draft message id to trigger upload if needed
            return messagesActions.setLastNonDraftMessage({
              lastNonDraftMessageId: groupMessages.messages[0].id,
            });
          }),
        );
      }),
    );
  });

  updateGroupStatus$ = createEffect(() => {
    return this.actions.pipe(
      ofType(messagesActions.updateGroupStatus),
      withLatestFrom(this.store.select(messagingFeatureSelector)),
      exhaustMap(([action, state]) => {
        return this.messagesApiService.changeGroupStatus(action.group).pipe(
          // todo check if cancel would work or not (state should be updated already) or update only one after request
          map(() => messagesActions.loadFirstPageOfGroups({})),
          catchError((err) => {
            console.warn('update group$ err', err);
            return of(messagesActions.cancel());
          }),
        );
      }),
    );
  });

  updateGroupReadStatus$ = createEffect(() => {
    return this.actions.pipe(
      ofType(messagesActions.updateMessagingGroupReadStatus),
      switchMap((action) => {
        return this.messagesApiService.updateGroupReadStatus(action.group, action.isRead).pipe(
          catchError((err) => {
            console.warn('update group$ err', err);
            return of(null);
          }),
          map((response) => {
            if (response) {
              return messagesActions.updateMessagingGroup({
                group: { id: action.group.id, is_read: response.is_read },
              });
            }
            return messagesActions.cancel();
          }),
        );
      }),
    );
  });

  deleteGroupMemberBackend$ = createEffect(() => {
    return this.actions.pipe(
      ofType(messagesActions.deleteGroupMemberFromBackend),
      withLatestFrom(this.store.select(messagingFeatureSelector)),
      exhaustMap(([action, state]) => {
        return this.messagesApiService.removeGroupMember(action.group, action.member).pipe(
          map(() => messagesActions.removeDiscussionMembers({ member: action.member })),
          catchError((err) => {
            console.warn('deleteGroupMemberBackend$ err', err);
            this.notif.showError(err.error.message);
            return of(messagesActions.cancel());
          }),
        );
      }),
    );
  });

  addGroupMemberBackend$ = createEffect(() => {
    return this.actions.pipe(
      ofType(messagesActions.addDiscussionMembersToBackend),
      withLatestFrom(this.store.select(messagingFeatureSelector)),
      exhaustMap(([action, state]) => {
        const members = [...state.discussionMembers];
        members.push(action.member);
        return this.messagesApiService.addGroupMember(action.group, members).pipe(
          map((group) => messagesActions.setDiscussionMembers({ members: group.members })),
          catchError((err) => {
            console.warn('addGroupMemberBackend$ err', err);
            this.notif.showError(err.error.message);
            return of(messagesActions.cancel());
          }),
        );
      }),
    );
  });

  filter$ = createEffect(() =>
    this.actions.pipe(
      ofType(messagesActions.filterMessages),
      // tap((action) => {this.store.dispatch()})
      withLatestFrom(this.store.select(messagingFeatureSelector)),
      switchMap(([action, state]) => {
        console.log(action);
        if (state.selectedView === MESSAGING_VIEWS.DISCUSSION_LIST) {
          const apiFilters = getGroupFilters({ ...action.filters, page: 0 });
          this.store.dispatch(
            setGroupFilters({
              filters: {
                page: 1,
                search: action.filters.search,
                discussionType: action.filters.discussionType,
              },
            }),
          );
          return this.messagesApiService
            .getMessagingGroups(apiFilters)
            .pipe(map((groups) => messagesActions.setGroups({ groups })));
        }

        if (state.selectedView === MESSAGING_VIEWS.DISCUSSION_VIEW) {
          const filters = getMessageFilters(action.filters);
          this.store.dispatch(setMessageFilters({ filters }));
          return this.messagesApiService.getGroupMessages(state.selectedGroupId, filters).pipe(
            map((groupMessages) => {
              // groupMessages.messages.sort((a, b) =>
              //   moment(a.updated_at).isBefore(b.updated_at) ? 1 : -1
              // );
              this.messagesStateService.filteredMessages.next({
                messages: groupMessages.messages,
                filters: { search: action.filters.search },
              });
              return messagesActions.setMessages({ groupMessagesAndDrafts: groupMessages });
            }),
          );
        }

        return of(messagesActions.cancel());
      }),
    ),
  );

  loadGroupFromMention$ = createEffect(() => {
    return this.actions.pipe(
      ofType(messagesActions.loadGroupFromMention),
      tap((action) => {
        this.store.dispatch(messagesActions.setIsLoading({ isLoading: true }));
        this.store.dispatch(setSelectedGroupId({ groupId: action.groupId }));
      }),
      mergeMap((action) => {
        return this.messagesApiService.getMessagingGroups({ group_id: action.groupId });
      }),
      map((groups: IMessagingGroup[]) => {
        if (!groups.length) {
          this.notif.showError('Could not find discussion!');
          this.store.dispatch(messagesActions.loadFirstPageOfGroups({}));
          return messagesActions.setMessagingView({ view: MESSAGING_VIEWS.DISCUSSION_LIST });
        }

        this.store.dispatch(loadMessages());

        return messagesActions.singleGroupLoaded({
          groups,
        });
      }),
    );
  });
}

const getGroupFilters = (inputFilters: IMessagingGroupFilters) => {
  const filter: IMessagingGroupApiFilters = {
    page: inputFilters?.page ? inputFilters.page + 1 : 1,
    perPage: inputFilters?.perPage ? inputFilters.perPage : MESSAGES_NOT_FIRST_PAGE_LENGTH,
  };
  if (inputFilters.search) {
    filter.search = inputFilters.search;
  }

  if (inputFilters.discussionType === DISCUSSION_FILTER_TYPE.ARCHIVED) {
    filter.status = DISCUSSION_TYPES.ARCHIVED;
  } else if (inputFilters.discussionType) {
    filter.type = discussionFilterTypeToDiscussionType[inputFilters.discussionType];
  }

  return filter;
};
//
// const getGroupApiFilters = (inputFilters: IMessagingGroupFilters) => {
//   const filters: IMessagingGroupApiFilters = {};
//
//
// };

const getMessageFilters = (action: IMessagingHeaderFilters) => {
  const filter: IMessageFilters = {};
  if (action.search) {
    filter.search = action.search;
  }
  return filter;
};
