<div class="interaction-bar-wrapper share-file-wrapper">
  <div class="interaction-bar-title text-capitalize">Share Link</div>

  <div class="interaction-bar-content">
    <ng-scrollbar class="std-scrollbar flex-1" appearance="native" orientation="vertical">
      <app-dropdown
        class="input"
        name="selectedRight"
        [options]="selectableRights"
        [(model)]="selectedRight"
        label="Share Rights"
        placeholder="Share Rights"
      >
      </app-dropdown>
    </ng-scrollbar>

    <button (click)="copyLink()" class="btn-save" mat-button>Copy Share Link</button>
  </div>
</div>
