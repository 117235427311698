<div class="bg-white rounded-md overlay-shadow p-2 w-[360px]">
  <div class="text-shade_1 font-semibold py-2 bottom-border flex items-center">
    <app-back-button-box *ngIf="selectedGLAccount" (clicked)="unselectAccount()"></app-back-button-box>
    <span class="text-sm">{{ selectedGLAccount ? selectedGLAccount.name : 'Please Select An Account Template' }}</span>
  </div>
  <ng-scrollbar
    visibility="visible"
    class="std-scrollbar !max-h-48 min-h-[192px] overflow-hidden"
    orientation="vertical"
    appearance="native"
  >
    <ng-container *ngIf="!selectedGLAccount">
      <div
        (click)="unlinkAccount()"
        *ngIf="hasTemplate"
        class="text-color_secondary font-medium text-xs py-2 bottom-border flex-1 pointer-cursor"
      >
        Unlink Template
      </div>
      <ng-container *ngFor="let template of glTemplates$ | async">
        <div
          *ngIf="!selectedGLAccount && !(template.id | isValidUUID)"
          class="text-shade_1 font-medium text-xs py-2 bottom-border"
        >
          <div class="w-full flex cursor-pointer" (click)="selectTemplate(template)">
            <span class="flex-1"> {{ template.name }} </span>
            <span class="icon-arrow-right-light text-color_secondary"></span>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div *ngIf="selectedGLAccount" class="w-full flex flex-col cursor-pointer">
      <ng-container *ngFor="let label of selectedGLAccount.labels">
        <div
          *ngIf="!(label.id | isValidUUID)"
          class="text-shade_1 font-medium text-xs py-2 bottom-border flex-1"
          (click)="selectOption(label)"
        >
          {{ label.name }}
        </div>
      </ng-container>
    </div>
  </ng-scrollbar>
</div>
