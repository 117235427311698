import { AfterViewInit, inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { IProperty } from '../../../store/properties/properties.interfaces';
import { Project } from '../../../pages/webapp/projects/projects.interface';
import {
  IReportingPayload,
  REPORTING_ALL_STATUSES,
  REPORTING_ALL_VALUES,
  REPORTING_SIDEBAR_TYPES,
  ReportingToggleFields,
} from './reporting.constants';
import { FiscalService } from '../../../services/fiscal.service';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import { ReportingService } from '../../../services/reporting.service';
import { NotificationsService } from '../../../services/notifications.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';

@Injectable()
export abstract class ReportingBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  abstract readonly PROPERTY_PAGE: number;
  abstract readonly PROJECTS_PAGE: number;
  abstract readonly MAX_PAGE: number;
  abstract reportFields: FormGroup<any>;
  // the type will be overwritten in the child classes
  abstract readonly reportingType: REPORTING_SIDEBAR_TYPES;
  allStatuses = [...REPORTING_ALL_STATUSES];

  private _projects: Project[] = [];
  set projects(value) {
    this._projects = value;
    this.allProjectIds = value.map((project) => project.id);
  }
  get projects() {
    return this._projects;
  }

  private _properties: IProperty[] = [];
  set properties(value) {
    if (value) {
      this._properties = value;
      this.propertiesToList = [...value];
      this.allPropertyIds = value.map((prop) => prop.id);
    }
  }
  get properties() {
    return this._properties;
  }

  propertiesToList: IProperty[] = [];
  allPropertyIds: number[] = [];
  allProjectIds: number[] = [];
  allServiceProviderIds: number[] = [];

  currentPage = 0;
  years: Array<{ key: number; value: number }> = [];
  propertySearch: string;
  projectSearch: string;
  isAllSelected: { [k in ReportingToggleFields]: boolean } = {
    project_status_ids: true,
    property_ids: false,
    project_ids: false,
    service_provider_ids: false,
  };
  // errors for each page
  errors: string[] = [null, null, null];

  submitDisabled = false;
  isLoading = false;
  destroyed$ = new Subject();

  /**
   * filter projects by status, property and search term
   */
  get filteredProjects(): Project[] {
    const propertyIds: number[] = this.reportFields.get('property_ids').value;
    const statuses: number[] = this.reportFields.get('project_status_ids').value;

    return this.projects
      .filter((project) => {
        // filter by property selection
        return propertyIds.includes(project.property_id);
      })
      .filter((project) => {
        // filter by status selection
        return statuses.includes(project.project_status.id);
      })
      .filter(
        // filter by search term
        (project) =>
          !this.projectSearch ||
          project?.title?.toLowerCase()?.includes(this.projectSearch?.toLowerCase()) ||
          project?.project_property?.formatted_address
            ?.toLowerCase()
            ?.includes(this.projectSearch?.toLowerCase()),
      );
  }

  /**
   * Filter projects by status and property, but not taking into account the search term.
   * This is needed to correctly set the "all selected" checkbox for projects when the user used the search bar.
   */
  get filteredProjectsWithoutSearchTerm(): Project[] {
    const propertyIds: number[] = this.reportFields.get('property_ids').value;
    const statuses: number[] = this.reportFields.get('project_status_ids').value;

    const filtered = this.projects
      .filter((project) => {
        // filter by property selection
        return propertyIds.includes(project.property_id);
      })
      .filter((project) => {
        // filter by status selection
        return statuses.includes(project.project_status.id);
      });
    return filtered;
  }

  get filteredProjectIdsWithoutSearchTerm(): number[] {
    return this.filteredProjectsWithoutSearchTerm.map((project) => project.id);
  }

  get nextDisabled() {
    return !!this.errors?.[this.currentPage] ?? true;
  }

  get errorMessage() {
    return this.errors?.[this.currentPage] ?? '';
  }

  protected fiscalService = inject(FiscalService);
  protected interactionBar = inject(InteractionBarStateService);
  protected reportingService = inject(ReportingService);
  protected notif = inject(NotificationsService);

  abstract incrementPage(): void;
  abstract checkOverallValidation(): void;
  abstract convertFormToPayload(): IReportingPayload;
  abstract checkStatusPageValidation(): boolean;

  ngOnInit(): void {
    this.fiscalService.fiscalYear$.pipe(takeUntil(this.destroyed$)).subscribe((fy) => {
      this.reportFields.get('start_year').setValue(fy);
      this.reportFields.get('end_year').setValue(fy);
    });

    // check the validation for the first page
    if (this.reportingType === REPORTING_SIDEBAR_TYPES.FULL_REPORT) {
      this.checkStatusPageValidation();
    } else if (this.reportingType === REPORTING_SIDEBAR_TYPES.INVOICE_LOG) {
      this.checkPropertyValidation();
    }
  }

  ngAfterViewInit(): void {
    this.reportFields.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.checkOverallValidation();
    });
  }

  decrementPage() {
    if (this.currentPage === 0) {
      return;
    }
    this.currentPage--;
  }

  sortProperties = (properties: any[]) => {
    return properties.sort((a, b) => {
      return a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase());
    });
  };

  sortProjects = (projects: any[]) => {
    return projects.sort((a, b) => {
      return a?.title?.toLowerCase()?.localeCompare(b?.title?.toLowerCase());
    });
  };

  /**
   * Toggles the "all selected" option for an entity (status, property, project or service provider).
   * @param fieldName
   * @param allValues
   */
  toggleAllValues(fieldName: ReportingToggleFields, allValues: number[]) {
    if (this.isAllSelected[fieldName]) {
      // if the ALL option is already selected, empty the list
      this.uncheckProjectsOfAProperty(fieldName, REPORTING_ALL_VALUES);
      this.reportFields.get(fieldName).setValue([]);
    } else {
      // if the ALL option is NOT selected, select all values
      this.reportFields.get(fieldName).setValue([...allValues]);
    }

    this.isAllSelected[fieldName] = !this.isAllSelected[fieldName];
  }

  /**
   * Toggles one specific value's selection of an entity (status, property, project or service provider).
   * It updates the "isAllSelected" flag accordingly.
   * @param fieldName
   * @param newValue
   * @param allValues
   */
  toggleSpecificValues(fieldName: ReportingToggleFields, newValue: number, allValues: number[]) {
    if (!this.reportFields.get(fieldName)) {
      console.warn('Field to toggle not found', fieldName);
      return;
    }
    const currentlySelectedIds: number[] = this.reportFields.get(fieldName).value;
    let updatedValues = [];
    if (currentlySelectedIds.includes(newValue)) {
      // if the id is selected, remove from the list
      updatedValues = currentlySelectedIds.filter((current) => current !== newValue);
      this.reportFields.get(fieldName).setValue(updatedValues);
      this.uncheckProjectsOfAProperty(fieldName, newValue);
    } else {
      // if the id is not selected, add to the list
      updatedValues = [...currentlySelectedIds, newValue];
      this.reportFields.get(fieldName).setValue(updatedValues);
    }

    this.isAllSelected[fieldName] = updatedValues.length === allValues.length;
  }

  /**
   * Updates all values from the isAllSelected object accordingly.
   * This function should be called when incrementing a page, because the user
   * can select one property and select all of its projects, then go to the properties page
   * and select more properties and then go back to the projects page where more projects appeared.
   */
  updateAllSelected() {
    this.isAllSelected.project_status_ids =
      this.reportFields.get('project_status_ids')?.value?.length === this.allStatuses.length;

    this.isAllSelected.property_ids =
      this.reportFields.get('property_ids')?.value?.length === this.allPropertyIds.length;

    this.isAllSelected.project_ids =
      this.reportFields.get('project_ids')?.value?.length ===
      this.filteredProjectIdsWithoutSearchTerm.length;

    this.isAllSelected.service_provider_ids =
      this.reportFields.get('service_provider_ids')?.value?.length ===
      this.allServiceProviderIds.length;
  }

  /**
   * if a property is unchecked, uncheck all projects of that property
   * (it can happen if you selected some projects and go back to properties page and unchecked a property)
   * @param fieldName
   * @param selectedPropertyId
   */
  uncheckProjectsOfAProperty(
    fieldName: ReportingToggleFields,
    selectedPropertyId: number | typeof REPORTING_ALL_VALUES,
  ) {
    if (fieldName !== 'property_ids') {
      return;
    }

    const selectedProjects: number[] = this.reportFields.get('project_ids').value;

    const projectsIdsToUncheck: number[] = this.projects
      .filter(
        (project) =>
          project.property_id === selectedPropertyId || selectedPropertyId === REPORTING_ALL_VALUES,
      )
      .map((project) => project.id);

    const projectsNewValue = selectedProjects.filter(
      (projectId) => !projectsIdsToUncheck.includes(projectId),
    );

    this.reportFields.get('project_ids').setValue(projectsNewValue);
  }

  toggleProject(projectId: number) {
    this.toggleSpecificValues(
      'project_ids',
      projectId,
      this.filteredProjectsWithoutSearchTerm.map((project) => project.id),
    );
  }
  toggleProperty(propertyId: number) {
    this.toggleSpecificValues('property_ids', propertyId, this.allPropertyIds);
  }

  propertySearchChanged(searchTerm: string) {
    if (!searchTerm) {
      this.propertiesToList = [...this.properties];
    }
    this.propertiesToList = this.properties.filter(
      (prop) =>
        prop?.name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        prop?.formatted_address?.toLowerCase()?.includes(searchTerm.toLowerCase()),
    );
  }

  onProjectIconClick() {
    if (this.projectSearch) {
      this.projectSearch = '';
    }
  }

  onPropertyIconClick() {
    if (this.propertySearch) {
      this.propertySearch = '';
      this.propertySearchChanged(this.propertySearch);
    }
  }

  checkPropertyValidation() {
    this.errors[this.PROPERTY_PAGE] = null;
    const propertyIds = this.reportFields.get('property_ids').value;
    if (propertyIds.length === 0) {
      this.errors[this.PROPERTY_PAGE] = 'At least one property is required.';
    }
    return !this.errors[this.PROPERTY_PAGE];
  }

  checkProjectValidation() {
    this.errors[this.PROJECTS_PAGE] = null;
    const projectIds = this.reportFields.get('project_ids').value;
    if (projectIds.length === 0) {
      this.errors[this.PROJECTS_PAGE] = 'At least one project is required.';
    }
    return !this.errors[this.PROJECTS_PAGE];
  }

  async submit() {
    if (this.reportFields.valid && !this.submitDisabled) {
      const data = this.convertFormToPayload();

      try {
        const response = await this.reportingService.generateReportOnServer(data);
        setTimeout(() => {
          this.notif.showSuccess(
            response?.message ||
              "The report is being generated. You will get notified when it's ready.",
          );
        }, 100);
        this.interactionBar.close();
      } catch (err) {
        console.warn('reporting error', err);
        this.notif.showError(err?.error?.message ?? 'An error happened.');
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
