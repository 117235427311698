import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DateCustomPipe, MMMDDYYYY, UTC_DATE } from '../../pipes/framework/date-custom.pipe';
import { CurrentUserService } from '../../services/current-user.service';
import moment from 'moment/moment';
import { OPTIONS } from '../constants/options-list.constants';
import { takeUntil, throttleTime } from 'rxjs/operators';
import {
  DynamicTableData,
  DynamicTableDataKeys,
  DynamicTableDataType,
  DynamicTableHeaders,
  DynamicTableRowData,
  DynamicTableRowStyle,
  TableScrollEvent,
} from '../constants/dynamic-table.constants';
import _ from 'lodash';
import { NgScrollbar } from 'ngx-scrollbar';
import { fromEvent, Subject } from 'rxjs';
import { MoneyPipe } from '../../pipes/framework/money-short.pipe';
import { UnStyledOptionsListComponent } from '../overlays/un-styled-options-list/un-styled-options-list.component';
import { FadedTextComponent } from '../faded-text/faded-text.component';
import { MatProgressBar } from '@angular/material/progress-bar';
import { SortArrowComponent } from '../sort-arrow/sort-arrow.component';
import { MatTooltip } from '@angular/material/tooltip';
import {
  NgClass,
  NgFor,
  NgIf,
  NgStyle,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgTemplateOutlet,
} from '@angular/common';

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
  standalone: true,
  imports: [
    NgStyle,
    NgFor,
    NgSwitch,
    NgSwitchDefault,
    MatTooltip,
    SortArrowComponent,
    NgIf,
    MatProgressBar,
    NgScrollbar,
    NgClass,
    NgSwitchCase,
    FadedTextComponent,
    UnStyledOptionsListComponent,
    NgTemplateOutlet,
    MoneyPipe,
    DateCustomPipe,
  ],
})
export class DynamicTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() tableHeaders: DynamicTableHeaders;
  private _tableData: DynamicTableData;
  private lastScrollPosition = 0;
  @Input() set tableData(value: DynamicTableData) {
    this._tableData = value;
    if (this.sortByKey) {
      this.sortBy(this.sortByKey, false);
    }
  }
  get tableData(): DynamicTableData {
    return this._tableData;
  }

  @Input() rowStyle: DynamicTableRowStyle;
  @Input() dataKeys: DynamicTableDataKeys = [];
  @Input() dataTypes: DynamicTableDataType;
  @Input() totalsKeys: DynamicTableDataKeys = [];
  @Input() totalsData: DynamicTableRowData;
  @Input() isLoading = false;
  @Input() sortByKey: string;
  @Input() optionsList = [OPTIONS.EDIT, OPTIONS.DELETE];

  @Input() projectTags: string[] = ['priority', 'approved'];
  @Input() separatorClass: string = '';
  @Input() tableClass: string = '';

  @Output() selectedTag = new EventEmitter<string[]>();
  @Output() statusChanged = new EventEmitter<number[]>();
  @Output() openRollupSideBar = new EventEmitter<void>();
  @Output() selectedOption = new EventEmitter<{ event: OPTIONS; data: any }>();
  @Output() reload = new EventEmitter<void>();
  @Output() projectWorkProgressChanged = new EventEmitter<{
    projectId: number;
    work_percentage: number;
  }>();
  @Output() onScroll = new EventEmitter<TableScrollEvent>();

  // this array contains the last sort order for the headers
  lastSortedAscending: boolean[] = [];
  // last sorted by (header index); -1 => no sort applied
  lastSortedBy = -1;
  UTC_DATE = UTC_DATE;
  MMMDDYYYY = MMMDDYYYY;

  @ViewChild('scrollbar') scrollbar: NgScrollbar;

  isDestroyed$ = new Subject();

  constructor(
    public user: CurrentUserService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {}

  ngOnInit() {
    this.lastSortedAscending = Array(this.dataKeys.length).fill(true);
  }

  ngAfterViewInit() {
    this.handleScrollEvents();
  }

  handleScrollEvents() {
    // scrolledToBottom is a wrapper for the scroll event to throttle emitions
    const scrolledToBottom = new Subject<TableScrollEvent>();
    scrolledToBottom.pipe(takeUntil(this.isDestroyed$), throttleTime(1000)).subscribe((value) => {
      this.onScroll.emit(value);
    });

    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.scrollbar.nativeElement, 'scroll')
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe((event) => {
          this.onScroll.emit({ scrollEvent: event, scrolledToBottom: false });
          const target = event.target as HTMLElement;

          if (this.lastScrollPosition < target.scrollTop) {
            // if user scrolled downwards
            if (target.offsetHeight + target.scrollTop >= target.scrollHeight * 0.8) {
              // if user is in the bottom part (80%-100%) of the scrollbar track
              scrolledToBottom.next({ scrollEvent: event, scrolledToBottom: true });
            }
          }
          this.lastScrollPosition = target.scrollTop;
        });
    });
  }

  /**
   * Sorts the table by the given key.
   * @param key the object index from headers array
   * @param changeNextSortOrder should the next sort order be changed
   */
  sortBy(key: string, changeNextSortOrder = true) {
    if (!key) {
      return;
    }
    if (key === 'attributes' || key === 'status_name') {
      // don't sort by attributes and status
      return;
    }

    const index = this.dataKeys.findIndex((value) => value === key);
    if (index < 0) {
      console.warn('wrong sort index');
    }
    const computedKeys = ['line_items_name', 'accounts_name', 'tags_name'];
    if (computedKeys.includes(key)) {
      key = 'name';
    }

    if (key.startsWith('template_')) {
      key = key + '.context.$implicit.name';
    }

    if (key === 'complete_work_percentage') {
      key = 'complete';
    }

    this.lastSortedBy = index;
    let sortDescending: boolean;
    if (changeNextSortOrder) {
      sortDescending = this.lastSortedAscending[index];
      this.lastSortedAscending[index] = !this.lastSortedAscending[index];
    } else {
      sortDescending = !this.lastSortedAscending[index];
    }

    const order = sortDescending ? -1 : +1; // a multiplier for sort order

    // provide different sort functions for different types
    let sortFunc: (a, b) => number;
    if (this.dataTypes[key]?.startsWith('date')) {
      sortFunc = (a, b) => {
        if (moment(_.get(a, key)).isBefore(moment(_.get(b, key)))) {
          return -1 * order;
        }
        return +1 * order;
      };
    } else if (typeof _.get(this.tableData, '[0].' + key) === 'string') {
      sortFunc = (a, b) => {
        const aValue = _.get(a, key);
        const bValue = _.get(b, key);
        if (!aValue) {
          return -1;
        }
        if (!bValue) {
          return 1;
        }

        // converting to string because we can get errors if data is malformed
        // and some values are strings but some are not
        return String(aValue)?.localeCompare(bValue) * order;
      };
    } else {
      // defaulting to number, but comparing with '<' may work for other types as well
      sortFunc = (a, b) => {
        if (_.get(a, key) < _.get(b, key)) {
          return -1 * order;
        }
        return +1 * order;
      };
    }

    this.tableData?.sort(sortFunc);
  }

  isNumber(value: any) {
    // todo create a directive
    return typeof value === 'number';
  }

  // todo: move outside / more like wait for a refactor then delete it
  onOptionSelected(event: OPTIONS, data: any) {
    this.selectedOption.emit({ event, data });
  }

  scrollbarUpdated() {
    this.cdr.detectChanges(); // against ExpressionChangedAfterItHasBeenCheckedError
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
