import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAll, spendFeatureKey, SpendState } from './spend.reducer';
import {
  IBudget,
  ICommittedItem,
  IForecastBudget,
  ILastSpendStoreUpdate,
  ILineItem,
  ILineItemExtended,
  ILineItemsTotal,
  IMonthlySpendData,
  SpendStoreUpdateTypes,
} from './spend.interfaces';
import {
  defaultMonthlyData,
  defaultMonthlyDisable,
} from '../../framework/constants/spend.constants';
import { FiscalService } from '../../services/fiscal.service';
import { SPEND_TYPES } from '../../framework/constants/budget.constants';

export const spendFeatureSelector = createFeatureSelector<SpendState>(spendFeatureKey);

export const getAllLineItemData = createSelector(spendFeatureSelector, selectAll);

export const getSelectedYear = createSelector(spendFeatureSelector, (state) => state.selectedYear);

export const getSpendDistributionState = createSelector(
  spendFeatureSelector,
  (state) => state.spendDistributionState,
);

export const getModifiedLineItems = createSelector(
  spendFeatureSelector,
  (state) =>
    ({
      modifiedLineItems: state.modifiedLineItems,
      newLineItems: state.newLineItems,
      deletedLineItems: state.deletedLineItems,
    }) as Partial<SpendState>,
);

export const getProjectStartDate = createSelector(
  spendFeatureSelector,
  (state) => state.projectStartDate,
);
export const getSelectedSpendType = createSelector(
  spendFeatureSelector,
  (state) => state.selectedSpendType,
);
export const getIsLoading = createSelector(spendFeatureSelector, (state) => state.isLoading);

export const getLineItemById = (id) =>
  createSelector(getAllLineItemData, (items) => {
    return items.find((item) => item.id === id);
  });

export const projectHasBudget = createSelector(getAllLineItemData, (lineItems) => {
  return lineItems.length > 0;
});

export const getLineItemsExtended = createSelector(
  getAllLineItemData,
  getSelectedSpendType,
  getSelectedYear,
  (lineItems, selectedType, selectedYear) => {
    const lineItemsSorted = lineItems.toSorted((a, b) => a.row_number - b.row_number);
    return lineItemsSorted.map((item) => {
      const lineItem = getLineItemExtended(item, selectedYear);
      lineItem.committed_items = lineItem.committed_items.sort(
        (a, b) => a.committed_row_number - b.committed_row_number,
      );
      return lineItem;
    });
  },
);

export const getAllCommittedItems = createSelector(getLineItemsExtended, (items) => {
  return items
    .flatMap((item) =>
      item.committed_items.map((committedItem) => ({ ...committedItem, lineItem: item })),
    )
    .sort((a, b) => a.row_number - b.row_number);
});

const getMonthlyDataForYear = (budget: IBudget[], selectedYear: number): IMonthlySpendData => {
  const yearData = budget?.find((bud) => bud.year === selectedYear);
  return yearData?.monthly_budget ?? { ...defaultMonthlyData };
};

const getMonthlyForecastForYear = (
  budget: IForecastBudget[],
  selectedYear: number,
): IMonthlySpendData => {
  const yearData = budget?.find((bud) => bud.year === selectedYear);
  return yearData?.monthly_forecast ?? { ...defaultMonthlyData };
};

export const getForecastProjectTotalPerItem = (item: ILineItem | ICommittedItem) => {
  return item.budget
    ?.map((bud) => bud?.monthly_forecast ?? { ...defaultMonthlyData })
    ?.reduce(
      (total, monthlySpendData) =>
        total +
        Object.values(monthlySpendData).reduce(
          (monthlyTotal: number, value: number) => monthlyTotal + value,
          0,
        ),
      0,
    );
};

const yearlyTotal = (monthlyData: IMonthlySpendData) => {
  return monthlyData ? Object.values(monthlyData).reduce((acc, curr) => acc + curr, 0) : 0;
};

export const projectTotalPerItem = (item: ILineItem | ICommittedItem) => {
  return item.budget
    ?.map((bud) => bud?.monthly_budget ?? { ...defaultMonthlyData })
    ?.reduce(
      (total, monthlySpendData) =>
        total +
        Object.values(monthlySpendData).reduce(
          (monthlyTotal: number, value: number) => monthlyTotal + value,
          0,
        ),
      0,
    );
};

const getLineItemExtended = (item: ILineItem, selectedYear: number) => {
  const monthlyData: IMonthlySpendData = getMonthlyDataForYear(item.budget, selectedYear);
  const yearTotal = yearlyTotal(monthlyData);
  const projectTotal = projectTotalPerItem(item);
  const lineItem: ILineItemExtended = {
    ...item,
    year_total: yearTotal,
    monthly_data: monthlyData,
    monthly_disable: { ...defaultMonthlyDisable },
    project_total: projectTotal,
    committed_items: item.committed_items.map((committedItem) => {
      const commitedMonthlyData: IMonthlySpendData = getMonthlyForecastForYear(
        committedItem.budget,
        selectedYear,
      );
      return {
        ...committedItem,
        monthly_data: commitedMonthlyData,
        monthly_disable: { ...defaultMonthlyDisable },
        year_total: yearlyTotal(commitedMonthlyData),
        project_total: getForecastProjectTotalPerItem(committedItem),
      };
    }),
  };

  return lineItem;
};

export const getLineItemTotals = createSelector(
  getLineItemsExtended,
  getSelectedSpendType,
  getSelectedYear,
  (lineItems, selectedSpendType, selectedYear) => {
    const total: ILineItemsTotal = {
      project_total: 0,
      monthly_data: { ...defaultMonthlyData },
      year_total: 0,
    };
    lineItems.forEach((item) => {
      if (selectedSpendType === SPEND_TYPES.COMMITMENTS) {
        item.committed_items.forEach((committedItem) => {
          total.project_total += committedItem.project_total ? committedItem.project_total : 0;
          total.year_total += committedItem.year_total ? committedItem.year_total : 0;
          Object.keys(total.monthly_data).forEach((monthKey) => {
            total.monthly_data[monthKey] += committedItem.monthly_data?.[monthKey]
              ? committedItem.monthly_data[monthKey]
              : 0;
          });
        });
      } else {
        total.project_total += item.project_total ? item.project_total : 0;
        total.year_total += item.year_total ? item.year_total : 0;
        Object.keys(total.monthly_data).forEach((monthKey) => {
          total.monthly_data[monthKey] += item.monthly_data?.[monthKey]
            ? item.monthly_data[monthKey]
            : 0;
        });
      }
    });
    return total;
  },
);

// return the newly added years which are not be present in line item budget data
export const getNewYears = createSelector(spendFeatureSelector, (state) => state.newYears);

// returns all the years that can be selected in the dropdown
export const getSelectableYears = createSelector(
  getAllLineItemData,
  getNewYears,
  (lineItems, newYears) => {
    return getAllPossibleYearsFunction(lineItems, newYears);
  },
);

const getLastStoreUpdate = createSelector(spendFeatureSelector, (state) => state.lastStoreUpdate);

export const getNextOrder = createSelector(getAllLineItemData, (lineItems): number => {
  if (!lineItems.length) {
    return 1;
  }

  const maxOrder = Math.max(...lineItems.map((item) => item?.row_number ?? 0));
  return maxOrder + 1;
});

export const getStoreUpdates = createSelector(
  getLineItemsExtended, // todo: it should not recalculate everything
  getLastStoreUpdate,
  (filteredLineItems, update): ILastSpendStoreUpdate => {
    let lineItems: ILineItemExtended[] = [];
    if (
      update.type === SpendStoreUpdateTypes.SET_ALL ||
      update.type === SpendStoreUpdateTypes.FILTER_CHANGE
    ) {
      lineItems = filteredLineItems;
    } else if (
      update.type === SpendStoreUpdateTypes.DELETE ||
      update.type === SpendStoreUpdateTypes.NONE
    ) {
      lineItems = [];
    } else {
      const item = filteredLineItems.find((filtered) => filtered.id === update.lineId);
      lineItems.push(item);
    }

    return {
      lastStoreUpdate: update,
      lineItems,
    };
  },
);

// mixes together the years from line items and years added added by the user
// returns an ascending continuous array (no gaps between years)
export const getAllPossibleYearsFunction = (lineItems: ILineItem[], newYears: number[]) => {
  const possibleYears = getLineItemPossibleYearsFunction(lineItems);
  if (possibleYears?.[0] === undefined) {
    return [];
  }
  const allYears = possibleYears.concat(newYears);
  const minYear = allYears.reduce((acc, value) => (acc > value ? value : acc), allYears[0]);
  const maxYear = allYears.reduce((acc, value) => (acc < value ? value : acc), allYears[0]);
  return new Array(maxYear - minYear + 1).fill(0).map((__, index) => minYear + index);
};

// returns the years present in lineItem.budget
export const getLineItemPossibleYearsFunction = (lineItems: ILineItem[]) => {
  if (lineItems.length === 0) {
    return [FiscalService.fiscalYear];
  }
  const allYears = lineItems
    .map((item) => item.budget.map((budget) => budget.year))
    .reduce((acc, curr) => {
      acc.push(...curr);
      return acc;
    }, []);

  return [...new Set(allYears)].sort((a, b) => a - b);
};

/**
 * @deprecated - we use the hasCommitments selector instead for disabling things
 */
export const getDisableBasedOnTemplate = createSelector(spendFeatureSelector, (state) => {
  return state.hasTemplates;
});

export const getSpendLineItemSummary = createSelector(spendFeatureSelector, (state) => {
  return state.spendLineItemSummary;
});

export const hasCommitments = createSelector(getAllLineItemData, (lineItems) => {
  return lineItems.some((lineItem) => !!lineItem.commitment_start_date);
});
