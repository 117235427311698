import { Component, ElementRef, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { delay, filter, tap } from 'rxjs/operators';
import { NotificationsService } from './services/notifications.service';
import { combineLatest, interval } from 'rxjs';
import { IntercomService } from './services/intercom.service';
import { CurrentUserService } from './services/current-user.service';
import { RouterOutlet } from '@angular/router';
import { NotificationComponent } from './framework/notification/notification.component';
import { INTERACTION_BAR_STATES } from './framework/constants/interaction-bar.constants';
import { InteractionBarStateService } from './services/interaction-bar-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NotificationComponent, RouterOutlet],
})
export class AppComponent implements OnInit {
  title = 'Skillhop';

  constructor(
    public el: ElementRef,
    private user: CurrentUserService,
    private swUpdate: SwUpdate,
    private notif: NotificationsService,
    private intercomService: IntercomService,
    private interactionBarState: InteractionBarStateService,
  ) {}

  ngOnInit() {
    this.intercomService.bootIntercom();
    this.loadScript();

    if (this.swUpdate.isEnabled) {
      const minute = 1000 * 60; // 1 minute in ms
      interval(0.5 * minute).subscribe(() => this.swUpdate.checkForUpdate());
    }

    const serviceWorkerSource$ = this.swUpdate.versionUpdates.pipe(
      tap((event) => {
        if (event.type === 'VERSION_INSTALLATION_FAILED') {
          this.notif.showError(
            "Auto-update failed. There's a new version available, please refresh the page or open it in a new tab.",
          );
        }
      }),
      filter((evt) => evt.type === 'VERSION_READY'),
    );

    // Automatically reload the page if there's no sidebar open.
    // When there's an update it should wait until a sidebar closes  to call the refresh.
    // See readme for more info
    combineLatest([this.interactionBarState.announceState, serviceWorkerSource$])
      .pipe(
        filter(([state, _]) =>
          [
            INTERACTION_BAR_STATES.NONE,
            INTERACTION_BAR_STATES.CLOSE,
            INTERACTION_BAR_STATES.SUCCESS_CLOSED,
          ].includes(state.action),
        ),
        tap(([_, __]) => {
          this.notif.showLoading('New version available, reloading...');
        }),
        delay(2000),
      )
      .subscribe(([_, __]) => {
        this.swUpdate.activateUpdate().then(() => document.location.reload());
      });
  }

  private loadScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid: ${environment.hotjar_key},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(document.createComment('Hotjar Tracking Code'));
      document.head.appendChild(script);
    }
  }
}
