@use 'src/styles' as s;
//::ng-deep {
//  .e-richtexteditor {
//    .e-rte-placeholder {
//      /* placeholder style */
//      font-family: monospace;
//      color: deeppink;
//    }
//
//  }
//}

.messages.e-richtexteditor {
  border-left: none !important;
  border-right: none !important;
  border-color: s.$shade_5 !important;
  .e-toolbar {
    background: rgba(white, 0);
    .e-toolbar-items {
      background: rgba(white, 0);
      button {
        background: rgba(white, 0);
        .e-btn-icon {
          color: s.$shade_1;
        }
      }
      .e-toolbar-item.e-separator {
        min-height: 14px;
      }
    }
  }
  .e-rte-content {
    border-bottom: none !important;
    border-color: s.$shade_5 !important;
    .e-content,
    .e-rte-placeholder {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .rte-placeholder.e-rte-placeholder {
    color: s.$shade_3;
    opacity: 1;
  }

  .icon-attachments-chain {
    margin: 0 auto;
    font-size: s.$font_size_xxl;
    color: s.$shade_1;
  }

  .icon-upload {
    margin: 0 auto;
    font-size: s.$font_size_xxl;
    color: s.$shade_1;
  }

  .custom-hashtag-icon {
    margin: 0 auto;
    color: s.$shade_1;
    font-size: s.$font_size_icon;
  }
}

.hide-placeholder.e-richtexteditor {
  .rte-placeholder.e-rte-placeholder {
    color: transparent;
    opacity: 0;
  }
}
