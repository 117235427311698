import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { DefaultOptionsListComponent } from '../../default-options-list/default-options-list.component';
import { IMessagingMember, MESSAGING_VIEWS } from '../../../store/messages/messages.interfaces';
import { CurrentUserService } from '../../../services/current-user.service';
import { getMessagingView } from '../../../store/messages/messages.selectors';
import { AppState } from '../../../store/app-state';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-messaging-users-table',
  templateUrl: './messaging-users-table.component.html',
  styleUrls: ['./messaging-users-table.component.scss'],
  standalone: true,
  imports: [CommonModule, NgScrollbar],
})
export class MessagingUsersTableComponent extends DefaultOptionsListComponent implements OnInit {
  @Input() usersRelated: IMessagingMember[];
  @Input() discussionMembers: IMessagingMember[];
  @Output() closeDropdown = new EventEmitter();

  selectedView$ = this.store.select(getMessagingView);
  MESSAGING_VIEWS = MESSAGING_VIEWS;

  constructor(
    private userService: CurrentUserService,
    private store: Store<AppState>,
  ) {
    super();
  }

  ngOnInit(): void {}

  isSelected(user: IMessagingMember) {
    return !!this.discussionMembers.find((selectedUser) => selectedUser.user_id === user.user_id);
  }

  /**
   * Handling close and open events is done by its associated directive.
   * Component only detects if the user interaction is inside the component or not.
   */
  @HostListener('document:click')
  onClick(ev) {
    this.closeDropdown.emit(true);
  }

  isUserAdmin() {
    return this.discussionMembers.find(
      (selected) => selected.user_id === String(this.userService.data.id) && selected.is_admin,
    );
  }

  isSelfUser(user: IMessagingMember) {
    return user.user_id === String(this.userService.data.id);
  }

  isAddedByCurrentUser(user: IMessagingMember): boolean {
    if (!user.added_by) {
      return false;
    }
    return this.isSelfUser(user.added_by as IMessagingMember);
  }
}
