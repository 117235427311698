import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { InteractionBarStateService } from '../../services/interaction-bar-state.service';
import {
  INTERACTION_BAR_STATES,
  slightlyWiderPages,
  widerPages,
} from '../constants/interaction-bar.constants';
import { SelectProjectComponent } from './select-project/select-project.component';
import { WebappComponent } from '../../pages/webapp/webapp.component';
import { MatDrawer, MatDrawerContainer, MatSidenav } from '@angular/material/sidenav';
import { Subject, Subscription } from 'rxjs';
import { NotificationsService } from '../../services/notifications.service';
import { CurrentUserService } from '../../services/current-user.service';
import {
  discardMessagingGroupCache,
  setMessagingView,
} from '../../store/messages/messages.actions';
import { MESSAGING_VIEWS } from '../../store/messages/messages.interfaces';
import { AppState } from '../../store/app-state';
import { Store } from '@ngrx/store';
import { HandleInteractionBarPositionPipe } from '../../pipes/framework/handle-interaction-bar-position.pipe';
import { ManageProjectComponent } from '../../pages/webapp/projects/view-project/manage-project/manage-project.component';
import { ShareDocumentLinkComponent } from './share-document-link/share-document-link.component';
import { ShareDocumentComponent } from './share-document/share-document.component';
import { AnticipatedCostsComponent } from '../anticipated-costs/anticipated-costs.component';
import { BudgetAdjustmentLogComponent } from './budget-adjustment-log/budget-adjustment-log.component';
import { BudgetAdjustmentComponent } from '../budget-adjustment/budget-adjustment.component';
import { AddPropertyComponent } from './add-property/add-property.component';
import { ReportingBarComponent } from './reporting/reporting-bar.component';
import { ActivitySidebarComponent } from '../activity-sidebar/activity-sidebar.component';
import { TrackItemSidebarComponent } from './track-item-sidebar/track-item-sidebar.component';
import { MiscCostSidebarComponent } from '../commitments/sidebar/misc-cost-sidebar/misc-cost-sidebar.component';
import { InvoiceSidebarComponent } from '../commitments/sidebar/invoice-sidebar/invoice-sidebar.component';
import { ChangeOrderSidebarComponent } from '../commitments/sidebar/change-order-siderbar/change-order-sidebar.component';
import { ContractSidebarComponent } from '../commitments/sidebar/contract-sidebar/contract-sidebar.component';
import { SharedTeamPropProjSidebarComponent } from '../teams/team-prop-proj-sidebar/shared-team-prop-proj-sidebar.component';
import { ManageTeamMembersSidebarComponent } from '../teams/manage-team-members-sidebar/manage-team-members-sidebar.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { MessagesWrapperComponent } from '../messages/messages-wrapper/messages-wrapper.component';
import { LineItemSpendSummaryComponent } from '../line-item-spend-summary/line-item-spend-summary.component';
import { RollupsInteractionBarComponent } from '../../pages/webapp/rollups/rollups-interaction-bar/rollups-interaction-bar.component';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { skip, takeUntil } from 'rxjs/operators';
import { ProjectSetupComponent } from './project-setup/project-setup.component';
import { InvoiceReportPanelComponent } from './invoice-report-panel/invoice-report-panel.component';

@Component({
  selector: 'app-interaction-bar',
  templateUrl: './interaction-bar.component.html',
  styleUrls: ['./interaction-bar.component.scss'],
  standalone: true,
  imports: [
    MatDrawerContainer,
    MatDrawer,
    NgClass,
    NgIf,
    RollupsInteractionBarComponent,
    LineItemSpendSummaryComponent,
    MessagesWrapperComponent,
    NotificationsComponent,
    ManageTeamMembersSidebarComponent,
    SharedTeamPropProjSidebarComponent,
    ContractSidebarComponent,
    ChangeOrderSidebarComponent,
    InvoiceSidebarComponent,
    MiscCostSidebarComponent,
    TrackItemSidebarComponent,
    ActivitySidebarComponent,
    SelectProjectComponent,
    ReportingBarComponent,
    AddPropertyComponent,
    BudgetAdjustmentComponent,
    BudgetAdjustmentLogComponent,
    AnticipatedCostsComponent,
    ShareDocumentComponent,
    ShareDocumentLinkComponent,
    ManageProjectComponent,
    AsyncPipe,
    HandleInteractionBarPositionPipe,
    ProjectSetupComponent,
    InvoiceReportPanelComponent,
  ],
})
export class InteractionBarComponent implements OnInit, OnDestroy {
  @ViewChild('drawer', { static: true }) drawer: MatSidenav;
  @ViewChild('projectInvite') projectInvites: SelectProjectComponent;

  isEdit = false;
  drawerState: INTERACTION_BAR_STATES | string = null;
  drawerData: any;
  disableClose = false;
  isOpened = false;
  INTERACTION_BAR_STATE = INTERACTION_BAR_STATES;
  clickSubscription: Subscription;
  closeSubscription: Subscription;
  isPopupOpen = false;
  widerPages = widerPages;
  slightlyWiderPages = slightlyWiderPages;
  isDestroyed$ = new Subject();

  constructor(
    private el: ElementRef,
    private barState: InteractionBarStateService,
    private webApp: WebappComponent,
    private notif: NotificationsService,
    public user: CurrentUserService,
    private cdr: ChangeDetectorRef,
    private store: Store<AppState>,
  ) {
    this.registerBarStateChange();
  }

  ngOnInit() {
    this.drawer.closedStart.subscribe((data) => {
      this.barState.successClose();
    });

    this.clickSubscription = InteractionBarStateService.clickedEvents.subscribe((ev) => {
      this.openDrawer(ev);
    });

    this.closeSubscription = InteractionBarStateService.isBarCloseable.subscribe((isCloseable) => {
      this.disableClose = isCloseable;
      this.cdr.detectChanges();
    });
  }

  open() {
    this.drawer.open();
    this.isOpened = true;
    this.disableClose = false;
  }

  close() {
    this.disableClose = false;
    this.drawer.close();
    this.isOpened = false;
    this.store.dispatch(discardMessagingGroupCache());
    this.store.dispatch(setMessagingView({ view: MESSAGING_VIEWS.DISCUSSION_LIST }));
  }

  openDrawer(state: string) {
    this.drawerState = state;
    this.open();
  }

  clearActiveBtn(buttons = null) {
    this.drawerState = 'closed';
  }

  /**
   * Verify if messeges view is loaded in DOM.
   */
  private isReady(component): Promise<any> {
    return new Promise<any>((res) => {
      if (this[component] === undefined) {
        setTimeout(() => {
          this.isReady(component).then((data) => {
            res(data);
          });
        }, 10);
      } else {
        res(true);
      }
    });
  }

  /**
   * Registers requested view component.
   * Ex. Somebody wants to message a person.
   * TODO: REFACTOR THIS
   * WHAT A BAD SOLUTION TO LISTEN AND EMIT TO THE SAME OBSERVABLE.
   * THIS INTERACTION BAR IN TOTAL IS A WASTE.
   */
  private registerBarStateChange() {
    // skip 1 because of BehaviourSubject is used instead of EventEmitter
    this.barState.announceState.pipe(skip(1), takeUntil(this.isDestroyed$)).subscribe((data) => {
      if (INTERACTION_BAR_STATES.SUCCESS_CLOSED !== data.action) {
        if (data.action) {
          this.drawerState = data.action;
          this.drawerData = data.data;

          if (data.action === INTERACTION_BAR_STATES.CLOSE) {
            this.barState.announceState.next({
              action: INTERACTION_BAR_STATES.SUCCESS_CLOSED,
              oldState: InteractionBarStateService.state,
            });
            if (this.isOpened) {
              this.close();
            }
          } else {
            this.open();
          }
        } else {
          // todo remove this all BS structure
          // @ts-ignore
          this.drawerState = data;
          this.open();
        }
      } else {
        this.drawerData = null;
      }
    });
  }

  backDropClicked(): void {
    if (!this.disableClose || this.isPopupOpen) {
      return;
    }
    this.isPopupOpen = true;
    this.notif.showPopup('Do you want to discard changes?').then((response) => {
      if (response) {
        this.close();
      }
      this.isPopupOpen = false;
    });
  }

  ngOnDestroy() {
    if (this.clickSubscription) {
      this.clickSubscription.unsubscribe();
    }

    if (this.closeSubscription) {
      this.closeSubscription.unsubscribe();
    }
    this.isDestroyed$.next(true);
  }
}
