<div class="h-full overflow-hidden p-6 box-border">
  <div [ngSwitch]="viewType$ | async">
    <div class="text-shade_2 font-semibold text-sm" *ngSwitchCase="ROLLUP_VIEW_TYPES.BUDGET_LINE_ITEMS">
      Budget Line Breakdown
    </div>
    <div class="text-shade_2 font-semibold text-sm" *ngSwitchCase="ROLLUP_VIEW_TYPES.TAGS">Budget Tag Breakdown</div>
    <div class="text-shade_2 font-semibold text-sm" *ngSwitchCase="ROLLUP_VIEW_TYPES.ACCOUNTS">Account Breakdown</div>
  </div>
  <div class="flex flex-col h-full w-full overflow-hidden">
    <ng-scrollbar appearance="compact" orientation="vertical" class="std-scrollbar">
      <div class="row header">
        <div class="ml-2 col-span-3"></div>
        <div class="uppercase item text-shade_3 font-bold text-size_xs">Committed</div>
        <div class="uppercase item text-shade_3 font-bold text-size_xs">Actuals</div>
        <div class="uppercase item text-shade_3 font-bold text-size_xs">Act vs com</div>
      </div>
      <ng-container [ngSwitch]="viewType$ | async">
        <ng-container *ngSwitchCase="ROLLUP_VIEW_TYPES.BUDGET_LINE_ITEMS">
          <div class="row">
            <span class="col-span-3 text-color_primary font-bold text-base">
              {{ bliData?.name ?? 'Default Budget Line' }}
            </span>
            <span class="item text-color_primary font-bold text-sm">
              <app-formatted-money [value]="bliData?.total_committed"></app-formatted-money
            ></span>
            <span class="item text-color_primary font-bold text-sm">
              <app-formatted-money [value]="bliData?.actuals"></app-formatted-money
            ></span>
            <span class="item text-color_primary font-bold text-sm">
              <app-formatted-money [value]="bliData?.total_committed - bliData?.actuals"></app-formatted-money>
            </span>
          </div>

          <div class="border-bottom"></div>

          <ng-container *ngFor="let project of bliData?.projects; let last = last">
            <div class="ml-2 row">
              <span class="ml-2 col-span-3 text-color_primary font-semibold text-sm">{{ project?.title }}</span>
              <span class="item font-semibold text-sm">
                <app-formatted-money [value]="project?.cash_flow?.total_committed"></app-formatted-money>
              </span>
              <span class="item font-semibold text-sm">
                <app-formatted-money [value]="project?.cash_flow?.actuals"></app-formatted-money>
              </span>
              <span class="item font-semibold text-sm">
                <app-formatted-money
                  [value]="project?.cash_flow?.total_committed - project?.cash_flow?.actuals"
                ></app-formatted-money>
              </span>
            </div>
            <div *ngIf="!last" class="border-bottom"></div>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="ROLLUP_VIEW_TYPES.TAGS">
          <div class="row">
            <span class="col-span-3 text-color_primary font-bold text-base">
              {{ tagsData?.name ?? 'Default Tag' }}
            </span>
            <span class="item text-color_primary font-bold text-sm">
              <app-formatted-money [value]="tagsData?.total_committed"></app-formatted-money
            ></span>
            <span class="item text-color_primary font-bold text-sm">
              <app-formatted-money [value]="tagsData?.actuals"></app-formatted-money
            ></span>
            <span class="item text-color_primary font-bold text-sm">
              <app-formatted-money [value]="tagsData?.total_committed - tagsData?.actuals"></app-formatted-money>
            </span>
          </div>

          <div class="border-bottom"></div>

          <ng-container *ngFor="let item of tagsData?.items; let last = last">
            <div class="ml-2 row">
              <span class="ml-2 col-span-3 text-color_primary font-semibold text-sm">{{ item?.name }}</span>
              <span class="item font-semibold text-sm">
                <app-formatted-money [value]="item?.cash_flow?.total_committed"></app-formatted-money>
              </span>
              <span class="item font-semibold text-sm">
                <app-formatted-money [value]="item?.cash_flow?.actuals"></app-formatted-money>
              </span>
              <span class="item font-semibold text-sm">
                <app-formatted-money
                  [value]="item?.cash_flow?.total_committed - item?.cash_flow?.actuals"
                ></app-formatted-money>
              </span>
            </div>
            <div *ngIf="!last" class="border-bottom"></div>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="ROLLUP_VIEW_TYPES.ACCOUNTS">
          <div class="row">
            <span class="col-span-3 text-color_primary font-bold text-base">
              {{ accountsData?.name ?? 'Default Gl Account' }}
            </span>
            <span class="item text-color_primary font-bold text-sm">
              <app-formatted-money [value]="accountsData?.total_committed"></app-formatted-money
            ></span>
            <span class="item text-color_primary font-bold text-sm">
              <app-formatted-money [value]="accountsData?.actuals"></app-formatted-money
            ></span>
            <span class="item text-color_primary font-bold text-sm">
              <app-formatted-money
                [value]="accountsData?.total_committed - accountsData?.actuals"
              ></app-formatted-money>
            </span>
          </div>

          <div class="border-bottom"></div>

          <ng-container *ngFor="let item of accountsData?.items; let last = last">
            <div class="row">
              <span class="ml-2 col-span-3 flex flex-col gap-1">
                <span class="text-color_primary font-semibold text-sm">{{ item?.name }}</span>
                <span class="text-color_primary font-normal text-xs">{{ item?.project_title }}</span>
              </span>
              <span class="item font-semibold text-sm">
                <app-formatted-money [value]="item?.cash_flow?.total_committed"></app-formatted-money>
              </span>
              <span class="item font-semibold text-sm">
                <app-formatted-money [value]="item?.cash_flow?.actuals"></app-formatted-money>
              </span>
              <span class="item font-semibold text-sm">
                <app-formatted-money
                  [value]="item?.cash_flow?.total_committed - item?.cash_flow?.actuals"
                ></app-formatted-money>
              </span>
            </div>
            <div *ngIf="!last" class="border-bottom"></div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-scrollbar>
  </div>
</div>
