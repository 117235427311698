import { createReducer, on } from '@ngrx/store';
import { activitiesActions } from './activities.actions';
import {
  Activity,
  ACTIVITY_VIEW,
  ActivityCalendarDateWindow,
  ActivityFilters,
} from './activities.constants';

export type ActivitiesStore = {
  overdueProgress: Activity[]; // Overdue tracking items and daily activities only.
  openInvoices: Activity[]; // Open invoices and misc costs only.
  openContracts: Activity[]; // Open contracts and COs only.
  activityLogs: Activity[];
  calendarActivities: Activity[];
  filters: ActivityFilters;
  selectedView: ACTIVITY_VIEW;
  isKanbanLoading: {
    overdueProgress: boolean;
    openInvoices: boolean;
    openContracts: boolean;
  };
  isLoadingGeneral: boolean;
  nextKanbanPage: {
    overdueProgress: number;
    openInvoices: number;
    openContracts: number;
  };
  logPage: number;
  nextCalendarPage: number;
  // represents the date window [start_date, end_date] for which we have activities loaded into calendarActivities
  calendarDateWindow: ActivityCalendarDateWindow;
  // this indicates if there was any activity in the system
  // used to show "no match based on filters" or "no activity at all" message
  isThereAnyActivity: boolean;
};

const initialState: ActivitiesStore = {
  overdueProgress: [],
  openInvoices: [],
  openContracts: [],
  activityLogs: [],
  calendarActivities: [],
  nextKanbanPage: {
    overdueProgress: 1,
    openInvoices: 1,
    openContracts: 1,
  },
  nextCalendarPage: 1,
  isKanbanLoading: {
    overdueProgress: true,
    openInvoices: true,
    openContracts: true,
  },
  filters: {
    'types[]': [],
    search: '',
    unassigned: 0,
    'assigneeIds[]': [],
    startDateGTE: null, // these represent only the first month which is going to be loaded
    startDateLT: null, // these represent only the first month which is going to be loaded
  },
  calendarDateWindow: {
    startDateGTE: null,
    startDateLT: null,
  },
  isLoadingGeneral: false,
  logPage: 1,
  selectedView: ACTIVITY_VIEW.ACTIVITIES,
  isThereAnyActivity: false,
};

export const activitiesReducer = createReducer(
  initialState,
  on(activitiesActions.successfullyLoadedActivities, (state, action) => {
    // todo: this reducer is too complicated, we should simplify it
    let overdueProgress = state.overdueProgress;
    let openInvoices = state.openInvoices;
    let openContracts = state.openContracts;

    if (action.isFirstPage) {
      overdueProgress = action.overdueProgress ?? [];
      openInvoices = action.openInvoices ?? [];
      openContracts = action.openContracts ?? [];
    }

    if (!action.isFirstPage && action.overdueProgress) {
      overdueProgress = [...state.overdueProgress, ...action.overdueProgress];
    }
    if (!action.isFirstPage && action.openInvoices) {
      openInvoices = [...state.openInvoices, ...action.openInvoices];
    }
    if (!action.isFirstPage && action.openContracts) {
      openContracts = [...state.openContracts, ...action.openContracts];
    }

    const isThereAnyActivity =
      overdueProgress.length > 0 || openInvoices.length > 0 || openContracts.length > 0;
    return {
      ...state,
      overdueProgress,
      openInvoices,
      openContracts,
      nextKanbanPage: {
        overdueProgress:
          action.overdueProgress?.length === 0
            ? null
            : action.nextPage?.overdueProgress ?? state.nextKanbanPage.overdueProgress,
        openInvoices:
          action.openInvoices?.length === 0
            ? null
            : action.nextPage?.openInvoices ?? state.nextKanbanPage.openInvoices,
        openContracts:
          action.openContracts?.length === 0
            ? null
            : action.nextPage?.openContracts ?? state.nextKanbanPage.openContracts,
      },
      isKanbanLoading: {
        // consider separating these
        overdueProgress: false,
        openInvoices: false,
        openContracts: false,
      },
      isThereAnyActivity: state.isThereAnyActivity || isThereAnyActivity,
    };
  }),
  on(activitiesActions.successfullyLoadedActivityLog, (state, action) => {
    const activityLogs = action.resetLogs ? action.logs : [...state.activityLogs, ...action.logs];
    const isThereAnyActivity = activityLogs.length > 0;
    return {
      ...state,
      activityLogs,
      isThereAnyActivity: state.isThereAnyActivity || isThereAnyActivity,
      logPage: action.resetLogs ? 1 : action.logPage + 1,
      isLoadingGeneral: false,
    };
  }),
  on(activitiesActions.successfullyLoadedActivitiesCalendar, (state, action) => {
    let calendarActivities: Activity[];
    if (action.isFirstLoad) {
      calendarActivities = [...action.activities];
    } else if (action.direction < 0) {
      calendarActivities = [...action.activities, ...state.calendarActivities];
    } else {
      calendarActivities = [...state.calendarActivities, ...action.activities];
    }
    calendarActivities.sort((a, b) => (a.start_date < b.start_date ? -1 : 1));
    // filter out duplicates...
    calendarActivities = calendarActivities.filter(
      (item, pos) => calendarActivities.findIndex((innerItem) => innerItem.id === item.id) === pos,
    );

    let dateWindow: ActivityCalendarDateWindow = state.calendarDateWindow;
    if (action.isFirstLoad) {
      dateWindow = {
        startDateGTE: action.startDateGTE,
        startDateLT: action.startDateLT,
      };
    }

    return {
      ...state,
      calendarActivities,
      isLoadingGeneral: false,
      calendarDateWindow: dateWindow,
    };
  }),
  on(activitiesActions.refreshCalendarDateWindow, (state, action) => {
    let dateWindow: ActivityCalendarDateWindow = state.calendarDateWindow;

    if (action.direction < 0) {
      dateWindow = {
        ...state.calendarDateWindow,
        startDateGTE: action.startDateGTE,
      };
    } else if (action.direction > 0) {
      dateWindow = {
        ...state.calendarDateWindow,
        startDateLT: action.startDateLT,
      };
    }
    return {
      ...state,
      calendarDateWindow: dateWindow,
    };
  }),
  on(activitiesActions.calendarActivitiesDestroyed, (state, activities) => {
    return {
      ...state,
      calendarActivities: [],
    };
  }),
  on(activitiesActions.selectedViewChanged, (state, action) => {
    return {
      ...state,
      selectedView: action.view,
      // reset the date filters when switching to a view other than calendar
      filters: {
        ...state.filters,
        startDateGTE: action.view === ACTIVITY_VIEW.CALENDAR ? state.filters.startDateGTE : null,
        startDateLT: action.view === ACTIVITY_VIEW.CALENDAR ? state.filters.startDateLT : null,
      },
    };
  }),
  on(activitiesActions.saveFilters, (state, action) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        'types[]': 'types[]' in action ? action['types[]'] : state.filters['types[]'],
        search: 'search' in action ? action.search : state.filters.search,
        'assigneeIds[]':
          'assigneeIds[]' in action ? action['assigneeIds[]'] : state.filters['assigneeIds[]'],
        'projectIds[]':
          'projectIds[]' in action ? action['projectIds[]'] : state.filters['projectIds[]'],
        unassigned: 'unassigned' in action ? action.unassigned : state.filters.unassigned,
        startDateLT: 'startDateLT' in action ? action.startDateLT : state.filters.startDateLT,
        startDateGTE: 'startDateGTE' in action ? action.startDateGTE : state.filters.startDateGTE,
      },
    };
  }),
  on(activitiesActions.successfullyAssignedTeammate, (state, action) => {
    const mapFn = (activity: Activity) => {
      // update the activity with the new assignee
      if (activity.id === action.activity.id) {
        return action.activity;
      }
      return activity;
    };
    const overdueProgress = state.overdueProgress.map(mapFn);
    const openInvoices = state.openInvoices.map(mapFn);
    const openContracts = state.openContracts.map(mapFn);
    const activityLogs = state.activityLogs.map(mapFn);
    const calendarActivities = state.calendarActivities.map(mapFn);

    return {
      ...state,
      overdueProgress,
      openInvoices,
      openContracts,
      activityLogs,
      calendarActivities,
    };
  }),
  on(activitiesActions.setLoading, (state, action) => {
    return {
      ...state,
      isKanbanLoading: {
        overdueProgress:
          'overdueProgress' in action
            ? action.overdueProgress
            : state.isKanbanLoading.overdueProgress,
        openInvoices:
          'openInvoices' in action ? action.openInvoices : state.isKanbanLoading.openInvoices,
        openContracts:
          'openContracts' in action ? action.openContracts : state.isKanbanLoading.openContracts,
      },
    };
  }),
  on(activitiesActions.setIsLoadingGeneral, (state, action) => {
    return {
      ...state,
      isLoadingGeneral: action.isLoading,
    };
  }),
  on(activitiesActions.resetActivityLogs, (state) => {
    return {
      ...state,
      activityLogs: [],
      logPage: 1,
      isLoadingGeneral: false,
    };
  }),
  on(activitiesActions.cancel, (state) => {
    return {
      ...state,
      isKanbanLoading: {
        overdueProgress: false,
        openInvoices: false,
        openContracts: false,
      },
      isLoadingGeneral: false,
    };
  }),
  on(activitiesActions.clearActivities, (state) => {
    return {
      ...initialState,
    };
  }),
);
