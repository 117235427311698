import { Component, Input, OnInit } from '@angular/core';
import {
  DISCUSSION_FILTER_TYPE,
  discussionTypeToLiteral,
  getBubbleType,
} from '../../constants/messages.constants';
import { DateCustomPipe, DMMM, NO_TIMEZONE_DATE } from '../../../pipes/framework/date-custom.pipe';
import { AppState } from '../../../store/app-state';
import { Store } from '@ngrx/store';
import {
  setMessagingView,
  setSelectedGroupId,
  updateGroupStatus,
} from '../../../store/messages/messages.actions';
import { IMessagingGroup, MESSAGING_VIEWS } from '../../../store/messages/messages.interfaces';
import { DeepCopyService } from '../../../services/deep-copy.service';
import moment from 'moment/moment';
import { NotificationsService } from '../../../services/notifications.service';
import { RemoveLoggedInUserPipe } from '../../../pipes/framework/remove-logged-in-user.pipe';
import { NgIf } from '@angular/common';
import { FadedTextComponent } from '../../faded-text/faded-text.component';
import { MessagingBubbleComponent } from './messaging-bubble/messaging-bubble.component';

@Component({
  selector: 'app-discussions-list-item',
  templateUrl: './discussions-list-item.component.html',
  styleUrls: ['./discussions-list-item.component.scss'],
  standalone: true,
  imports: [
    MessagingBubbleComponent,
    FadedTextComponent,
    NgIf,
    DateCustomPipe,
    RemoveLoggedInUserPipe,
  ],
})
export class DiscussionsListItemComponent implements OnInit {
  private _group: IMessagingGroup;
  @Input() set group(group) {
    this._group = group;
    if (moment(group.last_comment_date).year < moment().year) {
      this.DATE_FORMAT = NO_TIMEZONE_DATE;
    } else {
      this.DATE_FORMAT = DMMM;
    }
  }

  get group() {
    return this._group;
  }
  DATE_FORMAT = DMMM;
  getBubbleType = getBubbleType;

  constructor(
    private store: Store<AppState>,
    private notif: NotificationsService,
  ) {}

  ngOnInit(): void {}

  openMessageView() {
    this.store.dispatch(setMessagingView({ view: MESSAGING_VIEWS.DISCUSSION_VIEW }));
  }

  setDiscussion(group: IMessagingGroup) {
    group = DeepCopyService.deepCopy(group);
    group.is_read = true;
    // this.store.dispatch(updateMessagingGroup({ group }));
    this.store.dispatch(setSelectedGroupId({ groupId: group.id }));
  }

  archiveGroup() {
    const group: IMessagingGroup = DeepCopyService.deepCopy(this.group);
    this.notif.showPopup(`Do you want to archive the group '${group.name}'?`).then((answer) => {
      if (answer) {
        // it needs to be archived with lower case, we should switch to key/type
        group.status = discussionTypeToLiteral[
          DISCUSSION_FILTER_TYPE.ARCHIVED
        ].toLowerCase() as 'archived';
        this.store.dispatch(updateGroupStatus({ group }));
      }
    });
  }

  discussionItemClicked() {
    this.openMessageView();
    this.setDiscussion(this.group);
  }
}
