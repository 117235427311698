import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { COMMITMENTS_INTERACTION_BAR_TYPE } from '../../../constants/interaction-bar.constants';
import { IBudgetTagItem, IBudgetTagTemplate } from '../../../../store/settings/settings.interface';
import { Observable, Subject } from 'rxjs';
import { getBudgetTagTemplates } from '../../../../store/settings/settings.selectors';
import { AppState } from '../../../../store/app-state';
import { Store } from '@ngrx/store';
import { commitmentsActions } from '../../../../store/commitments/commitments.actions';
import { DeepCopyService } from '../../../../services/deep-copy.service';
import { commitmentsSelectors } from '../../../../store/commitments/commitments.selectors';
import { FormsModule, NgForm } from '@angular/forms';
import { CostDescriptionComponent } from '../cost-description/cost-description.component';
import {
  BudgetLineItemViewTableDropdownComponent,
  SelectedOption,
} from '../../../budget-line-item-view-table-dropdown/budget-line-item-view-table-dropdown.component';
import { uniq } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import {
  CHANGE_ORDER_TYPE,
  defaultCommitmentItem,
  defaultCost,
} from '../../../../store/commitments/commitments.constants';
import {
  IChangeOrderType,
  ISidebarChangeOrder,
  ISidebarCommitmentItem,
  ISidebarContract,
  ISidebarCost,
  ISidebarDirectCost,
} from '../../../../store/commitments/commitments.types';
import { NotificationsService } from '../../../../services/notifications.service';
import { InputCalendarComponent } from '../../../inputs/input-calendar/input-calendar.component';
import {
  dropdownAlignRight,
  dropdownOverlayPositions,
} from '../../../overlays/option-list.constants';
import { getErrorMessage } from '../../commitments-interaction-bar-view/commitments-interaction-bar-view.utilities';
import { CommonModule, NgClass } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { BudgetTagTemplatesDropdownComponent } from '../../budget-tag-templates-dropdown/budget-tag-templates-dropdown.component';
import { OptionsListGeneralComponent } from '../../../overlays/options-list-general/options-list-general.component';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { InputTextModule } from 'primeng/inputtext';
import { CommitmentEntryTextComponent } from '../commitment-entry-text/commitment-entry-text.component';
import { DropdownComponent } from '../../../inputs/dropdown/dropdown.component';

@Component({
  selector: 'app-spend-entry',
  templateUrl: './spend-entry.component.html',
  styleUrls: ['./spend-entry.component.scss'],
  standalone: true,
  imports: [
    CostDescriptionComponent,
    NgClass,
    DropdownModule,
    InputCalendarComponent,
    CommonModule,
    FormsModule,
    BudgetTagTemplatesDropdownComponent,
    OptionsListGeneralComponent,
    CdkOverlayOrigin,
    InputTextModule,
    BudgetLineItemViewTableDropdownComponent,
    CommitmentEntryTextComponent,
    DropdownComponent,
  ],
})
export class SpendEntryComponent implements AfterViewInit, OnDestroy {
  @ViewChild('spendEntry') spendEntryForm: NgForm;
  @ViewChild('calendar_picker') calendarPicker: InputCalendarComponent;
  @ViewChild('dropDownOverlay') dropDownOverlay: ElementRef<HTMLElement>;
  @ViewChildren('costDescription') costDescriptions: QueryList<CostDescriptionComponent>;

  @Input() index: number;
  @Input() projectData: { id: number; start_date: string } = { id: null, start_date: null };
  @Input() commitmentAddedAt: string | Date = null;
  @Input() commitmentType: COMMITMENTS_INTERACTION_BAR_TYPE =
    COMMITMENTS_INTERACTION_BAR_TYPE.ADD_CONTRACT;
  _model: ISidebarCommitmentItem = { ...defaultCommitmentItem };
  @Input() changeOrderType: IChangeOrderType = null;
  @Input() disableAddDescription = false;
  @Input() disableRemovedEntry = false;
  @Input() disabledAddDescriptionTooltip = 'Select a line item first';
  @Input() disableBudgetTagDropdown = false;

  @Input() set model(value) {
    if (value) {
      this._model = value;
    }
  }

  get model() {
    return this._model;
  }

  disabledLineItems: Array<number> = [];

  isDropdownShown = {
    line_item: false,
    budget_tag: false,
    duration: false,
  };
  protected readonly dropdownOverlayPositions = dropdownOverlayPositions;
  protected readonly dropdownAlignRight = dropdownAlignRight;
  protected readonly COMMITMENTS_INTERACTION_BAR_TYPE = COMMITMENTS_INTERACTION_BAR_TYPE;
  protected readonly DURATION_MONTH_COUNT = 72;
  protected readonly getErrorMessage = getErrorMessage;
  protected readonly CHANGE_ORDER_TYPE = CHANGE_ORDER_TYPE;

  disabledBudgetTagIds = [];
  budgetTags$: Observable<IBudgetTagTemplate[]> = this.store.select(getBudgetTagTemplates);

  spendDurationMonths = Array(this.DURATION_MONTH_COUNT + 1)
    .fill(0)
    .map((x, i) => ({ value: i + 1, label: i + 1 }));

  isDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private notif: NotificationsService,
  ) {}

  ngAfterViewInit() {
    this.subscribeToChanges();
  }

  subscribeToChanges() {
    this.store
      .select(commitmentsSelectors.getSidebarCommitment(this.commitmentType))
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((commitment) => {
        if (this.commitmentType === COMMITMENTS_INTERACTION_BAR_TYPE.INVOICES) {
          return;
        }

        this.addZeroDurationIfNeeded();

        this.setRestrictions(
          commitment as ISidebarContract | ISidebarChangeOrder | ISidebarDirectCost,
        );
      });
  }

  setRestrictions(commitment: ISidebarContract | ISidebarChangeOrder | ISidebarDirectCost) {
    // the line item and tag combination should be unique always
    // but ONE line item without budget tag is permitted
    const lineIds = uniq(commitment.commitment_items.map((item) => item?.item?.id));

    const lineItemsToDisable: number[] = [];
    const tagsToDisable: number[] = [];

    const currentlySelectedLineItemId = this.model.item.id;
    if (currentlySelectedLineItemId) {
      // if line item is selected for current spend entry
      // disable the tag which is selected with current line item

      commitment.commitment_items
        .filter((commitmentItem) => commitmentItem?.item?.id === currentlySelectedLineItemId)
        .forEach((commitmentItem) => {
          tagsToDisable.push(commitmentItem.budget_tag?.id);
        });
    }

    const selectedBudgetTagId = this.model.budget_tag?.id;
    if (selectedBudgetTagId) {
      // if the spend entry has a tag selected then disable the line items which have the same tag
      const itemsWithSameTag = commitment.commitment_items.filter(
        (commitmentItem) => commitmentItem?.budget_tag?.id === selectedBudgetTagId,
      );
      itemsWithSameTag.forEach((commitmentItem) => {
        lineItemsToDisable.push(commitmentItem?.item?.id);
      });
    } else {
      // if no tag for current spend entry
      // check for every possible line item if it's selected without a tag
      lineIds.forEach((lineId) => {
        const commitmentItem = commitment.commitment_items.find((item) => {
          const hasTag = !!item.budget_tag?.id;
          const isLineItemSelected = !!item.item?.id;
          const idsMatch = item.item.id === lineId;
          // if line item is selected in one spend entry and has no tag than it should be disabled
          return idsMatch && !hasTag && isLineItemSelected;
        });
        if (commitmentItem) {
          // disable if selected without tag
          lineItemsToDisable.push(lineId);
        }
      });
    }

    this.setDisabledLineItemsUnique(lineItemsToDisable);
    this.setDisabledBudgetTagsUnique(tagsToDisable);
  }

  setDisabledLineItemsUnique(lineItemIds: number[]) {
    lineItemIds = lineItemIds.filter((id) => Number.isInteger(id));
    this.disabledLineItems = [...new Set([...lineItemIds])];
  }

  setDisabledBudgetTagsUnique(budgetTagIds: number[]) {
    budgetTagIds = budgetTagIds.filter((id) => Number.isInteger(id));
    this.disabledBudgetTagIds = [...new Set([...budgetTagIds])];
  }

  trackByIndexAndCost(cost: Partial<ISidebarCost>, index: number) {
    return (cost?.id ?? 0) + (cost?.name ?? '') + index;
  }

  addDefaultCostIfNeeded() {
    if (this.model?.costs?.length === 0 && this.changeOrderType !== CHANGE_ORDER_TYPE.DURATION) {
      // add a default cost when line item is selected
      if (
        this.commitmentType === COMMITMENTS_INTERACTION_BAR_TYPE.ADD_CONTRACT ||
        this.commitmentType === COMMITMENTS_INTERACTION_BAR_TYPE.DIRECT_COST
      ) {
        this.model.costs.push({ ...defaultCost, is_dropdown: false });
      } else {
        this.model.costs.push({ ...defaultCost, is_dropdown: true });
      }
    }
  }

  async checkIfTagCanBeChanged() {
    if (this.commitmentType !== COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER) {
      return true;
    }

    if (this.model?.costs?.length > 1 || this.model?.costs?.[0]?.name) {
      const agreed = await this.notif.showPopup(
        'Changing the Budget Tag will delete all costs associated with this spend entry. Do you agree?',
      );
      if (agreed) {
        this.model.costs.forEach((cost, costIndex) => {
          this.store.dispatch(
            commitmentsActions.removeChangeOrderSpendEntryCost({
              spendEntryIndex: this.index,
              costIndex: 0,
            }),
          );
        });
        this.model.costs = [];
        this.addDefaultCostIfNeeded();
      }
      return agreed;
    }
    return true;
  }

  async selectedBudgetTag(budgetTagItem: IBudgetTagItem) {
    this.isDropdownShown.budget_tag = false;

    const agreed = await this.checkIfTagCanBeChanged();
    if (!agreed) {
      return;
    }

    this.model.budget_tag = budgetTagItem;
    this.updateCommitmentItem();
  }

  async unlinkTemplate() {
    this.isDropdownShown.budget_tag = false;
    const agreed = await this.checkIfTagCanBeChanged();
    if (!agreed) {
      return;
    }

    this.model.budget_tag = {
      id: null,
      name: '',
    };
    this.updateCommitmentItem();
  }

  onLineItemInputClick(event) {
    event.stopPropagation();
    this.isDropdownShown.line_item = true;
  }

  async lineItemOptionSelected(selection: SelectedOption) {
    switch (selection.action) {
      case 'select': {
        // if there are non-empty costs and the user changes the line item to something else,
        // ask permission to delete all costs with parent_id pointing to a different line item
        const costsWithData = this.model.costs.filter(
          (cost) => !!cost.name || (!!cost.value && !cost.deleted),
        );
        if (
          this.model.item?.id &&
          costsWithData.length > 0 &&
          this.changeOrderType !== CHANGE_ORDER_TYPE.DURATION &&
          this.changeOrderType !== CHANGE_ORDER_TYPE.SCOPE
        ) {
          const agreed = await this.notif.showPopup(
            'Changing the Budget Line Item will delete all costs descriptions linked to another cost. Do you agree?',
            'z-[1044]',
          );
          if (!agreed) {
            break;
          }
        }

        this.model.item = {
          name: selection.item.name,
          id: selection.item.id,
          contractApproved: selection.item.contractApproved,
        };

        if (this.commitmentType === COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER) {
          this.removeOldCostsWithParentId();
        }

        this.addDefaultCostIfNeeded();
        this.updateCommitmentItem();
        break;
      }
      case 'delete': {
        this.removeEntry();
        break;
      }
      default: {
        console.warn('Action not handled.');
        break;
      }
    }
    this.isDropdownShown.line_item = false;
  }

  updateEntryDate(date: string) {
    this.model = {
      ...this.model,
      start_date: date,
    };
    this.updateCommitmentItem();
  }

  updateCommitmentItem() {
    switch (this.commitmentType) {
      case COMMITMENTS_INTERACTION_BAR_TYPE.ADD_CONTRACT:
        this.store.dispatch(
          commitmentsActions.updateContractSpendEntry({
            commitmentItem: DeepCopyService.deepCopy({ ...this.model }),
            index: this.index,
          }),
        );
        break;
      case COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER:
        this.store.dispatch(
          commitmentsActions.updateChangeOrderSpendEntry({
            commitmentItem: DeepCopyService.deepCopy({ ...this.model }),
            index: this.index,
          }),
        );
        break;
      case COMMITMENTS_INTERACTION_BAR_TYPE.DIRECT_COST:
        this.store.dispatch(
          commitmentsActions.updateDirectCostSpendEntry({
            commitmentItem: DeepCopyService.deepCopy({ ...this.model }),
            index: this.index,
          }),
        );
        break;
      default:
        console.warn('Action not handled.');
        break;
    }
  }

  removeEntry() {
    switch (this.commitmentType) {
      case COMMITMENTS_INTERACTION_BAR_TYPE.ADD_CONTRACT:
        this.store.dispatch(commitmentsActions.removeContractSpendEntry({ index: this.index }));
        break;
      case COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER:
        this.store.dispatch(commitmentsActions.removeChangeOrderSpendEntry({ index: this.index }));
        break;
      case COMMITMENTS_INTERACTION_BAR_TYPE.DIRECT_COST:
        this.store.dispatch(commitmentsActions.removeDirectCostSpendEntry({ index: this.index }));
        break;
      default:
        break;
    }
  }

  /**
   * When changing a line item, old costs should be deleted
   * if they have a parent_id pointing to a different line item (the previous one).
   */
  removeOldCostsWithParentId() {
    // add costs with a parent_id to deleted_costs if they have an id (they've been saved before)
    // if they don't have an id, we don't need to include them in the request, they will be simply removed from the model
    this.model.deleted_costs = [
      ...(this.model.deleted_costs ?? []),
      ...this.model.costs
        .filter((cost) => !!cost.parent_id && cost.id)
        .map((cost) => ({ ...cost, deleted: true })),
    ];

    this.model.costs = this.model.costs.filter((cost) => !cost.parent_id);
  }

  /**
   * The thing is we have a legacy type of Change Orders, which is called "DEFAULT".
   * In the past you could select 0 months as duration because we didn't have different kind of COs.
   * So this is just a legacy thing, we need to keep it for the old COs.
   */
  addZeroDurationIfNeeded() {
    if (
      this.commitmentType === COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER &&
      this.changeOrderType === CHANGE_ORDER_TYPE.DEFAULT
    ) {
      this.spendDurationMonths = [{ value: 0, label: 0 }, ...this.spendDurationMonths];
    }
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
