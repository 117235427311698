import { DISTRIBUTION_TYPES } from '../../framework/constants/spend.constants';

export interface ILineItem {
  id: number;
  name: string;
  budget: IBudget[];
  duration: number;
  start_date: string;
  commitment_start_date: string;
  project_id?: number;
  distribution: DISTRIBUTION_TYPES;
  row_number: number;
  committed_items: ICommittedItem[];
  original_budget_total?: number; // added on frontend
}

export interface ILineItemPayload {
  id: number;
  name: string;
  budget: IBudget[];
  duration: number;
  start_date: string;
  distribution: DISTRIBUTION_TYPES;
  row_number: number;
}

export interface ICommittedItem {
  id: number;
  commitment_id: number;
  row_number: number;
  committed_row_number: number; // used only when view is budget + commitments
  budget: IForecastBudget[];
  duration: number;
  start_date: string;
  project_id?: number;
  distribution: DISTRIBUTION_TYPES;
  commitment_name: string;
  commitment_title: string;
  item_id: number;
  original_budget_total?: number; // added on frontend
  type: 'commitment' | 'forecast_modification';
}

export interface ICommittedItemPayload {
  id: number;
  row_number: number;
  committed_row_number: number; // used only when view is budget + commitments
  budget?: IForecastBudget[];
  distribution: DISTRIBUTION_TYPES;
}

export interface ILineItemExtended extends ILineItem {
  // the budget is filtered by type and year
  monthly_data: IMonthlySpendData;
  monthly_disable: IMonthlyDisable;
  year_total: number;
  project_total: number;
  original_budget_total?: number;
  committed_items: ICommittedItemExtended[];
}

export interface ICommittedItemExtended extends ICommittedItem {
  monthly_data: IMonthlySpendData;
  monthly_disable: IMonthlyDisable;
  year_total: number;
  project_total: number;
  lineItem?: ILineItemExtended;
}

export interface ISpreadLineItem extends ILineItem {
  yearTotalFormula: string;
  budgetTotalFormula: string;
  committed_items: ISpreadCommittedItem[];
}

export interface ISpreadCommittedItem extends ICommittedItem {
  yearTotalFormula: string;
  dollarsTotalFormula: string;
}

export interface ILineItemsTotal {
  // the budget is filtered by type and year
  monthly_data: IMonthlySpendData;
  year_total: number;
  project_total: number;
}

export interface IMonthlySpendData {
  1: number;
  2: number;
  3: number;
  4: number;
  5: number;
  6: number;
  7: number;
  8: number;
  9: number;
  10: number;
  11: number;
  12: number;
}

export interface IMonthlyDisable {
  1: boolean;
  2: boolean;
  3: boolean;
  4: boolean;
  5: boolean;
  6: boolean;
  7: boolean;
  8: boolean;
  9: boolean;
  10: boolean;
  11: boolean;
  12: boolean;
}

export interface IForecastBudget {
  year: number;
  monthly_forecast: IMonthlySpendData;
}

export interface IBudget {
  year: number;
  monthly_budget: IMonthlySpendData;
}

export interface IBudgetPartial {
  year: number;
  monthly_budget?: IMonthlySpendData;
  monthly_forecast?: IMonthlySpendData;
}

export type DistributionFieldType = 'monthly_budget' | 'monthly_forecast';

export interface ISpendDistribution {
  budget: number;
  start_date: string;
  duration: number;
  field: DistributionFieldType;
  distribution: DISTRIBUTION_TYPES;
}

export interface SpendDistributionRequest extends ISpendDistribution {
  item_id?: number;
}

export enum SpendStoreUpdateTypes {
  NONE,
  SET_ALL,
  ADD,
  UPDATE,
  DELETE,
  FILTER_CHANGE, // spend type or selected year change
  SAVE_BACKEND,
}

export interface ISpendStoreUpdate {
  lineId: number;
  type: SpendStoreUpdateTypes;
}

export interface ILastSpendStoreUpdate {
  lastStoreUpdate: ISpendStoreUpdate;
  lineItems: ILineItemExtended[];
}

export type SpreadSheetInputData = {
  items: ILineItem[];
  isEditable: { [k: number]: boolean[] }[];
  selectedYear: number;
};

export interface ISpreadSheetChange {
  newValue: number;
  lineIndex: number; // indexed from 0, like arrays
  monthIndex: number; // indexed from 1, like months here
  sheetYear: number;
}

export interface IDistributionResponse {
  budget: IForecastBudget[] | IBudget[];
}

export enum SPEND_ERROR_TYPES {
  NEW,
  MODIFIED,
  DELETED,
  FAILED_TO_UPDATE_COMMITTED_LINE,
}

export type SpendError = {
  id;
  errorType: SPEND_ERROR_TYPES;
  error: true;
};

export interface ISpendLineItemSummary {
  name: string;
  spend_contracts: SpendContracts;
  spend_direct_costs: SpendDirectCosts;
  committed: number;
  isLoaded?: boolean;
}

export interface SpendContracts {
  contracts: Contract[];
  committed: number;
}

export interface Contract {
  title: string;
  company_name: string;
  committed: number;
  commitments: Commitment[];
}

export interface Commitment {
  title: string;
  committed: number;
  type: string;
}

export interface SpendDirectCosts {
  direct_costs: DirectCost[];
  committed: number;
}

export interface DirectCost {
  title: string;
  committed: number;
}
