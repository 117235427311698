<div class="text-sm upload-drop-area" appUploadDrop (fileDropped)="onFileDropped($event)">
  <label class="text-shade_2 font-semibold text-uppercase text-size_xs">
    Upload Document
    <!--    todo: check if custom title needed otherwise remove the below ng-container-->
    <!--    <ng-container *ngIf="commitmentType === COMMITMENTS_INTERACTION_BAR_TYPE.ADD_CONTRACT">-->
    <!--      Upload Document</ng-container-->
    <!--    >-->
    <!--    <ng-container *ngIf="commitmentType === COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER">-->
    <!--      Upload Document</ng-container-->
    <!--    >-->
    <!--    <ng-container *ngIf="commitmentType === COMMITMENTS_INTERACTION_BAR_TYPE.INVOICES"> Upload Invoice </ng-container>-->
    <!--    <ng-container *ngIf="commitmentType === COMMITMENTS_INTERACTION_BAR_TYPE.DIRECT_COST"-->
    <!--      >Upload Direct Cost-->
    <!--    </ng-container>-->
  </label>
  <div class="border border-shade_5 rounded border-solid p-3 mt-0.5">
    <span (click)="fileInput.click()" class="text-color_secondary flex items-center gap-1.5 pointer-cursor">
      <span class="text-xl icon-folded-file-upload flex items-center"></span>
      <span class="italic text-color_secondary font-bold text-size_l"> Click to upload files</span>
    </span>
    <ng-container *ngIf="commitmentFiles.length">
      <span
        (click)="fileInput.click()"
        class="flex items-center gap-5 pointer-cursor"
        *ngFor="let file of commitmentFiles; index as index"
      >
        <span class="text-color_secondary my-1.5 flex-1">
          {{ file.name | shortenFileName }}
        </span>
        <span
          (click)="removeFile($event, file, index)"
          class="icon-remove-circle-x text-size_icon text-color_secondary hover:text-color_accent"
        ></span>
      </span>
    </ng-container>
  </div>

  <input (change)="onFileChange($event)" class="upload disp-none" type="file" multiple #fileInput />
</div>
