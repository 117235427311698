import { Pipe, PipeTransform } from '@angular/core';
import { capitalize } from 'lodash';

/**
 * Simply transforms an array of strings into a coma separated string.
 */
@Pipe({
  name: 'arrayToComaSeparated',
  standalone: true,
})
export class ArrayToComaSeparatedPipe implements PipeTransform {
  transform(values: string[], type?: string, ...args: unknown[]): string {
    if (!values) {
      return '';
    }
    let formattedValue = '';
    if (type === 'capitalize') {
      values = values.map((value) => capitalize(value));
    }
    for (const [index, value] of values.entries()) {
      if (index === values.length - 1) {
        // don't put a comma after the last value
        formattedValue += value;
        break;
      }
      formattedValue += value + ', ';
    }
    return formattedValue;
  }
}
