export enum MENU_PAGES {
  ADD = 'projects/add',
  PROJECTS = 'projects',
  DOCUMENTS = 'documents',
  CONTRACTORS = 'contractors',
  PRINT = 'reporting',
  CASHFLOW = 'cashflow',
  SETTINGS = 'settings',
  ACTIVITIES = 'activities',
}

export enum CONTRACTOR_SELECTOR {
  ALL = 'all',
  PREFERRED = 'preferred',
  INVITED = 'invited',
  SCHEDULED = 'scheduled',
}

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
