import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../app-state/index';
import { NotificationsService } from '../../services/notifications.service';
import { cashflowActions } from './cashflow.actions';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { cashflowSelectors } from './cashflow.selectors';
import { of } from 'rxjs';
import { CashFlowService } from '../../services/cash-flow.service';
import {
  ALL_ID,
  CashflowFilters,
  getCashflowDataSorted,
} from '../../framework/constants/cashflow.constants';
import { ReportingService } from '../../services/reporting.service';
import { getProjectStatusesByKey } from '../projects/projects.selectors';

@Injectable()
export class CashflowEffects {
  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private notif: NotificationsService,
    private cashflowService: CashFlowService,
    private reportingService: ReportingService,
  ) {}

  loadCashflow$ = createEffect(() =>
    this.actions.pipe(
      ofType(cashflowActions.filtersChanged),
      withLatestFrom(
        this.store.select(cashflowSelectors.getCashflowState),
        this.store.select(
          getProjectStatusesByKey(['bidding', 'awarded', 'planned', 'in_progress', 'completed']),
        ),
      ),
      map(([action, state, projectStatuses]) => {
        this.reportingService.selectedYear = action.filters.year;
        if (Array.isArray(action?.filters?.project_ids)) {
          this.reportingService.selectedProjectId = [...action.filters.project_ids];
        }
        if (Array.isArray(action?.filters?.property_ids)) {
          this.reportingService.selectedProperty = [...action.filters.property_ids];
        }

        const filters: CashflowFilters = { ...state.filters, ...action.filters };
        if (filters.property_ids.includes(ALL_ID)) {
          filters.property_ids = [];
        }
        if (filters.project_ids.includes(ALL_ID)) {
          filters.project_ids = [];
        }
        return { filters, state, projectStatuses };
      }),
      switchMap(({ filters, state, projectStatuses }) => {
        this.store.dispatch(cashflowActions.startedLoading());
        const projectStatusIds = projectStatuses.map((status) => status.id);
        return this.cashflowService.getCashFlowData(filters, projectStatusIds).pipe(
          map((data) => {
            const sortedData = getCashflowDataSorted(data.data, state.sortNameBy);
            return cashflowActions.cashflowLoaded({ cashflow: { ...data, data: sortedData } });
          }),
        );
      }),
      catchError((error) => {
        console.error('Error loading cashflow', error);
        this.notif.showError(error?.message || 'An error occurred while loading the project');
        return of(cashflowActions.cancel());
      }),
    ),
  );
}
