import { PROJECT_STATUS_ID } from '../../../pages/webapp/projects/projects.interface';
import { FormControl, Validators } from '@angular/forms';

export enum PRINT_PAGE_TYPES {
  ROLLUP_PROJECTS,
  ROLLUP_BUDGET_LINES,
  ROLLUP_BUDGET_TAGS,
  CASHFLOW,
  PROJECT_VIEW_SPEND_DETAIL,
  PROJECT_VIEW_CASHFLOW,
  PROJECT_VIEW_COMMITMENTS,
}

export enum REPORTING_SIDEBAR_TYPES {
  INVOICE_LOG = 'invoice_log',
  FULL_REPORT = 'full_report',
}

export const POSSIBLE_PRINT_PAGES = [
  PRINT_PAGE_TYPES.ROLLUP_PROJECTS,
  PRINT_PAGE_TYPES.ROLLUP_BUDGET_LINES,
  PRINT_PAGE_TYPES.ROLLUP_BUDGET_TAGS,
  PRINT_PAGE_TYPES.CASHFLOW,
  PRINT_PAGE_TYPES.PROJECT_VIEW_SPEND_DETAIL,
  PRINT_PAGE_TYPES.PROJECT_VIEW_CASHFLOW,
  PRINT_PAGE_TYPES.PROJECT_VIEW_COMMITMENTS,
];

export const REPORTING_ALL_STATUSES: number[] = [
  PROJECT_STATUS_ID.COMPLETED,
  PROJECT_STATUS_ID.IN_PROGRESS,
  PROJECT_STATUS_ID.BIDDING,
  PROJECT_STATUS_ID.PLANNED,
  PROJECT_STATUS_ID.CANCELED,
  PROJECT_STATUS_ID.ON_HOLD,
];

export const REPORTING_ALL_VALUES = 'all';

export type ReportingToggleFields =
  | 'project_status_ids'
  | 'property_ids'
  | 'project_ids'
  | 'service_provider_ids';

export const reportingBooleanFields = [
  // invoice log
  'include_invoice_log',

  // portfolio
  'include_portfolio_summary',
  'include_portfolio_project_summary',
  'include_portfolio_budget_lines',
  'include_portfolio_budget_tags',
  'include_portfolio_cashflow',

  // property
  'include_property_project_summary',
  'include_property_budget_lines',
  'include_property_budget_tags',
  'include_property_cashflow',

  // project
  'include_project_cashflow',
  'include_project_progress_items',
  'include_project_schedule',
  'include_project_spend_detail',
  'include_project_updates',

  // project commitments
  'include_project_contracts',
  'include_project_contract_detail',
  'include_project_change_order_detail',
  'include_project_direct_cost_detail',
  'include_project_invoice_detail',
] as const;

export type ReportingBooleanFields = (typeof reportingBooleanFields)[number];

export type ReportingFormType = {
  // general stuff
  start_year?: FormControl<number>;
  end_year?: FormControl<number>;
  project_status_ids: FormControl<number[]>;
  project_ids: FormControl<number[]>;
  property_ids: FormControl<number[]>;
} & { [k in ReportingBooleanFields]: FormControl<boolean> };

export type ReportingInvoiceFormType = {
  invoice_log_start_date: FormControl<string>;
  invoice_log_end_date: FormControl<string>;
  service_provider_ids: FormControl<number[]>;
} & ReportingFormType;

export type IReportingPayload = {
  start_year?: number;
  end_year?: number;
  invoice_log_start_date?: string;
  invoice_log_end_date?: string;
  project_status_ids: number[];
  project_ids: number[] | 'all';
  property_ids: number[] | 'all';
  include_invoice_log?: boolean;
  tmp_sp_ids: number[];
  sp_ids: number[];
} & { [k in ReportingBooleanFields]: boolean };

/**
 * Returns the Form to be used when printing a "Full report".
 * This must be a function to regenerate the form every time it is used,
 * otherwise the values would remain the same between two prints.
 * Change initial value only from the component, as it is used in multiple places
 */
export function getReportingFormInitialValues(): ReportingFormType {
  return {
    start_year: new FormControl<number>(new Date().getFullYear(), [Validators.required]),
    end_year: new FormControl<number>(new Date().getFullYear(), [Validators.required]),
    project_status_ids: new FormControl<number[]>(
      [...REPORTING_ALL_STATUSES],
      [Validators.required],
    ),
    project_ids: new FormControl<number[]>([], [Validators.required]),
    property_ids: new FormControl<number[]>([], [Validators.required]),

    ...reportingBooleanFields.reduce(
      (acc, field) => {
        acc[field] = new FormControl<boolean>(false);
        return acc;
      },
      {} as Record<ReportingBooleanFields, FormControl<boolean>>,
    ),
  };
}

/**
 * Returns the Form to be used when printing an "Invoice log".
 * This must be a function to regenerate the form every time it is used,
 * otherwise the values would remain the same between two prints.
 * Change initial value only from the component, as it is used in multiple places
 */
export function getInvoiceReportingInitialValues(): ReportingInvoiceFormType {
  return {
    ...getReportingFormInitialValues(),
    include_invoice_log: new FormControl<boolean>(true),
    service_provider_ids: new FormControl<number[]>([]),
    invoice_log_start_date: new FormControl<string>(''),
    invoice_log_end_date: new FormControl<string>(''),
  };
}
