import { EventEmitter, Injectable } from '@angular/core';
import moment from 'moment/moment';
import { CurrentUserService } from './current-user.service';

/**
 * @deprecated - use the view project store instead. This service would behave silly.
 */

@Injectable({
  providedIn: 'root',
})
export class ProjectStateService {
  static updateContractorStats = new EventEmitter<void>();
  id = null; // can be string or number, but it's mostly string :Laughing:
  manualInput = false;

  budget = null;
  spend = null;
  forecast = null;

  constructor(private userService: CurrentUserService) {}

  clearProject() {
    this.budget = null;
    this.forecast = null;
    this.spend = null;
    this.id = null;
  }

  clear() {
    this.manualInput = false;
    this.clearProject();
  }

  getLineItemFiscalYear(startDate: string): number {
    const startDateMonth = moment(startDate).month() + 1;
    if (
      this.userService.data.fiscal_year_start > startDateMonth ||
      this.userService.data.fiscal_year_start === 1
    ) {
      return moment(startDate).year();
    }
    return moment(startDate).year() + 1;
  }
}
