<div class="sheet-wrapper" [ngClass]="{ 'opacity-barely': loading }">
  <ng-container *ngIf="isSheetDataLoaded">
    <ejs-spreadsheet
      #spreadsheet
      (created)="afterSheetCreated()"
      [activeSheetIndex]="activeSheetIndex"
      [allowSave]="true"
      [allowOpen]="false"
      [saveUrl]="SHEET_SAVE_URL"
      (beforeSave)="onBeforeSave($event)"
      (saveComplete)="onSaveComplete($event)"
    >
      <e-sheets>
        <!--          [protectSettings]="{ selectCells: true }"-->
        <!--        [isProtected]="true"-->
        <!--        [frozenRows]="1"-->
        <e-sheet *ngFor="let year of possibleYears" [name]="year + ''">
          <e-rows>
            <e-row [height]="30">
              <e-cells>
                <e-cell value="LINE ITEM"></e-cell>
                @if (spendType !== SPEND_TYPES.BUDGET) {
                  <e-cell value="COMMITMENT NAME"></e-cell>
                  <e-cell value="COMPANY NAME"></e-cell>
                }
                <e-cell [value]="spendType === SPEND_TYPES.BUDGET ? 'BUDGET' : 'DOLLARS'"></e-cell>
                <e-cell value="YEAR TOTAL"></e-cell>
                <e-cell *ngFor="let month of months" [value]="month"></e-cell>
              </e-cells>
            </e-row>
            @for (lineItem of items; track lineItem.id) {
              @if (spendType === SPEND_TYPES.BUDGET) {
                <e-row [height]="30">
                  <e-cells>
                    <e-cell [value]="lineItem.name"></e-cell>
                    <e-cell format="$#,##0.00" [formula]="lineItem.budgetTotalFormula"></e-cell>
                    <e-cell format="$#,##0.00" [formula]="lineItem.yearTotalFormula"></e-cell>
                    <e-cell
                      format="$#,##0.00"
                      [validation]="{ type: 'Decimal', operator: 'NotEqualTo', value1: '' }"
                      *ngFor="let month of months; let monthIndex = index"
                      [value]="getBudgetValue(lineItem, year, monthIndex)"
                    ></e-cell>
                  </e-cells>
                </e-row>
              } @else {
                @if (spendType === SPEND_TYPES.BUDGET_COMMITMENTS) {
                  <e-row [height]="30">
                    <e-cells>
                      <e-cell [value]="lineItem.name"></e-cell>
                      <e-cell [value]="''"></e-cell>
                      <e-cell [value]="''"></e-cell>
                      <e-cell format="$#,##0.00" [formula]="lineItem.budgetTotalFormula"></e-cell>
                      <e-cell format="$#,##0.00" [formula]="lineItem.yearTotalFormula"></e-cell>
                      <e-cell
                        format="$#,##0.00"
                        [validation]="{ type: 'Decimal', operator: 'NotEqualTo', value1: '' }"
                        *ngFor="let month of months; let monthIndex = index"
                        [value]="getBudgetValue(lineItem, year, monthIndex)"
                      ></e-cell>
                    </e-cells>
                  </e-row>
                }

                @for (commitedItem of lineItem.committed_items; track commitedItem.id) {
                  <e-row [height]="30">
                    <e-cells>
                      <e-cell [value]="lineItem.name"></e-cell>
                      <e-cell
                        [value]="
                          commitedItem.type === 'forecast_modification'
                            ? ANTICIPATED_COST_TEXT
                            : commitedItem.commitment_title
                        "
                      ></e-cell>
                      <e-cell [value]="commitedItem.commitment_name || ''"></e-cell>
                      <e-cell format="$#,##0.00" [formula]="commitedItem.dollarsTotalFormula"></e-cell>
                      <e-cell format="$#,##0.00" [formula]="commitedItem.yearTotalFormula"></e-cell>
                      <e-cell
                        format="$#,##0.00"
                        [validation]="{ type: 'Decimal', operator: 'NotEqualTo', value1: '' }"
                        *ngFor="let month of months; let monthIndex = index"
                        [value]="getBudgetValue(commitedItem, year, monthIndex)"
                      ></e-cell>
                    </e-cells>
                  </e-row>
                }
              }
            }

            @if (spendType !== SPEND_TYPES.BUDGET_COMMITMENTS) {
              <e-row *ngFor="let row of emptyRowsBeforeTotal" [height]="30"> </e-row>
              <e-row [height]="30">
                <e-cells>
                  <e-cell [value]="'Total'"></e-cell>
                  @if (spendType !== SPEND_TYPES.BUDGET) {
                    <e-cell></e-cell>
                    <e-cell></e-cell>
                  }
                  <e-cell format="$#,##0.00" *ngFor="let formula of totalCellFormulas" [formula]="formula"></e-cell>
                </e-cells>
              </e-row>
              <e-row [height]="30"> </e-row>
            }
          </e-rows>

          <e-columns>
            <e-column [width]="160"></e-column>
            <e-column [width]="160"></e-column>
            <e-column [width]="160"></e-column>
            @if (spendType !== SPEND_TYPES.BUDGET) {
              <e-column [width]="160"></e-column>
              <e-column [width]="160"></e-column>
            }
            <e-column *ngFor="let month of months" [width]="110"></e-column>
            <!--            <e-column [width]=120></e-column>-->
          </e-columns>
        </e-sheet>
      </e-sheets>
    </ejs-spreadsheet>
  </ng-container>
</div>

<div class="content" *ngIf="loading">
  <div class="loading">Loading...</div>
</div>
