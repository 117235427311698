<!-- so why these ng-containers with ngSwitch and ngFor of keys? Because: -->
<!--"Never spend 6 minutes doing something by hand when you can spend 6 hours failing to automate it"-->
<div class="projects-table-wrapper" [ngClass]="{ manager: user.isManager, contractor: !user.isManager }">
  <div class="grid-table">
    <div class="grid-row header-row">
      <ng-container *ngFor="let header of headers; let i = index">
        <ng-container [ngSwitch]="header.key">
          <ng-container *ngSwitchCase="'attributes'">
            <div class="item attributes">
              <div class="inner-container">
                <span class="select-label">{{ header.name }}</span>
                <mat-form-field class="input no-preview">
                  <mat-select multiple [formControl]="attributesControl">
                    <mat-option [value]="'all'">Show All</mat-option>
                    <mat-option *ngFor="let atr of PROJECT_ATTRIBUTES" [value]="atr.id">{{ atr.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'statusId'">
            <div class="item status">
              <div class="inner-container">
                <span class="select-label">{{ header.name }}</span>
                <mat-form-field class="input no-preview no-preview borderless">
                  <mat-select multiple [formControl]="statusControl">
                    <mat-option *ngFor="let status of allStatuses" [value]="status.id">{{ status.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'complete'">
            <div
              matTooltipPosition="below"
              matTooltip="{{ viewType === VIEWS.MANAGER ? header.tooltip : header.tooltipContractor }}"
              (click)="sortBy(i)"
              class="item header-{{ header.key }}"
            >
              <div class="inner-container">
                <span>
                  {{ header.name }}
                </span>
                <app-sort-arrow [hide]="lastSortedBy !== i" [isDesc]="!lastSortedAscending[i]"></app-sort-arrow>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div
              matTooltipPosition="below"
              matTooltip="{{ header.tooltip }}"
              (click)="sortBy(i)"
              class="item header-{{ header.key }}"
            >
              <div class="inner-container">
                <span>
                  {{ header.name }}
                </span>
                <app-sort-arrow [hide]="lastSortedBy !== i" [isDesc]="!lastSortedAscending[i]"></app-sort-arrow>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div *ngIf="tableData.length === 0 && !isLoading" class="error-msg">No Projects.</div>
  <div *ngIf="tableData.length === 0 && isLoading" class="flex w-full h-full items-center justify-center">
    <div class="notify-msg animate-pulsating-border">
      <div class="icon-logo-box-hex"></div>
    </div>
  </div>

  <ng-scrollbar appearance="compact" orientation="vertical" class="std-scrollbar">
    <div class="grid-table">
      <div *ngFor="let project of tableData" class="grid-row project-row">
        <ng-container *ngFor="let key of dataKeys">
          <ng-container [ngSwitch]="key">
            <!--          this ngSwitch thing is genius-->
            <ng-container *ngSwitchCase="'title'">
              <div (click)="navigateToProject(project.id, project.status)" class="item title">
                <div>{{ project.title }}</div>
                <div class="address">{{ project.project_property?.formatted_address }}</div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'complete'">
              <div class="item">
                <app-progress-bar [percent]="project.complete"></app-progress-bar>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'attributes'">
              <div class="item attributes">
                <span [ngClass]="{ inactive: !project.attributes.capex }" class="icon-capex"></span>
                <span [ngClass]="{ inactive: !project.attributes.opex }" class="icon-opex"></span>
                <span
                  [ngClass]="{ inactive: !project.attributes.priority }"
                  class="icon-attention icon-priority"
                ></span>
                <span
                  [ngClass]="{ inactive: !project.attributes.contracts_approved }"
                  class="icon-checkbox-round icon-approved"
                ></span>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'start_date'">
              <div class="item">
                {{ project[key] | datepipe: UTC_DATE }}
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'statusId'">
              <div class="item status">
                <app-project-bubble
                  (resetData)="updateProjectStatus($event)"
                  [project]="project"
                  [isDropDown]="true"
                  [center]="false"
                  [allStatuses]="allStatuses"
                ></app-project-bubble>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'approved'">
              <div [matTooltip]="project[key] ? 'Approved' : 'Pending'" class="item approved">
                <span *ngIf="project[key] === true" class="icon-approved"></span>
                <span *ngIf="project[key] === false" class="icon-attention"></span>
              </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <ng-container [ngSwitch]="isNumber(project[key])">
                <ng-container *ngSwitchCase="true">
                  <div class="item" [ngClass]="{ negative: project[key] < 0 }">
                    <span
                      [matTooltipClass]="{ 'negative-tooltip': project[key] < 0 }"
                      [matTooltip]="project[key] !== 0 ? (project[key] | money: 'positiveComa') : '$ 0'"
                    >
                      {{ project[key] | money: 'shortSimpleSpace' }}
                    </span>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                  <div class="item">
                    {{ project[key] }}
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-scrollbar>
  <div *ngIf="showTotals" class="grid-table totals">
    <div class="grid-row">
      <div class="item">Totals</div>
      <div class="item">{{ totals.numberOfProjects }} projects</div>
      <div [ngClass]="{ 'visibility-hidden': viewType === VIEWS.CONTRACTOR }">
        <span
          [matTooltipClass]="{ 'negative-tooltip': totals.current_budget < 0 }"
          [matTooltip]="totals.current_budget | money: 'positiveComa'"
          [ngClass]="{ negative: totals.current_budget < 0 }"
          class="item"
        >
          {{ totals.current_budget | money: 'shortSimpleSpace' }}
        </span>
      </div>

      <div [ngClass]="{ 'visibility-hidden': viewType === VIEWS.CONTRACTOR }">
        <span
          [matTooltipClass]="{ 'negative-tooltip': totals.total_committed < 0 }"
          [matTooltip]="totals.total_committed | money: 'positiveComa'"
          [ngClass]="{ negative: totals.total_committed < 0 }"
          class="item"
        >
          {{ totals.total_committed | money: 'shortSimpleSpace' }}
        </span>
      </div>

      <div>
        <span
          *ngIf="viewType === VIEWS.MANAGER"
          [matTooltipClass]="{ 'negative-tooltip': totals.forecasts_to_complete < 0 }"
          [matTooltip]="totals.forecasts_to_complete | money: 'positiveComa'"
          [ngClass]="{ negative: totals.forecasts_to_complete < 0 }"
          class="item"
        >
          {{ totals.forecasts_to_complete | money: 'shortSimpleSpace' }}
        </span>
        <span
          *ngIf="viewType === VIEWS.CONTRACTOR"
          [matTooltipClass]="{ 'negative-tooltip': totals.proposal_sum < 0 }"
          [matTooltip]="totals.proposal_sum | money: 'positiveComa'"
          [ngClass]="{ negative: totals.proposal_sum < 0 }"
          class="item"
        >
          {{ totals.proposal_sum | money: 'shortSimpleSpace' }}
        </span>
      </div>

      <div>
        <span
          *ngIf="viewType === VIEWS.MANAGER"
          [matTooltipClass]="{ 'negative-tooltip': totals.actuals < 0 }"
          [matTooltip]="totals.actuals | money: 'positiveComa'"
          [ngClass]="{ negative: totals.actuals < 0 }"
          class="item"
        >
          {{ totals.actuals | money: 'shortSimpleSpace' }}
        </span>
        <span
          *ngIf="viewType === VIEWS.CONTRACTOR"
          [matTooltipClass]="{ 'negative-tooltip': totals.total_committed < 0 }"
          [matTooltip]="totals.total_committed | money: 'positiveComa'"
          [ngClass]="{ negative: totals.total_committed < 0 }"
          class="item"
        >
          {{ totals.total_committed | money: 'shortSimpleSpace' }}
        </span>
      </div>

      <div *ngIf="viewType === VIEWS.CONTRACTOR">
        <span
          [matTooltipClass]="{ 'negative-tooltip': totals.actuals < 0 }"
          [matTooltip]="totals.actuals | money: 'positiveComa'"
          [ngClass]="{ negative: totals.actuals < 0 }"
          class="item"
        >
          {{ totals.actuals | money: 'shortSimpleSpace' }}
        </span>
      </div>

      <div class="item">
        <app-progress-bar [percent]="totals.complete"></app-progress-bar>
      </div>
    </div>
  </div>
</div>
