<app-general-sidebar-header [section]="true" title="Budget Adjustment Log">
  <div section class="pl-2.5 py-0.5 uppercase text-base font-bold">Budget Adjustments</div>
</app-general-sidebar-header>

<ng-scrollbar orientation="vertical" appearance="compact" class="std-scrollbar flex-1">
  <div class="py-5 px-7 mx-0.5 h-full flex flex-col gap-10">
    @for (logGroup of groupLogDate; track logGroup.date) {
      <div class="flex flex-col gap-5">
        @for (log of logGroup.logs; track log.id) {
          <div class="flex flex-col">
            <span class="text-shade_2 font-bold text-xs uppercase border-bottom border-b-2 pb-0.5">{{
              logGroup.date | dayjsFormatter: 'LL'
            }}</span>

            <div class="row">
              <div class="title">Amount</div>
              <div class="value" [ngClass]="{ negative: log.value < 0 }">
                {{ log.value | money: 'positiveComa' }}
              </div>
            </div>
            <div class="row">
              <div class="title">From</div>
              <div class="value">
                {{ log.is_external_adjustment ? 'External Source ' : log.from_name }}
              </div>
            </div>
            <div class="row">
              <div class="title">Towards</div>
              <div class="value">{{ log?.to_name }}</div>
            </div>
            <div class="row">
              <div class="title">Reason & Notes</div>
              <div class="value">{{ log.note }}</div>
            </div>
          </div>
        }
      </div>
    }

    @if (!groupLogDate.length) {
      <div class="flex flex-col items-center justify-center h-full">
        <span class="text-shade_2 text-size_sm">No budget adjustments have been made</span>
      </div>
    }
  </div>
</ng-scrollbar>

<div class="flex items-center py-4 px-8">
  <app-simple-button
    [wrapperClass]="{
      'rounded text-sm font-semibold': true
    }"
    class="flex ml-auto"
    (click)="close()"
  >
    Close
  </app-simple-button>
</div>
