import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subject } from 'rxjs';
import { ShortenFileNamePipe } from '../../pipes/framework/shorten-file-name.pipe';
import { takeUntil } from 'rxjs/operators';
import { UploadDropDirective } from '../../directives/upload-drop.directive';
import { NotificationsService } from '../../services/notifications.service';
import { UploadHiddenInputComponent } from '../upload/upload-hidden-input/upload-hidden-input.component';
import {
  DefaultUploadUiComponent,
  IFile,
} from '../upload/default-upload-ui/default-upload-ui.component';
import {
  IMetaData,
  PresignedFileUploadService,
} from '../../services/presigned-file-upload.service';

/**
 * General upload component mainly used for input file upload
 * Html can be projected from outside
 * To be extended with other use cases if needed
 *
 * @example
 * <!--Example to be used from parent: -->
 * <!--<ng-template #projectedContent>-->
 * <!--  <div>This content is projected from parent</div>-->
 * <!--</ng-template>-->
 */
@Component({
  selector: 'app-input-upload',
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
    ShortenFileNamePipe,
    UploadDropDirective,
    UploadHiddenInputComponent,
    DefaultUploadUiComponent,
  ],
  templateUrl: './input-upload.component.html',
})
export class InputUploadComponent implements OnInit, OnDestroy {
  @ContentChild('projectedContent') projectedContentTemplate: TemplateRef<any>;
  @Input() uploadFiles$: Subject<void>;
  @Input() disableUpload = false;
  @Input() hideRemoveFileIcon = false;
  @Input() files: (File | IFile | any)[] = [];
  @Input() allowMultipleFiles = false;
  @Output() onFilesChange = new EventEmitter();
  @Output() onUploadFinished = new EventEmitter<any>();
  @Output() removeFile = new EventEmitter<{ file: File | any; index: number }>();
  private isDestroyed$ = new Subject();

  constructor(
    private notif: NotificationsService,
    private presignedFileUploadService: PresignedFileUploadService,
  ) {}

  private uploadMetaData: Partial<IMetaData>;

  get metadata() {
    return this.uploadMetaData;
  }

  @Input() set metaData(data: Partial<IMetaData>) {
    if (data) {
      this.uploadMetaData = data;
    }
  }

  ngOnInit() {
    this.uploadFiles$?.pipe(takeUntil(this.isDestroyed$)).subscribe((_) => {
      this.uploadFiles();
    });
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  /**
   * Handles file change event, emits files to parent component
   * Parent has to handle the files as needed in its context and send back the current state of files to this component
   * (e.g. current files + local files or just local files)
   * @param files - chosen files to be uploaded
   */
  onFileInputChange(files: (File | IFile)[]) {
    this.onFilesChange.emit(files);
  }

  /**
   * Add files to upload queue from drag and drop event
   * @param droppedFiles
   */
  onFileDropped(droppedFiles: File[]) {
    const files = Array.from(droppedFiles);
    if (files.length > 1 && !this.allowMultipleFiles) {
      this.notif.showError('You can only upload one file in this section');
      return;
    }
    this.onFilesChange.emit([...files]);
  }

  onRemoveFile({ file, index }: { file: File | any; index: number }) {
    this.removeFile.emit({ file, index });
  }

  /**
   * Start uploading files to backend
   * @private
   */
  private uploadFiles() {
    setTimeout(() => {
      this.presignedFileUploadService
        .uploadMultipleFilesToS3(
          this.files,
          this.files.map((_) => this.metadata),
        )
        .subscribe(
          (uploadedFileStatuses) => {
            this.notif.showSuccess('Files uploaded successfully.');
            this.onUploadFinished.emit(uploadedFileStatuses);
          },
          (error) => {
            console.warn('Error uploading files to S3', error);
            this.notif.showError('Error uploading files.');
            this.onUploadFinished.emit(error);
          },
        );
    });
  }
}
