<div class="cashflow-table-wrapper relative">
  <app-dynamic-projected-table
    emptyTableMessage="There is no data corresponding to the filters."
    [isEmpty]="(notEmptyData$ | async)?.length === 0"
    [isLoading]="isLoading$ | async"
    [useHorizontalScrollbar]="false"
  >
    <div tableHeader class="grid-table header">
      <div class="grid-row">
        <div class="item flex items-center">
          <div [ngClass]="{ 'cursor-pointer': isViewProject }" (click)="toggleNameSorting()">
            {{ isViewProject ? 'Budget Lines' : 'Projects' }}
          </div>
          <app-sort-arrow
            *ngIf="isViewProject"
            class="ml-1 mt-0.5"
            [hide]="(sortNameBy$ | async) === 'row_number' || !isViewProject"
            [isDesc]="(sortNameBy$ | async) === 'desc'"
          ></app-sort-arrow>
          <p-inputSwitch
            [(ngModel)]="showZeroDollarLineItems"
            (onChange)="onZeroDollarChange($event)"
            [pTooltip]="showZeroDollarLineItems ? 'Hide zero dollar lines' : 'Show zero dollar lines'"
            tooltipPosition="right"
            class="ml-6"
          ></p-inputSwitch>
        </div>
        <div class="item">
          <span> Year </span>
        </div>
        <div class="item month" *ngFor="let month of months">
          {{ month }}
        </div>
      </div>
    </div>
    <!--    [ngStyle]="{
        'grid-template-rows': 'repeat(' + (filteredData.length + 1) + ', auto) 1fr'
      }"-->
    <div projectedTable class="grid-table content">
      @for (data of data$ | async; track data.line_id || data.project_id; let lineIndex = $index) {
        <div *ngIf="data?.table_data" class="grid-row">
          <div
            class="item"
            [ngClass]="{
              'cursor-default': isViewProject,
              'cursor-pointer': !isViewProject
            }"
            (click)="openViewProject(data)"
          >
            <app-faded-text>
              {{ data.name }}
            </app-faded-text>
          </div>
          <div
            [tooltipStyleClass]="data.table_data.year_total_actuals_or_forecast < 0 ? 'negative' : ''"
            [pTooltip]="data.table_data.year_total_actuals_or_forecast | money: 'positiveComa'"
            tooltipPosition="bottom"
            [ngClass]="{ negative: data.table_data.year_total_actuals_or_forecast < 0 }"
            class="item"
          >
            {{ data.table_data.year_total_actuals_or_forecast | money: 'shortSignSimpleSpace' }}
          </div>
          <div
            class="item cashflow-value"
            [ngClass]="{ negative: actOrFcst < 0 }"
            *ngFor="let actOrFcst of data.table_data?.monthly_actuals_or_forecast ?? []"
          >
            <span
              [tooltipStyleClass]="actOrFcst < 0 ? 'negative' : ''"
              [pTooltip]="actOrFcst | money: 'positiveComa'"
              tooltipPosition="bottom"
            >
              {{ actOrFcst | money: 'shortSignSimpleSpace' }}
            </span>
          </div>
        </div>
      }
    </div>

    <div tableFooter>
      <div class="grid-table totals">
        <div class="grid-row">
          <div class="item">Total</div>
          <!--          <div class="item icon-graph visibility-none pointer-none"></div>-->
          <div
            class="item"
            [tooltipStyleClass]="(totals$ | async)?.actuals_or_forecast < 0 ? 'negative' : ''"
            [pTooltip]="(totals$ | async)?.actuals_or_forecast | money: 'positiveComa'"
            [ngClass]="{ negative: (totals$ | async)?.actuals_or_forecast < 0 }"
            tooltipPosition="top"
          >
            {{ (totals$ | async)?.actuals_or_forecast | money: 'shortSignSimpleSpace' }}
          </div>
          @for (total of (totals$ | async)?.monthly_actuals_or_forecast; track $index) {
            <div [ngClass]="{ negative: total < 0 }" class="item totals-value">
              <span
                [tooltipStyleClass]="total < 0 ? 'negative' : ''"
                [pTooltip]="total | money: 'positiveComa'"
                tooltipPosition="top"
              >
                {{ total | money: 'shortSignSimpleSpace' }}
              </span>
            </div>
          }
        </div>
      </div>
    </div>
  </app-dynamic-projected-table>
</div>
