import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { commitmentsSelectors } from '../../../../store/commitments/commitments.selectors';
import { AppState } from '../../../../store/app-state';
import { Store } from '@ngrx/store';
import { fillIn } from '../../../../../assets/styles/animations';

import {
  COMMITMENT_APPROVAL_STATUS,
  CommitmentContractor,
} from '../../../../store/commitments/commitments.types';
import { TemporaryContractorIconComponent } from '../../../temporary-contractor-icon/temporary-contractor-icon.component';
import { AsyncPipe, CommonModule } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';
import { BehaviorSubject, Subject } from 'rxjs';
import { COMMITMENTS_TYPE } from '../../../constants/interaction-bar.constants';
import { takeUntil } from 'rxjs/operators';

export enum COMPANY_DROPDOWN_SELECT_TYPES {
  CHANGE_DROPDOWN,
  SELECT,
}

export interface ICompanyDropdownSelectEvent {
  type: COMPANY_DROPDOWN_SELECT_TYPES;
  data?: CommitmentContractor;
  isTemporaryContractor?: boolean;
}
@Component({
  selector: 'app-company-dropdown',
  templateUrl: './company-dropdown.component.html',
  styleUrls: ['./company-dropdown.component.scss'],
  animations: [fillIn],
  standalone: true,
  imports: [TemporaryContractorIconComponent, AsyncPipe, NgScrollbar, CommonModule],
})
export class CompanyDropdownComponent implements AfterViewInit, OnDestroy {
  @Output() optionSelected = new EventEmitter<ICompanyDropdownSelectEvent>();
  @Input() hideAddCompany = false;
  @Input() commitmentType: COMMITMENTS_TYPE;

  allContractors$ = this.store.select(commitmentsSelectors.allContractors);
  filteredContractors$: BehaviorSubject<{
    contractors: CommitmentContractor[];
    temporary_contractors: CommitmentContractor[];
  }> = new BehaviorSubject({ contractors: [], temporary_contractors: [] });
  isDestroyed$ = new Subject();

  constructor(private store: Store<AppState>) {}

  ngAfterViewInit() {
    this.allContractors$.pipe(takeUntil(this.isDestroyed$)).subscribe((contractors) => {
      if (this.commitmentType !== COMMITMENTS_TYPE.INVOICES) {
        this.filteredContractors$.next(contractors);
        return;
      }

      const filteredContractors = contractors.contractors.filter((contractor) =>
        contractor.commitments.some(
          (commitment) =>
            commitment.approval_status === COMMITMENT_APPROVAL_STATUS.APPROVED &&
            commitment.type === COMMITMENTS_TYPE.CONTRACTS,
        ),
      );
      const filteredTemporaryContractors = contractors.temporary_contractors.filter(
        (contractor) => {
          return contractor.commitments.some(
            (commitment) =>
              commitment.approval_status === COMMITMENT_APPROVAL_STATUS.APPROVED &&
              commitment.type === COMMITMENTS_TYPE.CONTRACTS,
          );
        },
      );

      // only contractors with approved contracts
      this.filteredContractors$.next({
        contractors: filteredContractors,
        temporary_contractors: filteredTemporaryContractors,
      });
    });
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  selectOption(contractor: CommitmentContractor, isTemporaryContractor: boolean) {
    this.optionSelected.emit({
      type: COMPANY_DROPDOWN_SELECT_TYPES.SELECT,
      data: contractor,
      isTemporaryContractor,
    });
  }

  switchDropdown() {
    this.optionSelected.emit({ type: COMPANY_DROPDOWN_SELECT_TYPES.CHANGE_DROPDOWN });
  }
}
