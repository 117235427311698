import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AsyncPipe, JsonPipe, NgClass, NgForOf } from '@angular/common';
import { SPEND_TYPES } from '../../../../../../framework/constants/budget.constants';
import {
  defaultLineItemsTotal,
  MONTHS_KEYS,
} from '../../../../../../framework/constants/spend.constants';
import { SpendCustomInputComponent } from '../../../../../../framework/inputs/spend-custom-input/spend-custom-input.component';
import { ILineItemsTotal } from '../../../../../../store/spend/spend.interfaces';
import { getIsLoading as areTemplatesLoading } from '../../../../../../store/settings/settings.selectors';
import { Store } from '@ngrx/store';
import { getLineItemTotals, hasCommitments } from '../../../../../../store/spend/spend.selectors';
import { addDefaultLineItem } from '../../../../../../store/spend/spend.actions';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-budget-line-item-totals',
  standalone: true,
  imports: [AsyncPipe, NgClass, SpendCustomInputComponent, NgForOf, JsonPipe, TooltipModule],
  templateUrl: './budget-line-item-totals.component.html',
  styleUrl: 'budget-line-item-totals.component.scss',
})
export class BudgetLineItemTotalsComponent implements OnInit, OnDestroy {
  protected readonly store = inject(Store);

  @Input({ required: true }) selectedBudgetType: SPEND_TYPES;
  @Input({ required: true }) hideTotals: boolean;

  protected readonly MONTHS_KEYS = MONTHS_KEYS;
  protected readonly SPEND_TYPES = SPEND_TYPES;

  protected readonly areTemplatesLoading$ = this.store.select(areTemplatesLoading);
  protected readonly isDestroyed$ = new Subject<boolean>();
  protected readonly totalLineItems$ = this.store.select(getLineItemTotals);
  protected readonly hasCommitments$ = this.store.select(hasCommitments);
  protected totals: ILineItemsTotal = defaultLineItemsTotal;

  trackByIndex = (index: number) => index;
  addLineToStore() {
    this.store.dispatch(addDefaultLineItem());
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  ngOnInit(): void {
    this.totalLineItems$.pipe(takeUntil(this.isDestroyed$)).subscribe((totals) => {
      this.totals = totals;
    });
  }
}
