<div class="bg-white shadow p-2 rounded sk-multi-select">
  <ng-scrollbar appearance="native" class="std-scrollbar min-w-[23rem] min-h-[10rem]">
    <p-checkbox
      (ngModelChange)="checkAll()"
      [(ngModel)]="areAllSelected"
      [binary]="true"
      inputId="allCheckbox"
    ></p-checkbox>
    <label for="allCheckbox" class="text-size_l cursor-pointer text-color_black font-semibold"> All Properties </label>
    <div *ngFor="let option of options; trackBy: trackByFn" class="flex items-center gap-1 py-1">
      <p-checkbox
        (onChange)="onChange()"
        [value]="option.id"
        [(ngModel)]="_selectedIds"
        [inputId]="option.id"
      ></p-checkbox>
      <label
        class="text-size_l overflow-hidden text-ellipsis cursor-pointer text-color_black font-semibold"
        [for]="option.id"
      >
        {{ option[key] }}
      </label>
    </div>
  </ng-scrollbar>
</div>
