import { AfterViewInit, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { DropdownComponent } from '../../../../../../framework/inputs/dropdown/dropdown.component';
import { FloatingInputComponent } from '../../../../../../framework/inputs/floating-input/floating-input.component';
import { InputCalendarComponent } from '../../../../../../framework/inputs/input-calendar/input-calendar.component';
import {
  defaultLineItemsTotal,
  defaultMonthlyData,
  DISTRIBUTION_TYPES,
  distributionTypes,
  MONTHS_KEYS,
} from '../../../../../../framework/constants/spend.constants';
import { SPEND_TYPES } from '../../../../../../framework/constants/budget.constants';
import { SpendCustomInputComponent } from '../../../../../../framework/inputs/spend-custom-input/spend-custom-input.component';
import { TooltipModule } from 'primeng/tooltip';
import { Subject } from 'rxjs';
import {
  ILineItemExtended,
  ISpendDistribution,
} from '../../../../../../store/spend/spend.interfaces';
import cloneDeep from 'lodash/cloneDeep';
import {
  deleteLineItem,
  updateDistribution,
  updateLineItem,
} from '../../../../../../store/spend/spend.actions';
import moment from 'moment';
import { CurrentUserService } from '../../../../../../services/current-user.service';
import { getSelectedYear, hasCommitments } from '../../../../../../store/spend/spend.selectors';
import { Store } from '@ngrx/store';
import { BudgetLineItemTableHeaderComponent } from '../budget-line-item-table-header/budget-line-item-table-header.component';
import { takeUntil } from 'rxjs/operators';
import { SpendDistributionService } from '../../../../../../services/spend-distribution.service';

@Component({
  selector: 'app-budget-line-item',
  standalone: true,
  imports: [
    AsyncPipe,
    CdkDrag,
    CdkDragHandle,
    DropdownComponent,
    FloatingInputComponent,
    InputCalendarComponent,
    SpendCustomInputComponent,
    NgClass,
    NgForOf,
    NgIf,
    TooltipModule,
    BudgetLineItemTableHeaderComponent,
  ],
  templateUrl: './budget-line-item.component.html',
  styleUrl: './budget-line-item.component.scss',
})
export class BudgetLineItemComponent implements OnInit, AfterViewInit, OnDestroy {
  protected readonly userService = inject(CurrentUserService);
  protected readonly store = inject(Store);
  protected readonly spendDistributionService = inject(SpendDistributionService);

  protected readonly distributionTypes = distributionTypes;
  protected readonly MONTHS_KEYS = MONTHS_KEYS;
  protected readonly DISTRIBUTION_TYPES = DISTRIBUTION_TYPES;
  protected readonly SPEND_TYPES = SPEND_TYPES;
  protected readonly totals = defaultLineItemsTotal;
  private readonly DATE_FORMAT = 'YYYY-MM-DD';

  @Input({ required: true }) selectedBudgetType: SPEND_TYPES = SPEND_TYPES.BUDGET;

  private _item: ILineItemExtended;
  @Input({ required: true }) set item(value: ILineItemExtended) {
    this._item = value;
    this.setDisabledMonth(value);
  }
  get item(): ILineItemExtended {
    return this._item;
  }

  @Input({ required: true }) minStartDate: string;
  @Input({ required: true }) canDelete: boolean;

  isDestroyed$: Subject<boolean> = new Subject<boolean>();
  hasCommitments$ = this.store.select(hasCommitments);
  selectedYear$ = this.store.select(getSelectedYear);

  selectedYear = 0;

  durationMonths = Array(72)
    .fill(0)
    .map((_, i) => i + 1)
    .map((i) => {
      return {
        label: i === 1 ? `${i} month` : `${i} months`,
        value: i,
      };
    });

  ngOnInit(): void {
    this.selectedYear$.pipe(takeUntil(this.isDestroyed$)).subscribe((year) => {
      this.selectedYear = year;
      this.setDisabledMonth(this.item);
    });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  deleteLineFromStore(id: number) {
    this.store.dispatch(deleteLineItem({ id }));
  }

  setDisabledMonth(item: ILineItemExtended) {
    const availableMonths = this.spendDistributionService.getDisabledMonths(
      this.selectedYear,
      item,
    );

    // disable all months that should be non-editable
    Object.keys(item.monthly_data).forEach((monthKey, index) => {
      item.monthly_disable[monthKey] = !availableMonths[index]; // key starts with 1
    });
  }

  clearName(item: ILineItemExtended) {
    if (item.name === 'Project Cost') {
      item.name = '';
    }
  }

  showName(item: ILineItemExtended) {
    if (!item.name) {
      item.name = 'Project Cost';
      this.registerChangeName(item.name, item);
    }
  }

  registerChangeName(name: string, item: ILineItemExtended) {
    const lineItem: ILineItemExtended = cloneDeep(item);
    lineItem.name = name;
    this.store.dispatch(
      updateLineItem({ lineItem: this.spendDistributionService.extendedItemToOriginal(lineItem) }),
    );
  }

  registerChange(inputValue: number, item: ILineItemExtended, month: string) {
    const newValue = inputValue;
    const lineItem: ILineItemExtended = cloneDeep(item);
    let budget = lineItem.budget.find((el) => el.year === this.selectedYear);
    if (!budget) {
      budget = {
        year: this.selectedYear,
        monthly_budget: { ...defaultMonthlyData },
      };
      lineItem.budget.push(budget);
    }

    if (!isNaN(newValue)) {
      budget.monthly_budget[month] = newValue;
    }

    lineItem.distribution = DISTRIBUTION_TYPES.MANUAL;

    this.store.dispatch(
      updateLineItem({ lineItem: this.spendDistributionService.extendedItemToOriginal(lineItem) }),
    );
  }

  setItemTotal(total: string, item: ILineItemExtended) {
    const value = parseFloat(total);
    item.project_total = isNaN(value) ? 0 : value;
  }

  setStartDate(date: string, item: ILineItemExtended) {
    item.start_date = date;
  }

  setItemDuration(duration: number, item: ILineItemExtended) {
    item.duration = duration;
  }

  setItemDistribution(distribution: DISTRIBUTION_TYPES, item: ILineItemExtended) {
    item.distribution = distribution;
  }

  calcDistribution(item: ILineItemExtended) {
    const distribution: ISpendDistribution = {
      distribution: item.distribution,
      start_date: moment(item.start_date).format(this.DATE_FORMAT),
      field: 'monthly_budget',
      duration: item.duration,
      budget: item.project_total,
    };
    this.setDisabledMonth(this.item);
    this.store.dispatch(updateDistribution({ lineId: item.id, distribution }));
  }

  trackByIndex = (index: number) => index;
}
