import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MONTHS } from '../constants/view.constants';
import moment from 'moment/moment';
import { CdkDrag, CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';
import { NotificationsService } from '../../services/notifications.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { GanttService } from '../../services/gantt.service';
import { NgForm } from '@angular/forms';
import { viewProjectSelectors } from '../../store/view-project/view-project.selectors';
import { Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField } from '@angular/material/form-field';
import { InputCalendarComponent } from '../inputs/input-calendar/input-calendar.component';
import { NgFor, NgIf, NgStyle } from '@angular/common';

const MAX_DURATION = 72;

@Component({
  selector: 'app-gantt-chart',
  templateUrl: './gantt-chart.component.html',
  styleUrls: ['./gantt-chart.component.scss'],
  providers: [NgForm],
  standalone: true,
  imports: [
    NgScrollbar,
    NgFor,
    InputCalendarComponent,
    MatFormField,
    MatSelect,
    MatOption,
    NgIf,
    NgStyle,
    CdkDrag,
    MatButton,
    MatIcon,
  ],
})

// later
// todo autoscroll
// todo refactor
export class GanttChartComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() closeGantt = new EventEmitter<string>();
  @Output() showLoading = new EventEmitter<string>();
  @Output() closeLoading = new EventEmitter<string>();

  set data(project) {
    this._projectData = project;
    this.calculateMonthsYears();
    this.closeLoading.emit();
    this.setAllHandleTransform();
    console.log(this._projectData);
  }

  get data() {
    return this._projectData;
  }

  constructor(
    private ngZone: NgZone,
    private notif: NotificationsService,
    private changeDetectorRef: ChangeDetectorRef,
    private ganttService: GanttService,
    private store: Store,
  ) {}

  @ViewChildren('dragHandleRight') dragHandleRight: QueryList<ElementRef>;
  @ViewChildren('dragHandleLeft') dragHandleLeft: QueryList<ElementRef>;
  @ViewChildren('resizeBox') resizeBox: QueryList<ElementRef>;
  @ViewChildren('taskName') mainTaskNameInput: QueryList<ElementRef>;
  @ViewChildren('subTaskName') subTaskNameInput: QueryList<ElementRef>;
  @ViewChild('ngScrollbar', { static: true }) ngScrollbar: NgScrollbar;
  @ViewChild('tableHeader', { static: true }) monthsHeader: ElementRef;
  @ViewChild('tasks') tasks: ElementRef;
  _projectData;
  years = [];
  months = [];
  monthsDistribution = ''; // how much space each month takes in header
  totalDays: number;
  gridWidth = 3; // px
  monthWidth = this.gridWidth * 30;
  gridColumnEnd;
  gridColumnStart;
  right;
  startDate;
  endDate;
  movedBlock;
  updateMonth = 30;
  paginationNumber = 0;
  taskName: any;
  previousTaskName = '';
  minimumDaysDistance = 10;

  nrOfTaskBgColorFctCall = 0;
  realTaskId = 0;
  highestTaskId = 0;
  availableDurations = [];

  projectId$ = this.store.select(viewProjectSelectors.getProjectId);
  projectId: number;
  isDestroyed$ = new Subject();

  ngOnInit(): void {
    this.ngScrollbar.nativeElement.addEventListener(
      'scroll',
      (e) => this.onHorizontalScroll(e),
      true,
    );
    this.showLoading.emit();

    this.projectId$.pipe(takeUntil(this.isDestroyed$)).subscribe((projectId) => {
      this.projectId = projectId;
    });

    this.projectId$
      .pipe(
        takeUntil(this.isDestroyed$),
        switchMap((projectId) => this.ganttService.getAll(projectId)),
        catchError((err) => {
          console.warn('GanttDataSubscription error: ', err);
          this.notif.showError('Error while fetching Gantt data.');
          return of(null);
        }),
        filter((data) => !!data),
        map((data) => {
          const start = moment(data.start_date);
          return {
            ...data,
            project_start_date: start.format('YYYY-MM-DD'),
            end_date: start.add(5, 'years').format('YYYY-MM-DD'),
          };
        }),
      )
      .subscribe((data) => {
        this.data = data;
      });
  }

  ngAfterViewInit() {
    this.setAllHandleTransform();
    // this.setHorizontalScrollPosition();
  }

  setHorizontalScrollPosition() {
    setTimeout(() => {
      const scrollBarX = this.ngScrollbar.nativeElement.getElementsByTagName('scrollbar-x');
      if ((scrollBarX[0] as HTMLElement) && this.tasks.nativeElement.clientWidth) {
        (scrollBarX[0] as HTMLElement).style.left = this.tasks.nativeElement.clientWidth + 'px';
      } else {
        this.setHorizontalScrollPosition();
      }
    }, 100);
  }

  setAllHandleTransform() {
    for (let idx = 0; idx < this.resizeBox.toArray().length; ++idx) {
      const rect = this.resizeBox.toArray()[idx].nativeElement.getBoundingClientRect();
      this.setHandleTransform(this.dragHandleRight.toArray()[idx].nativeElement, rect, 'right');
      this.setHandleTransform(this.dragHandleLeft.toArray()[idx].nativeElement, rect, 'left');
    }
  }

  setHandleTransform(
    dragHandle: HTMLElement,
    targetRect: ClientRect | DOMRect,
    position: 'right' | 'left',
  ) {
    const dragRect = dragHandle.getBoundingClientRect();
    if (position === 'right') {
      const onePixel = 0;
      dragHandle.style.left = targetRect.width - dragRect.width + onePixel + 'px';
    }
    if (position === 'left') {
      dragHandle.style.left = '0';
    }
  }

  dragMove(
    resizeBox: HTMLElement,
    dragHandle: HTMLElement,
    event: CdkDragMove<any>,
    position,
    task,
  ) {
    const distance = event.distance.x;
    this.ngZone.runOutsideAngular(() => {
      this.resize(dragHandle, resizeBox, distance, position, task);
      if (position === 'left') {
        event.source._dragRef.reset();
      }
    });
  }

  resize(dragHandle: HTMLElement, target: HTMLElement, distance, position, task) {
    const movedDays = Math.round(distance / this.gridWidth);
    switch (position) {
      case 'right': {
        if (!this.endDate) {
          this.endDate = task.end_date;
        }
        const potential = moment(this.endDate).clone();
        const potentialStartDate = moment(task.start_date).clone();
        potential.add(movedDays, 'days');
        if (
          this.checkData(potentialStartDate, potential) &&
          this.checkMinimumDaysLimit(potentialStartDate, potential)
        ) {
          task.end_date = this.getFormattedData(potential, 'YYYY-MM-DD');
        }
        break;
      }
      case 'left': {
        if (!this.startDate) {
          this.startDate = task.start_date;
        }

        const potential = moment(this.startDate).clone();
        const potentialEndDate = moment(task.end_date).clone();
        potential.add(movedDays, 'days');
        if (
          this.checkData(potential, potentialEndDate) &&
          this.checkMinimumDaysLimit(potential, potentialEndDate)
        ) {
          task.start_date = this.getFormattedData(potential, 'YYYY-MM-DD');
        }
        break;
      }
    }
  }

  calculateMonthsYears(): void {
    const start = moment(this._projectData.start_date);
    const end = moment(this._projectData.end_date);
    const startingYear = start.year();
    const endingYear = end.year();
    const yearsLength = endingYear - startingYear + 1;
    const monthsLength = Math.ceil(end.diff(start, 'months', true)) + 1;
    const monthsDuration = monthsLength * 2 > MAX_DURATION ? MAX_DURATION : monthsLength * 2;
    this.availableDurations = Array(monthsDuration)
      .fill(monthsDuration)
      .map((arr, i) => {
        return {
          value: i + 1,
          view: i + 1 + ' Months',
        };
      });
    this.years = Array(yearsLength)
      .fill(yearsLength)
      .map((arr, i) => {
        return {
          name: i + startingYear,
          width: 0,
        };
      });

    this.totalDays =
      Math.ceil(end.diff(start, 'days', true)) + start.date() + (end.daysInMonth() - end.date());

    let daysCounter = 0;
    let yearsCounter = 0;
    this.months = Array(monthsLength)
      .fill(monthsLength)
      .map((arr, i) => {
        let currentMonth = start.clone();
        currentMonth = currentMonth.add(i, 'months');
        const month = {
          month: currentMonth.format('MMM'),
          start: daysCounter ? daysCounter : 1,
          end: daysCounter + currentMonth.daysInMonth(),
          width: 0,
          days: currentMonth.daysInMonth() + 1,
          year: currentMonth.year(),
        };
        if (i === 0) {
          month.end++;
          daysCounter++;
        }

        daysCounter += currentMonth.daysInMonth();
        month.width = month.end - month.start;
        this.years[yearsCounter].width += month.width * this.gridWidth;
        if (month.month === 'Dec') {
          yearsCounter++;
        }
        return month;
      });
  }

  getMonths(month) {
    return MONTHS[month - 1];
  }

  isFinalMonth(month) {
    const finalMonth = 12;
    return month === finalMonth;
  }

  addRequiredSpace(placement) {
    this.monthsDistribution += placement + 'fr ';
  }

  getTaskDuration(task) {
    const start = moment(task.start_date);
    const end = moment(task.end_date);
    const duration = Math.round(end.diff(start, 'months', true));
    return duration ? duration : 1;
  }

  getTaskDurationWithUnit(task) {
    return this.getTaskDuration(task) + ' Months';
  }

  getTaskMonthStartColumn(date) {
    const startDate = moment(this._projectData.start_date);
    startDate.subtract(startDate.date() - 1, 'days');
    const taskStartDate = moment(date);
    return Math.ceil(taskStartDate.diff(startDate, 'days', true)) + 1;
  }

  getTaskMonthEndColumn(task) {
    const startDate = moment(task.start_date);
    const endDate = moment(task.end_date);
    return Math.ceil(endDate.diff(startDate, 'days', true)) + 1;
  }

  drop(event, item) {
    if (!this.movedBlock) {
      this.movedBlock = Object.assign({}, item);
    }

    const movedDays = Math.round(event.distance.x / this.gridWidth);
    const startDate = moment(this.movedBlock.start_date).add(movedDays, 'days');
    const endDate = moment(this.movedBlock.end_date).add(movedDays, 'days');

    if (this.checkData(startDate, endDate)) {
      // if inside shown interval
      item.start_date = this.getFormattedData(startDate, 'YYYY-MM-DD');
      item.end_date = this.getFormattedData(endDate, 'YYYY-MM-DD');
    }
    event.source._dragRef.reset();
  }

  private checkData(startDate, finalDate) {
    const start = moment(this._projectData.project_start_date);
    const firstDate = start.clone();
    const insideBoundary = startDate >= firstDate;
    if (!insideBoundary) {
      this.notif.showError('Task cannot start before project start date.');
    }
    return insideBoundary;
  }

  private checkMinimumDaysLimit(startDate, finalDate) {
    return finalDate.diff(startDate, 'days') >= this.minimumDaysDistance;
  }

  resetTransform(event: CdkDragEnd, task: any, draggedItem: HTMLElement) {
    this.gridColumnEnd = null;
    this.gridColumnStart = null;
    this.endDate = null;
    this.startDate = null;
    this.movedBlock = null;
    event.source._dragRef.reset();
    // todo set handle only to dragged item (later, to optimize)
    this.setAllHandleTransform();
  }

  onHorizontalScroll(event: any) {
    const pageNr = Math.floor(
      Number(event.target.scrollLeft) / (this.monthWidth * this.updateMonth),
    );
    // this.logPagination(event, pageNr);
    if (pageNr > this.paginationNumber) {
      this._projectData.end_date = moment(this._projectData.end_date)
        .clone()
        .add(this.updateMonth, 'months');
      this.paginationNumber++;
      this.notif.showLoading('Loading Gant Chart!');
      this.calculateMonthsYears();
      this.ngScrollbar.update();
      setTimeout((e) => {
        this.notif.close();
      }, 500);
    }
  }

  logPagination(event, pageNr) {
    console.log('event', event);
    console.log('pageNr', pageNr);
    console.log('scrollLeft', event.target.scrollLeft);
    console.log(
      'fractional page',
      Number(event.target.scrollLeft) / (this.monthWidth * this.updateMonth),
      'out of total loaded: ',
      this.paginationNumber,
      'each contains months:',
      this.updateMonth,
    );
  }

  removeTaskPopup(task, subtask = '') {
    this.notif.showPopup('Are you sure you want to remove task?').then((response) => {
      if (response) {
        this.removeTask(task, subtask);
      }
    });
  }

  removeTask(task, subtask = '') {
    if (subtask) {
      const subtaskIndex = task.tasks.findIndex((elem) => elem === subtask);
      task.tasks.splice(subtaskIndex, 1);
    } else {
      const taskIndex = this._projectData.tasks.findIndex((elem) => elem === task);
      this._projectData.tasks.splice(taskIndex, 1);
      task.tasks.splice(taskIndex, 1);
      this.changeDetectorRef.detectChanges();
    }
  }

  addSubtaskTask(task, row) {
    const newData = {
      name: 'New Subtask',
      start_date: task.start_date,
      end_date: this.getFormattedData(
        moment(task.start_date).clone().add(1, 'month'),
        'YYYY-MM-DD',
      ),
      task: [],
    };
    task.tasks.push(newData);
    this.checkTaskAppeared(task, row.children.length);
  }

  checkTaskAppeared(task, htmlElementLength, isMainTask = 0) {
    setTimeout(() => {
      if (task.tasks.length === Number(htmlElementLength) - isMainTask) {
        this.setAllHandleTransform();
        this.changeDetectorRef.detectChanges();
        this.ngScrollbar.update();
      } else {
        this.checkTaskAppeared(task, htmlElementLength, isMainTask);
      }
    }, 100);
  }

  addMainTask(htmlElementLength) {
    const newData = {
      name: 'New Task',
      start_date: this.getFormattedData(this._projectData.project_start_date, 'YYYY-MM-DD'),
      end_date: this.getFormattedData(
        moment(this._projectData.project_start_date).clone().add(1, 'month'),
        'YYYY-MM-DD',
      ),
      tasks: [],
    };
    this._projectData.tasks.push(newData);
    this.checkTaskAppeared(this._projectData, htmlElementLength.length, 1);
  }

  close() {
    this.closeGantt.emit();
  }

  save() {
    const ganttData = this.data;
    delete ganttData.project_start_date;
    this.ganttService.saveAll(this.projectId, ganttData).then(
      () => {
        this.notif.showSuccess('Gantt chart has been saved.');
      },
      (err) => {
        console.warn(err);
        this.notif.showError(err.error.message);
      },
    );
    this.paginationNumber = 0;
  }

  nameFocusHandler(event, input: HTMLInputElement, task, isSubtask) {
    let index;
    if (isSubtask) {
      index = this.subTaskNameInput
        ?.toArray()
        ?.findIndex((el) => el.nativeElement.value === input.value);
    } else {
      index = this.mainTaskNameInput
        ?.toArray()
        ?.findIndex((el) => el.nativeElement.value === input.value);
    }
    switch (event) {
      case 'focusin':
        this.previousTaskName = input.value;
        this.setTaskName(isSubtask, index);
        break;
      case 'focusout':
        if (!input.value) {
          this.setTaskName(isSubtask, index, this.previousTaskName);
        } else {
          task.name = input.value;
        }
        break;
      case 'change':
        this.setTaskName(isSubtask, index, input.value);
        break;
    }
  }

  private setTaskName(isSubtask, index, prev = '') {
    const name = prev;
    if (isSubtask) {
      this.subTaskNameInput.toArray()[index].nativeElement.value = name;
    } else {
      this.mainTaskNameInput.toArray()[index].nativeElement.value = name;
    }
  }

  availableTask() {
    if (!this.data || !this._projectData.tasks) {
      return 0;
    } else {
      return this.data.tasks.length;
    }
  }

  getFormattedData(date, format: string) {
    return moment(date).format(format);
  }

  taskBgColor(taskId, subTaskId) {
    enum taskColors {
      blue = '#4392F1',
      yellow = '#FFBA49',
      green = '#6FCF97',
      grey = '#A5B4DE',
    }

    if (taskId < this.highestTaskId) {
      this.realTaskId = 0;
      this.nrOfTaskBgColorFctCall = 0;
      this.highestTaskId = 0;
    } else {
      if (taskId === 0 && subTaskId === undefined) {
        this.realTaskId = 0;
        this.nrOfTaskBgColorFctCall = 0;
        this.highestTaskId = 0;
      } else {
        this.highestTaskId = taskId;
      }
    }

    this.realTaskId++;
    this.nrOfTaskBgColorFctCall++;

    switch (this.nrOfTaskBgColorFctCall) {
      case 1: {
        return taskColors.blue;
      }
      case 2: {
        return taskColors.yellow;
      }
      case 3: {
        return taskColors.green;
      }
      case 4: {
        this.nrOfTaskBgColorFctCall = 0;
        return taskColors.grey;
      }
    }
  }

  durationChange(task, duration) {
    task.end_date = this.getFormattedData(
      moment(task.start_date).add(duration, 'months'),
      'YYYY-MM-DD',
    );
  }

  getDurationDropDown(tasks) {
    return this.getTaskDuration(tasks);
  }

  registerStartDateChange(item: any, newDate: string) {
    const newStartDate = moment.utc(newDate);
    const oldStartDate = moment.utc(item.start_date);
    const endDate = moment.utc(item.end_date);
    if (!(newStartDate.isValid() && oldStartDate.isValid() && endDate.isValid())) {
      console.warn('Date parsing problem');
      return;
    }

    // this diff maybe would be better if calculated based on duration input
    const diff = Math.abs(endDate.diff(oldStartDate, 'days'));
    item.start_date = newDate;
    item.end_date = newStartDate.add(diff, 'days').format('YYYY-MM-DD');
  }

  ngOnDestroy() {
    this.ngScrollbar.nativeElement.removeEventListener(
      'scroll',
      (e) => this.onHorizontalScroll(e),
      true,
    );

    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
