<!-- Note: please do not include any [iframeSettings] because it blocks the placeholder to show up-->
<!--  (keyup)="onKeyUp($event)"-->
<ejs-richtexteditor
  [value]="editorValue"
  *ngIf="isReady"
  (keydown)="onKeyDown($event)"
  (focusout)="onFocusOut()"
  #richTextEditorComponent
  [height]="height"
  [toolbarSettings]="richTextConfig"
  [quickToolbarSettings]="quickToolbarSettings"
  [readonly]="isReadOnly"
  [placeholder]="placeholder"
  [ngClass]="[cleanEditorIO ? 'hide-placeholder' : '', customClass]"
  [insertImageSettings]="imageInsertSettings"
>
</ejs-richtexteditor>
