<div class="manage-project-wrapper">
  <div class="tabs">
    @if ((userService.data$ | async)?.isManager) {
      <div
        (click)="setSelectedTab(tabs.SERVICE_TEAM)"
        [ngClass]="{ active: selectedTab === tabs.SERVICE_TEAM }"
        class="tab"
      >
        <div class="label">Service Team</div>
        <div class="icon-service-team"></div>
      </div>
    }
    <div
      (click)="setSelectedTab(tabs.COMMITMENTS)"
      [ngClass]="{ active: selectedTab === tabs.COMMITMENTS }"
      class="tab"
    >
      <div class="label">Add Commitment</div>
      <div class="icon-commitments"></div>
    </div>

    @if ((userService.data$ | async)?.isManager) {
      <div
        (click)="setSelectedTab(tabs.BUDGET_CASHFLOW)"
        [ngClass]="{ active: selectedTab === tabs.BUDGET_CASHFLOW }"
        class="tab"
      >
        <div class="label">Budget & Cashflow</div>
        <div class="icon-budget-cashflow"></div>
      </div>
    } @else {
      <div (click)="setSelectedTab(tabs.CONTACTS)" [ngClass]="{ active: selectedTab === tabs.CONTACTS }" class="tab">
        <div class="label">Contact Details</div>
        <div class="icon-contacts"></div>
      </div>
    }
  </div>

  <ng-scrollbar class="std-scrollbar" appearance="compact" orientation="vertical">
    <div class="tab-content" *ngIf="selectedTab === tabs.SERVICE_TEAM">
      @if (isLoading) {
        <app-page-loading></app-page-loading>
      } @else if (isContractorsListEmpty()) {
        <div class="mt-4 text-center text-size_xl text-shade_2 font-semibold">
          No Service Providers invited to this project.
        </div>
      } @else {
        @for (contractorType of contractorTypes; track contractorType.id) {
          <div class="contractor-group" *ngIf="contractors?.[contractorType.id]?.length > 0">
            <div class="contractor-title contractor-item">{{ contractorType.text }}</div>
            @for (contractor of contractors[contractorType.id]; track contractor.id) {
              <div class="contractor-item flex justify-between items-center">
                <div>
                  <div class="font-semibold text-size_xl">
                    {{ contractor.user.first_name + ' ' + contractor.user.last_name }}
                  </div>
                  @if (contractor.has_commitments && contractor.total_committed !== 0) {
                    <div class="text-size_m">Contract: {{ contractor.total_committed | money: 'positiveComa' }}</div>
                  } @else if (
                    contractor.bid?.status?.id === BID_STATUS.PENDING ||
                    contractor.bid?.status?.id === BID_STATUS.ACCEPTED
                  ) {
                    <div class="text-size_m">Proposal: {{ contractor.bid?.value | money: 'positiveComa' }}</div>
                  } @else {
                    <div class="text-size_m">No Proposal Submitted</div>
                  }
                </div>
                <div class="flex gap-3">
                  <app-un-styled-options-list
                    (click)="$event.stopPropagation()"
                    [listItems]="itemOptions[contractor.id]"
                    [disabledActionList]="disabledOptions[contractor.id]"
                    (selectedOption)="registerItemOption($event, contractor)"
                    #unStyledOptionsList
                  >
                    <ng-template #projectedContent>
                      <span
                        (click)="unStyledOptionsList.optionsListGeneralComponent.toggleShow()"
                        class="icon-options-inverted text-color_secondary"
                      ></span>
                    </ng-template>
                  </app-un-styled-options-list>

                  <span
                    [ngClass]="{
                      'text-color_accept': contractor?.bid?.status?.id === BID_STATUS.ACCEPTED,
                      'text-shade_4': contractor?.bid?.status?.id !== BID_STATUS.ACCEPTED
                    }"
                    class="icon-paid"
                  ></span>

                  @if (isFavoriteLoading[contractor.id]) {
                    <span class="icon-refresh animate-spin"></span>
                  } @else {
                    <span
                      (click)="toggleFavorite(contractor)"
                      [ngClass]="{
                        'text-color_accent hover:text-shade_4': !!contractor?.is_favorite,
                        'text-shade_4 hover:text-color_accent': !contractor?.is_favorite
                      }"
                      class="icon-heart cursor-pointer duration-200 transition-all"
                    ></span>
                  }
                </div>
              </div>
            }
          </div>
        }
      }
    </div>

    <div class="tab-content" *ngIf="selectedTab === tabs.COMMITMENTS">
      <div class="mt-5 flex flex-col gap-2.5">
        <div *ngIf="!userService.isManager" (click)="addProposal()" class="manage-button">Proposal</div>
        <div (click)="addCommitment(INTERACTION_BAR_STATES.SIDEBAR_ADD_CONTRACT)" class="manage-button">
          <span *ngIf="!userService.isManager">Prime </span>
          <span>Contract</span>
        </div>
        <div
          [pTooltip]="
            !(hasContract$ | async)
              ? 'Please make sure the project has at least one approved contract to enable this option.'
              : ''
          "
        >
          <div
            [ngClass]="{ 'p-disabled': !(hasContract$ | async) }"
            (click)="addCommitment(INTERACTION_BAR_STATES.SIDEBAR_CHANGE_ORDER)"
            class="manage-button"
          >
            <span *ngIf="!userService.isManager">Prime </span>
            <span>Change Order</span>
          </div>
        </div>
        <div
          [pTooltip]="
            !(hasContract$ | async)
              ? 'Please make sure the project has at least one approved contract to enable this option.'
              : ''
          "
        >
          <div
            [ngClass]="{ 'p-disabled': !(hasContract$ | async) }"
            (click)="addCommitment(INTERACTION_BAR_STATES.SIDEBAR_INVOICE)"
            class="manage-button"
          >
            <span *ngIf="!userService.isManager">Prime </span>
            <span>Invoice</span>
          </div>
        </div>
        <div
          *ngIf="userService.isManager"
          (click)="addCommitment(INTERACTION_BAR_STATES.SIDEBAR_MISC_COST)"
          class="manage-button"
        >
          Miscellaneous Cost
        </div>
      </div>
    </div>

    <div class="tab-content" *ngIf="selectedTab === tabs.BUDGET_CASHFLOW">
      <div class="mt-5 flex flex-col gap-2.5">
        <div (click)="setSpendView(SPEND_TYPES.BUDGET)" class="manage-button">Cashflow Allocation</div>
        <div (click)="openBudgetAdjustment()" class="manage-button">Budget Adjustment</div>
        <div (click)="openAnticipatedCost()" class="manage-button">Add Anticipated Costs</div>
        <div (click)="setHistoryView()" class="manage-button">Budget Historical</div>
      </div>
    </div>

    <div class="tab-content" *ngIf="selectedTab === tabs.PROPOSAL">
      <app-bid-proposal></app-bid-proposal>
    </div>

    <div class="tab-content" *ngIf="selectedTab === tabs.CONTACTS">
      <div class="mt-6 text-shade_2 font-semibold text-size_xl text-center">No contacts to show at the moment.</div>
    </div>
  </ng-scrollbar>
</div>
