<div class="lines-wrapper min-h-6 w-fit 2xl:w-auto">
  <div class="left">
    <div class="form column titles-column box-border pl-6 ml-px">Budget Lines</div>
    <div class="budget-area column column-header">Dollars</div>
  </div>
  <div class="right">
    <div class="distribution-type-area">
      <div class="column">Start Date</div>
      <div class="column">Duration</div>
      <div class="column">Distribution</div>
    </div>
    <div class="months bg-white">
      <div>year total</div>
      <div *ngFor="let month of MONTHS; trackBy: trackByIndex">{{ month }}</div>
    </div>
  </div>
</div>
