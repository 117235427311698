import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DropdownComponent } from '../../../../../../framework/inputs/dropdown/dropdown.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { SimpleButtonComponent } from '../../../../../../framework/buttons/simple-medium-button/simple-button.component';
import { SpendViewProjectStateService } from '../../../../../../services/spend-view-project-state.service';
import { viewProjectActions } from '../../../../../../store/view-project/view-project.actions';
import { VIEW_PROJECT_REFRESH_CAUSE } from '../../../../../../framework/constants/view-project.constants';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { IBudgetTemplate } from '../../../../../../store/settings/settings.interface';
import { Store } from '@ngrx/store';
import { viewProjectSelectors } from '../../../../../../store/view-project/view-project.selectors';
import {
  getSelectableYears,
  getSelectedSpendType,
  getSelectedYear,
  hasCommitments,
} from '../../../../../../store/spend/spend.selectors';
import { Project } from '../../../projects.interface';
import { getIsLoading as areTemplatesLoading } from '../../../../../../store/settings/settings.selectors';
import { NotificationsService } from '../../../../../../services/notifications.service';
import { ProjectApiService } from '../../../../../../services/project-api.service';
import { SPEND_TYPES } from '../../../../../../framework/constants/budget.constants';
import {
  addDefaultLineItem,
  addNewYear,
  saveToBackend,
  setSelectedSpendType,
  setSelectedYear,
} from '../../../../../../store/spend/spend.actions';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-spend-distribution-header',
  standalone: true,
  imports: [DropdownComponent, NgClass, SimpleButtonComponent, AsyncPipe, TooltipModule],
  templateUrl: './spend-distribution-header.component.html',
  styleUrl: './spend-distribution-header.component.scss',
})
export class SpendDistributionHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  protected readonly store = inject(Store);
  protected readonly notif = inject(NotificationsService);
  protected readonly projectApi = inject(ProjectApiService);
  protected readonly changeDetectorRef = inject(ChangeDetectorRef);
  templateId: number; // can be null, -1 (default template), or any valid template id (> 0)
  @Input() selectedBudgetType: SPEND_TYPES = SPEND_TYPES.BUDGET;

  @Output() openSpreadsheetView = new EventEmitter<void>();

  protected readonly SPEND_TYPES = SPEND_TYPES;

  ADD_YEAR = -1;
  currentProject: Project;
  oldTemplateId: number = null;
  DEFAULT_TEMPLATE_NAME = 'Default';
  DEFAULT_TEMPLATE_ID = -1; // 0 is not valid as dropdown does not detect if value is 0, please leave it at -1
  templates: IBudgetTemplate[] = [];
  possibleYearsOptions = [];
  spendBudgetTypes: SPEND_TYPES[] = [
    SPEND_TYPES.BUDGET,
    SPEND_TYPES.BUDGET_COMMITMENTS,
    SPEND_TYPES.COMMITMENTS,
  ];

  hasCommitments$ = this.store.select(hasCommitments);
  selectedProject$ = this.store.select(viewProjectSelectors.getSelectedProject);
  templates$ = this.store.select(viewProjectSelectors.getProjectTemplates(false));
  areTemplatesLoading$ = this.store.select(areTemplatesLoading);
  isDestroyed$ = new Subject<void>();
  possibleYears$ = this.store.select(getSelectableYears);
  selectedBudgetType$ = this.store.select(getSelectedSpendType);
  selectedYear$ = this.store.select(getSelectedYear);
  spendAllocationBudgetMissing$ = this.store.select(
    viewProjectSelectors.spendAllocationBudgetMissing,
  );
  // true if the original template has modifications (items added, or removed)
  templateAdjusted$ = this.store.select(viewProjectSelectors.budgetTemplateAdjusted);
  templateDropdownHovered: boolean;
  templateSelectionDisabled$: Observable<boolean> = combineLatest([
    this.hasCommitments$,
    this.selectedBudgetType$,
    this.areTemplatesLoading$,
  ]).pipe(
    map(
      ([hasACommitment, selectedBudgetType, templatesLoading]) =>
        hasACommitment || selectedBudgetType !== SPEND_TYPES.BUDGET || templatesLoading,
    ),
  );

  ngAfterViewInit() {
    combineLatest([this.templates$, this.selectedProject$])
      .pipe(
        takeUntil(this.isDestroyed$),
        filter(([_, project]) => !!project),
      )
      .subscribe(([templates, project]) => {
        // Handle templates
        const defaultTemplate: IBudgetTemplate = {
          name: this.DEFAULT_TEMPLATE_NAME,
          id: this.DEFAULT_TEMPLATE_ID,
          template_items: [],
          is_trashed: false,
        };

        this.templates = [defaultTemplate, ...templates];
        this.currentProject = project;

        if (project?.items?.length === 0 && project.budget_template_id === null) {
          // project doesn't have a budget, in this case user needs to select a template
          this.templateId = null;
        } else {
          // user has a template, set template or default template
          this.templateId = project?.budget_template_id ?? this.DEFAULT_TEMPLATE_ID;
          this.oldTemplateId = project?.budget_template_id ?? this.DEFAULT_TEMPLATE_ID;
        }

        // if the selected template is deleted, add it to the list, to display its name as selected template
        if (project?.budget_template?.deleted_at) {
          this.templates = [project.budget_template, ...templates];
        }

        this.changeDetectorRef.detectChanges();
      });
  }

  openSpreadsheet() {
    this.openSpreadsheetView.emit();
    this.notif.showLoading();
  }

  setTemplate(templateId: number) {
    this.spendAllocationBudgetMissing$.pipe(take(1)).subscribe((isMissing) => {
      if (isMissing) {
        this.updateProjectTemplate(templateId);
      } else {
        this.updateProjectTemplateWithConfirmation(templateId);
      }
    });
  }

  updateProjectTemplateWithConfirmation(templateId: number) {
    if (SpendViewProjectStateService.isBudgetLocked) {
      this.notif.showError('Please make sure budget is unlocked before changing templates.');
      this.changeDetectorRef.detectChanges();
      this.templateId = this.oldTemplateId;
      this.changeDetectorRef.detectChanges();
      return;
    }

    this.notif
      .showPopup(
        'You are about to reset project templates. This change is irreversible. Are you sure you want to continue?',
      )
      .then((resetBudget) => {
        if (!resetBudget) {
          this.changeDetectorRef.detectChanges();
          this.templateId = this.oldTemplateId;
          this.changeDetectorRef.detectChanges();
          return;
        }

        this.updateProjectTemplate(templateId);
      });
  }

  updateProjectTemplate(templateId: number) {
    this.projectApi
      .updateProject({
        id: this.currentProject.id,
        start_date: this.currentProject.start_date,
        status: this.currentProject.status,
        title: this.currentProject.title,
        property_id: this.currentProject.property_id,
        budget_template_id: templateId === this.DEFAULT_TEMPLATE_ID ? null : templateId, // default template is null on backend
      })
      .then(
        (updatedProject) => {
          SpendViewProjectStateService.isBudgetLocked = true;
          if (templateId === this.DEFAULT_TEMPLATE_ID && updatedProject.items.length === 0) {
            this.store.dispatch(addDefaultLineItem());
            this.store.dispatch(saveToBackend());
            return;
          } else {
            this.store.dispatch(
              viewProjectActions.refreshNeeded({
                cause: VIEW_PROJECT_REFRESH_CAUSE.SPEND_SAVE,
              }),
            );
          }

          this.changeDetectorRef.detectChanges();
          this.templateId = templateId;
          this.oldTemplateId = templateId;
          this.changeDetectorRef.detectChanges();
          this.notif.showSuccess('Template successfully saved.');
        },
        (err) => {
          this.notif.showError('Error setting template');
          this.changeDetectorRef.detectChanges();
          this.templateId = this.oldTemplateId;
          this.changeDetectorRef.detectChanges();
        },
      );
  }

  private handlePossibleYearsOptions() {
    this.possibleYears$.pipe(takeUntil(this.isDestroyed$)).subscribe((years) => {
      this.possibleYearsOptions = years.map((year) => {
        return {
          key: year,
          value: year,
        };
      });
      this.possibleYearsOptions.push({
        key: 'Add Year',
        value: this.ADD_YEAR,
      });
    });
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  ngOnInit(): void {
    this.handlePossibleYearsOptions();
  }

  setSelectedYear(value: number) {
    if (value !== this.ADD_YEAR) {
      this.store.dispatch(setSelectedYear({ year: value }));
      return;
    }
    this.store.dispatch(addNewYear());
  }

  setBudgetType(value: SPEND_TYPES) {
    this.store.dispatch(setSelectedSpendType({ spendType: value }));
  }
}
