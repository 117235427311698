import { inject, Injectable } from '@angular/core';
import {
  ICommittedItem,
  ICommittedItemExtended,
  ILineItem,
  ILineItemExtended,
} from '../store/spend/spend.interfaces';
import moment from 'moment';
import { CurrentUserService } from './current-user.service';

@Injectable({
  providedIn: 'root',
})
export class SpendDistributionService {
  private readonly userService = inject(CurrentUserService);
  private readonly DATE_FORMAT = 'YYYY-MM-DD';

  // todo: this needs a refactor, it's not too clear, year and selectedYear is a bit weird
  getDisabledMonths(
    year: number,
    item: ILineItem | ICommittedItem,
    selectedYear: number = year,
    isBudget: boolean = true,
  ): boolean[] {
    const availableMonths = Array(12).fill(false);
    if (year === 0) {
      return availableMonths;
    }

    const fyStart = this.userService.data.fiscal_year_start - 1;
    const startDate = item.start_date;
    const duration = item.duration;
    const itemStart = moment(startDate);
    const itemEnd = moment(startDate).add(duration, 'month');

    // set to fiscal year start, just as in the header
    const currentMonth = moment(`${year}-01-01`, this.DATE_FORMAT);
    if (fyStart) {
      currentMonth.subtract(12 - fyStart, 'month');
    }

    const key = isBudget ? 'monthly_budget' : 'monthly_forecast';

    // set all months that should be available, months by default are unavailable
    availableMonths.forEach((month, index) => {
      const budget = item.budget.find((bud) => bud.year === selectedYear);
      const hasFieldValue =
        budget?.[key][index + 1] !== 0 && budget?.[key][index + 1] !== undefined;
      // const isManualDistribution = item[distKey] === DISTRIBUTION_TYPES.MANUAL;
      const isSameOrAfterStart = currentMonth.isSameOrAfter(itemStart, 'month');
      const isInsideLineDuration = isSameOrAfterStart && currentMonth.isBefore(itemEnd, 'month');
      if (isInsideLineDuration || (hasFieldValue && isSameOrAfterStart)) {
        availableMonths[index] = true;
      }
      currentMonth.add(1, 'month');
    });

    return availableMonths;
  }

  extendedItemToOriginal(item: ILineItemExtended): ILineItem {
    return {
      name: item.name,
      id: item.id,
      project_id: item.project_id,
      distribution: item.distribution,
      duration: item.duration,
      budget: item.budget,
      start_date: item.start_date,
      commitment_start_date: item.commitment_start_date,
      row_number: item.row_number,
      committed_items: [], // handled in the store
    };
  }

  extendedCommitmentItemToOriginal(item: ICommittedItemExtended): ICommittedItem {
    return {
      id: item.id,
      commitment_id: item.commitment_id,
      commitment_name: item.commitment_name,
      commitment_title: item.commitment_title,
      budget: item.budget,
      duration: item.duration,
      start_date: item.start_date,
      distribution: item.distribution,
      row_number: item.row_number,
      committed_row_number: item.committed_row_number,
      item_id: item.item_id,
      type: item.type,
    };
  }
}
