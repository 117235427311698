<div [formGroup]="messageForm" class="discussion-view-wrapper h-100 flex flex-col">
  <ng-container *ngIf="!hideSentMessages">
    <ng-container>
      <div class="py-3 flex border-b border-solid border-shade_5">
        <app-messaging-bubble
          [isFilled]="!selectedGroup?.is_read"
          [type]="getBubbleType(selectedGroup)"
        ></app-messaging-bubble>
        <div class="ml-6 text-shade_1 font-semibold">{{ (selectedGroup$ | async)?.name }}</div>
      </div>
      <div #selectedUserWrapper class="form dropdown-form hide-underline flex border-b border-solid border-shade_5">
        <mat-form-field
          subscriptSizing="dynamic"
          (click)="showRelatedUsers = false; optionsListGeneralComponent.toggleShow()"
          #userTableTrigger="cdkOverlayOrigin"
          cdkOverlayOrigin
          class="borderless"
        >
          <app-options-list-general
            #optionsListGeneralComponent
            (showChange)="!!$event ? loadUsers() : ''"
            [trigger]="userTableTrigger"
            [withAnimation]="false"
          >
            <app-messaging-users-table
              [listItems]="itemOptions"
              [usersRelated]="usersData.usersRelated"
              [discussionMembers]="usersData.discussionMembers"
              (selectedOption)="onMemberOptionSelected($event); optionsListGeneralComponent.toggleShow()"
            ></app-messaging-users-table>
          </app-options-list-general>
          <span
            (click)="$event.stopPropagation(); showRelatedUsers = true; optionsListGeneralComponent.toggleShow()"
            [ngClass]="{ 'icon-new-message-plus-chat-unfilled': !showRelatedUsers, 'icon-at-sign': showRelatedUsers }"
            class="icon"
          ></span>
          <span class="user-selected-wrapper ml-4">
            <ng-container *ngFor="let user of usersData.discussionMembers">
              <span [ngClass]="{ 'font-bold': user?.is_admin }" class="pr-5 text-shade_1 font-normal font-sm">
                {{ user.name }}
              </span>
            </ng-container>
          </span>
          <input
            #input
            class="discussion-dropdown-input"
            [ngClass]="{ 'disp-none': !showRelatedUsers }"
            autocomplete="off"
            formControlName="participants"
            (ngModelChange)="loadUsers($event)"
            matInput
          />
          <div matSuffix class="flex items-center cursor-pointer pl-2">
            <app-arrow-button-box
              [direction]="isDropDownOpen ? 'up' : 'down'"
              [isFilled]="isDropDownOpen"
            ></app-arrow-button-box>
          </div>
        </mat-form-field>
      </div>
    </ng-container>
    <!--  class can be found in global styles-->
    <mat-progress-bar
      *ngIf="isPaginationLoading$ | async"
      class="custom-loading-progress-bar"
      mode="indeterminate"
    ></mat-progress-bar>
    <div class="flex-1 min-h-0">
      <div class="h-100 flex justify-center items-center" *ngIf="isLoading$ | async">
        <span class="icon-refresh animate-spin text-size_title_xl text-shade_2"></span>
      </div>
      <ng-scrollbar
        #scrollbar
        *ngIf="!(isLoading$ | async)"
        class="std-scrollbar"
        orientation="vertical"
        appearance="native"
      >
        <div [ngClass]="{ 'mr-3': scrollbar.isVerticallyScrollable() }">
          <ng-container *ngFor="let message of allMessages; trackBy: trackByFn">
            <div
              *ngIf="!(message.body | isMessageEmpty) || message.attachments?.length"
              id="{{ 'message-' + message.id }}"
              [ngClass]="{ 'py-5 border-b border-solid border-shade_5 first-child:py-0': !message.is_announcement }"
            >
              <app-message-item *ngIf="!message?.is_announcement" [message]="message"></app-message-item>
              <app-message-item-announcement
                *ngIf="message?.is_announcement"
                [message]="message"
              ></app-message-item-announcement>
            </div>
          </ng-container>
        </div>
      </ng-scrollbar>
    </div>
    <div class="upload-container" *ngIf="filesToUpload?.length">
      <ng-scrollbar class="std-scrollbar" orientation="vertical" appearance="native">
        <ng-container *ngFor="let fileToUpload of filesToUpload; let index = index">
          <span class="flex">
            <div class="text-shade_1 flex-1">
              {{ fileToUpload.file.name }}
              <!--            <img [src]="fileStructure.url" />-->
            </div>
            <span
              (click)="removeFile(fileToUpload, index)"
              class="mr-2 cursor-pointer text-color_secondary icon-remove-circle-x"
            ></span>
          </span>
        </ng-container>
      </ng-scrollbar>
    </div>
  </ng-container>
  <input
    #fileUploadSubstitute
    (click)="$event.stopPropagation()"
    (change)="registerFileAdded($event)"
    class="disp-none"
    type="file"
    multiple
  />
  <div
    class="py-2 options rich-text-edit-upload upload-drop-area"
    appUploadDrop
    (fileDropped)="registerFileUpload($event)"
  >
    <app-rich-text-editor
      #editorComponent
      [specialKeys]="editorSpecialKeys"
      [quickToolbarSettings]="quickToolbarSettings"
      (specialKeyPressed)="specialKeyPressed($event)"
      [editorValue]="editorInput"
      [placeholder]="'Type message here'"
      [editorSettings]="editorSetting"
      (editorChanged)="onEditorValueChanged($event)"
      customClass="messages"
      [editorView]="RTE_VIEWS.MESSAGING"
      [imageInsertSettings]="imageModel"
    ></app-rich-text-editor>
  </div>
</div>
