import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { CurrentUserService } from '../../services/current-user.service';
import { NotificationsService } from '../../services/notifications.service';
import {
  IBudget,
  ICommittedItem,
  IForecastBudget,
  ISpreadLineItem,
  SpreadSheetInputData,
} from '../../store/spend/spend.interfaces';
import { getSelectableYears } from '../../store/spend/spend.selectors';
import { AppState } from '../../store/app-state';
import { Store } from '@ngrx/store';
import { Spreadsheet, SpreadsheetModule } from '@syncfusion/ej2-angular-spreadsheet';
import { delay, take } from 'rxjs/operators';
import { SHEET_SAVE_URL } from '../constants/syncfusion.constants';
import { updateLineItem } from '../../store/spend/spend.actions';
import { DeepCopyService } from '../../services/deep-copy.service';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { saveAs } from 'file-saver';
import { SPEND_TYPES } from '../constants/budget.constants';
import cloneDeep from 'lodash/cloneDeep';
import { MONTHS, SPEND_DISTRIBUTION_STATE } from '../constants/spend.constants';
import { viewProjectActions } from '../../store/view-project/view-project.actions';
import { PROJECT_VIEWS } from '../constants/view-project.constants';
import { SpendViewProjectStateService } from '../../services/spend-view-project-state.service';
import { viewProjectSelectors } from '../../store/view-project/view-project.selectors';

@Component({
  selector: 'app-spreadsheet',
  templateUrl: './spreadsheet.component.html',
  styleUrls: ['./spreadsheet.component.scss'],
  standalone: true,
  imports: [SpreadsheetModule, NgClass, NgIf, NgForOf],
})
export class SpreadsheetComponent {
  @ViewChild('spreadsheet') spreadsheet: Spreadsheet;
  months = cloneDeep(MONTHS).map((monthName) => monthName.toUpperCase());

  @Input() spendType: SPEND_TYPES = SPEND_TYPES.BUDGET;
  @Input() useOnlyAsDownload = false;

  @Input() set data(data: SpreadSheetInputData) {
    if (!data) {
      return;
    }
    this.loading = false;
    this.items = data.items as unknown as ISpreadLineItem[];
    this.isEditable = data.isEditable;
    this.selectedYear = data.selectedYear;
    this.possibleYears$.pipe(take(1)).subscribe((possibleYears) => {
      this.possibleYears = possibleYears;
      const sheetIndex = this.possibleYears?.findIndex((year) => year === this.selectedYear);
      this.activeSheetIndex = sheetIndex < 0 ? 0 : sheetIndex;
      this.calculateFormulas();
      this.isSheetDataLoaded = true;
      this.cd.detectChanges();
    });
  }

  // insert empty rows after line items and before totals
  emptyRowsBeforeTotal = new Array(3).fill({});
  totalCellFormulas = [];

  // 15 columns are in use (12 months + 1 for names + 2 for totals
  // this array contains the column names (15 letters starting from 'A'): ['A', 'B', 'C', ...]
  columnNames = new Array(15).fill(0).map((val, ind) => String.fromCharCode(65 + ind));

  items: ISpreadLineItem[] = [];
  isEditable: any[];
  selectedYear: number;
  headerOffset = 2; // this value should be subtracted from spreadsheet line index to get the lineItem index
  isSheetDataLoaded = false;

  loading = true;
  isOpen = false;

  projectData$ = this.store.select(viewProjectSelectors.getSelectedProject);
  possibleYears$ = this.store.select(getSelectableYears);
  possibleYears: number[] = [];
  activeSheetIndex = 0; // the sheet(year) is selected (it is set based on selectedYear)

  SHEET_SAVE_URL = SHEET_SAVE_URL; // spreadsheet needs this url for save
  protected readonly SPEND_TYPES = SPEND_TYPES;
  LINE_ITEM_COLOR = '#f0f6fe'; // 'rgba(67, 146, 241, 0.08)';
  COMMITTED_ITEM_COLOR = '#f8f8f8'; // 'rgba(189, 189, 189, 0.10)';
  // DEFAULT_BACKGROUND = '#F6F8FF';
  DEFAULT_BACKGROUND = '#e2e7f5';
  WHITE = '#ffffff';
  FORECAST_MODIFICATION_COLOR = '#f0f2f8'; // 'rgb(102 125 188 / 0.1)'
  ANTICIPATED_COST_TEXT = 'Anticipated Cost';

  // number of columns in the sheet which contain any info
  get columnCount() {
    return this.spendType === SPEND_TYPES.BUDGET ? 15 : 17;
  }

  // number of columns in the sheet which contain additional info (line item name, year total, etc)
  get infoColumnCount() {
    return this.spendType === SPEND_TYPES.BUDGET ? 3 : 5;
  }

  // the number of rows with data in the sheet (number of line items or committed items)
  get dataRowCount() {
    const lineItemCount = this.items.length;
    const committedItemCount = this.items.reduce(
      (acc, item) => acc + item.committed_items.length,
      0,
    );
    if (this.spendType === SPEND_TYPES.BUDGET) {
      return lineItemCount;
    }
    if (this.spendType === SPEND_TYPES.COMMITMENTS) {
      return committedItemCount;
    }

    return lineItemCount + committedItemCount;
  }

  // the number of rows with any kind of data in the sheet
  get totalRowCount() {
    if (this.spendType !== SPEND_TYPES.BUDGET_COMMITMENTS) {
      // 1 row for the total row, 1 row for the header, the other ones are empty
      return this.dataRowCount + 1 + this.emptyRowsBeforeTotal.length + 1;
    }
    return this.dataRowCount + 1;
  }

  // the letter of the column which holds the year total, e.g. 'C' for budget
  get yearTotalColumnLetter() {
    return this.getLetterByIndex(this.infoColumnCount - 1);
  }

  // the letter of the first column with data, e.g. 'D' for budget
  get dataStartColumnLetter() {
    return this.getLetterByIndex(this.infoColumnCount);
  }

  // the letter of the last column with data, e.g. 'O' for budget
  get endColumnLetter() {
    return this.getLetterByIndex(this.columnCount - 1);
  }

  constructor(
    public user: CurrentUserService,
    protected notif: NotificationsService,
    private cd: ChangeDetectorRef,
    private store: Store<AppState>,
    private state: SpendViewProjectStateService,
  ) {}

  /**
   * sets all kind of formatting for the spreadsheet (font & background color mainly)
   */
  setFormatting() {
    this.possibleYears.forEach((year) => {
      // general settings for all cells
      this.spreadsheet.cellFormat(
        {
          fontFamily: 'Roboto',
          color: '#091D51',
          fontWeight: 'normal',
          verticalAlign: 'middle',
        },
        `${year}!A1:${this.endColumnLetter}${this.totalRowCount}`,
      );

      // center all data except the first column
      const firstCenteredColumn = this.getLetterByIndex(this.infoColumnCount - 2);
      this.spreadsheet.cellFormat(
        { textAlign: 'center' },
        `${year}!${firstCenteredColumn}1:${this.endColumnLetter}${this.totalRowCount}`,
      );

      // header styling
      this.spreadsheet.cellFormat(
        { backgroundColor: this.DEFAULT_BACKGROUND, fontWeight: 'bold' },
        `${year}!A1:${this.endColumnLetter}1`,
      );

      // other cells styling than header
      // set the proper background based on the item type
      let rowIndex = this.headerOffset; // we start from the first row after the header and increment it for each row
      this.items.forEach((item) => {
        if (this.spendType === SPEND_TYPES.BUDGET) {
          this.spreadsheet.cellFormat(
            { backgroundColor: this.getBackgroundColor(item) },
            `${year}!A${rowIndex}:${this.endColumnLetter}${rowIndex}`,
          );
          rowIndex++;
        } else {
          // if the view is BUDGET_COMMITMENTS, we need to iterate over line items AND the nested committed items
          // to set their background color
          if (this.spendType === SPEND_TYPES.BUDGET_COMMITMENTS) {
            this.spreadsheet.cellFormat(
              { backgroundColor: this.getBackgroundColor(item) },
              `${year}!A${rowIndex}:${this.endColumnLetter}${rowIndex}`,
            );
            rowIndex++;
          }

          item.committed_items.forEach((committedItem) => {
            this.spreadsheet.cellFormat(
              { backgroundColor: this.getBackgroundColor(committedItem) },
              `${year}!A${rowIndex}:${this.endColumnLetter}${rowIndex}`,
            );
            rowIndex++;
          });
        }
      });

      // set the background for the empty rows before the total row and the total row itself
      if (this.spendType !== SPEND_TYPES.BUDGET_COMMITMENTS) {
        for (const item of this.emptyRowsBeforeTotal) {
          this.spreadsheet.cellFormat(
            { backgroundColor: this.WHITE },
            `${year}!A${rowIndex}:${this.endColumnLetter}${rowIndex}`,
          );
          rowIndex++;
        }
      }

      this.spreadsheet.cellFormat(
        { backgroundColor: this.DEFAULT_BACKGROUND },
        `${year}!A${rowIndex}:${this.endColumnLetter}${rowIndex}`,
      );
    });
  }

  protectAllSheets() {
    this.possibleYears.forEach((year) => {
      this.spreadsheet.protectSheet(year + '');
    });
  }

  unprotectAlSheets() {
    this.possibleYears.forEach((year) => {
      this.spreadsheet.unprotectSheet(year + '');
    });
  }

  /**
   * this function determines the sum formulas for year total and budget total fields
   * budget total references multiple sheets (every year)
   */
  calculateFormulas() {
    if (this.spendType === SPEND_TYPES.BUDGET) {
      this.items.forEach((item, index) => {
        item.yearTotalFormula = `=SUM(${this.dataStartColumnLetter}${index + this.headerOffset}:${this.endColumnLetter}${index + this.headerOffset})`;
        item.budgetTotalFormula = this.getDollarsTotalFormula(index);
      });
    } else {
      // manual indexing is needed for committed items as they're nested in the line items array
      let index = 0;
      this.items.forEach((item) => {
        if (this.spendType === SPEND_TYPES.BUDGET_COMMITMENTS) {
          item.yearTotalFormula = `=SUM(${this.dataStartColumnLetter}${index + this.headerOffset}:${this.endColumnLetter}${index + this.headerOffset})`;
          item.budgetTotalFormula = this.getDollarsTotalFormula(index);
          index++;
        }
        item.committed_items.forEach((committedItem) => {
          committedItem.yearTotalFormula = `=SUM(${this.dataStartColumnLetter}${index + this.headerOffset}:${this.endColumnLetter}${index + this.headerOffset})`;
          committedItem.dollarsTotalFormula = this.getDollarsTotalFormula(index);
          index++;
        });
      });
    }

    if (this.spendType !== SPEND_TYPES.BUDGET_COMMITMENTS) {
      this.setTotals();
    }
  }

  /**
   * return the Excel formula for the total of all years for a specific line item/committed item
   * @param index - the index of the line item/committed item
   */
  getDollarsTotalFormula(index: number): string {
    let formula = '';
    this.possibleYears.forEach((year: number, yearIndex: number) => {
      formula += `'${year}'!${this.yearTotalColumnLetter}${index + this.headerOffset}`;
      if (yearIndex < this.possibleYears.length - 1) {
        formula += ',';
      }
    });
    return `=SUM(${formula})`;
  }

  /**
   * determine the total formulas for total row for each column
   */
  setTotals() {
    const lineCount = this.dataRowCount + 1;
    // 12 months columns  + 2 total columns
    const currencyColumns = new Array(12 + 2).fill(0);
    const colOffset = this.infoColumnCount - 2; // in the first N columns we don't calculate totals
    currencyColumns.forEach((col, columnIndex) => {
      const colName = this.columnNames[columnIndex + colOffset];
      this.totalCellFormulas.push(`=SUM(${colName}${this.headerOffset}:${colName}${lineCount})`);
    });
  }

  getBudgetValue(item: ISpreadLineItem | ICommittedItem, year: number, monthIndex: number): number {
    const budget = item.budget.find((bud) => bud.year === year);
    switch (this.spendType) {
      case SPEND_TYPES.BUDGET:
        return (budget as IBudget)?.monthly_budget?.[monthIndex + 1] ?? 0;
      case SPEND_TYPES.COMMITMENTS:
        return (budget as IForecastBudget)?.monthly_forecast?.[monthIndex + 1] ?? 0;
      case SPEND_TYPES.BUDGET_COMMITMENTS:
        if ((item as ICommittedItem).commitment_id) {
          return (budget as IForecastBudget)?.monthly_forecast?.[monthIndex + 1] ?? 0;
        }
        if ((item as ICommittedItem).type === 'forecast_modification') {
          return (budget as IForecastBudget)?.monthly_forecast?.[monthIndex + 1] ?? 0;
        }
        return (budget as IBudget)?.monthly_budget?.[monthIndex + 1] ?? 0;
    }
  }

  afterSheetCreated() {
    this.setFormatting();
    // this.setLocks();
    this.protectAllSheets();

    if (this.useOnlyAsDownload) {
      this.projectData$.pipe(take(1), delay(500)).subscribe((project) => {
        this.spreadsheet.save({
          saveType: 'Xlsx',
          fileName: project.title,
        });
      });
    }
  }

  onBeforeSave(args) {
    this.unprotectAlSheets();
    args.isFullPost = false;
    this.notif.showLoading('Converting file...');
  }

  onSaveComplete(data) {
    this.protectAllSheets();
    if (data.status === 'Failure') {
      this.notif.showError('Failed to save. Please try again later!');
      this.closeIfOnlyAsDownload();
      return;
    }

    if (data.blobData) {
      saveAs(data.blobData, `${data.fileName}.${data.saveType.toLowerCase()}`);
    }

    this.notif.close();

    this.closeIfOnlyAsDownload();
  }

  /**
   * returns the letter of the alphabet by the index
   * @param index - number between 0 and 25
   */
  getLetterByIndex(index: number) {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    if (index >= 0 && index < alphabet.length) {
      return alphabet[index];
    } else {
      return 'Index out of range';
    }
  }

  /**
   * return the background color for the item based on its type (line item or committed item)
   * @param item - line item or committed item
   */
  getBackgroundColor(item: ISpreadLineItem | ICommittedItem) {
    if ((item as ICommittedItem).type === 'forecast_modification') {
      return this.FORECAST_MODIFICATION_COLOR;
    }

    if (!(item as ICommittedItem).commitment_id) {
      return this.LINE_ITEM_COLOR;
    }

    return this.COMMITTED_ITEM_COLOR;
  }

  /**
   * on every cell change update the store with new budget values.
   * @param event spreadsheet event
   * @deprecated - we don't save the data anymore, the spreadsheet is read-only
   */
  onValueChange(event) {
    const address = event.address as string; // in the format: 2021!D4
    const year = Number.parseInt(address.split('!')[0], 10);
    const cell = address.split('!')[1];
    const line = cell.replace(/[a-zA-Z]/, '');
    const column = cell.replace(/[0-9]/, '');

    const lineItemIndex = Number.parseInt(line, 10) - 2;
    const monthIndex = this.columnNames.findIndex((colName) => colName === column) - 3;
    const newValue = Number.parseFloat(event.value);

    if (lineItemIndex >= 0 && monthIndex >= 0 && !isNaN(newValue) && !isNaN(year)) {
      const lineItem = DeepCopyService.deepCopy(this.items[lineItemIndex]);
      lineItem.budget.find((bud) => bud.year === year).monthly_budget[monthIndex + 1] = newValue;
      this.store.dispatch(updateLineItem({ lineItem }));
    } else {
      console.warn('not going to update spend, some values are not valid.');
    }
  }

  /**
   * set cells which cannot be edited
   * Note: will not work without e-sheet [protectSettings]="{ selectCells: true }
   * @deprecated - all the cells are locked now, we don't allow editing
   */
  setLocks() {
    this.isEditable.forEach((years, yearIndex) => {
      Object.entries(years).forEach(([year, value]: [string, boolean[]]) => {
        value.forEach((isMonthLocked, monthIndex) => {
          const monthStartColumnIndex = 3;
          const cell = this.columnNames[monthIndex + monthStartColumnIndex];
          const cellIndex = yearIndex + this.headerOffset;
          if (!isMonthLocked) {
            this.spreadsheet.cellFormat(
              { backgroundColor: '#F6F8FF' },
              `${year}!${cell}${cellIndex}`,
            );
          }
          // todo: it should be visible which cells are "manual" distribution to be edited and other cells like in the inputs app view
          // todo: currently isEditable contains only editable fields without the "only manual fields can be modified" change
          // this.spreadsheet.lockCells(`${year}!${cell}${cellIndex}`, !isMonthLocked);
          // lock all cells for now
          this.spreadsheet.lockCells(`${year}!${cell}${cellIndex}`, true);
        });
      });
    });
  }

  private closeIfOnlyAsDownload() {
    if (!this.useOnlyAsDownload) {
      return;
    }

    if (this.state.currentState.value !== SPEND_DISTRIBUTION_STATE.SHOW_SPREADSHEET) {
      this.store.dispatch(viewProjectActions.viewChanged({ view: PROJECT_VIEWS.DEFAULT }));
    }
    this.state.close(); // maybe the state isn't needed
  }
}
