import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloatingInputComponent } from '../floating-input/floating-input.component';
import {
  ControlContainer,
  FormsModule,
  NgForm,
  NgModel,
  ReactiveFormsModule,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-tag-input',
  standalone: true,
  imports: [
    CommonModule,
    FloatingInputComponent,
    ReactiveFormsModule,
    InputTextModule,
    FormsModule,
    MatTooltipModule,
    TooltipModule,
  ],
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class TagInputComponent {
  private _model: string[];
  @Input() label = '';
  @Input() toolTip =
    'Insert a tag and press ENTER to add it. You can add multiple tags but their name should be unique.';

  @Input() required = false;
  @Input() name = 'input';
  @Input() set model(value: string[]) {
    this._model = value;
    this.modelChange.emit(this._model);
  }
  get model(): string[] {
    return this._model;
  }
  @Input() maxlength: string | number = null;
  @Input() minlength: string | number = null;
  @Input() wrapperClass = 'mt-6';

  @Output() modelChange = new EventEmitter<string[]>();

  inputModel: string;

  @ViewChild('input', { read: NgModel }) input: NgModel;

  onModelChange($event: any) {
    const lowerCaseModel = this.model.map((tag) => tag?.toLowerCase());
    const lowerCaseInput = this.input.control.value.toLowerCase();
    if (lowerCaseModel.includes(lowerCaseInput)) {
      this.input.control.setErrors({ tag_not_unique: true });
    } else if (this.input.control.errors?.tag_not_unique) {
      delete this.input.control.errors.tag_not_unique;
      this.input.control.updateValueAndValidity();
    }
  }

  enterPressed($event: any) {
    if (!this.input.control.valid || !this.inputModel) {
      return;
    }
    this.model.push(this.inputModel);
    this.inputModel = '';
  }

  removeTag(index: number) {
    this.model.splice(index, 1);
  }
}
