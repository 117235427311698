import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { NotificationsService } from '../../../../services/notifications.service';
import { COMMITMENTS_INTERACTION_BAR_TYPE } from '../../../constants/interaction-bar.constants';
import { Store } from '@ngrx/store';
import { commitmentsSelectors } from '../../../../store/commitments/commitments.selectors';
import { Subject } from 'rxjs';
import { commitmentsActions } from '../../../../store/commitments/commitments.actions';
import { ISidebarCommitment } from '../../../../store/commitments/commitments.types';
import { ShortenFileNamePipe } from '../../../../pipes/framework/shorten-file-name.pipe';
import { CommonModule } from '@angular/common';
import { UploadDropDirective } from '../../../../directives/upload-drop.directive';

type CustomFile = File & { id?: number };
@Component({
  selector: 'app-upload-commitments-sidebar',
  templateUrl: './upload-commitments-sidebar.component.html',
  styleUrls: ['./upload-commitments-sidebar.component.scss'],
  standalone: true,
  imports: [ShortenFileNamePipe, CommonModule, UploadDropDirective],
})
export class UploadCommitmentsSidebarComponent implements AfterViewInit, OnDestroy {
  @Input() commitmentType: COMMITMENTS_INTERACTION_BAR_TYPE =
    COMMITMENTS_INTERACTION_BAR_TYPE.ADD_CONTRACT;
  @Input() disableUpload = false;

  commitmentFiles: CustomFile[] = [];
  removeFileIds: number[] = [];

  isDestroyed$ = new Subject();

  protected readonly COMMITMENTS_INTERACTION_BAR_TYPE = COMMITMENTS_INTERACTION_BAR_TYPE;

  constructor(
    private notif: NotificationsService,
    private store: Store,
  ) {}

  ngAfterViewInit(): void {
    this.handleSidebarDataChanges();
  }

  handleSidebarDataChanges() {
    if (!this.commitmentType) {
      console.warn('no commitment type in upload component!');
      return;
    }
    this.store
      .select(commitmentsSelectors.getSidebarCommitment(this.commitmentType))
      .subscribe((commitment: ISidebarCommitment) => {
        this.removeFileIds = [...(commitment?.removeFileIds ?? [])];

        const commitmentFiles = commitment.files ?? [];
        this.commitmentFiles = commitmentFiles.map((commitmentFile) => {
          // cannot delete files from backend if the commitment is approved
          // see https://stackoverflow.com/questions/67374630/why-is-the-javascript-spread-notation-not-working-here
          const file: CustomFile = new File([commitmentFile], commitmentFile.name, {
            type: commitmentFile.type,
          });
          file.id = commitmentFile.id;
          return file;
        });
      });
  }

  onFileDropped(droppedFiles: File[]) {
    this.notif.showPopup('Do you want to upload this file?').then((data) => {
      if (data) {
        const files = Array.from(droppedFiles);
        const commitmentFiles = [...this.commitmentFiles, ...files];
        this.updateStore(commitmentFiles, this.removeFileIds);
      }
    });
  }

  onFileChange(file) {
    console.log('on file change', file);
    const files: Array<File> = Array.from(file.target.files);
    if (files.length === 0) {
      return;
    }

    const commitmentFiles = [...this.commitmentFiles, ...files];
    console.log(commitmentFiles);
    this.updateStore(commitmentFiles, this.removeFileIds);
  }

  removeFile(event, file: File | any, index: number) {
    event.stopPropagation();

    if (file.id) {
      this.removeFileIds.push(file.id);
    }

    const commitmentFiles = this.commitmentFiles.filter((_, i) => i !== index);
    this.updateStore(commitmentFiles, this.removeFileIds);
  }

  updateStore(files: File[], removeFileIds: number[] = []) {
    let action;
    switch (this.commitmentType) {
      case COMMITMENTS_INTERACTION_BAR_TYPE.ADD_CONTRACT: {
        action = commitmentsActions.contractFilesChanged;
        break;
      }
      case COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER: {
        action = commitmentsActions.changeOrderFilesChanged;
        break;
      }
      case COMMITMENTS_INTERACTION_BAR_TYPE.INVOICES: {
        action = commitmentsActions.invoiceFilesChanged;
        break;
      }
      case COMMITMENTS_INTERACTION_BAR_TYPE.DIRECT_COST: {
        action = commitmentsActions.directCostFilesChanged;
        break;
      }
      default: {
        console.warn('not implemented');
      }
    }
    if (!action) {
      console.warn('no action');
      return;
    }

    this.store.dispatch(action({ files, removeFileIds }));
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
