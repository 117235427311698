import { Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MessagesStateService } from '../../../services/messages-state.service';
import { AppState } from '../../../store/app-state';
import { Store } from '@ngrx/store';
import { messagesActions } from '../../../store/messages/messages.actions';
import {
  getMessagingGroups,
  getMessagingLoading,
} from '../../../store/messages/messages.selectors';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { NgScrollbar } from 'ngx-scrollbar';
import { fromEvent, Subject } from 'rxjs';
import { DiscussionsListItemComponent } from '../discussions-list-item/discussions-list-item.component';
import { MatProgressBar } from '@angular/material/progress-bar';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-discussions-list',
  templateUrl: './discussions-list.component.html',
  styleUrls: ['./discussions-list.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatProgressBar,
    NgScrollbar,
    NgFor,
    DiscussionsListItemComponent,
    AsyncPipe,
    NgClass,
  ],
})
export class DiscussionsListComponent implements OnInit, OnDestroy {
  @ViewChild('scrollbar') scrollbarRef: NgScrollbar;
  isDestroyed$ = new Subject<void>();
  discussions: any[];
  // groups: MessagingGroup[] = [];
  groups$ = this.store.select(getMessagingGroups);
  @Input() data;
  isLoading$ = this.store.select(getMessagingLoading);
  lastScrollPosition = 0;
  constructor(
    private messageState: MessagesStateService,
    private store: Store<AppState>,
    private ngZone: NgZone,
  ) {}

  // we used to dispatch loadFirstPageOfGroups from here
  // now it needs to be called before this component loads
  ngOnInit(): void {
    this.waitScrollbarLoading();
  }

  handlePagination() {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.scrollbarRef.nativeElement, 'scroll')
        .pipe(takeUntil(this.isDestroyed$), debounceTime(10))
        .subscribe((event) => {
          const target = event.target as HTMLElement;
          if (this.lastScrollPosition < target.scrollTop) {
            if (target.offsetHeight + target.scrollTop + 10 >= target.scrollHeight) {
              // console.log('bottom');
              this.store.dispatch(messagesActions.loadMessagingGroupsNextPage({}));
            }
          }
          this.lastScrollPosition = target.scrollTop;
        });
    });
  }

  /**
   * Waits until this.scrollbarRef is not undefined (= the component is loaded) then calls handlePagination
   * @private
   */
  private waitScrollbarLoading() {
    this.groups$
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((groups) => !!groups && groups.length > 0),
      )
      .subscribe((groups) => {
        const intervalId = setInterval(() => {
          if (this.scrollbarRef) {
            clearInterval(intervalId);
            this.handlePagination();
          }
        }, 50);
      });
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }
}
