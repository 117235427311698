<div
  cdkDropListOrientation="vertical"
  cdkDropListLockAxis="y"
  cdkDropList
  cdkScrollable
  class="data-wrapper"
  [ngClass]="{
    'cursor-grab': isDragging
  }"
  (cdkDropListDropped)="drop($event)"
>
  <app-budget-line-item-table-header
    [selectedBudgetType]="selectedBudgetType$ | async"
  ></app-budget-line-item-table-header>

  @if (
    (selectedBudgetType$ | async) === SPEND_TYPES.BUDGET ||
    (selectedBudgetType$ | async) === SPEND_TYPES.BUDGET_COMMITMENTS
  ) {
    <div
      cdkDrag
      (cdkDragStarted)="isDragging = true"
      (cdkDragEnded)="isDragging = false"
      *ngFor="let item of items; trackBy: trackByItemId"
      class="cdk-drag-animating"
    >
      <app-budget-line-item
        class="form column lines-wrapper w-fit 2xl:w-auto"
        [item]="item"
        [selectedBudgetType]="selectedBudgetType$ | async"
        [minStartDate]="projectStartDate"
        [canDelete]="
          (selectedBudgetType$ | async) === SPEND_TYPES.BUDGET && items.length > 1 && !(hasCommitments$ | async)
        "
      ></app-budget-line-item>

      <div
        *ngIf="(selectedBudgetType$ | async) === SPEND_TYPES.BUDGET_COMMITMENTS"
        cdkDropListOrientation="vertical"
        cdkDropListLockAxis="y"
        cdkDropList
        class="grid"
        [ngClass]="{
          'cursor-grab': isDragging
        }"
        (cdkDropListDropped)="dropCommittedItem($event, item)"
      >
        <app-committed-line-item
          class="form column lines-wrapper"
          cdkDrag
          (cdkDragStarted)="isDragging = true"
          (cdkDragEnded)="isDragging = false"
          [cdkDragDisabled]="commitedItem.type === 'forecast_modification'"
          *ngFor="let commitedItem of item.committed_items; trackBy: trackByItemId"
          [committedItem]="commitedItem"
          [lineItem]="item"
          [selectedBudgetType]="selectedBudgetType$ | async"
          [minStartDate]="projectStartDate"
        ></app-committed-line-item>
      </div>
    </div>
  } @else {
    <div
      cdkDropListOrientation="vertical"
      cdkDropListLockAxis="y"
      cdkDropList
      cdkScrollable
      class="grid"
      [ngClass]="{
        'cursor-grab': isDragging
      }"
      (cdkDropListDropped)="dropCommittedItemOnly($event)"
    >
      <app-committed-line-item
        *ngFor="let commitedItem of committedItems; trackBy: trackByItemId"
        class="form column lines-wrapper"
        cdkDrag
        [cdkDragDisabled]="commitedItem.type === 'forecast_modification'"
        (cdkDragStarted)="isDragging = true"
        (cdkDragEnded)="isDragging = false"
        [committedItem]="commitedItem"
        [lineItem]="commitedItem.lineItem"
        [selectedBudgetType]="selectedBudgetType$ | async"
        [minStartDate]="projectStartDate"
      ></app-committed-line-item>
    </div>
  }

  <app-budget-line-item-totals
    [hideTotals]="!items.length || (selectedBudgetType$ | async) === SPEND_TYPES.BUDGET_COMMITMENTS"
    [selectedBudgetType]="selectedBudgetType$ | async"
  ></app-budget-line-item-totals>
</div>
