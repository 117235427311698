import { Injectable } from '@angular/core';
import { RestRequestService } from '../restApi/rest-request.service';
import {
  FORECAST_MODIFICATIONS,
  REST_BUDGET_ADJUSTMENTS,
  REST_CALC_DISTRIBUTION,
  REST_CASHFLOW,
  REST_COMMITTED_ITEMS,
  REST_LINE_ITEMS_BY_PROJECT,
  REST_PROJECT_ITEMS,
  REST_PROJECTS,
} from '../restApi/RestRoutes';
import { ErrorHandlerRest } from '../restApi/errorHandler-rest';
import { CurrentUserService } from './current-user.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import moment from 'moment/moment';
import {
  ICommittedItem,
  ICommittedItemPayload,
  IDistributionResponse,
  ILineItem,
  ILineItemPayload,
  ISpendDistribution,
  SpendDistributionRequest,
} from '../store/spend/spend.interfaces';
import { catchError } from 'rxjs/operators';
import { StorageService } from './storage.service';

export interface ProjectSpendTableData {
  original_budget: number;
  budget_adjustment: number;
  current_budget: number;
  contracts: number;
  change_orders: number;
  direct_costs: number;
  total_committed: number;
  total_invoiced: number;
  actuals: number;
  forecasts_to_complete: number;
  forecasts_adjustment: number;
  over_or_under: number;
  remaining_to_complete: number;
}

export interface ProjectSpend {
  id: number;
  name: string;
  row_number: number;
  table_data: ProjectSpendTableData;
  budget_adjustments: any[];
  forecast_modifications: any[];
}

export interface BudgetHistoryData {
  id: number;
  name: string;
  original_budget: number;
  current_budget: number;
  adjustments: Array<{
    date: string;
    value: number;
    is_external_adjustment: boolean;
    id?: number;
  }>;
}

export interface AdjustmentExtended {
  created_at: string;
  from_item: ProjectSpend;
  from_item_id: number;
  id: number;
  is_external_adjustment: boolean;
  note: string;
  to_item: ProjectSpend;
  to_item_id: number;
  updated_at: string;
  value: number;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectSpendService extends ErrorHandlerRest {
  isLoading = new BehaviorSubject<boolean>(false);
  DATE_FORMAT = 'MM/DD/YYYY';

  // this is displayed in the spend overview component
  // left here to keep the functionality and interface
  get showZeroLines(): boolean {
    return this.storage.getShowZeroLineItems();
  }

  set showZeroLines(value: boolean) {
    this.storage.setShowZeroLineItems(value);
  }

  constructor(
    private rest: RestRequestService,
    protected user: CurrentUserService,
    protected router: Router,
    private storage: StorageService,
  ) {
    super(user, router);
  }

  getAll(projectId: number, showZeroLines?: boolean): Promise<ProjectSpend[]> {
    if (!projectId) {
      return new Promise((res, rej) => rej({ message: 'Project ID is not defined' }));
    }

    if (showZeroLines === undefined) {
      showZeroLines = this.showZeroLines;
    }

    this.isLoading.next(true);
    return new Promise<ProjectSpend[]>((res, rej) => {
      this.rest
        .get(
          `${REST_PROJECTS}/${projectId}/${REST_CASHFLOW}`,
          {},
          { showZeroDollarLines: showZeroLines ? 1 : 0 },
        )
        .then(
          (data) => {
            this.isLoading.next(false);
            res(data);
          },
          (error) => {
            rej(this.handleError(error));
          },
        );
    });
  }

  getAllWithObservable(projectId: number, showZeroLines?: boolean): Observable<ProjectSpend[]> {
    if (!projectId) {
      return throwError(() => ({ message: 'Project ID is not defined' }));
    }
    if (showZeroLines === undefined) {
      showZeroLines = this.showZeroLines;
    }
    console.log('get all showZeroLines', showZeroLines);
    return this.rest.getWithObservable(
      `${REST_PROJECTS}/${projectId}/${REST_CASHFLOW}`,
      {},
      { showZeroDollarLines: showZeroLines ? 1 : 0 },
    );
  }

  getSpendLineItemSummary(lineItemId: number) {
    return this.rest.getWithObservable(`${REST_PROJECT_ITEMS}/${lineItemId}/spend-detail`);
  }

  getBudgetAdjustments(projectId: number, spends) {
    const adjustments: AdjustmentExtended[] = this.filterByAdjustId(spends);
    const filteredByLineItem = this.formatForTable(spends, adjustments);
    const totals = this.calculateTotals(spends, adjustments);
    const dates = [];
    adjustments.forEach((adjust) => {
      dates.push(moment(adjust.updated_at).format(this.DATE_FORMAT));
    });

    return { lineItems: filteredByLineItem, dates, totals };
  }

  adjustBudget(formData) {
    return new Promise((resolve, reject) => {
      this.rest.post(REST_BUDGET_ADJUSTMENTS, formData).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(this.handleError(err));
        },
      );
    });
  }

  modifyForecast(body) {
    return new Promise((resolve, reject) => {
      this.rest.post(FORECAST_MODIFICATIONS, body).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(this.handleError(err));
        },
      );
    });
  }

  private filterByAdjustId(data): AdjustmentExtended[] {
    const filteredById = [];
    data.forEach((lineItem) => {
      lineItem.budget_adjustments.forEach((adjustment) => {
        if (filteredById.findIndex((adj) => adj.id === adjustment.id) < 0) {
          const fromItem = data.find((i) => i.id === adjustment.from_item_id);
          const toItem = data.find((i) => i.id === adjustment.to_item_id);
          const filteredLineItem = {
            from_item: fromItem,
            to_item: toItem,
            ...adjustment,
          };
          filteredById.push(filteredLineItem);
        }
      });
    });
    filteredById.sort((a, b) => {
      if (moment(a.updated_at).isBefore(moment(b.updated_at))) {
        return -1;
      }
      return 1;
    });
    return filteredById;
  }

  private formatForTable(lineItems, adjustments: AdjustmentExtended[]) {
    const formattedItems: BudgetHistoryData[] = this.initFormattedLineItems(lineItems);
    adjustments.forEach((adjust, adjustIndex) => {
      const fromItem = formattedItems.find((i) => i.id === adjust.from_item_id);
      const toItem = formattedItems.find((i) => i.id === adjust.to_item_id);

      // all values default to 0, this is overwritten below at some line items
      formattedItems.forEach((item) => {
        item.adjustments.push({
          date: moment(adjust.updated_at).format(this.DATE_FORMAT),
          is_external_adjustment: adjust.is_external_adjustment,
          value: 0,
        });
      });

      // if it's an external adjustment it doesn't have a source line item
      if (!adjust.is_external_adjustment) {
        fromItem.adjustments[adjustIndex].value = -adjust.value;
      }
      toItem.adjustments[adjustIndex].value = adjust.value;
    });

    return formattedItems;
  }

  private initFormattedLineItems(lineItems) {
    const formattedItems = [];
    lineItems.forEach((item) => {
      const formatted: BudgetHistoryData = {
        id: item.id,
        name: item.name,
        original_budget: item.table_data.original_budget,
        current_budget: item.table_data.current_budget,
        adjustments: [],
      };
      formattedItems.push(formatted);
    });
    return formattedItems;
  }

  calculateTotals(spends, adjustments: AdjustmentExtended[]) {
    const totals = {
      original_budget: 0,
      current_budget: 0,
      adjustments: [],
    };
    spends.forEach((item) => {
      totals.original_budget += item.table_data.original_budget;
      totals.current_budget += item.table_data.current_budget;
    });

    // this is kept for legacy code
    adjustments.forEach((record) => {
      totals.adjustments.push({
        date: moment(record.updated_at).format(this.DATE_FORMAT),
        value: record.is_external_adjustment ? record.value : 0,
      });
    });

    return totals;
  }

  formatString(value) {
    const regex = /[^\d.-]/g;
    return typeof value === 'string' ? value.replace(regex, '') : value;
  }

  getLineItemsByProjectId(id: number, includeBudgetAdjustments = null) {
    const params: any = { fiscal_view: 1 };
    if (includeBudgetAdjustments) {
      params.with_budget_adjustment = 1;
    }
    return this.rest.getWithObservable(`${REST_LINE_ITEMS_BY_PROJECT}/${id}`, {}, params);
  }

  createLineItem$(lineItem: ILineItem) {
    const payload = this.getLineItemPayload(lineItem);

    return this.rest.postWithObservable(REST_PROJECT_ITEMS, payload);
  }

  // get only necessary data
  getLineItemPayload(lineItem: ILineItem): ILineItemPayload {
    const payload = {
      id: lineItem.id,
      name: lineItem.name,
      budget: lineItem.budget,
      duration: lineItem.duration,
      start_date: moment(lineItem.start_date).format('YYYY-MM-DD'),
      distribution: lineItem.distribution,
      row_number: lineItem.row_number,
      project_id: lineItem.project_id,
    };

    return payload;
  }

  updateLineItem(lineItem: ILineItem) {
    const payload = this.getLineItemPayload(lineItem);

    return this.rest
      .putWithObservable(`${REST_PROJECT_ITEMS}/${payload.id}?fiscal_view=1`, payload)
      .pipe(catchError((err) => throwError(err)));
  }

  // get only necessary data
  getCommittedItemPayload(lineItem: ICommittedItem): ICommittedItemPayload {
    const payload = {
      id: lineItem.id,
      row_number: lineItem.row_number,
      committed_row_number: lineItem.committed_row_number,
      budget: lineItem.budget,
      distribution: lineItem.distribution,
      project_id: lineItem.project_id,
    };

    return payload;
  }

  updateCommittedLineItem(lineItem: ICommittedItem) {
    const payload = this.getCommittedItemPayload(lineItem);

    return this.rest
      .patchWithObservable(`${REST_COMMITTED_ITEMS}/${payload.id}`, payload)
      .pipe(catchError((err) => throwError(err)));
  }

  deleteLineItem(id: number) {
    return this.rest.delWithObservable(`${REST_PROJECT_ITEMS}/${id}`);
  }

  calculateDistribution(
    dist: ISpendDistribution,
    lineId: number | null = null,
  ): Promise<IDistributionResponse> {
    const params: SpendDistributionRequest = {
      budget: dist.budget,
      start_date: dist.start_date,
      duration: dist.duration,
      field: dist.field,
      distribution: dist.distribution,
    };
    if (lineId) {
      params.item_id = lineId;
    }
    return this.rest.get(`${REST_CALC_DISTRIBUTION}`, {}, params);
  }
}
