<div class="cost-view-dropdown-container">
  <ng-scrollbar class="std-scrollbar rows-wrapper" appearance="native" orientation="vertical">
    <div *ngIf="!commitmentItems?.length && !costs?.length">
      <div class="text-center pt-4 text-sm text-shade_3">
        There are no existing spend entries associated with the Budget Line & Tag selected
      </div>
    </div>

    <div class="table-wrapper">
      <div *ngIf="commitmentItems?.length > 0 || costs.length > 0" class="row">
        <div class="row-title">Spend Description</div>
        <div class="row-title">Budget tag</div>
        <div class="row-title">Budget Line</div>
        <div class="row-title">Commit</div>
        <div class="row-title">Invoiced</div>
        <div class="row-title">Balance</div>
      </div>

      <!--      Change Order costs -->
      <ng-container *ngFor="let commitmentItem of commitmentItems">
        <ng-container *ngFor="let cost of commitmentItem.costs">
          <div class="row">
            <div (click)="selectCost(cost)" [ngClass]="{ disabled: cost.is_disabled }" class="pointer-cursor">
              <app-faded-text>
                {{ cost.name }}
              </app-faded-text>
            </div>
            <app-faded-text>
              {{ commitmentItem?.budget_tag?.name ?? '' }}
            </app-faded-text>
            <div>
              <app-faded-text>{{ commitmentItem?.item?.name + '' }} </app-faded-text>
            </div>
            <div [ngClass]="{ negative: cost.total_committed < 0 }">
              {{ cost.total_committed | money: 'shortWithParentheses' }}
            </div>
            <div [ngClass]="{ negative: cost.total_invoiced < 0 }">
              {{ cost.total_invoiced | money: 'shortWithParentheses' }}
            </div>
            <div [ngClass]="{ negative: cost.total_committed - cost.total_invoiced < 0 }">
              {{ cost.total_committed - cost.total_invoiced | money: 'shortWithParentheses' }}
            </div>
          </div>
        </ng-container>
      </ng-container>

      <!--      Invoice costs-->
      <ng-container *ngFor="let cost of costs">
        <div class="row">
          <div (click)="selectInvoiceCost(cost)" [ngClass]="{ disabled: cost.is_disabled }" class="pointer-cursor">
            <app-faded-text>
              {{ cost.name }}
            </app-faded-text>
          </div>
          <app-faded-text>
            {{ cost?.budget_tag?.name ?? '' }}
          </app-faded-text>
          <div>
            <app-faded-text>{{ cost?.item?.name + '' }} </app-faded-text>
          </div>
          <div [ngClass]="{ negative: cost.total_committed < 0 }">
            {{ cost.total_committed | money: 'shortWithParentheses' }}
          </div>
          <div [ngClass]="{ negative: cost.total_invoiced < 0 }">
            {{ cost.total_invoiced | money: 'shortWithParentheses' }}
          </div>
          <div [ngClass]="{ negative: cost.total_committed - cost.total_invoiced < 0 }">
            {{ cost.total_committed - cost.total_invoiced | money: 'shortWithParentheses' }}
          </div>
        </div>
      </ng-container>
    </div>
  </ng-scrollbar>
</div>
