<ng-template #separator>
  <div class="min-w-7 w-7 px-0.5">
    <div class="separator-border w-px h-full ml-[50%]"></div>
  </div>
</ng-template>

<div @slideIn class="history-wrapper flex flex-col">
  <div class="flex w-full min-h-14 py-px bg-shade_7 border-y border-solid border-shade_6 items-center">
    <app-simple-button
      size="large"
      class="ml-auto mr-2 gap-1"
      [ngClass]="{ disabled: isLoading }"
      (click)="openBudgetLog()"
      [wrapperClass]="{ 'px-2.5 w-40': true }"
    >
      <div class="flex justify-between w-full">
        <span> View Budget Log</span>
        <span class="icon-view"></span>
      </div>
    </app-simple-button>
  </div>

  @if (!isLoading) {
    <ng-scrollbar appearance="compact" orientation="auto" class="std-scrollbar flex-1">
      <div class="flex flex-col">
        <div class="flex text-shade_2 font-bold text-size_xs uppercase pt-5 top-0 sticky bg-white">
          <div class="min-w-56 w-56 mb-1.5">Budget Lines</div>
          <ng-template *ngTemplateOutlet="separator"></ng-template>

          <div class="min-w-32 w-32 -mr-0.5 mb-1.5">Current Budget</div>
          <ng-template *ngTemplateOutlet="separator"></ng-template>

          @for (date of dates; let index = $index; track index) {
            <div class="flex">
              <span class="min-w-32 w-32 -mr-0.5 mb-1.5">{{ date }} Budget</span>
              <ng-template *ngTemplateOutlet="separator"></ng-template>
            </div>
          }

          <div class="min-w-32 w-32 -mr-0.5 mb-1.5">Original Budget</div>
          <ng-template *ngTemplateOutlet="separator"></ng-template>
        </div>
        <!--      <ng-scrollbar visibility="always" appearance="compact" orientation="vertical" class="std-scrollbar">-->
        <div class="flex flex-col h-full flex-1">
          @for (item of lineItems; track item.id) {
            <div class="flex">
              <app-floating-input
                class="min-w-56 w-56 mod-spend-bg"
                [disabled]="true"
                [verticalSpacing]="false"
                [(model)]="item.name"
                [classes]="{
                  wrapper: '',
                  input: ''
                }"
                [name]="'checklistInput_' + item.id"
              ></app-floating-input>
              <ng-template *ngTemplateOutlet="separator"></ng-template>

              <app-spend-custom-input
                class="min-w-28 w-28 border-box"
                [disabled]="true"
                [allowNegatives]="true"
                [value]="item.current_budget"
              />
              <ng-template *ngTemplateOutlet="separator"></ng-template>

              @for (adjust of item.adjustments; let index = $index; track index) {
                <app-spend-custom-input
                  class="min-w-28 w-28 border-box"
                  [disabled]="true"
                  [allowNegatives]="true"
                  [value]="adjust.value"
                />
                <ng-template *ngTemplateOutlet="separator"></ng-template>
              }

              <app-spend-custom-input
                class="min-w-28 w-28 border-box"
                [disabled]="true"
                [allowNegatives]="true"
                [value]="item.original_budget"
              />
              <ng-template *ngTemplateOutlet="separator"></ng-template>
            </div>
          }

          <div class="flex font-bold text-color_primary text-xs flex-1 pb-5">
            <div class="min-w-56 w-56"></div>
            <ng-template *ngTemplateOutlet="separator"></ng-template>

            <div
              class="min-w-32 w-32 -mr-0.5 pl-4 pt-2.5 box-border"
              [ngClass]="{ negative: totals.current_budget < 0 }"
            >
              $ {{ Math.abs(totals.current_budget) | money: 'comaNoZero' }}
            </div>
            <ng-template *ngTemplateOutlet="separator"></ng-template>

            @for (adjust of totals.adjustments; let index = $index; track index) {
              <div class="flex">
                <span class="min-w-32 w-32 -mr-0.5 pl-4 pt-2.5 box-border" [ngClass]="{ negative: adjust.value < 0 }"
                  >$ {{ Math.abs(adjust.value) | money: 'comaNoZero' }}</span
                >
                <ng-template *ngTemplateOutlet="separator"></ng-template>
              </div>
            }

            <div
              class="min-w-32 w-32 -mr-0.5 pl-4 pt-2.5 box-border"
              [ngClass]="{ negative: totals.original_budget < 0 }"
            >
              $ {{ Math.abs(totals.original_budget) | money: 'comaNoZero' }}
            </div>
            <ng-template *ngTemplateOutlet="separator"></ng-template>
          </div>
        </div>
      </div>
      <!--      </ng-scrollbar>-->
    </ng-scrollbar>

    <div class="flex items-center h-20 border-t border-solid border-shade_5">
      <app-simple-button
        size="large"
        [wrapperClass]="{
          'bg-white border rounded border-solid border-color_secondary text-color_secondary text-sm font-semibold': true
        }"
        class="flex ml-auto"
        (click)="closeView()"
      >
        Close
      </app-simple-button>
    </div>
  }

  @if (isLoading) {
    <div class="loading-content">
      <div class="notify-msg animate-pulsating-border">
        <div class="icon-logo-box-hex"></div>
      </div>
    </div>
  }
</div>
