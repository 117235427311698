<div class="tab-container">
  <!--  keep it hidden, it's not visible if user wants to export, only used for download, component is loaded only if needed-->
  @if ((spendState$ | async) === SPEND_DISTRIBUTION_STATE.SHOW_SPREADSHEET) {
    <div class="spreadsheet-container hidden">
      <app-spreadsheet
        class="tab-content-wrapper"
        [data]="spreadsheetData"
        [spendType]="selectedBudgetType$ | async"
        useOnlyAsDownload="true"
        #spreadsheet
      ></app-spreadsheet>
    </div>
  }

  <div class="tab-content-wrapper spend-distribution-wrapper">
    <div *ngIf="(spendState$ | async) === SPEND_DISTRIBUTION_STATE.LOADING" class="loading-screen">
      <span class="icon-refresh animate-spin"></span>
    </div>

    <app-spend-distribution-header
      [selectedBudgetType]="selectedBudgetType$ | async"
      (openSpreadsheetView)="openSpreadsheet()"
    ></app-spend-distribution-header>

    @if (isBudgetMissing$ | async) {
      <app-general-empty-splash class="h-full" title="Please select a project template"></app-general-empty-splash>
    } @else {
      <app-budget-line-item-table></app-budget-line-item-table>
    }
  </div>
</div>
