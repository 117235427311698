<div class="interaction-bar-wrapper share-file-wrapper">
  <div class="interaction-bar-title text-capitalize">Share {{ itemType ?? 'document' }}</div>

  <div *ngIf="!isLoading" class="interaction-bar-content">
    <div *ngIf="itemType" class="name-wrapper section">
      <div class="text-uppercase label">{{ itemType }} name</div>
      <div class="item-name">{{ item?.name }}</div>
    </div>

    <ng-scrollbar class="std-scrollbar flex-1" appearance="native" orientation="vertical">
      <app-dropdown
        *ngIf="!showEmailInput && accessibleUsers.length > 0"
        class="input"
        name="participantsControlModel"
        [(model)]="participantsControlModel"
        [multiple]="true"
        [options]="accessibleUsers"
        optionLabel="name"
        optionValue="id"
        label="Select who to share with"
        placeholder="Select who to share with"
        required
      >
        <ng-template #selectedContent #optionContent let-option>
          <ng-container *ngIf="option.id">
            <div>{{ option.name ?? option.email }}</div>
          </ng-container>
          <ng-container *ngIf="!option.id">
            <span class="custom-mat-option" (click)="addManualEmailInput()">
              <span class="icon-add-round"></span>
              <span>Manually Input Email</span>
            </span>
          </ng-container>
        </ng-template>
      </app-dropdown>

      @if (showEmailInput || accessibleUsers?.length === 0) {
        <div class="section">
          <app-floating-input
            formControlName="emailControl"
            (focusout)="addEmailToSelectOptions()"
            (keyup.enter)="addEmailToSelectOptions()"
            [formControl]="emailControl"
            [iconClass]="{
              position: 'p-input-icon-right',
              icon: 'icon icon-remove'
            }"
            (iconClick)="addManualEmailInput()"
            ngDefaultControl
            type="email"
            placeholder="Select who to share with (email)"
            label="Select who to share with (email)"
          ></app-floating-input>
        </div>
      }

      <app-dropdown
        class="input"
        name="selectedRight"
        [options]="selectableRights"
        [(model)]="selectedRight"
        label="Share Rights"
        placeholder="Share Rights"
      >
      </app-dropdown>

      <div class="accessors section">
        <div class="label text-uppercase">current access</div>
        <div *ngIf="currentAccessors.length === 0">No users with access.</div>
        <div
          class="accessor-wrapper"
          [ngClass]="accessor.is_bundle ? 'email' : ''"
          *ngFor="let accessor of currentAccessors"
        >
          <div class="accessor">
            <div class="accessor-info">
              <app-faded-text class="accessor-name user">{{ accessor?.name }}</app-faded-text>
              <app-faded-text class="accessor-name">{{
                accessor?.file?.name ?? accessor?.folder?.name
              }}</app-faded-text>
            </div>
            <span
              [ngClass]="{
                'access-read-only': accessor.permissions === SHARE_PERMISSIONS.READ_ONLY,
                'access-read-upload': accessor.permissions === SHARE_PERMISSIONS.READ_UPLOAD
              }"
              class="accessor-permission"
              >{{
                accessor.permissions === SHARE_PERMISSIONS.READ_ONLY || accessor.is_bundle ? '(R)' : '(R & U)'
              }}</span
            >
            <span class="flex items-end">
              <span (click)="removeAccessor(accessor)" class="button"> Remove </span>
            </span>
          </div>
          <div (click)="copyEmailLink(accessor)" class="copy-link" *ngIf="accessor.is_bundle">copy link</div>
        </div>
      </div>
    </ng-scrollbar>

    <button [disabled]="showEmailInput" (click)="share()" class="btn-save" mat-button>Share</button>
  </div>
  <div *ngIf="isLoading" class="notify-msg animate-pulsating-border">
    <div class="icon-logo-box-hex"></div>
  </div>
</div>
