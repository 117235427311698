import { EventEmitter, Injectable } from '@angular/core';
import { REST_GANTT_TASKS } from '../restApi/RestRoutes';
import { RestRequestService } from '../restApi/rest-request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GanttService {
  public announceState = new EventEmitter();

  constructor(private restRequest: RestRequestService) {}
  getAll(projectId: number): Observable<any> {
    // todo types
    return this.restRequest.getWithObservable(REST_GANTT_TASKS + '/' + projectId);
  }

  saveAll(projectId, ganttData) {
    return new Promise<any>((resolve, reject) => {
      this.restRequest.post(REST_GANTT_TASKS + '/' + projectId, ganttData).then(
        (data) => {
          this.announceState.emit(true);
          resolve(data);
        },
        (error) => reject(error),
      );
    });
  }
}
