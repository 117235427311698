import { Component, inject, Input, OnInit } from '@angular/core';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { SPEND_TYPES } from '../../../../../../framework/constants/budget.constants';
import cloneDeep from 'lodash/cloneDeep';
import { MONTHS } from 'src/app/framework/constants/spend.constants';
import { CurrentUserService } from '../../../../../../services/current-user.service';

@Component({
  selector: 'app-budget-line-item-table-header',
  standalone: true,
  imports: [NgForOf, NgClass, NgIf],
  templateUrl: './budget-line-item-table-header.component.html',
  styleUrl: './budget-line-item-table-header.component.scss',
})
export class BudgetLineItemTableHeaderComponent implements OnInit {
  protected readonly userService = inject(CurrentUserService);

  protected readonly SPEND_TYPES = SPEND_TYPES;
  @Input({ required: true }) selectedBudgetType: SPEND_TYPES;
  MONTHS = cloneDeep(MONTHS);

  ngOnInit() {
    this.setMonths();
  }

  setMonths() {
    let fyStart = this.userService.data.fiscal_year_start;

    if (fyStart) {
      fyStart = fyStart - 1;
      for (let i = 0; i < fyStart; i++) {
        const aux = this.MONTHS[0];
        this.MONTHS.shift();
        this.MONTHS.push(aux);
      }
    }
  }

  trackByIndex = (index: number) => index;
}
