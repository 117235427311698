import { DocumentsEffects } from './documents/documents.effects';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { documentsReducer } from './documents/documents.reducer';
import { ProjectApiService } from '../services/project-api.service';
import { DocumentsResolver } from './documents/documents.resolver';
import { projectDocumentsReducer } from './projectDocuments/projectDocuments.reducer';
import { ProjectDocumentsEffects } from './projectDocuments/projectDocuments.effects';
import { SpendEffects } from './spend/spend.effects';
import { spendFeatureKey, spendReducer } from './spend/spend.reducer';
import { messagesReducer, messagingFeatureKey } from './messages/messages.reducer';
import { MessagesGroupEffects } from './messages/messagesGroup.effects';
import { MessagingEffects } from './messages/messages.effects';
import { projectsFeatureKey, projectsReducer } from './projects/projects.reducer';
import { ProjectsEffects } from './projects/projects.effects';
import { SettingsEffects } from './settings/settings.effects';
import { settingsFeatureKey, settingsReducer } from './settings/settings.reducer';
import {
  teamManagementFeatureKey,
  teamManagementReducer,
} from './team-management/team-management.reducer';
import { TeamManagementEffects } from './team-management/team-management.effects';
import { rollupFeatureKey, rollupsReducer } from './rollups/rollups.reducer';
import { RollupEffects } from './rollups/rollups.effects';
import { commitmentsFeatureKey, commitmentsReducer } from './commitments/commitments.reducer';
import { CommitmentsEffects } from './commitments/commitments.effects';
import { propertiesFeatureKey, propertiesReducer } from './properties/properties.reducer';
import { PropertiesEffects } from './properties/properties.effects';
import { itemTrackingFeatureKey, itemTrackingReducer } from './item-tracking/item-tracking.reducer';
import { ItemTrackingEffects } from './item-tracking/item-tracking.effects';
import { activitiesReducer } from './activities/activities.reducer';
import { activitiesFeatureKey } from './activities/activities.constants';
import { ActivitiesEffects } from './activities/activities.effects';
import { viewProjectFeatureKey, viewProjectReducer } from './view-project/view-project.reducer';
import { ViewProjectEffects } from './view-project/view-project.effects';
import { CashflowEffects } from './cashflow/cashflow.effects';
import { cashflowFeatureKey, cashflowReducer } from './cashflow/cashflow.reducer';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {
  CUSTOM_DATE_FORMATS,
  CustomDateAdapter,
} from '../framework/inputs/calendar/CustomDateAdapter';
import { DateCustomPipe } from '../pipes/framework/date-custom.pipe';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    DateCustomPipe,
    StoreModule.forFeature('documents', documentsReducer),
    StoreModule.forFeature('projectDocuments', projectDocumentsReducer),
    StoreModule.forFeature(spendFeatureKey, spendReducer),
    StoreModule.forFeature(messagingFeatureKey, messagesReducer),
    StoreModule.forFeature(projectsFeatureKey, projectsReducer),
    StoreModule.forFeature(rollupFeatureKey, rollupsReducer),
    StoreModule.forFeature(propertiesFeatureKey, propertiesReducer),
    StoreModule.forFeature(settingsFeatureKey, settingsReducer),
    StoreModule.forFeature(teamManagementFeatureKey, teamManagementReducer),
    StoreModule.forFeature(commitmentsFeatureKey, commitmentsReducer),
    StoreModule.forFeature(itemTrackingFeatureKey, itemTrackingReducer),
    StoreModule.forFeature(activitiesFeatureKey, activitiesReducer),
    StoreModule.forFeature(viewProjectFeatureKey, viewProjectReducer),
    StoreModule.forFeature(cashflowFeatureKey, cashflowReducer),
    EffectsModule.forFeature([
      DocumentsEffects,
      ProjectDocumentsEffects,
      SpendEffects,
      RollupEffects,
      SettingsEffects,
      MessagesGroupEffects,
      MessagingEffects,
      ProjectsEffects,
      TeamManagementEffects,
      CommitmentsEffects,
      PropertiesEffects,
      ItemTrackingEffects,
      ActivitiesEffects,
      ViewProjectEffects,
      CashflowEffects,
    ]),
  ],
  providers: [
    ProjectApiService,
    DocumentsResolver,
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: CUSTOM_DATE_FORMATS,
    },
    DateCustomPipe,
  ],
  bootstrap: [],
})
export class StoreAppStateModule {}
