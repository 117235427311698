<div class="interaction-bar-content">
  <ng-scrollbar
    #propertyScroll
    class="std-scrollbar"
    orientation="vertical"
    [ngClass]="{ expanded: propertyScroll.isVerticallyScrollable() }"
  >
    <div [formGroup]="propertyForm" class="edit-property flex flex-col scrollable-content">
      <div class="row">
        <app-floating-input ngDefaultControl formControlName="name" placeholder="Name" label="Name">
        </app-floating-input>
      </div>

      <div class="row">
        <app-floating-input
          ngDefaultControl
          formControlName="street"
          placeholder="Address"
          label="Address"
        ></app-floating-input>
      </div>

      <div class="row">
        <app-floating-input ngDefaultControl formControlName="city" placeholder="City" label="City">
        </app-floating-input>
      </div>

      <div class="row">
        <app-dropdown
          ngDefaultControl
          [options]="states"
          optionLabel="name"
          optionValue="code"
          formControlName="state"
          label="State"
          placeholder="State"
        >
        </app-dropdown>
      </div>

      <div class="row">
        <app-floating-input
          type="number"
          ngDefaultControl
          formControlName="zip"
          placeholder="Zip Code"
          label="Zip Code"
        >
        </app-floating-input>
      </div>

      <div class="row">
        <app-dropdown
          ngDefaultControl
          formControlName="type_id"
          placeholder="Property Type"
          label="Property Type"
          [options]="propertyTypes"
          optionLabel="name"
          optionValue="id"
        >
        </app-dropdown>
      </div>

      <div class="row">
        <app-floating-input
          ngDefaultControl
          formControlName="type_unit_value"
          label="Number Of Units"
          placeholder="Number Of Units"
        >
        </app-floating-input>
      </div>
    </div>
  </ng-scrollbar>

  <app-simple-small-button
    class="save-btn"
    (click)="save()"
    wrapperClass="font-semibold bg-color_accent text-white ml-auto w-32 h-7 flex items-center justify-center rounded pointer-cursor"
  >
    @if (isLoading) {
      <span class="icon-refresh animate-spin"></span>
    } @else {
      {{ property ? 'Update' : 'Save' }}
    }
  </app-simple-small-button>
</div>
