<div *ngIf="!(isSidebarLoading$ | async); else loadingScreen" class="h-full flex flex-col">
  <app-commitment-sidebar-title
    [showBackButton]="!isEdit || (currentPage$ | async) !== COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE"
    [title]="isEdit ? 'Edit Invoice' : 'Add Invoice'"
    (goBack)="goBack()"
  ></app-commitment-sidebar-title>

  <app-commitment-entry-text
    *ngIf="(currentPage$ | async) === 1"
    entryType="General"
    text="Invoice Entry"
  ></app-commitment-entry-text>

  <ng-scrollbar
    [ngClass]="{ 'first-page': (currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE }"
    class="std-scrollbar wider-track"
    appearance="compact"
    orientation="vertical"
  >
    <div
      [ngClass]="{ 'mt-5': (currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE }"
      class="scrollable-content mb-5"
    >
      <form
        class="flex flex-col flex-1 horizontal-spacing"
        #firstPageForm="ngForm"
        role="form"
        *ngIf="(currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE; else secondPage"
        [@fadeInGeneral]
      >
        <div class="flex-1 flex flex-col">
          <app-upload-commitments-sidebar
            [commitmentType]="COMMITMENTS_INTERACTION_BAR_TYPE.INVOICES"
          ></app-upload-commitments-sidebar>

          <div class="two-input-row">
            <div
              [ngClass]="{
                'p-disabled': isEdit
              }"
              (click)="isDropdownShown.service_provider = true"
              class="w-full p-float-label p-input-icon-right mb-2 mt-6 cursor-pointer"
            >
              <input
                pInputText
                required
                readonly
                #optionsTrigger="cdkOverlayOrigin"
                cdkOverlayOrigin
                class="w-full cursor-pointer pr-8 overflow-hidden text-nowrap text-ellipsis"
                id="serviceProviderUserId"
                name="serviceProviderUserId"
                #serviceProviderModel="ngModel"
                [disabled]="isEdit"
                [(ngModel)]="model.service_provider.name"
                [ngClass]="{
                  'ng-invalid ng-dirty': serviceProviderModel.invalid && serviceProviderModel.touched
                }"
              />
              <label>Company Name</label>
              <small
                [ngClass]="{
                  hidden: serviceProviderModel.valid || serviceProviderModel.untouched
                }"
                class="p-error text-uppercase"
                >REQUIRED</small
              >
              <i
                class="-mt-[4px]"
                [ngClass]="isDropdownShown.service_provider ? 'dropdown-icon opened' : 'dropdown-icon closed'"
              ></i>

              <app-options-list-general
                [(show)]="isDropdownShown.service_provider"
                [trigger]="optionsTrigger"
                [overlayPositions]="dropdownOverlayPositions"
              >
                <app-company-dropdown
                  (optionSelected)="selectedCompany($event, serviceProviderModel)"
                  [hideAddCompany]="true"
                  [commitmentType]="COMMITMENTS_TYPE.INVOICES"
                ></app-company-dropdown>
              </app-options-list-general>
            </div>

            <app-dropdown
              [required]="true"
              name="contract_id"
              [(model)]="model.contract_id"
              [options]="filteredContractsByCompany"
              [disabled]="isEdit"
              optionLabel="title"
              optionValue="id"
              (modelChange)="updateInvoice(); loadContractSummaryDetails()"
              label="Contract Title"
            >
            </app-dropdown>
          </div>

          <div class="two-input-row">
            <app-floating-input
              [required]="true"
              name="title"
              [(model)]="model.title"
              (modelChange)="updateInvoice()"
              label="Invoice Title"
            >
            </app-floating-input>

            <app-dropdown
              [required]="true"
              placeholder="Invoice Status"
              label="Invoice Status"
              name="paid_status"
              [(model)]="model.paid_status"
              [options]="paidStatuses"
              (modelChange)="updateInvoice()"
            >
              <ng-template let-status #optionContent>
                <div
                  *ngIf="status"
                  [ngClass]="status.value ? 'text-color_primary' : 'text-shade_2'"
                  class="capitalize text-medium"
                >
                  {{ status.text }}
                </div>
              </ng-template>
              <ng-template let-status #selectedContent>
                <div
                  *ngIf="status"
                  [ngClass]="status.value ? 'text-color_primary' : 'text-shade_2'"
                  class="capitalize text-medium"
                >
                  {{ status.text }}
                </div>
              </ng-template>
            </app-dropdown>
          </div>

          <div class="two-input-row">
            <app-input-calendar
              #added_at_calendar
              [required]="true"
              [date]="model.added_at"
              [minStartDate]="(selectedContract$ | async)?.contract?.added_at ?? projectData?.start_date"
              (dateChanged)="updateAddedAtDate($event)"
              placeholder="Invoice Date"
              name="added_at"
              [ngClass]="{
                'col-span-2': !model.paid_status
              }"
              [iconClass]="{
                iconPosition: 'p-input-icon-right',
                icon: 'icon-calendar-box text-color_secondary'
              }"
            ></app-input-calendar>

            @if (!!model.paid_status) {
              <app-input-calendar
                #paid_calendar
                [required]="true"
                [date]="model.paid_date"
                [minStartDate]="model?.added_at ?? projectData?.start_date"
                (dateChanged)="updatePaidDate($event)"
                placeholder="Paid Date"
                name="paid_date"
                [iconClass]="{
                  iconPosition: 'p-input-icon-right',
                  icon: 'icon-calendar-box text-color_secondary'
                }"
              ></app-input-calendar>
            }
          </div>

          <app-floating-textarea
            [(model)]="model.description"
            (modelChange)="updateInvoice()"
            rows="5"
            cols="30"
            label="Invoice Description"
          >
          </app-floating-textarea>
        </div>
      </form>
      <ng-template #secondPage>
        <div [@fadeInGeneral]>
          <app-commitment-entry-text
            entryType="Invoice"
            text="Invoice Entry"
            disabledAddDescriptionTooltip="All costs have been chosen."
            [disableAddDescription]="disableAddDescription"
          ></app-commitment-entry-text>

          <div class="horizontal-spacing">
            <div
              class="p-float-label flex flex-col p-input-icon-right mb-2 mt-7"
              (click)="isDropdownShown.budget_tag = true"
            >
              <input
                pInputText
                cdkOverlayOrigin
                #optionsTrigger="cdkOverlayOrigin"
                id="budget_tag"
                name="budget_tag"
                #budget_tag="ngModel"
                [(ngModel)]="model.budget_tag.name"
                class="pointer-cursor"
                [ngClass]="{
                  'ng-invalid ng-dirty': budget_tag.invalid && budget_tag.touched
                }"
                placeholder="Budget Tag"
                readonly
              />

              <label>Budget Tag</label>
              <small
                [ngClass]="{
                  hidden: budget_tag.valid || budget_tag.untouched
                }"
                class="p-error text-uppercase"
                >REQUIRED</small
              >

              <i
                class="-mt-[4px]"
                [ngClass]="isDropdownShown.budget_tag ? 'dropdown-icon opened' : 'dropdown-icon closed'"
              ></i>

              <app-options-list-general
                [(show)]="isDropdownShown.budget_tag"
                [trigger]="optionsTrigger"
                [overlayPositions]="dropdownOverlayPositions"
              >
                <app-budget-tag-templates-dropdown
                  *ngIf="isDropdownShown.budget_tag"
                  [budgetTemplateTags]="budgetTagTemplates"
                  [hasTemplate]="!!model.budget_tag.id"
                  [showAllTags]="true"
                  (optionSelected)="selectedBudgetTag($event)"
                  (selectAllTagsLocalOption)="selectedAllBudgetTags()"
                  (unlinkTemplate)="unlinkTemplate()"
                ></app-budget-tag-templates-dropdown>
              </app-options-list-general>
            </div>
            <ng-container *ngFor="let cost of costs; trackBy: trackByIndex; let index = index">
              <app-cost-description
                #costDescription
                [commitmentType]="commitmentType"
                [spendEntryIndex]="0"
                [costIndex]="index"
                [model]="cost"
                [totalCostCount]="costs.length"
              >
              </app-cost-description>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-scrollbar>

  <div class="bottom-container" [ngClass]="{ bordered: (currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.LAST_PAGE }">
    <span
      *ngIf="(currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.LAST_PAGE"
      class="text-shade_1 text-base font-bold flex flex-1"
    >
      <span class="flex-1">Total Invoice</span>
      <span class="flex-1 justify-center flex" [ngClass]="{ negative: (totalInvoiceCost$ | async) < 0 }">
        <span *ngIf="(totalInvoiceCost$ | async) !== 0">{{ totalInvoiceCost$ | async | money: 'positiveComa' }}</span>
        <span *ngIf="(totalInvoiceCost$ | async) === 0">$ {{ totalInvoiceCost$ | async | number }}</span>
      </span>
    </span>

    @if ((currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE) {
      <app-simple-button
        [wrapperClass]="{
          rounded: true
        }"
        (click)="incrementPage()"
        [disabled]="isLoading"
      >
        <span *ngIf="!isLoading">Next</span>
        <span *ngIf="isLoading" class="icon-refresh animate-spin"></span>
      </app-simple-button>
    } @else {
      <app-simple-button
        (click)="save()"
        [disabled]="isLoading"
        [wrapperClass]="{
          'bg-color_accent rounded': true
        }"
      >
        <span *ngIf="!isLoading">Submit</span>
        <span *ngIf="isLoading" class="icon-refresh animate-spin"></span>
      </app-simple-button>
    }
  </div>
</div>
<ng-template #loadingScreen>
  <div class="flex w-full h-full items-center justify-center">
    <div class="notify-msg animate-pulsating-border">
      <div class="icon-logo-box-hex"></div>
    </div>
  </div>
</ng-template>
