import { Component, Input, ViewChild } from '@angular/core';
import { BackButtonBoxComponent } from '../../back-button-box/back-button-box.component';
import { ChecklistItemComponent } from '../../inputs/checklist-item/checklist-item.component';
import { FloatingInputComponent } from '../../inputs/floating-input/floating-input.component';
import { MatButton } from '@angular/material/button';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';
import {
  FormControl,
  FormGroup,
  FormsModule,
  NgForm,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { take } from 'rxjs/operators';
import { IServiceProvider } from './invoice-report-panel.types';
import { Project, PROJECT_STATUS_KEY } from '../../../pages/webapp/projects/projects.interface';
import {
  getInvoiceReportingInitialValues,
  IReportingPayload,
  REPORTING_ALL_STATUSES,
  REPORTING_SIDEBAR_TYPES,
  ReportingInvoiceFormType,
} from '../reporting/reporting.constants';
import { InputCalendarComponent } from '../../inputs/input-calendar/input-calendar.component';
import dayjs from 'dayjs';
import { LoadingPulseComponent } from '../../loading-pulse/loading-pulse.component';
import { ReportingBaseComponent } from '../reporting/reporting-base.component';

@Component({
  selector: 'app-invoice-report-panel',
  standalone: true,
  imports: [
    BackButtonBoxComponent,
    ChecklistItemComponent,
    FloatingInputComponent,
    MatButton,
    NgForOf,
    NgClass,
    NgIf,
    NgScrollbar,
    ReactiveFormsModule,
    InputCalendarComponent,
    FormsModule,
    LoadingPulseComponent,
  ],
  templateUrl: './invoice-report-panel.component.html',
  styleUrl: '../reporting/reporting-bar.component.scss',
})
export class InvoiceReportPanelComponent extends ReportingBaseComponent {
  @ViewChild('dateRangesPageForm') dateRangesPageForm: NgForm;
  readonly PROPERTY_PAGE = 0;
  readonly PROJECTS_PAGE = 1;
  readonly SP_PAGE = 2;
  readonly DATES_PAGE = 3;
  readonly MAX_PAGE = 3;
  readonly reportingType = REPORTING_SIDEBAR_TYPES.INVOICE_LOG;

  serviceProviderSearch = '';

  private _serviceProvidersList: IServiceProvider[] = [];
  set serviceProvidersList(value) {
    this._serviceProvidersList = value;
    this.allServiceProviderIds = value.map((sp) => sp.id);
  }
  get serviceProvidersList() {
    return this._serviceProvidersList;
  }

  allStatuses = [...REPORTING_ALL_STATUSES];

  reportFields: FormGroup<ReportingInvoiceFormType> = new FormGroup<ReportingInvoiceFormType>({
    ...getInvoiceReportingInitialValues(),
    invoice_log_start_date: new FormControl<string>(null, [Validators.required]),
    invoice_log_end_date: new FormControl<string>(null, [Validators.required]),
  });
  dateRanges = {
    invoice_log_start_date: null,
    invoice_log_end_date: null,
  };

  @Input() set data(value: { properties: any; projects: Project[] }) {
    if (value) {
      if (value.properties) {
        this.properties = this.sortProperties(Array(...value.properties));
        this.propertiesToList = [...this.properties];
        this.allPropertyIds = this.properties.map((prop) => prop.id);
      }
      if (value.projects) {
        const projects = value.projects.filter(
          (proj) =>
            proj.project_status?.key !== PROJECT_STATUS_KEY.DELETED &&
            proj.project_status?.key !== PROJECT_STATUS_KEY.ARCHIVED,
        );
        this.projects = this.sortProjects([...projects]);
        this.allProjectIds = this.projects.map((proj) => proj.id);
      }
    }
  }

  /**
   * Get filtered service providers list
   */
  get filteredServiceProvidersList(): IServiceProvider[] {
    return this.serviceProvidersList.filter(
      (sp: IServiceProvider) =>
        !this.serviceProviderSearch ||
        sp.name.toLowerCase().includes(this.serviceProviderSearch.toLowerCase()),
    );
  }

  checkOverallValidation() {
    let isValid = true; // if any call returns false, this will be false
    isValid = isValid && this.checkPropertyValidation();
    isValid = isValid && this.checkProjectValidation();
    isValid = isValid && this.checkSPValidation();
    this.submitDisabled = !isValid;
  }

  checkSPValidation() {
    this.errors[this.SP_PAGE] = null;
    if (this.reportFields.get('service_provider_ids').value.length === 0) {
      this.errors[this.SP_PAGE] = 'At least one service provider is required.';
    }
    return !this.errors[this.SP_PAGE];
  }

  checkStatusPageValidation(): boolean {
    return true;
  }

  onCompanyIconClick() {
    this.serviceProviderSearch = '';
  }

  toggleAllSP() {
    this.toggleAllValues(
      'service_provider_ids',
      this.serviceProvidersList.map((sp) => sp.id),
    );
    this.checkSPValidation();
  }

  toggleSP(companyId: number) {
    this.toggleSpecificValues(
      'service_provider_ids',
      companyId,
      this.serviceProvidersList.map((sp) => sp.id),
    );
    this.checkSPValidation();
  }

  convertFormToPayload(): IReportingPayload {
    const serviceProviders = this.serviceProvidersList.filter((sp) => sp.is_tmp === 0);
    const tempServiceProviders = this.serviceProvidersList.filter((sp) => sp.is_tmp === 1);
    const spIds = serviceProviders
      .filter((contractor) =>
        this.reportFields.get('service_provider_ids').value.includes(contractor.id),
      )
      .map((sp) => sp.id);

    const tmpSpIds = tempServiceProviders
      .filter((contractor) =>
        this.reportFields.get('service_provider_ids').value.includes(contractor.id),
      )
      .map((sp) => sp.id);

    const formData = this.reportFields.getRawValue();
    delete formData.service_provider_ids;
    const data: IReportingPayload = {
      ...formData,
      property_ids: this.reportFields.get('property_ids').value,
      project_ids: this.reportFields.get('project_ids').value,
      project_status_ids: this.reportFields.get('project_status_ids').value,
      invoice_log_start_date: formData.invoice_log_start_date,
      start_year: dayjs(formData.invoice_log_start_date).year(),
      end_year: dayjs(formData.invoice_log_end_date).year(),
      tmp_sp_ids: tmpSpIds,
      sp_ids: spIds,
    };

    return data;
  }

  checkDatesValidation() {
    this.dateRangesPageForm.control.markAllAsTouched();
    this.errors[this.DATES_PAGE] = null;
    const startDate = this.dateRanges.invoice_log_start_date;
    const endDate = this.dateRanges.invoice_log_end_date;

    if (!startDate || !endDate) {
      this.errors[this.DATES_PAGE] = 'Start and end dates are required.';
    } else {
      this.errors[this.DATES_PAGE] = null;
    }

    return !this.errors[this.DATES_PAGE];
  }

  async fillFormAndSubmit() {
    if (this.checkDatesValidation()) {
      this.reportFields
        .get('invoice_log_start_date')
        .setValue(this.dateRanges.invoice_log_start_date);
      this.reportFields.get('invoice_log_end_date').setValue(this.dateRanges.invoice_log_end_date);
      this.reportFields
        .get('start_year')
        .setValue(dayjs(this.dateRanges.invoice_log_start_date).year());
      this.reportFields
        .get('end_year')
        .setValue(dayjs(this.dateRanges.invoice_log_end_date).year());
      await this.submit();
    }
  }

  incrementPage() {
    if (this.currentPage === this.MAX_PAGE) {
      return;
    }

    let canGoTrough = false;
    if (this.currentPage === this.PROPERTY_PAGE) {
      canGoTrough = this.checkPropertyValidation();
    } else if (this.currentPage === this.PROJECTS_PAGE) {
      canGoTrough = this.checkProjectValidation();
    } else if (this.currentPage === this.SP_PAGE) {
      canGoTrough = this.checkSPValidation();
    }

    if (canGoTrough) {
      this.currentPage++;
      this.checkOverallValidation();
      this.updateAllSelected();
    }

    if (this.currentPage === this.SP_PAGE) {
      this.loadCompanies();
    }

    // if (this.currentPage === this.PROJECTS_PAGE) {
    //   this.checkAllProjectsSelected();
    // }
  }

  loadCompanies() {
    this.isLoading = true;
    const projectIds = this.reportFields.get('project_ids').value;
    this.reportingService
      .getServiceProvidersList(projectIds)
      .pipe(take(1))
      .subscribe((spList) => {
        this.serviceProvidersList = spList;
        this.isLoading = false;
        this.updateAllSelected();
      });
  }
}
