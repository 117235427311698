<div class="bud-template-column">
  <div class="item add-item">
    <span (click)="createTemplate()" class="icon-add-round template-add-input"></span>
    <mat-form-field subscriptSizing="dynamic" class="template-add-input borderless">
      <input (keyup.enter)="createTemplate()" [(ngModel)]="templateName" matInput placeholder="New Template" />
    </mat-form-field>
  </div>
  <ng-scrollbar class="std-scrollbar" appearance="native" orientation="vertical">
    <ng-container *ngFor="let template of templates$ | async">
      <div class="flex">
        <div
          (click)="selectTemplate(template.id)"
          [ngClass]="{ selected: template?.id === (selectedTemplate | async)?.id }"
          class="item"
        >
          <app-selector-input
            class="w-full flex"
            (unselectTemplate)="unselectTemplate.emit()"
            [templateType]="templateType"
            [template]="template"
          ></app-selector-input>
        </div>

        <div [ngClass]="{ invisible: template?.id !== (selectedTemplate | async)?.id }" class="selected-template"></div>
        <!--      show active template indicator-->
      </div>
    </ng-container>
  </ng-scrollbar>
</div>
