<div class="gl-accounts-row-wrapper">
  <div class="gl-accounts-wrapper">
    <div class="templates">
      <app-template-selector (unselectTemplate)="unselectTemplate()" [type]="'gl_accounts'"></app-template-selector>
    </div>
    <div class="flex">
      <div class="item-container-wrapper" *ngIf="!!selectedTemplate">
        <div class="items-container header-container">
          <span class="item header-item uppercase col-span-2">Accounts</span>
        </div>
        <ng-container *ngIf="!showHelp">
          <div class="items-container header-container">
            <span
              (click)="showHelpGuideline(true)"
              class="item help-item bg-shade_7 text-color_secondary capitalize col-span-2"
              >Need help with accounts?</span
            >
          </div>
          <ng-scrollbar class="std-scrollbar" appearance="compact" orientation="vertical">
            <div class="items-container">
              <ng-container *ngFor="let item of selectedTemplate?.labels; let index = index">
                <span class="item index">{{ index + 1 }}</span>
                <span class="item">
                  <input
                    [readOnly]="!item?.permissions?.can_edit"
                    (change)="onInputChange(item, index, $event)"
                    (paste)="onPasteEvent(index, $event)"
                    class="template-input"
                    [value]="item.name"
                  />
                </span>
                <span class="item">
                  <!-- it removes an item, the add icon is rotated to be an X-->
                  <span *ngIf="item?.permissions?.can_delete" (click)="removeItem(item)" class="icon-add"></span>
                </span>
              </ng-container>

              <ng-container *ngIf="selectedTemplate?.permissions?.can_edit">
                <span class="item index"></span>
                <span class="item col-span-2">
                  <input
                    [(ngModel)]="newItemValue"
                    (change)="onNewItemChange()"
                    (paste)="onPasteEvent(null, $event)"
                    class="template-input"
                  />
                </span>
              </ng-container>
            </div>
          </ng-scrollbar>
        </ng-container>
        <app-templates-help
          class="h-full"
          [showHelp]="showHelp"
          (closeHelpGuideLine)="showHelp = false"
          type="gl_accounts"
          *ngIf="showHelp"
        ></app-templates-help>
      </div>
    </div>
  </div>
  <div class="gl-accounts-wrapper -ml-px">
    <div></div>
    <div class="buttons-wrapper">
      <span class="discard-btn btn flex flex-1 justify-center p-1.5 pointer-cursor" (click)="onDiscard()">Discard</span>
      <span class="send-btn btn flex flex-1 justify-center p-1.5 pointer-cursor" (click)="onSave()">Save</span>
    </div>
  </div>
</div>
