import { Component, OnInit, ViewChild } from '@angular/core';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import { NotificationsService } from '../../../services/notifications.service';
import { ProjectApiService } from '../../../services/project-api.service';
import { MatButton } from '@angular/material/button';
import { NgScrollbar } from 'ngx-scrollbar';
import { DecimalPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { FadedTextComponent } from '../../faded-text/faded-text.component';
import { ISimpleProject } from '../../../pages/webapp/projects/projects.interface';
import { ChecklistInputComponent } from '../../inputs/checklist-input/checklist-input.component';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-select-project',
  templateUrl: './select-project.component.html',
  styleUrls: ['./select-project.component.scss'],
  standalone: true,
  imports: [
    FadedTextComponent,
    NgClass,
    NgScrollbar,
    MatSelectionList,
    NgFor,
    MatListOption,
    NgIf,
    MatButton,
    ChecklistInputComponent,
    CheckboxModule,
    DecimalPipe,
    FormsModule,
  ],
})
export class SelectProjectComponent implements OnInit {
  @ViewChild('projectsSelector', { static: true }) projectsSelector: MatSelectionList;
  contractorIds: number[] = null;
  projects: Array<ISimpleProject> = [];
  title = 'Projects';
  loading = true;

  selectedProjectIds: number[] = [];

  constructor(
    private projectsApi: ProjectApiService,
    private notif: NotificationsService,
    private barState: InteractionBarStateService,
  ) {}

  ngOnInit() {
    this.projectsApi
      .getSimpleProjects({
        status_filter: ['bidding', 'awarded', 'planned', 'in_progress', 'completed', 'on_hold'],
      })
      .subscribe({
        next: (data) => {
          this.projects = data;
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
          this.notif.showError(`Failed to load projects: ${err?.message ?? err}`);
          console.error(err);
        },
      });

    this.barState.announceState.subscribe((state) => {
      this.contractorIds = state?.data?.contractorIds ?? [];
      this.title = state?.data?.title ?? 'Projects';
    });
  }

  async inviteToProject() {
    console.log(this.contractorIds);

    try {
      const createInviteResponse = await this.projectsApi.createInvite(
        this.selectedProjectIds,
        this.contractorIds,
      );
      console.log(createInviteResponse);
      this.notif.showSuccess('Successfully invited!');
    } catch (error) {
      console.log(error);
      this.notif.showError(error);
    } finally {
      this.barState.close();
    }
  }

  selectProject(project: ISimpleProject) {
    // a new array needs to be created to trigger change detection
    if (this.selectedProjectIds.includes(project.id)) {
      this.selectedProjectIds = this.selectedProjectIds.filter((id) => id !== project.id);
    } else {
      this.selectedProjectIds = [...this.selectedProjectIds, project.id];
    }
  }
}
