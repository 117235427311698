<div class="interaction-bar-wrapper">
  <div class="interaction-bar-title">Project Invite</div>

  <div class="interaction-bar-content">
    <div class="header">
      <div class="title">
        <app-faded-text>{{ title }}</app-faded-text>
      </div>
    </div>
    <div class="subtitle">PROJECT NAME</div>
    <div class="projects-container" [ngClass]="{ 'center-container': loading }">
      <ng-scrollbar orientation="vertical" class="std-scrollbar">
        @for (project of projects; track project.id) {
          <div
            (click)="selectProject(project)"
            class="flex gap-4 py-2 pl-2 pr-4 hover:bg-shade_1/10 rounded-s cursor-pointer"
          >
            <p-checkbox
              #projectCheckboxes
              [value]="project.id"
              [(ngModel)]="selectedProjectIds"
              [inputId]="project.id | number"
            ></p-checkbox>
            <div class="text-color_primary overflow-ellipsis overflow-hidden whitespace-nowrap">
              {{ project.title }}
            </div>
          </div>
        }
      </ng-scrollbar>

      <div *ngIf="loading" class="icon-refresh animate-spin notify-text"></div>
      <div class="notify-text" *ngIf="projects.length === 0 && !loading">No bidding projects</div>
    </div>
    <div class="invite-actions">
      <button mat-button class="btn-invite" (click)="inviteToProject()">Send invite</button>
    </div>
  </div>
</div>
