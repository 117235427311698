import { Injectable } from '@angular/core';
import { RestRequestService } from '../restApi/rest-request.service';
import {
  REST_DRIVE_FILES,
  REST_DRIVE_FILES_PRESIGNED,
  REST_DRIVE_VIEW_EXTERNAL_DRIVE,
} from '../restApi/RestRoutes';
import { combineLatest, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, exhaustMap, mergeMap, tap } from 'rxjs/operators';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { NotificationsService } from './notifications.service';

export interface IMetaData {
  company_picture?: string | number;
  profile_picture?: string | number;
  user_drive_folder_id?: number;
  user_drive_file_id?: number;
  progress_item_checklist_item_id?: number;
  message_id?: number;
  commitment_id?: number;
  bid_id?: number;
  name: string;
  size: number;
  type: string; // file type
}

export interface IFileUpload {
  file_id: number;
  presigned_url: string;
}

interface IFileUploadStatus {
  totalBytes: number;
  loadedBytes: number;
  completedWithSuccess: boolean; // completed means all bytes are uploaded
  completedWithError: boolean; // an error occurred during upload
  validated?: boolean; // if the file was validated after upload
}

interface IUploadTotals {
  loadedBytes: number;
  percent: number;
  uploadedCount: number;
  fileCount: number;
  requestCount: number;
  errorCount: number;
  completed: boolean; // completed with success OR error
  completedWithError: boolean;
}

interface IConstantTotals {
  totalBytes: number;
  fileCount: number;
}

interface IUploadStatus {
  [key: string]: IFileUploadStatus;
}

enum VALIDATION_STATUS {
  PENDING,
  SUCCESS,
  ERROR,
}

@Injectable({
  providedIn: 'root',
})
export class PresignedFileUploadService {
  constructor(
    private restService: RestRequestService,
    private notif: NotificationsService,
  ) {}

  /**
   * Handle upload status for all uploads in progress
   * @private
   */
  private uploadStatus: IUploadStatus = {};
  private constantTotals: IConstantTotals = { totalBytes: 0, fileCount: 0 };
  requestCount = 0; // not sure if this is needed

  /**
   * Create a presigned url for a file upload to S3 with given metadata
   * @param metaData - used to configure the file upload
   * @param uuid - external drive uuid
   */
  initiateFileUpload(metaData: IMetaData, uuid: string = null): Observable<IFileUpload> {
    return this.restService.postWithObservable(
      uuid
        ? `${REST_DRIVE_VIEW_EXTERNAL_DRIVE}/${uuid}/user-drive-files/presigned`
        : REST_DRIVE_FILES_PRESIGNED,
      metaData,
    );
  }

  /**
   * Upload a file to S3 using a presigned url and reoorting progress meanwhile
   * @param file - the file to upload
   * @param presignedUrl - the presigned url to upload the file to
   * @param uuid - external drive uuid, if specified upload to external drive
   */
  uploadFileToPresignedUrl(presignedUrl: string, file: Blob): Observable<HttpEvent<any>> {
    return this.restService
      .putWithObservableReportProgress(presignedUrl, file, {
        Authorization: 'none',
        'Content-Type': file.type,
        'ngsw-bypass': 'true', // ngsw-bypass is needed to bypass angular service worker
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          this.notif.showError(
            err?.error?.message ? err.error.message : 'An error occurred during the upload.',
          );
          return throwError(err);
        }),
      );
  }

  /**
   * Validate a file upload
   * @param id - the id of the file to validate
   * @param uuid - external drive uuid, validate uploaded file in external drive
   */
  validateFileUpload(id: number, uuid: string = null) {
    return this.restService.patchWithObservable(
      uuid
        ? `${REST_DRIVE_VIEW_EXTERNAL_DRIVE}/${uuid}/user-drive-files/validate/${id}`
        : `${REST_DRIVE_FILES}/validate/${id}`,
      {},
    );
  }

  /**
   * Upload a file to S3 using a presigned url using the following steps:
   * 1. Create a presigned url for a file upload to S3 with given metadata
   * 2. Upload a file to S3 using a presigned url
   * 3. Validate a file upload
   * @param file
   * @param metadata - used to configure the file upload
   * @param uuid - external drive uuid, if specified upload to external drive
   */
  handleS3FileUpload(
    file: File,
    metadata: IMetaData,
    uuid: string = null,
  ): Observable<File | string> {
    let uploadPathData: IFileUpload;
    this.requestCount++;

    return this.initiateFileUpload(metadata, uuid).pipe(
      exhaustMap((data) => {
        uploadPathData = data;
        const blobData = new Blob([file], { type: file.type });
        return combineLatest([
          of({ id: data.file_id }),
          this.uploadFileToPresignedUrl(uploadPathData.presigned_url, blobData),
        ]);
      }),
      mergeMap(([fileData, fileStatus]) => {
        this.setFileUploadStatus(fileData.id, fileStatus, VALIDATION_STATUS.PENDING);

        if (fileStatus.type === HttpEventType.Response && fileStatus.ok) {
          // if file uploaded, validate once again
          return this.validateFileUpload(uploadPathData.file_id, uuid).pipe(
            tap((validatedResponse) => {
              this.setFileUploadStatus(fileData.id, fileStatus, VALIDATION_STATUS.SUCCESS);
            }),
            catchError((err) => {
              this.setFileUploadStatus(fileData.id, fileStatus, VALIDATION_STATUS.ERROR);
              return throwError(err);
            }),
          );
        }

        // if not completed just return current status
        return of(fileStatus);
      }),
      catchError((err) => {
        console.error(err, metadata, file);

        // if we couldn't get a presigned url, set a random id for the file and a made-up error status
        // we create a fake response error because we didn't get a real response on upload progress,
        // because we don't have a presigned url to upload to
        this.setFileUploadStatus(
          `no_file_id_${Math.round(Math.random() * 1000)}`,

          { type: HttpEventType.Response, status: 400, ok: false } as HttpEvent<any>,
          VALIDATION_STATUS.ERROR,
        );
        this.notif.showError(
          err?.error?.message ? err.error.message : 'An error occurred during the upload.',
        );
        return throwError(err);
      }),
    );
  }

  /**
   * Default implementation of presigned url upload to S3 with notification handling and reset state
   * Returns all events, which is the validation result or an error
   * Use if you want to use the upload and see the progress at every step
   * @param file - the file to upload
   * @param metadata - the metadata to use for the file upload
   * @param uuid - external drive uuid, if specified upload to external drive
   * @param isCalledSequentially - if it is called in sequence to upload folders and their content - wont update the total state
   */
  uploadFileToS3WithNotifs(
    file: File,
    metadata: IMetaData,
    uuid: string = null,
    isCalledSequentially = false,
  ): Observable<any> {
    if (!metadata.type) {
      // if no type is available, use this as default,
      // otherwise S3 will reject the upload and we won't get a presigned URL
      metadata.type = 'application/octet-stream';
    }

    return this.handleS3FileUpload(file, metadata, uuid).pipe(
      tap((data: any) => {
        const totals = this.getTotals();
        const percentage = totals.percent.toFixed(0);
        const uploaded = totals.uploadedCount;
        const total = totals.fileCount;
        if (data.type === HttpEventType.UploadProgress) {
          let message = `Uploaded ${percentage}% (${uploaded} of ${total})`;
          if (totals.errorCount > 0) {
            message += `, ${totals.errorCount} failed`;
          }
          if (uploaded === total) {
            message += ', finishing up...';
          }
          this.notif.showLoading(message);
        }

        // checks if all chunks are uploaded
        if (totals.completed && !isCalledSequentially) {
          this.resetUploadState();
          this.notif.close();
        }
      }),
      catchError((err) => {
        if (!isCalledSequentially) {
          this.notif.showError('An error occurred during the upload.');
          this.resetUploadState();
        }
        return throwError(err);
      }),
    );
  }

  /**
   * Upload multiple files to S3 using a presigned url, return only when all requests have finished <br/>
   * Note that this component won't emit an error observable, only the error object in the response array. <br/>
   * Use if you want to use the upload and see only the result
   * @param files - the files to upload
   * @param metadata - the metadata to use for the file upload
   * @param uuid - external drive uuid, if specified upload to external drive
   * @param isCalledSequentially - if it is called in sequence to upload folders and their content (every folder is uploaded separately)
   */
  uploadMultipleFilesToS3(
    files: File[],
    metadata: Partial<IMetaData>[],
    uuid: string = null,
    isCalledSequentially = false,
  ) {
    const fileUploadRequests: Observable<any>[] = [];
    if (!isCalledSequentially) {
      this.notif.showLoading('Uploading...');
    }
    const size = files.reduce((acc, file) => acc + file.size, 0);
    this.updateConstantTotals(size, files.length, true);
    files.forEach((file, index) => {
      const fileMetadata: IMetaData = {
        name: file.name,
        size: Number(file.size),
        type: file.type,
        ...metadata[index],
      };

      fileUploadRequests.push(
        this.uploadFileToS3WithNotifs(file as File, fileMetadata, uuid, isCalledSequentially).pipe(
          catchError((error) => {
            console.warn(error, fileMetadata, file);
            // return null instead of error message to continue with other uploads
            this.notif.showError(
              error?.error?.message ? error.error.message : 'An error occurred during the upload.',
            );
            return of(error);
          }),
        ),
      );
    });
    return forkJoin(fileUploadRequests).pipe(
      tap((response) => {
        if (
          !isCalledSequentially &&
          response.filter((status) => !status?.error).length === files.length
        ) {
          // close only if all uploads are successful
          this.notif.close();
        }
      }),
    );
  }

  /**
   * Clear upload status
   */
  resetUploadState() {
    this.uploadStatus = {};
    this.requestCount = 0;
    this.constantTotals = { totalBytes: 0, fileCount: 0 };
  }

  /**
   * Set upload progress for each file separately, progress is broken down into loaded and total
   * @param id - id of the file to be uploaded or 'no_file_id_${random_number}' if no id is available
   * @param fileStatus - an HTTPEvent from HTTPClient
   * @param validationStatus - the result of the validation request - it may be in pending if the request is not finished yet
   */
  setFileUploadStatus(
    id: number | string,
    fileStatus: HttpEvent<any>,
    validationStatus: VALIDATION_STATUS = VALIDATION_STATUS.PENDING,
  ) {
    if (
      fileStatus.type === HttpEventType.Sent ||
      fileStatus.type === HttpEventType.DownloadProgress ||
      fileStatus.type === HttpEventType.User
    ) {
      // We don't care of these. No need to update progress for these events
      return;
    }

    if (fileStatus.type === HttpEventType.UploadProgress) {
      // if fileStatus is a progress event from Angular HttpClient
      const newStatus: IFileUploadStatus = {
        totalBytes: fileStatus.total,
        loadedBytes: fileStatus.loaded,
        completedWithSuccess: false,
        completedWithError: false,
      };
      this.uploadStatus = {
        ...this.uploadStatus,
        [id]: newStatus,
      };
    }

    if (
      fileStatus.type === HttpEventType.Response ||
      fileStatus.type === HttpEventType.ResponseHeader
    ) {
      // if fileStatus is a response object from the server
      const completedWithError =
        !fileStatus.ok || fileStatus.status >= 400 || validationStatus === VALIDATION_STATUS.ERROR;
      const completedWithSuccess =
        this.uploadStatus[id]?.totalBytes === this.uploadStatus[id]?.loadedBytes &&
        this.uploadStatus[id]?.totalBytes > 0;
      const validated = validationStatus === VALIDATION_STATUS.SUCCESS;

      const newStatus: IFileUploadStatus = {
        ...this.uploadStatus[id],
        completedWithSuccess,
        completedWithError,
        validated,
      };

      this.uploadStatus = {
        ...this.uploadStatus,
        [id]: newStatus,
      };
    }
  }

  getTotals(): IUploadTotals {
    let total = this.constantTotals.totalBytes;
    let fileCount = this.constantTotals.fileCount;

    if (this.constantTotals.totalBytes === 0 && this.constantTotals.fileCount === 0) {
      // fallback values if init constant totals was not called
      total = Object.values(this.uploadStatus)
        .map((s) => s.totalBytes)
        .reduce((acc, i) => acc + i, 0);

      fileCount = Object.values(this.uploadStatus).length;
    }

    const loaded = Object.values(this.uploadStatus)
      .map((s) => s.loadedBytes || 0)
      .reduce((acc, i) => acc + i, 0);
    const uploadedCount = Object.keys(this.uploadStatus).reduce((acc, key) => {
      if (
        this.uploadStatus[key].loadedBytes === this.uploadStatus[key].totalBytes &&
        this.uploadStatus[key].totalBytes > 0
      ) {
        return acc + 1;
      }
      return acc;
    }, 0);

    const completedCount = Object.keys(this.uploadStatus).reduce((accumulator, key) => {
      if (this.uploadStatus[key].completedWithSuccess) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);

    const errorCount = Object.keys(this.uploadStatus).reduce((accumulator, key) => {
      if (this.uploadStatus[key].completedWithError) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);

    let percent = 0;
    if (total !== 0) {
      percent = (loaded / total) * 100;
    }

    const completed = completedCount + errorCount >= fileCount; // it shouldn't be higher
    const completedWithError = errorCount > 0;

    return {
      percent,
      uploadedCount,
      completed,
      completedWithError,
      errorCount,
      requestCount: this.requestCount,
      loadedBytes: loaded,
      fileCount,
    };
  }

  /**
   * Initialize totals for all uploads. <br/>
   * Can be called even if upload is in progress, it will add to the current totals. <br/>
   * Can be called externally to set total number of files and bytes before upload. <br/>
   * This is desired if you want to upload a folder (which will be traversed deeply)
   * and the uploadMultipleFilesToS3 method is called for each subFolder.
   * In this case, you should call this method before calling uploadMultipleFilesToS3 for each subFolder.
   * @param totalBytes - number
   * @param fileCount - number
   * @param softReset - if true, only update totals if they are not set
   */
  updateConstantTotals(totalBytes: number, fileCount: number, softReset: boolean = false) {
    if (softReset && this.constantTotals.totalBytes && this.constantTotals.fileCount) {
      return;
    }

    this.constantTotals = {
      totalBytes: (this.constantTotals?.totalBytes ?? 0) + totalBytes,
      fileCount: (this.constantTotals?.fileCount ?? 0) + fileCount,
    };
  }
}
