import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app-state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationsService } from '../../services/notifications.service';
import { itemTrackingActions } from './item-tracking.actions';
import { catchError, delay, exhaustMap, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { ProgressItemsService } from '../../services/progress-items.service';
import { forkJoin, Observable, of } from 'rxjs';
import { activitiesFeatureSelector, itemTrackingSelectors } from './item-tracking.selectors';
import { IActivityProgressItem, IItemTrackingSidebar } from './item-tracking.reducer';

@Injectable()
export class ItemTrackingEffects {
  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private notif: NotificationsService,
    private progressItemService: ProgressItemsService,
  ) {}

  loadTrackingItems$ = createEffect(() => {
    return this.actions.pipe(
      ofType(itemTrackingActions.loadAllProgressItems),
      tap(() => {
        this.store.dispatch(itemTrackingActions.setIsLoading({ isLoading: true }));
      }),
      withLatestFrom(this.store.select(activitiesFeatureSelector)),
      exhaustMap(([_, state]) => {
        return this.progressItemService.loadProgressItems(state.project_id, state.loadFilter).pipe(
          map((items) => {
            return itemTrackingActions.setAllProgressItems({ progressItems: items });
          }),
          catchError(() => of(itemTrackingActions.cancel())),
        );
      }),
    );
  });

  selectTrackingItemToEdit$ = createEffect(() => {
    return this.actions.pipe(
      ofType(itemTrackingActions.editTrackingItemSidebar),
      withLatestFrom(
        this.store.select(activitiesFeatureSelector),
        this.store.select(itemTrackingSelectors.allActivityProgressItems),
      ),
      exhaustMap(([action, state, cachedProgressItems]) => {
        return this.progressItemService.loadProgressItems(state.project_id, state.loadFilter).pipe(
          map((items): { action: any; items: IActivityProgressItem[] } => ({
            action,
            items,
          })),
          catchError(() => of({ action, items: cachedProgressItems })), // Ensure fallback returns cached items from store
        );
      }),
      tap(({ action, items }) => {
        this.store.dispatch(itemTrackingActions.setAllProgressItems({ progressItems: items }));
      }),
      map(({ action, items }) => {
        return items.find((item) => item.id === action.id); // find returns undefined if not found
      }),
      delay(10),
      map((trackingItem) => {
        const sidebarItem: IItemTrackingSidebar = {
          id: trackingItem.id,
          title: trackingItem.title,
          start_date: trackingItem.start_date,
          end_date: trackingItem.end_date,
          checklist: trackingItem.checklist,
        };
        return itemTrackingActions.selectTrackingItemToEdit({ item: sidebarItem });
      }),
    );
  });

  loadChecklist$ = createEffect(() => {
    return this.actions.pipe(
      ofType(itemTrackingActions.loadAllChecklist),
      exhaustMap((action) => {
        return this.progressItemService.loadAllChecklist(action.withTrash).pipe(
          map((data) => {
            return itemTrackingActions.setChecklist({ checklist: data });
          }),
          catchError(() => of(itemTrackingActions.cancel())),
        );
      }),
    );
  });

  deleteCheckLists$ = createEffect(() => {
    return this.actions.pipe(
      ofType(itemTrackingActions.deleteChecklistFromBackend),
      withLatestFrom(this.store.select(itemTrackingSelectors.deletedChecklists)),
      tap((action) => {
        this.store.dispatch(itemTrackingActions.setIsLoading({ isLoading: true }));
      }),
      exhaustMap(([_, deletedChecklistIds]) => {
        const deleteRequests = deletedChecklistIds.map((id) =>
          this.progressItemService.deleteChecklist(id).pipe(catchError((err) => of(err))),
        );
        return forkJoin(deleteRequests);
      }),
      map((_) => {
        return itemTrackingActions.clearDeletedChecklist();
      }),
      catchError(() => of(itemTrackingActions.clearDeletedChecklist())),
    );
  });

  deleteProgressItem$ = createEffect(() => {
    return this.actions.pipe(
      ofType(itemTrackingActions.deleteProgressItem),
      tap(() => {
        this.store.dispatch(itemTrackingActions.setIsLoading({ isLoading: true }));
      }),
      exhaustMap((action) => {
        return this.progressItemService.deleteProgressItem(action.id).pipe(
          map((data) => {
            this.notif.showSuccess(data?.message ?? 'Progress item deleted successfully!');
            return itemTrackingActions.loadAllProgressItems();
          }),
          catchError(() => of(itemTrackingActions.cancel())),
        );
      }),
    );
  });

  saveTrackingItem$ = createEffect(() => {
    return this.actions.pipe(
      ofType(itemTrackingActions.saveTrackingItem),
      withLatestFrom(this.store.select(activitiesFeatureSelector)),
      exhaustMap(([action, state]) => {
        if (action.trackingItem.id) {
          return this.updateTrackingItem(action.trackingItem, state.project_id);
        }
        return this.createTrackingItem(action.trackingItem, state.project_id);
      }),
      map((_) => {
        this.store.dispatch(itemTrackingActions.deleteChecklistFromBackend());
        return itemTrackingActions.loadAllProgressItems();
      }),
    );
  });

  updateTrackingItemFromList$ = createEffect(() => {
    return this.actions.pipe(
      ofType(itemTrackingActions.saveProgressItemFromList),
      tap((action) => {
        this.store.dispatch(itemTrackingActions.setIsLoading({ isLoading: action.reloadData }));
      }),
      withLatestFrom(this.store.select(activitiesFeatureSelector)),
      mergeMap(([action, state]) => {
        return this.progressItemService
          .updateProgressItem(action.trackingItem, state.project_id)
          .pipe(
            map((_) => {
              if (action.reloadData) {
                return itemTrackingActions.loadAllProgressItems();
              }
              this.notif.showSuccess('Progress item updated successfully!');
              return itemTrackingActions.cancel();
            }),
            catchError((err) => {
              this.store.dispatch(itemTrackingActions.loadAllProgressItems());
              return this.handleError(err);
            }),
          );
      }),
      delay(200),
      tap(() => {
        this.store.dispatch(itemTrackingActions.setIsLoading({ isLoading: false }));
      }),
    );
  });

  updateTrackingItem(trackingItem: IItemTrackingSidebar, projectId: number) {
    return this.progressItemService.updateProgressItem(trackingItem, projectId).pipe(
      map((data) => {
        console.log('saveTrackingItem', data);
        this.store.dispatch(itemTrackingActions.successfullySavedTrackingItem());
        return of(data);
      }),
      catchError((err) => this.handleError(err)),
    );
  }

  createTrackingItem(trackingItem: IItemTrackingSidebar, projectId: number) {
    return this.progressItemService.createProgressItem(trackingItem, projectId).pipe(
      map((data) => {
        console.log('saveTrackingItem', data);
        this.store.dispatch(itemTrackingActions.successfullySavedTrackingItem());
        // will reload other stuff later
        return of(data);
      }),
      catchError((err) => this.handleError(err)),
    );
  }

  // Define your function to handle errors
  handleError(err: any): Observable<any> {
    console.log(err);
    // todo: discuss or refactor
    // we could log error in the component too, separate update, save and error states
    // handle in component stuff like this.notif.showError(err?.error?.message ?? 'Failed to save.');
    // also add a loading on save button to prevent double click
    this.notif.showError(err?.error?.message ?? 'Failed to save.');
    return of(itemTrackingActions.cancel());
  }
}
