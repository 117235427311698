export const enum PROJECT_TYPE {
  NONE,
  BUDGET,
  BIDDING,
  MISCELL,
}

export enum BID_STATUS {
  NONE,
  PENDING,
  ACCEPTED,
  DECLINED,
}

export enum PROJECT_TYPE_CONTRACTOR {
  NONE,
  INVITED,
  BIDDING,
}

export const PROJECT_ATTRIBUTES = [
  { id: 'capex', name: 'CapEX' },
  { id: 'opex', name: 'OpEX' },
  { id: 'priority', name: 'Priority' },
  { id: 'approved', name: 'Approved' },
];

export const PROJECT_ATTRIBUTES_OBJ = {
  capex: 'capex',
  opex: 'opex',
  priority: 'priority',
  approved: 'approved',
};

export const PROJECTS_ALL_PROPERTIES_SELECTED = 0;

export const projectsTableHeaders = [
  { name: 'Project', tooltip: null, key: 'title' },
  { name: 'Company Name', tooltip: null, key: 'company_name' },
  {
    name: 'Start Date',
    tooltip: 'The date that the project expect to start.',
    key: 'start_date',
  },
  // todo: extend these:>
  { name: 'Approval', tooltip: null, key: 'approved' },
  { name: 'Proposal', tooltip: 'All submitted proposals', key: 'proposal' },
  { name: 'Contract', tooltip: 'All approved contracts', key: 'contract_sum' },
  { name: 'Invoiced', tooltip: 'All paid invoices', key: 'invoice_sum' },
  {
    name: 'Current Budget',
    tooltip: 'The most up-to-date project budget.',
    key: 'current_budget',
  },
  {
    name: 'Committed',
    tooltip: 'Total Committed = Contracts + Change Orders',
    key: 'total_committed',
  },
  { name: 'Invoiced', tooltip: 'All paid invoices', key: 'actuals' },
  {
    name: 'Fcst to Complete',
    tooltip: 'Fcst to Complete = Current Budget - Committed.',
    key: 'forecasts_to_complete',
  },
  // {
  //   name: 'Actuals',
  //   tooltip:
  //     'A total of all paid invoices and direct costs. Invoices and direct costs marked "not paid" are not included.',
  //   key: 'actuals',
  // },
  {
    name: '%Complete',
    tooltip: '%Complete = (Actuals / Committed) * 100',
    tooltipContractor: '%Complete = Paid Invoices / Total Committed',
    key: 'complete',
  },
  { name: 'Project Attributes', tooltip: null, key: 'attributes' },
  { name: 'Project Status', tooltip: null, key: 'statusId' },
  { name: 'Project Status', tooltip: null, key: 'status' },
];
