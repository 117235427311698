import { COMMITMENTS_TYPE } from '../../framework/constants/interaction-bar.constants';
import { IBudgetTagItem } from '../settings/settings.interface';

export enum COMMITMENT_VIEWS {
  SUMMARY,
  CONTRACT,
  DIRECT_COST,
}

// the string values must match the object keys
export enum SORT_OPTIONS {
  company_name = 'company_name',
  contract_title = 'title',
  contract_value = 'contract_value',
  change_orders = 'change_orders',
  total_commit = 'total_committed',
  total_invoiced = 'total_invoiced',
  paid_invoices = 'total_paid',
  completePercentage = 'complete',
  approvalStatus = 'approval_status',
}

export interface FilteredContract {
  id: number;
  company_name: string;
  is_company_temporary?: boolean;
  contract_title: string;
  approval_status?: COMMITMENT_APPROVAL_STATUS;
  contract_value: number;
  change_orders: number;
  total_commit: number;
  total_invoiced: number;
  paid_invoices: number;
  completePercentage: number;
  completePercentageBarWidth: number;
}

export interface CommitmentLineItem {
  id: number;
  name: string;
  forecast_start_date: string;
  forecast_duration: string;
  budget_tag_id: number;
  parent_id?: number;
  current_budget?: number;
  committed?: number;
}

export interface FilteredDirectCost {
  id: number;
  added_at: string;
  approval_status: null;
  contract_id: number;
  cost_total?: number;
  description: null;
  user_drive_file_id?: number;
  invoice_number: number | string;
  commitment_items: CommitmentItems[];
  paid_date: string;
  paid_status: number;
  project_id: number;
  service_provider_user_id: number;
  title: string;
  type: string;
  user_id: number;
}

export interface DirectCostTotals {
  total_commit: number;
  paid_invoices: number;
  complete_percent: number;
}

export interface FilteredCommitmentData {
  contractors: CommittedContractor[];
  direct_costs: FilteredDirectCost[];
}

export interface Cost {
  id: number;
  name: string;
  value: number;
  parent_id?: number;
  paid_sum?: number;
  percentageCompleted?: number;
  percentageCompletedBar?: number;
  total_change_order_costs?: number;
  total_committed_approved?: number;
  has_attachment?: boolean;
  is_change_order_cost?: boolean; // just used to see the change order costs that are connected to the contract
}

export interface CostBackend {
  id: number;
  name: string;
  value: number;
  contract_value: number;
  change_order_value: number;
  total_paid: number;
  complete: number;
  total_committed: number;
  total_invoiced: number;

  // contract_value: number;
  // change_order_value: number;
  // total_paid: number;
  // total_invoiced: number;
  // complete: number;
}

export interface CostBackendInvoiceExtended extends CostBackendExtended {
  budget_tag: IBudgetTagItem;
  item: { id: number; name: string; contractApproved?: boolean };
}

export interface CostBackendExtended {
  id: number;
  parent_id?: number;
  name: string;
  value: number;
  contract_value: number;
  change_order_value: number;
  total_paid: number;
  total_invoiced: number;
  complete: number;
  total_committed: number;
  is_disabled: boolean;
}

// todo: delete and not use it, used in too many things in previous implementation :)) way more easy than refactoring it
export interface CommitmentItems {
  id?: number | string;
  costs: Cost[];
  item: CommitmentLineItem;
  item_id?: number;
  deleted?: boolean;
  has_cost_attachment?: boolean;
  budget_tag?: { id: number; name: string };
  is_change_order_line_item?: boolean; // just used to see the change order line items that are connected to the contract
  contract_value: number;
  change_order_value: number;
  total_committed: number;
  total_invoiced: number;
  total_paid: number;
  complete: number;
  duration?: number;
}

export interface CommitmentItemBackend {
  id?: number | string;
  item_id?: number;
  item: CommitmentLineItem;
  budget_tag?: { id: number; name: string };
  start_date: string;
  costs: CostBackend[];
  contract_value: number;
  change_orders: number;
  committed: number;
  invoices_paid: number;
  complete: number;
}

export interface CommitmentItemBackendExtended {
  id?: number | string;
  item_id?: number;
  item: CommitmentLineItem;
  budget_tag?: { id: number; name: string };
  start_date: string;
  costs: CostBackendExtended[];
  contract_value: number;
  change_orders: number;
  committed: number;
  invoices_paid: number;
  complete: number;
}

export interface Commitment {
  id: number;
  type: string;
  change_order_type?: string;
  user_id: number;
  service_provider_user_id: number;
  temporary_service_provider_id?: any;
  project_id: number;
  user_drive_file_id?: number;
  item_id: number;
  file: { id: number; name: string; type: string };
  contract_id?: number;
  title: string;
  description: string;
  added_at: string;
  paid_date?: any;
  paid_status?: boolean;
  approval_status: COMMITMENT_APPROVAL_STATUS;
  invoice_number: string;
  commitment_items: CommitmentItems[];
}

export interface ContractSummary {
  id: number;
  title: string;
  service_provider?: {
    id: number;
    company_name: string;
  };
  temporary_service_provider?: {
    id: number;
    company_name: string;
  };
  description: string;
  added_at: string; // currently missing
  // paid_date?: any;
  // paid_status?: number;
  approval_status: COMMITMENT_APPROVAL_STATUS;
  commitment_items: CommitmentItemBackend[];
  summary: {
    contract_value: number;
    change_order_value: number;
    total_committed: number;
    total_paid: number;
    total_invoiced: number;
    complete: number;
  };
}

export interface ContractChangeOrder extends Commitment {
  total_costs?: number;
  total_paid_costs?: number;
  percentageCompleted?: number;
  percentageCompletedBar?: number;
}

export interface ContractInvoice extends Commitment {
  total_costs?: number;
}

export interface Contract extends Commitment {
  total_contract_costs: number;
  total_contract_costs_approved: number;
  total_contract_paid_sum: number;
  total_change_order_costs: number;
  total_change_order_paid_sum: number;
  total_invoices_costs_paid: number;
  percentageCompleted: number;
  percentageCompletedBar: number;
  change_orders: ContractChangeOrder[];
  invoices: ContractInvoice[];
  total_invoices_costs: number;
  total_committed_approved: number;
  percentageSummaryCompleted: number;
  percentageSummaryCompletedBar: number;
  percentageCompletedBarChangeOrder: number;
  percentageCompletedChangeOrder: number;
  has_invoice: boolean;
  has_change_order: boolean;
  contractor?: CommittedContractor;
}

export interface ChangeOrder extends Commitment {
  has_invoice: boolean;
}

export interface CommitmentWithSummary extends Commitment {
  summary: {
    contract_value: number;
    change_order_value: number;
    total_committed: number;
    total_paid: number;
    total_invoiced: number;
  };
}

export interface ChangeOrderWithSummary extends Commitment {
  summary: {
    contract_value: number;
    change_order_value: number;
    total_committed: number;
    total_paid: number;
    total_invoiced: number;
  };
  totalDuration: number;
  change_order_type: string;
}

export interface ContractSummaryResponse {
  contract: ContractSummary;
  change_orders: ChangeOrderWithSummary[];
  invoices: CommitmentWithSummary[];
}

export interface CommittedContractor {
  id: number;
  percentageCompleted?: number;
  percentageCompletedBar?: number;
  total_paid_sum: number;
  contracts_paid_sum: number;
  commitments: {
    contracts: Contract[];
    change_orders: ChangeOrder[];
    invoices: Commitment[];
  };
  complete?: number;
  company_name: string;
  contracts_sum: number;
  contracts_approved_sum: number;
  change_orders_sum: number;
  change_orders_approved_sum: number;
  change_orders_approved_paid_sum?: number;
  invoices_sum: number;
  invoices_paid_sum: number;
  total_committed: number;
  direct_costs_sum: number;
  direct_costs: any[];
  is_temporary: boolean;
}

export interface ISidebarContract {
  id?: number;
  has_commitments?: boolean;
  project_id: number;
  type: COMMITMENTS_TYPE;
  added_at: string;
  title: string;
  description: string;
  approval_status: string;
  files: File[];
  removeFileIds: number[];
  commitment_items: ISidebarCommitmentItem[];
  deleted_commitment_items: ISidebarCommitmentItem[];
  service_provider?: {
    temporary_service_provider_id?: number;
    service_provider_user_id?: number;
    name: string;
  };
}

export interface ISidebarDirectCost {
  id?: number;
  project_id: number;
  vendor: string;
  has_commitments?: boolean;
  type: COMMITMENTS_TYPE;
  added_at: string;
  title: string;
  description: string;
  paid_status: string;
  paid_date: string;
  files: File[];
  removeFileIds: number[];
  commitment_items: Partial<ISidebarCommitmentItem>[];
  deleted_commitment_items: Partial<ISidebarCommitmentItem>[];
  service_provider?: {
    temporary_service_provider_id?: number;
    service_provider_user_id?: number;
    name: string;
  };
}

export type IChangeOrderType = 'dollars' | 'duration' | 'dollars_duration' | 'scope' | 'default';

export interface ISidebarChangeOrder extends ISidebarContract {
  contract_id: number;
  change_order_type: IChangeOrderType;
}

export interface ISidebarInvoice {
  id?: number;
  project_id: number;
  has_commitments?: boolean;
  contract_id: number;
  type: COMMITMENTS_TYPE;
  added_at: string;
  paid_date: string;
  title?: string;
  invoice_number?: string;
  description: string;
  paid_status: string;
  files: any[];
  removeFileIds: number[];
  costs: ISidebarInvoiceCost[];
  deleted_costs: ISidebarInvoiceCost[];
  budget_tag: IBudgetTagItem;
  service_provider?: {
    temporary_service_provider_id?: number;
    service_provider_user_id?: number;
    name: string;
  };
}

export interface ISidebarCommitmentItemsDTO {
  id?: number;
  item_id: number;
  start_date?: string;
  duration?: number;
  budget_tag_id: number;
  budget_tag_name: string;
  deleted?: boolean;
  costs: Partial<ISidebarCost>[];
}

export interface ISidebarContractPayload {
  id?: number;
  project_id: number;
  type: COMMITMENTS_TYPE;
  added_at: string;
  title: string;
  description: string;
  approval_status: string;
  commitment_items: ISidebarCommitmentItemsDTO[];
  temporary_service_provider_id?: number;
  service_provider_user_id?: number;
  tmp_sp_name?: string;
}

export interface ISidebarInvoicePayload {
  id?: number;
  project_id: number;
  type: COMMITMENTS_TYPE;
  added_at: string;
  paid_date: string;
  contract_id: number;
  invoice_number: string;
  description: string;
  paid_status: string;
  costs: Partial<ISidebarCost>[];
  temporary_service_provider_id?: number;
  service_provider_user_id?: number;
  tmp_sp_name?: string;
}

export interface ISidebarDirectCostPayload {
  id?: number;
  project_id: number;
  vendor: string;
  title: string;
  type: COMMITMENTS_TYPE;
  added_at: string;
  paid_status: string;
  paid_date: string;
  description: string;
  commitment_items: ISidebarCommitmentItemsDTO[];
}

export interface ISidebarChangeOrderPayload extends ISidebarContractPayload {
  contract_id: number;
  change_order_type: IChangeOrderType;
}

export interface ISidebarCommitmentItem {
  id?: number;
  item_id?: number;
  start_date?: string;
  duration?: number;
  budget_tag: IBudgetTagItem;
  has_commitments?: boolean;
  item?: { id: number; name: string; contractApproved?: boolean };
  costs: Partial<ISidebarCost>[];
  deleted_costs: Partial<ISidebarCost>[];
  deleted?: boolean;
}

export interface ISidebarCost {
  id?: number;
  name: string;
  value: number;
  parent_id: number;
  deleted?: boolean;
  has_commitments?: boolean;
  is_dropdown?: boolean;
  is_disabled?: boolean;
}

export interface ISidebarInvoiceCost extends ISidebarCost {
  budget_tag: IBudgetTagItem;
  item: { id: number; name: string };
}

export enum COMMITMENT_APPROVAL_STATUS {
  APPROVED = 'approved',
  PENDING = 'pending',
  REVIEW = 'review',
  DRAFT = 'draft',
}

export type ISidebarCommitment =
  | ISidebarContract
  | ISidebarChangeOrder
  | ISidebarInvoice
  | ISidebarDirectCost;

export interface CommitmentContractor {
  id: number;
  company_name: string;
  contracts_sum: number;
  change_orders_sum: number;
  invoices_sum: number;
  invoices_paid_sum: number;
  commitments: Commitment[];
}

export interface AllCommitmentsSummaryContracts {
  id: number;
  company_name: string;
  temporary_service_provider_id: number;
  service_provider_user_id: number;
  is_tmp_service_provider: boolean;
  approval_status: COMMITMENT_APPROVAL_STATUS;
  title: string;
  contract_value: number;
  change_order_value: number;
  total_committed: number;
  total_invoiced: number;
  total_paid: number;
  complete: number;
}

export interface AllCommitmentsSummaryDirectCosts {
  total_committed: number;
  total_paid: number;
  total_invoiced: number;
  complete: number;
}

export interface AllCommitmentsSummaryTotals {
  contract_value: number;
  change_order_value: number;
  total_committed: number;
  total_invoiced: number;
  total_paid: number;
  complete: number;
}

export interface AllCommitmentsResponse {
  contractors: CommitmentContractor[];
  temporary_contractors: CommitmentContractor[];
  direct_costs: Commitment[];
}

export interface AllCommitmentsSummaryResponse {
  contracts: AllCommitmentsSummaryContracts[];
  direct_costs: AllCommitmentsSummaryDirectCosts;
  total: AllCommitmentsSummaryTotals;
}

export interface DirectCost {
  id: number;
  title: string;
  vendor: string;
  paid_status: boolean;
  paid_date: string;
  total_invoiced: number;
  total_paid: number;
  complete: number;
  commitment_items: CommitmentItems[];
}

export interface ICommitmentBudgetLineItem {
  id: number;
  name: string;
  current_budget: number;
  committed: number;
  forecast_start_date: string;
  forecast_duration: number;
  contractApproved?: boolean;
}

export interface DirectCostsSummaryResponse {
  direct_costs: DirectCost[];
}

export interface CommitmentsState {
  allCommitments: AllCommitmentsResponse | null;
  allCommitmentsSummary: AllCommitmentsSummaryResponse | null;
  directCostsSummary: DirectCostsSummaryResponse | null;
  selectedCommitment: Commitment;
  selectedContract: ContractSummaryResponse;
  sideBarContract: ISidebarContract;
  sideBarChangeOrder: ISidebarChangeOrder;
  sideBarDirectCost: ISidebarDirectCost;
  sidebarPage: number;
  budgetLineItems: ICommitmentBudgetLineItem[];
  sidebarSelectedContractSummary: ContractSummaryResponse;
  sideBarInvoice: ISidebarInvoice;
  isSidebarLoading: boolean;
  isSelectedCommitmentViewSummaryLoading: boolean;
  sideBarChanged: boolean;
  projectData: {
    id: number;
    start_date: string;
  };
}

export enum COMMITMENT_SIDEBAR_PAGE {
  FIRST_PAGE = 1,
  LAST_PAGE = 2,
}
