<div class="interaction-bar-wrapper notifications">
  <div class="interaction-bar-title default-cursor">
    Notifications
    <span class="counter">({{ unseenCount }})</span>
  </div>

  <div class="header">
    <div class="header-navigation">
      <div (click)="prevTab()" class="btn-back">
        <span class="icon-arrow-right"></span>
      </div>
      <div class="title default-cursor">
        <span *ngIf="selectedCategory == NOTIFICATION_CATEGORIES.ALL">All Notifications</span>
        <span *ngIf="selectedCategory == NOTIFICATION_CATEGORIES.MESSAGES">Messages</span>
        <span *ngIf="selectedCategory == NOTIFICATION_CATEGORIES.SCHEDULE">Schedule</span>
        <span *ngIf="selectedCategory == NOTIFICATION_CATEGORIES.PROPOSALS">Proposals</span>
        <span *ngIf="selectedCategory == NOTIFICATION_CATEGORIES.INVOICES">Invoices</span>
        <span *ngIf="selectedCategory == NOTIFICATION_CATEGORIES.INVITES">Invites</span>
        <span *ngIf="selectedCategory == NOTIFICATION_CATEGORIES.TEAM">Teams</span>
      </div>
      <div (click)="nextTab()" class="btn-next">
        <span class="icon-arrow-right"></span>
      </div>
    </div>

    <div class="bubble-selection">
      <div
        *ngFor="let category of notificationCategories"
        (click)="setSelectedCategory(category)"
        class="notification-bubble"
      >
        <span
          [ngClass]="{
            checked: selectedCategory === category,
            unchecked: selectedCategory !== category,
            'bubble-pink': category === NOTIFICATION_CATEGORIES.SCHEDULE,
            'bubble-yellow': category === NOTIFICATION_CATEGORIES.MESSAGES,
            'bubble-green': category === NOTIFICATION_CATEGORIES.PROPOSALS,
            'bubble-blue': category === NOTIFICATION_CATEGORIES.INVOICES,
            'bubble-dark': category === NOTIFICATION_CATEGORIES.ALL || category === NOTIFICATION_CATEGORIES.INVITES,
            'bubble-team': category === NOTIFICATION_CATEGORIES.TEAM
          }"
          class="icon-checkbox-round-u"
        ></span>
      </div>
    </div>
    <mat-progress-bar
      [ngClass]="{ 'opacity-0': !isLoading }"
      class="custom-loading-progress-bar"
      mode="indeterminate"
    ></mat-progress-bar>
  </div>

  <div class="interaction-bar-content">
    <ng-scrollbar class="std-scrollbar" appearance="native" visibility="visible" orientation="vertical">
      <div class="mb-4 mr-3">
        <div *ngIf="notificationCount > 0 && !isLoading && unseenCount !== 0">
          <div (click)="markAllAsRead()" class="mark-all btn-small">Mark all as read</div>
        </div>
        <!--  present unseen notifications first      -->
        <ng-container *ngFor="let notificationMaps of notificationGroups">
          <div *ngFor="let notificationMap of notificationMaps | keyvalue: mapCompareFn; let index = index">
            <div *ngIf="notificationMap?.value?.length > 0" class="date-separator">
              <ng-container *ngIf="notificationMap.key === today"> TODAY </ng-container>
              <ng-container *ngIf="notificationMap.key !== today">
                <ng-container *ngIf="isThisYear(notificationMap.key); else lastYearFormat">
                  {{ notificationMap.key | datepipe: DMMM }}
                </ng-container>
                <ng-template #lastYearFormat>
                  {{ notificationMap.key | datepipe: UTC_TO_LOCAL }}
                </ng-template>
              </ng-container>
            </div>
            <div *ngFor="let notification of notificationMap.value">
              <div (click)="openNotification(notification)" class="notification-item">
                <div class="text-wrapper">
                  <div class="title">
                    {{ notification.title }}
                  </div>
                  <div class="additional-info" [innerHTML]="notification.message"></div>
                  <div class="subtitle-info">
                    {{ notification.subtitle }}
                  </div>
                </div>
                <div *ngIf="!notification.is_seen" class="notification-bubble">
                  <span
                    [ngClass]="{
                      'bubble-pink': notification?.notification_type?.includes(NOTIFICATION_CATEGORIES.SCHEDULE),
                      'bubble-yellow': notification?.notification_type?.includes(NOTIFICATION_CATEGORIES.MESSAGES),
                      'bubble-green': notification?.notification_type?.includes(NOTIFICATION_CATEGORIES.PROPOSALS),
                      'bubble-blue': notification?.notification_type?.includes(NOTIFICATION_CATEGORIES.INVOICES),
                      'bubble-dark': notification?.notification_type?.includes(NOTIFICATION_CATEGORIES.INVITES),
                      'bubble-team': notification?.notification_type?.includes(NOTIFICATION_CATEGORIES.TEAM)
                    }"
                    class="icon-checkbox-round-u"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-scrollbar>

    <div *ngIf="notificationCount === 0 && !isLoading" class="default-cursor notification-item">
      <div class="text-wrapper default-cursor no-notifications-wrapper">
        <div class="additional-info no-data default-cursor">No Notifications</div>
      </div>
    </div>
  </div>
</div>
