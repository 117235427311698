<div class="gant-content">
  <ng-scrollbar orientation="auto" class="std-scrollbar rows-wrapper min-h-0" appearance="compact" #ngScrollbar>
    <div class="scrollable-content">
      <div #tasks class="tasks">
        <div class="table-header">
          <div class="column">Line Item</div>
          <div class="column">Start Date</div>
          <div class="column">Duration</div>
          <div class="column">Remove Task</div>
          <div class="column">Add Task</div>
        </div>

        <div #table class="table">
          <div #row *ngFor="let item of data?.tasks; let index = index">
            <div class="row">
              <div class="column item-name">
                <input
                  #taskName
                  (change)="nameFocusHandler('change', taskName, item, false)"
                  (focusin)="nameFocusHandler('focusin', taskName, item, false)"
                  (focusout)="nameFocusHandler('focusout', taskName, item, false)"
                  value="{{ index + 1 }}.0 {{ item.name }}"
                />
              </div>
              <div class="column time">
                <app-input-calendar
                  [showLabel]="false"
                  class="prime-calendar-gantt"
                  [date]="item.start_date"
                  (dateChanged)="registerStartDateChange(item, $event)"
                  [name]="'start_date' + index"
                  [minStartDate]="data.project_start_date"
                  topPosition="top-0"
                >
                </app-input-calendar>
              </div>
              <div class="column time">
                <mat-form-field subscriptSizing="dynamic" class="input borderless">
                  <mat-select
                    (selectionChange)="durationChange(item, $event.value)"
                    [value]="getDurationDropDown(item)"
                  >
                    <mat-option [value]="duration.value" *ngFor="let duration of availableDurations">
                      {{ duration.view }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div (click)="removeTaskPopup(item)" class="column button pointer-cursor">Remove</div>
              <div (click)="addSubtaskTask(item, row)" class="column button pointer-cursor user-select-none">
                Subtask
              </div>
            </div>
            <div *ngFor="let task of item?.tasks; let taskIndex = index">
              <div *ngIf="task" class="row">
                <div class="column item-name inner">
                  <input
                    #subTaskName
                    (change)="nameFocusHandler('change', subTaskName, task, true)"
                    (focusin)="nameFocusHandler('focusin', subTaskName, task, true)"
                    (focusout)="nameFocusHandler('focusout', subTaskName, task, true)"
                    value="{{ index + 1 }}.{{ taskIndex + 1 }} {{ task.name }}"
                  />
                </div>
                <div class="column time">
                  <app-input-calendar
                    class="prime-calendar-gantt"
                    [showLabel]="false"
                    [date]="task.start_date"
                    (dateChanged)="registerStartDateChange(task, $event)"
                    [name]="'start_date' + index + '_' + taskIndex"
                    [minStartDate]="data.project_start_date"
                    topPosition="top-0"
                  ></app-input-calendar>
                </div>
                <div class="column time">
                  <mat-form-field subscriptSizing="dynamic" class="input borderless">
                    <mat-select
                      (selectionChange)="durationChange(task, $event.value)"
                      [value]="getDurationDropDown(task)"
                    >
                      <mat-option [value]="duration.value" *ngFor="let duration of availableDurations">
                        {{ duration.view }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div (click)="removeTaskPopup(item, task)" class="column button pointer-cursor">Remove</div>
                <div class="column button"></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column button"></div>
            <div (click)="addMainTask(table.children)" class="column button pointer-cursor user-select-none">Add</div>
          </div>
          <div class="row final">
            <div class="column final"></div>
            <div class="column final"></div>
            <div class="column final"></div>
            <div class="column final"></div>
            <div class="column final"></div>
          </div>
        </div>
      </div>
      <div class="gant-chart">
        <div class="table-header" #tableHeader>
          <div class="double-row">
            <div [ngStyle]="{ width: totalDays * gridWidth + 'px)' }" class="row-years">
              <div *ngFor="let year of years" [ngStyle]="{ width: year.width - 1 + 'px' }" class="row-item">
                {{ year.name }}
              </div>
            </div>
            <div [ngStyle]="{ width: totalDays * gridWidth + 'px)' }" class="row-months">
              <div
                *ngFor="let month of months"
                [ngStyle]="{ width: month.width * gridWidth - 1 + 'px' }"
                class="row-item"
              >
                {{ month.month }}
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!availableTask()" class="table">
          <div class="row">
            <div [ngStyle]="{ width: totalDays * gridWidth + 'px)' }" class="task-column"></div>
          </div>
        </div>

        <div class="table" *ngFor="let item of data?.tasks; let index = index">
          <div class="row default-cursor">
            <div [ngStyle]="{ width: totalDays * gridWidth + 'px)' }" class="task-column default-cursor">
              <div
                #resizeBox
                cdkDragLockAxis="x"
                cdkDrag
                (cdkDragMoved)="drop($event, item)"
                (cdkDragEnded)="resetTransform($event, item, resizeBox)"
                [ngStyle]="{
                  'margin-left': getTaskMonthStartColumn(item.start_date) * gridWidth - gridWidth + 'px',
                  width: getTaskMonthEndColumn(item) * gridWidth + 'px'
                }"
                class="task drag-cursor"
              >
                <div class="inside-task-items">
                  <span
                    #dragHandleLeft
                    cdkDragLockAxis="x"
                    class="dragHandle left"
                    cdkDrag
                    (cdkDragMoved)="dragMove(resizeBox, dragHandleLeft, $event, 'left', item)"
                    (cdkDragEnded)="resetTransform($event, item, resizeBox)"
                  >
                  </span>
                  <div class="user-select-none overflow-hidden">
                    {{ item.name }}
                    <!--                      {{getTaskMonthEndColumn(item)}} days-->
                  </div>
                  <span
                    #dragHandleRight
                    cdkDragLockAxis="x"
                    class="dragHandle"
                    cdkDrag
                    (cdkDragMoved)="dragMove(resizeBox, dragHandleRight, $event, 'right', item)"
                    (cdkDragEnded)="resetTransform($event, item, resizeBox)"
                  >
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div *ngFor="let task of item?.tasks; let taskIndex = index">
            <div *ngIf="task" class="row default-cursor">
              <div [ngStyle]="{ width: totalDays * gridWidth + 'px)' }" class="task-column default-cursor">
                <div
                  #resizeBox
                  (cdkDragMoved)="drop($event, task)"
                  (cdkDragEnded)="resetTransform($event, item, resizeBox)"
                  cdkDragLockAxis="x"
                  cdkDrag
                  [ngStyle]="{
                    'margin-left': getTaskMonthStartColumn(task.start_date) * gridWidth - gridWidth + 'px',
                    width: getTaskMonthEndColumn(task) * gridWidth + 'px'
                  }"
                  class="task subtask drag-cursor"
                >
                  <div class="inside-task-items">
                    <span
                      #dragHandleLeft
                      cdkDragLockAxis="x"
                      class="dragHandle"
                      cdkDrag
                      (cdkDragMoved)="dragMove(resizeBox, dragHandleLeft, $event, 'left', task)"
                      (cdkDragEnded)="resetTransform($event, task, resizeBox)"
                    >
                    </span>
                    <div class="user-select-none">
                      {{ task.name }}
                      <!--                        {{getTaskMonthEndColumn(task)}} days-->
                    </div>
                    <span
                      #dragHandleRight
                      cdkDragLockAxis="x"
                      class="dragHandle"
                      cdkDrag
                      (cdkDragMoved)="dragMove(resizeBox, dragHandleRight, $event, 'right', task)"
                      (cdkDragEnded)="resetTransform($event, task, resizeBox)"
                    >
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-scrollbar>
</div>
<div class="buttons">
  <button class="first-button" mat-button (click)="close()">
    CLOSE
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
  <button disabled class="second-button" mat-button>SAVE AS PDF</button>
  <button (click)="save()" class="third-button" mat-button>SAVE</button>
</div>
