import { Component, ElementRef, OnInit } from '@angular/core';
import { ProjectStateService } from '../../services/project-state.service';
import { CurrentUserService } from '../../services/current-user.service';
import { Intercom } from '@supy-io/ngx-intercom';
import { AppComponent } from '../../app.component';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { MenuRouteService } from '../../services/menu-route.service';
import { SpendViewProjectStateService } from '../../services/spend-view-project-state.service';
import { IntercomService } from '../../services/intercom.service';
import { PricingBannerComponent } from '../../framework/pricing/pricing-banner/pricing-banner.component';
import { InteractionBarComponent } from '../../framework/interaction-bar/interaction-bar.component';
import { MenuBarComponent } from '../../framework/menu-bar/menu-bar.component';
import { Store } from '@ngrx/store';
import { projectActions } from '../../store/projects/projects.actions';

@Component({
  selector: 'app-webapp',
  templateUrl: './webapp.component.html',
  styleUrls: ['./webapp.component.scss'],
  providers: [ProjectStateService, SpendViewProjectStateService],
  standalone: true,
  imports: [MenuBarComponent, InteractionBarComponent, PricingBannerComponent, RouterOutlet],
})
export class WebappComponent implements OnInit {
  constructor(
    private user: CurrentUserService,
    public el: ElementRef,
    private router: Router,
    private menuState: MenuRouteService,
    public intercom: Intercom,
    private app: AppComponent,
    private intercomService: IntercomService,
    private store: Store,
  ) {
    this.router.events.subscribe((ev) => {
      const navEnd = ev as NavigationEnd;
      if (navEnd.url) {
        const page = navEnd.url.split('/')[2];
        this.menuState.setActive(page);
      }
    });

    // this.isIntercomBooted().then(data => {
    //   this.intercom.update({
    //     hide_default_launcher: true
    //   });
    // });
  }

  public isIntercomBooted(): Promise<any> {
    return new Promise<any>((res) => {
      if (this.intercom.getVisitorId()) {
        res(true);
      } else {
        setTimeout(() => {
          this.isIntercomBooted().then((data) => {
            res(data);
          });
        }, 500);
      }
    });
  }

  ngOnInit() {
    this.intercomService.bootIntercom();
    this.store.dispatch(projectActions.loadBackendStatuses());
  }
}
