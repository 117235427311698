import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AppState } from '../../../../store/app-state';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { commitmentsActions } from '../../../../store/commitments/commitments.actions';
import { filter, take, takeUntil } from 'rxjs/operators';
import { commitmentsSelectors } from '../../../../store/commitments/commitments.selectors';
import { NgForm } from '@angular/forms';
import { SpendEntryComponent } from '../spend-entry/spend-entry.component';
import { NotificationsService } from '../../../../services/notifications.service';
import { StorageService } from '../../../../services/storage.service';
import { CurrentUserService } from '../../../../services/current-user.service';
import { InteractionBarStateService } from '../../../../services/interaction-bar-state.service';
import {
  COMMITMENTS_INTERACTION_BAR_TYPE,
  INTERACTION_BAR_STATES,
} from '../../../constants/interaction-bar.constants';
import { IBudgetTagTemplate } from '../../../../store/settings/settings.interface';
import { getBudgetTagTemplates } from '../../../../store/settings/settings.selectors';
import {
  AllCommitmentsSummaryContracts,
  COMMITMENT_SIDEBAR_PAGE,
  ISidebarChangeOrder,
  ISidebarContract,
  ISidebarDirectCost,
  ISidebarInvoice,
} from '../../../../store/commitments/commitments.types';
import { dropdownOverlayPositions } from '../../../overlays/option-list.constants';
import {
  COMMITMENT_STATUS,
  getErrorMessage,
} from '../../commitments-interaction-bar-view/commitments-interaction-bar-view.utilities';
import { InputCalendarComponent } from '../../../inputs/input-calendar/input-calendar.component';
import { MANAGE_PROJECT_TABS } from '../../../constants/view-project.constants';

@Component({
  selector: 'app-commitment-sidebar',
  template: '',
  standalone: true,
})
export class CommitmentSidebarComponent implements OnInit, OnDestroy {
  @ViewChild('firstPageForm') formFirstPage: NgForm;
  @ViewChildren('spendEntries') spendEntryComponents: QueryList<SpendEntryComponent>;

  @ViewChild('added_at_calendar') addedAtCalendar: InputCalendarComponent;
  @ViewChild('paid_calendar') paidDateCalendar: InputCalendarComponent;

  commitmentType: COMMITMENTS_INTERACTION_BAR_TYPE = COMMITMENTS_INTERACTION_BAR_TYPE.ADD_CONTRACT;

  isDestroyed$ = new Subject();
  projectData$ = this.store.select(commitmentsSelectors.getProjectData);
  currentPage$ = this.store.select(commitmentsSelectors.currentSidebarPage);
  selectedContract$ = this.store.select(commitmentsSelectors.getSidebarSelectedContractSummary);
  isSidebarLoading$ = this.store.select(commitmentsSelectors.isSidebarLoading);
  contracts$ = this.store.select(commitmentsSelectors.getAllContractsSummary);
  commitmentsSidebarChanged$ = this.store.select(commitmentsSelectors.getSidebarChanged);
  hasCommitments$ = this.store.select(
    commitmentsSelectors.getHasCommitmentDependency(this.commitmentType),
  );
  budgetTags$: Observable<IBudgetTagTemplate[]> = this.store.select(getBudgetTagTemplates);

  paidStatuses = [
    { value: true, text: 'Paid' },
    { value: false, text: 'Not Paid' },
  ];
  filteredContractsByCompany: AllCommitmentsSummaryContracts[] = [];
  isEdit = false;
  projectData: { id: number; start_date: string } = { id: null, start_date: null };
  isLoading = false;
  budgetTags: IBudgetTagTemplate[] = [];

  model:
    | Partial<ISidebarContract>
    | Partial<ISidebarChangeOrder>
    | Partial<ISidebarInvoice>
    | Partial<ISidebarDirectCost>;

  protected readonly COMMITMENTS_INTERACTION_BAR_TYPE = COMMITMENTS_INTERACTION_BAR_TYPE;
  protected readonly COMMITMENT_SIDEBAR_PAGE = COMMITMENT_SIDEBAR_PAGE;
  protected readonly dropdownOverlayPositions = dropdownOverlayPositions;
  protected readonly getErrorMessage = getErrorMessage;

  constructor(
    protected store: Store<AppState>,
    protected notif: NotificationsService,
    protected storage: StorageService,
    protected user: CurrentUserService,
    protected interactionBar: InteractionBarStateService,
  ) {}

  /**
   * Add data that is being used in all children components, if override is needed, use super.ngOnInit() in child component
   */
  ngOnInit(): void {
    console.warn('init commitment sidebar parent');
    this.projectData$
      .pipe(
        filter((data) => !!(data?.id || data?.start_date)),
        take(1),
      )
      .subscribe((data) => {
        this.projectData = data;
      });

    this.commitmentsSidebarChanged$
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((sidebarChangeState) => {
        console.warn('sidebar changed', sidebarChangeState);
        this.interactionBar.setDisableClose(sidebarChangeState);
      });

    this.budgetTags$.pipe(takeUntil(this.isDestroyed$)).subscribe((tags) => {
      this.budgetTags = tags;
    });
  }
  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
    this.store.dispatch(commitmentsActions.clearSidebar());
  }

  trackByIndex(index: number) {
    return index;
  }

  incrementPage() {
    this.formFirstPage.form.markAllAsTouched();

    if (this.addedAtCalendar) {
      this.addedAtCalendar.validate();

      if (!this.addedAtCalendar?.isValid()) {
        return;
      }
    }

    if (this.paidDateCalendar) {
      this.paidDateCalendar.validate();

      if (!this.paidDateCalendar.isValid()) {
        return;
      }
    }

    if (this.formFirstPage.valid) {
      this.store.dispatch(commitmentsActions.incrementPage());
    }
  }

  decrementPage() {
    this.store.dispatch(commitmentsActions.decrementPage());
  }

  resetEntryDates() {
    console.warn('not implemented');
  }

  addSpendEntry() {
    console.warn('not implemented');
  }

  save() {
    let isValid = true;
    const entries = Array.from(this.spendEntryComponents);

    if (!entries.length) {
      this.notif.showError('Please add at least one spend entry.');
    }

    entries.forEach((entry) => {
      entry.spendEntryForm.form.markAllAsTouched();
      // validate calendar picker
      if (entry.calendarPicker) {
        entry.calendarPicker.matGeneralDate.control.markAllAsTouched();
        isValid = isValid && entry.calendarPicker.matGeneralDate.control.valid;
      }
      // validate form
      isValid = isValid && entry.spendEntryForm.valid;
      if (
        this.commitmentType === COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER &&
        (this.model as ISidebarChangeOrder)?.change_order_type === 'duration'
      ) {
        // duration only changes do not require costs
        return;
      }
      const costs = Array.from(entry.costDescriptions);
      if (!costs.length) {
        this.notif.showError('Please add at least one cost description to each spend entry.');
        isValid = false;
      }
      costs.forEach((cost) => {
        cost.costDescriptionForm.form.markAllAsTouched();
        isValid = isValid && cost.costDescriptionForm.form.valid;
      });
    });
    if (isValid) {
      this.saveOrModify();
    }
  }

  saveOrModify() {
    console.warn('not implemented');
  }

  getApprovalStatuses(): COMMITMENT_STATUS[] {
    console.warn('not implemented');
    return [];
  }

  changeViewToManageProject() {
    this.interactionBar.openInteractionBar(INTERACTION_BAR_STATES.MANAGE_PROJECT, {
      tab: MANAGE_PROJECT_TABS.COMMITMENTS,
    });
  }

  goBack() {
    this.currentPage$.pipe(take(1)).subscribe((page) => {
      page === 1 ? this.changeViewToManageProject() : this.decrementPage();
    });
  }

  filterContractsBySelectedCompany() {
    this.contracts$
      .pipe(
        filter((allContracts) => allContracts?.length > 0),
        take(1),
        takeUntil(this.isDestroyed$),
      )
      .subscribe((allContracts) => {
        this.filteredContractsByCompany = allContracts.filter((contract) => {
          if (contract.approval_status !== 'approved') {
            return false;
          }

          if (contract.temporary_service_provider_id) {
            return (
              this.model.service_provider.temporary_service_provider_id ===
              contract.temporary_service_provider_id
            );
          }
          return (
            this.model.service_provider.service_provider_user_id ===
            contract.service_provider_user_id
          );
        });
      });
  }
}
