import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SpendViewProjectStateService } from '../../../../../services/spend-view-project-state.service';
import { NotificationsService } from '../../../../../services/notifications.service';
import { SpreadsheetComponent } from '../../../../../framework/spreadsheet/spreadsheet.component';
import { SPEND_TYPES } from '../../../../../framework/constants/budget.constants';
import { AppState } from '../../../../../store/app-state';
import { Store } from '@ngrx/store';
import {
  getIsLoading,
  getLineItemsExtended,
  getSelectableYears,
  getSelectedSpendType,
  getSelectedYear,
} from '../../../../../store/spend/spend.selectors';
import {
  ICommittedItemExtended,
  ILineItemExtended,
  SpreadSheetInputData,
} from '../../../../../store/spend/spend.interfaces';
import {
  DISTRIBUTION_TYPES,
  SPEND_DISTRIBUTION_STATE,
} from '../../../../../framework/constants/spend.constants';
import { delay, filter, take, takeUntil, tap } from 'rxjs/operators';
import cloneDeep from 'lodash/cloneDeep';
import { NgScrollbar, ScrollViewport } from 'ngx-scrollbar';
import { getSpreadSheetData } from '../spend/spend.converter';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { AsyncPipe, JsonPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { DropdownComponent } from '../../../../../framework/inputs/dropdown/dropdown.component';
import { SpendCustomInputComponent } from '../../../../../framework/inputs/spend-custom-input/spend-custom-input.component';
import { InputCalendarComponent } from '../../../../../framework/inputs/input-calendar/input-calendar.component';
import { FloatingInputComponent } from '../../../../../framework/inputs/floating-input/floating-input.component';
import { TooltipModule } from 'primeng/tooltip';
import { SimpleButtonComponent } from '../../../../../framework/buttons/simple-medium-button/simple-button.component';
import { CdkDrag, CdkDragHandle, CdkDropList } from '@angular/cdk/drag-drop';
import { CdkScrollable } from '@angular/cdk/overlay';
import { SpendDistributionHeaderComponent } from './spend-distribution-header/spend-distribution-header.component';
import { BudgetLineItemComponent } from './budget-line-item/budget-line-item.component';
import { BudgetLineItemTableComponent } from './budget-line-item-table/budget-line-item-table.component';
import { SpendDistributionService } from '../../../../../services/spend-distribution.service';
import { viewProjectSelectors } from '../../../../../store/view-project/view-project.selectors';
import { GeneralEmptySplashComponent } from '../../../../../framework/activities/empty-activities-splash/empty-splash-content/general-empty-splash.component';

@Component({
  selector: 'app-spend-distribution',
  templateUrl: './spend-distribution.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./spend-distribution.component.scss'],
  providers: [NgForm],
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    SpreadsheetComponent,
    DropdownComponent,
    NgClass,
    NgScrollbar,
    SpendCustomInputComponent,
    InputCalendarComponent,
    NgForOf,
    FloatingInputComponent,
    TooltipModule,
    JsonPipe,
    SimpleButtonComponent,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
    CdkScrollable,
    ScrollViewport,
    SpendDistributionHeaderComponent,
    BudgetLineItemComponent,
    BudgetLineItemTableComponent,
    GeneralEmptySplashComponent,
  ],
})
export class SpendDistributionComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly notif = inject(NotificationsService);
  private readonly state = inject(SpendViewProjectStateService);
  private readonly store = inject(Store<AppState>);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly spendDistributionService = inject(SpendDistributionService);

  @ViewChild('spreadsheet') spreadsheet: SpreadsheetComponent;

  syncedLineItems: ILineItemExtended[] = [];
  spreadsheetData: SpreadSheetInputData;
  SPEND_TYPES = SPEND_TYPES;
  SPEND_DISTRIBUTION_STATE = SPEND_DISTRIBUTION_STATE;
  selectedSpendType: SPEND_TYPES = SPEND_TYPES.BUDGET;
  selectedYear = 0;

  selectedBudgetType$ = this.store.select(getSelectedSpendType);
  possibleYears$ = this.store.select(getSelectableYears);
  isLoading$ = this.store.select(getIsLoading);
  selectedYear$ = this.store.select(getSelectedYear);
  spendState$ = this.state.currentState;
  isDestroyed$ = new Subject<void>();

  loadingHash: undefined | string;

  protected readonly Number = Number;
  isBudgetMissing$ = this.store.select(viewProjectSelectors.spendAllocationBudgetMissing);

  ngOnInit() {
    this.store
      .select(getLineItemsExtended)
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((value) => !!value),
      )
      .subscribe((lineItems) => {
        this.syncedLineItems = cloneDeep(lineItems);
      });

    this.isLoading$.pipe(takeUntil(this.isDestroyed$)).subscribe((isLoading) => {
      isLoading ? (this.loadingHash = this.notif.showLoading()) : this.notif.close();
    });

    this.spendState$
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((value) => value === SPEND_DISTRIBUTION_STATE.SHOW_SPREADSHEET),
        tap((_) => (this.spreadsheetData = undefined)),
        delay(100),
      )
      .subscribe(this.onOpenSpreadSheet);

    this.selectedBudgetType$.pipe(takeUntil(this.isDestroyed$)).subscribe((type) => {
      this.selectedSpendType = type;
    });

    this.selectedYear$.pipe(takeUntil(this.isDestroyed$)).subscribe((year) => {
      this.selectedYear = year;
    });
  }

  ngAfterViewInit() {
    this.state.currentState.next(SPEND_DISTRIBUTION_STATE.DEFAULT);
  }

  setBudgetLock(val: boolean) {
    SpendViewProjectStateService.isBudgetLocked = val;
  }

  onOpenSpreadSheet = (_) => {
    this.possibleYears$.pipe(take(1)).subscribe((years) => {
      const isEditable = [];
      this.syncedLineItems.forEach((item) => {
        const isEditableItem = {};
        item.budget.forEach((budget) => {
          isEditableItem[budget.year] = this.spendDistributionService.getDisabledMonths(
            budget.year,
            item,
            this.selectedYear,
          );
        });
        isEditable.push(isEditableItem);
      });

      const items = [...getSpreadSheetData(this.syncedLineItems, years, this.selectedSpendType)];
      this.spreadsheetData = {
        items,
        isEditable,
        selectedYear: this.selectedYear,
      };
      console.log(
        'spreadsheet data',
        this.spreadsheetData,
        'selectedBudgetType',
        this.selectedSpendType,
      );
      this.changeDetectorRef.detectChanges(); // otherwise spreadsheet won't open
    });
  };

  getIsBudgetLocked() {
    return SpendViewProjectStateService.isBudgetLocked;
  }

  openSpreadsheet() {
    this.state.currentState.next(SPEND_DISTRIBUTION_STATE.SHOW_SPREADSHEET);
  }

  trackByIndex = (index: number) => index;

  /**
   * Check if the project total is equal to the initial line item total for manual distributions
   * only if the commitment start date is set
   */
  hasManualDistributionTotalMismatch() {
    const manualBudgetItems = this.syncedLineItems.filter(
      (item) =>
        item.distribution === DISTRIBUTION_TYPES.MANUAL &&
        !!item.commitment_start_date &&
        item.project_total !== item.original_budget_total,
    );

    const manualCommittedItems = this.syncedLineItems
      .flatMap((item) => item.committed_items)
      .filter((item) => item.distribution === DISTRIBUTION_TYPES.MANUAL);

    return (
      manualBudgetItems.some((item) => item.project_total !== item.original_budget_total) ||
      manualCommittedItems.some(
        (item: ICommittedItemExtended) => item.project_total !== item.original_budget_total,
      )
    );
  }

  ngOnDestroy() {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
    SpendViewProjectStateService.isBudgetLocked = true;
  }
}
