<div class="bubble-wrapper" [ngClass]="{ center: center, 'full-width': !center }">
  <div
    [ngClass]="{
      'bg-color_misc': project?.project_status?.key === PROJECT_STATUS_KEY.IN_PROGRESS,
      'bg-color_light_green_3': project?.project_status?.key === PROJECT_STATUS_KEY.COMPLETED,
      'bg-color_accent_shade_2': project?.project_status?.key === PROJECT_STATUS_KEY.PLANNED,
      'bg-color_purple': project?.project_status?.key === PROJECT_STATUS_KEY.CANCELED,
      'bg-color_grey_2': project?.project_status?.key === PROJECT_STATUS_KEY.ARCHIVED,
      'bg-color_accent': project?.project_status?.key === PROJECT_STATUS_KEY.BIDDING,
      'bg-color_accept': project?.project_status?.key === PROJECT_STATUS_KEY.AWARDED,
      'bg-color_red_light': project?.project_status?.key === PROJECT_STATUS_KEY.ON_HOLD,
      'bg-color_cancel':
        project?.project_status?.key === PROJECT_STATUS_KEY.CANCELED ||
        project?.project_status?.key === PROJECT_STATUS_KEY.DELETED
    }"
    class="round-mark"
  ></div>
  <span *ngIf="!isDropDown && selectedStatus" class="project-status">
    {{ selectedStatus?.name }}
  </span>
  <span *ngIf="!selectedStatus" class="project?-status">-</span>

  <div class="mat-form-field-container" *ngIf="isDropDown && selectedStatus">
    <mat-form-field subscriptSizing="dynamic" class="borderless">
      <mat-select [value]="selectedStatus.id" (valueChange)="changeProjectStatus($event)">
        <mat-option *ngFor="let status of availableStatuses" [value]="status.id">
          <div class="general-selection-text">{{ status.name | titlecase }}</div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
