import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  HtmlEditorService,
  ImageService,
  LinkService,
  NodeSelection,
  RichTextEditor,
  RichTextEditorModule,
  ToolbarService,
  ToolbarSettingsModel,
  ToolbarType,
} from '@syncfusion/ej2-angular-richtexteditor';
import { ImageSettingsModel } from '@syncfusion/ej2-richtexteditor/src/rich-text-editor/models/models';
import { IToolbarItems } from '@syncfusion/ej2-richtexteditor/src/rich-text-editor/base/interface';
import { QuickToolbarSettingsModel } from '@syncfusion/ej2-richtexteditor/src/rich-text-editor/models/toolbar-settings-model';
import { richTextItemsConfig } from '../../constants/rich-text.constants';
import { NotificationsService } from '../../../services/notifications.service';
import { CreateLinkComponent } from '../../dialogs/create-link/create-link.component';
import { MatDialog } from '@angular/material/dialog';
import { NgClass, NgIf } from '@angular/common';

export enum RTE_VIEWS {
  NONE,
  MESSAGING,
}

export interface RTEConfig {
  richTextConfigItems: (string | IToolbarItems)[];
  enableToolbar: boolean;
  isReady: boolean;
  height: string;
  isReadOnly?: boolean;
}

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, RichTextEditorModule, NgClass],
})
export class RichTextEditorComponent implements OnInit, AfterViewInit {
  @ViewChild('richTextEditorComponent') richTextEditorComponent: RichTextEditor;
  @Output() editorChanged = new EventEmitter();
  @Output() specialKeyPressed = new EventEmitter<{ key: string; html?: string }>();
  @Output() editorFocusOut = new EventEmitter();
  private _editorValue = '';
  public isReadOnly = false;
  public height = '100%';
  cleanEditorIO: string;

  // special keys represent keyboard keys.
  // If one of these is pressed, the specialKeyPressed will emit an event instead of editorChanged.
  // Useful when you want to implement custom behaviour (like send on 'Enter').
  @Input() specialKeys = [];
  @Input() placeholder = '';
  private ENTER_KEY = 'Enter';
  @Input() set editorValue(value) {
    if (value) {
      this._editorValue = value;
      this.cleanEditorIO = value.replace('<p><br></p>', '');
    }
  }

  get editorValue() {
    return this._editorValue;
  }

  @Input() set imageInsertSettings(value: ImageSettingsModel) {
    if (value) {
      this.imageModel = value;
    }
  }

  get imageInsertSettings(): ImageSettingsModel {
    return this.imageModel;
  }
  @Input() customClass = '';

  @Input() set editorSettings(value: RTEConfig) {
    this.richTextConfig.enable = value.enableToolbar;
    this.isReadOnly = value.isReadOnly;
    this.height = value.height ?? '100%';
    if (value.richTextConfigItems) {
      this.richTextConfig.items = value.richTextConfigItems;
    }
  }

  // By setting this variable you can implement custom behaviour dependent on the parent component.
  @Input() editorView = RTE_VIEWS.NONE;
  // used to disable click on links
  @Input() quickToolbarSettings: QuickToolbarSettingsModel;

  richTextConfig: ToolbarSettingsModel = {
    type: ToolbarType.Expand,
    enableFloating: true,
    items: richTextItemsConfig,
    enable: true,
  };
  imageModel: ImageSettingsModel = {};
  isReady = false;

  constructor(
    private dialog: MatDialog,
    private notif: NotificationsService,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.refresh();
  }

  refresh() {
    this.richTextEditorComponent.refreshUI();
    this.richTextEditorComponent.refresh();
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    // please don't condemn us, it was necessary. Otherwise, the library used raises an error.
    setTimeout(() => (this.isReady = true));
  }

  onKeyDown(event: KeyboardEvent): void {
    // if shift + enter is pressed don't emit special key
    if (
      this.specialKeys.includes(event.key) &&
      ((!event.shiftKey && event.key === this.ENTER_KEY) || event.key !== this.ENTER_KEY)
    ) {
      this.handleSpecialKeyPressed(event.key);
      return;
    }
    setTimeout(() => {
      // setTimeout is needed. Otherwise, the getHtml function won't return the most recent value.
      this.cleanEditorIO = this.richTextEditorComponent.getHtml().replace('<p><br></p>', '');
      this.editorChanged.next(this.richTextEditorComponent.getHtml());
    });
  }

  /**
   * Called by parent component if the hashtag needs to be inserted programmatically.
   */
  insertHashtag() {
    this.richTextEditorComponent?.executeCommand('insertText', ' #');
    this.handleSpecialKeyPressed('#');
  }

  /**
   * Opens a popup to create a link from a selected text
   */
  createLink() {
    const selectedHtml = this.getSelectedHtml();
    if (!selectedHtml) {
      this.notif.showError('Please select a text to create a link');
      return;
    }
    const selection: NodeSelection = new NodeSelection();
    const range: Range = selection.getRange(document);
    const saveSelection: NodeSelection = selection.save(range, document);
    (this.richTextEditorComponent.contentModule.getEditPanel() as HTMLElement).focus();

    const dialogRef = this.dialog.open(CreateLinkComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        saveSelection.restore();
        const link = `<a href='${response}' target="_blank">${selectedHtml}</a>`;
        this.richTextEditorComponent?.executeCommand('insertHTML', link);
        this.editorChanged.next(this.richTextEditorComponent.getHtml());
      }
    });
  }

  /**
   * Opens a popup to edit an existing link
   */
  editLink() {
    const selection: NodeSelection = new NodeSelection();
    const range: Range = selection.getRange(document);
    const saveSelection: NodeSelection = selection.save(range, document);
    (this.richTextEditorComponent.contentModule.getEditPanel() as HTMLElement).focus();
    const clickedNode = saveSelection.get(document);
    const parentElement = clickedNode.anchorNode.parentElement;
    const dialogRef = this.dialog.open(CreateLinkComponent, {
      width: '500px',
      data: {
        href: parentElement.getAttribute('href'),
      },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        parentElement.setAttribute('href', response);
        this.editorValue = this.richTextEditorComponent.getHtml();
        this.editorChanged.next(this.richTextEditorComponent.getHtml());
        this.refresh();
      }
    });
  }

  /**
   * There could be some special keys when this component is used with messages.
   * ex: on `@` pressed display the users in conversation or send messages on Enter.
   * SetTimeout is needed to wait for the character to appear in the result of `.getHtml()`.
   */
  handleSpecialKeyPressed(key: string) {
    if (this.editorView === RTE_VIEWS.NONE) {
      this.specialKeyPressed.next({ key });
      return;
    }
    setTimeout(() => {
      const html = this.richTextEditorComponent.getHtml();
      switch (key) {
        case '@': {
          this.atPressed(html);
          break;
        }
        case '#': {
          this.hashtagPressed(html);
          break;
        }
        default: {
          this.specialKeyPressed.next({ key, html });
          break;
        }
      }
    }, 50);
  }

  /**
   * Search for the appropriate `@` character and wrap in a special <span> tag which is needed to get the overlay's position.
   */
  atPressed(html) {
    let regexToReplace = /( |&nbsp;)@/;
    if (html.match(/<p>@<\/p>\s*/)) {
      regexToReplace = /@/;
    }
    this.editorValue = html.replace(regexToReplace, `<span class="span-at">&nbsp;@</span>`);
    this.refresh();
    this.setCursorAfterSelector('.span-at');
    this.specialKeyPressed.next({ key: '@', html: this.editorValue });
  }

  /**
   * Search for the appropriate `#` character and wrap in a special <span> tag which is needed to get the overlay's position.
   */
  hashtagPressed(html) {
    let regexToReplace = /( |&nbsp;)#/;
    if (html.match(/<p>#<\/p>\s*/)) {
      regexToReplace = /#/;
    }
    this.editorValue = html.replace(regexToReplace, `<span class="span-hashtag">&nbsp;#</span>`);
    this.refresh();
    this.setCursorAfterSelector('.span-hashtag');
    this.specialKeyPressed.next({ key: '#', html: this.editorValue });
  }

  /**
   * Sets the text cursor after the given querySelector inside the RTE.
   * Note: this works only if the RTE is based on HTML.
   * SetTimeout is needed to wait for the element to appear in the DOM.
   */
  setCursorAfterSelector(querySelector: string) {
    setTimeout(() => {
      const element: Element = this.richTextEditorComponent.contentModule
        .getDocument()
        .querySelector(`.e-richtexteditor ${querySelector}`);
      if (!element) {
        console.warn(`no ${querySelector} found`);
        return;
      }
      const selectioncursor: NodeSelection = new NodeSelection();
      const range: Range = document.createRange();
      range.setStart(element, 1); // to set the range
      selectioncursor.setRange(document, range); // to set the cursor
    }, 100);
  }

  clearEditor() {
    this._editorValue = '';
    // this value overwriting is needed as it does not update the second time an empty string is written to the editor
    // _editorValue is not enough
    this.richTextEditorComponent.value = '';
    this.refresh();
  }

  getSelectedHtml() {
    return this.richTextEditorComponent.getSelection();
  }

  /**
   * used when value is overwritten from outside if emitted
   * update only in the end on focusout, this way cursor will not reset
   * emit editor value, to be used only if the value needs to be saved at the end
   */
  onFocusOut() {
    this.editorFocusOut.next(this.richTextEditorComponent.getHtml());
  }
}
