<div [ngClass]="{ 'hide-add-company': hideAddCompany }" class="dropdown-container" [@fillIn]="'in'">
  <ng-scrollbar
    *ngIf="
      (filteredContractors$ | async)?.contractors.length ||
      (filteredContractors$ | async)?.temporary_contractors?.length
    "
    class="std-scrollbar"
    appearance="native"
  >
    <span class="cursor-pointer" *ngFor="let contractor of (filteredContractors$ | async)?.contractors">
      <span (click)="selectOption(contractor, false)" class="flex text-shade_1 text-size_l font-bold p-2">{{
        contractor.company_name
      }}</span>
      <span class="border-bottom"></span>
    </span>

    <span
      class="cursor-pointer"
      *ngFor="let temporaryContractor of (filteredContractors$ | async)?.temporary_contractors; let last = last"
    >
      <span (click)="selectOption(temporaryContractor, true)" class="flex p-2">
        <span class="text-shade_1 text-size_l font-bold">{{ temporaryContractor.company_name }}</span>
        <app-temporary-contractor-icon></app-temporary-contractor-icon>
      </span>
      <span [ngClass]="{ '!hidden': last && hideAddCompany }" class="border-bottom"></span>
    </span>
  </ng-scrollbar>
  <span
    *ngIf="
      !(filteredContractors$ | async)?.contractors.length &&
      !(filteredContractors$ | async)?.temporary_contractors?.length
    "
    class="flex flex-1 font-bold text-size_l pt-2 px-2 pb-0.5"
    >No companies found</span
  >
  <span
    *ngIf="!hideAddCompany"
    (click)="switchDropdown()"
    class="text-color_secondary font-bold pointer-cursor text-size_l pt-2 px-2 pb-0.5"
    >Add Company
  </span>
</div>
