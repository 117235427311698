<div class="bg-white rounded-md overlay-shadow p-2 w-[350px]">
  <div class="text-shade_1 font-semibold py-2 flex items-center gap-1">
    <!--    <app-back-button-box *ngIf="selectedTemplate" (clicked)="unselectTemplate()"></app-back-button-box>-->
    @if (selectedTemplate) {
      <app-bordered-dropdown-icon
        (click)="unselectTemplate()"
        [isClosed]="true"
        useAsBackButton="true"
      ></app-bordered-dropdown-icon>
    }
    <span class="text-sm">{{ selectedTemplate ? selectedTemplate.name : 'Please Select a Budget Template Tag' }}</span>
  </div>
  <ng-scrollbar #scrollbar class="std-scrollbar !max-h-48 min-h-[192px]" appearance="native" orientation="vertical">
    <div [ngClass]="{ 'mr-3': scrollbar.isVerticallyScrollable() }">
      <div
        (click)="removeTemplate()"
        *ngIf="hasTemplate"
        class="text-color_secondary font-medium text-xs py-2 bottom-border flex-1 pointer-cursor"
      >
        Unlink Budget Template Tag
      </div>

      <ng-container *ngIf="!selectedTemplate; else tagItems">
        <div [ngClass]="{ 'bottom-border cursor-pointer': showAllTags && budgetTemplateTags?.length }" class="flex">
          <span
            *ngIf="showAllTags"
            (click)="this.selectAllTagsLocalOption.emit()"
            class="text-shade_1 font-medium text-xs py-2 flex-1"
            >All Tags
          </span>

          <div
            class="flex flex-1 h-full text-center pt-4 text-sm text-shade_3"
            *ngIf="!budgetTemplateTags?.length && !showAllTags"
          >
            Currently there are no budget template tags. Please create tag templates.
          </div>
        </div>
        <div *ngFor="let template of budgetTemplateTags" class="flex items-center cursor-pointer bottom-border">
          <span (click)="selectTemplate(template)" class="text-shade_1 font-medium text-xs py-2 flex-1"
            >{{ template.name }}
          </span>
          <span class="icon-arrow-right-light text-color_secondary"></span>
        </div>
      </ng-container>
      <ng-template #tagItems>
        <div
          [ngClass]="{
            'pointer-events-none': tag.id | containsValue: disabledBudgetTagIds
          }"
          class="w-full flex bottom-border"
          (click)="selectTag(tag)"
          *ngFor="let tag of selectedTemplate?.tags"
        >
          <span
            [ngClass]="{
              '!text-color_disabled': tag.id | containsValue: disabledBudgetTagIds
            }"
            class="text-shade_1 font-medium text-xs py-2 flex-1 cursor-pointer"
            >{{ tag.name }}</span
          >
        </div>
      </ng-template>
    </div>
  </ng-scrollbar>
</div>
