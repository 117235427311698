<div [@fadeInGeneral] class="summary-wrapper">
  <div class="table-wrapper">
    <ng-scrollbar class="std-scrollbar" orientation="vertical" appearance="compact">
      <div class="inner-wrapper">
        <table [ngClass]="{ contractor: !user.isManager }" class="table">
          <thead class="font-weight-bold">
            <tr class="header">
              <th *ngIf="user.isManager" (click)="sortBy(SORT.company_name)" class="table-cell">
                <div class="table-cell-inner-container">
                  <span>Company Name</span>
                  <app-sort-arrow [hide]="sortedBy !== SORT.company_name" [isDesc]="lastSortOrder < 0"></app-sort-arrow>
                </div>
              </th>
              <th (click)="sortBy(SORT.contract_title)" class="table-cell">
                <div class="table-cell-inner-container">
                  <span>Contract Title</span>
                  <app-sort-arrow
                    [hide]="sortedBy !== SORT.contract_title"
                    [isDesc]="lastSortOrder < 0"
                  ></app-sort-arrow>
                </div>
              </th>
              <th
                *ngIf="!user.isManager"
                [ngClass]="{ contractor: !user.isManager }"
                (click)="sortBy(SORT.approvalStatus)"
                class="table-cell"
              >
                <div class="table-cell-inner-container">
                  <span>Approved</span>
                  <app-sort-arrow
                    [hide]="sortedBy !== SORT.approvalStatus"
                    [isDesc]="lastSortOrder < 0"
                  ></app-sort-arrow>
                </div>
              </th>
              <th (click)="sortBy(SORT.contract_value)" class="table-cell">
                <div class="table-cell-inner-container">
                  <span pTooltip="Total approved contracts on the project." tooltipPosition="bottom"
                    >Contract<br />Value</span
                  >
                  <app-sort-arrow
                    [hide]="sortedBy !== SORT.contract_value"
                    [isDesc]="lastSortOrder < 0"
                  ></app-sort-arrow>
                </div>
              </th>
              <th (click)="sortBy(SORT.change_orders)" class="table-cell">
                <div class="table-cell-inner-container">
                  <span pTooltip="Total approved change orders." tooltipPosition="bottom">Change<br />Orders</span>
                  <app-sort-arrow
                    [hide]="sortedBy !== SORT.change_orders"
                    [isDesc]="lastSortOrder < 0"
                  ></app-sort-arrow>
                </div>
              </th>
              <th (click)="sortBy(SORT.total_commit)" class="table-cell">
                <div class="table-cell-inner-container">
                  <span pTooltip="Total Committed = Contracts + Change Orders + Direct Costs." tooltipPosition="bottom"
                    >Total<br />Committed</span
                  >
                  <app-sort-arrow [hide]="sortedBy !== SORT.total_commit" [isDesc]="lastSortOrder < 0"></app-sort-arrow>
                </div>
              </th>
              <th (click)="sortBy(SORT.total_invoiced)" class="table-cell">
                <div class="table-cell-inner-container">
                  <span pTooltip="Total paid and unpaid invoices." tooltipPosition="bottom">Total<br />Invoiced</span>
                  <app-sort-arrow
                    [hide]="sortedBy !== SORT.total_invoiced"
                    [isDesc]="lastSortOrder < 0"
                  ></app-sort-arrow>
                </div>
              </th>
              <th (click)="sortBy(SORT.paid_invoices)" class="table-cell">
                <div class="table-cell-inner-container">
                  <span pTooltip="Total paid invoices only. Unpaid invoices are not included." tooltipPosition="bottom"
                    >Paid<br />Invoices</span
                  >
                  <app-sort-arrow
                    [hide]="sortedBy !== SORT.paid_invoices"
                    [isDesc]="lastSortOrder < 0"
                  ></app-sort-arrow>
                </div>
              </th>
              <th class="table-cell complete-percent-cell cursor-default">
                <!--                <div class="table-cell-inner-container">-->
                <!--                  <span pTooltip="%Complete = Paid Invoices / Total Committed." tooltipPosition="bottom"-->
                <!--                    >Contract Progress</span-->
                <!--                  >-->
                <!--                  <app-sort-arrow-->
                <!--                    [hide]="sortedBy !== SORT.completePercentage"-->
                <!--                    [isDesc]="lastSortOrder < 0"-->
                <!--                  ></app-sort-arrow>-->
                <!--                </div>-->
              </th>
              <th class="dummy"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let contract of contracts" class="content-row">
              <td *ngIf="user.isManager" (click)="selectedContract.emit(contract)" class="table-cell">
                <div class="wrapper">
                  <app-faded-text class="company-name-faded-text">
                    {{ contract.company_name }}
                  </app-faded-text>
                  <app-temporary-contractor-icon
                    *ngIf="contract.is_tmp_service_provider"
                  ></app-temporary-contractor-icon>
                </div>
              </td>
              <td
                (click)="selectedContract.emit(contract)"
                class="table-cell contract-title"
                [ngClass]="{
                  contractor: !user.isManager
                }"
              >
                <app-faded-text>{{ contract.title }}</app-faded-text>
              </td>
              <td [ngClass]="{ contractor: !user.isManager }" *ngIf="!user.isManager" class="table-cell">
                <div class="icons-container">
                  <span
                    [ngClass]="{
                      'icon-approved': contract.approval_status === 'approved',
                      'icon-draft': contract.approval_status === 'draft' && user.isManager,
                      'icon-review': contract.approval_status === 'review' && user.isManager,
                      'icon-pending': contract.approval_status === 'pending' && user.isManager,
                      'icon-attention': contract.approval_status !== 'approved' && !user.isManager
                    }"
                    [pTooltip]="contract.approval_status | caseTransform: 'capitalize'"
                    class="icon"
                  >
                  </span>
                </div>
              </td>
              <td class="table-cell" [ngClass]="{ negative: contract.contract_value < 0 }">
                <span
                  [pTooltip]="contract.contract_value | money: 'positiveComa'"
                  [tooltipStyleClass]="contract.contract_value < 0 ? 'negative' : ''"
                  tooltipPosition="bottom"
                >
                  {{ contract.contract_value === 0 ? '-' : (contract.contract_value | money: 'shortSignSimpleSpace') }}
                </span>
              </td>
              <td class="table-cell" [ngClass]="{ negative: contract.change_order_value < 0 }">
                <span
                  [pTooltip]="contract.change_order_value | money: 'positiveComa'"
                  [tooltipStyleClass]="contract.change_order_value < 0 ? 'negative' : ''"
                  tooltipPosition="bottom"
                >
                  {{
                    contract.change_order_value === 0
                      ? '-'
                      : (contract.change_order_value | money: 'shortSignSimpleSpace')
                  }}
                </span>
              </td>

              <td class="table-cell" [ngClass]="{ negative: contract.total_committed < 0 }">
                <span
                  [pTooltip]="contract.total_committed | money: 'positiveComa'"
                  [tooltipStyleClass]="contract.total_committed < 0 ? 'negative' : ''"
                  tooltipPosition="bottom"
                >
                  {{
                    contract.total_committed === 0 ? '-' : (contract.total_committed | money: 'shortSignSimpleSpace')
                  }}
                </span>
              </td>
              <td class="table-cell" [ngClass]="{ negative: contract.total_invoiced < 0 }">
                <span
                  [pTooltip]="contract.total_invoiced | money: 'positiveComa'"
                  [tooltipStyleClass]="contract.total_invoiced < 0 ? 'negative' : ''"
                  tooltipPosition="bottom"
                >
                  {{ contract.total_invoiced === 0 ? '-' : (contract.total_invoiced | money: 'shortSignSimpleSpace') }}
                </span>
              </td>
              <td class="table-cell" [ngClass]="{ negative: contract.total_paid < 0 }">
                <span
                  [pTooltip]="contract.total_paid | money: 'positiveComa'"
                  [tooltipStyleClass]="contract.total_paid < 0 ? 'negative' : ''"
                  tooltipPosition="bottom"
                >
                  {{ contract.total_paid === 0 ? '-' : (contract.total_paid | money: 'shortSignSimpleSpace') }}
                </span>
              </td>
              <td class="table-cell complete-percent-cell">
                <app-progress-bar-projectable
                  [isNegative]="contract.complete < 0"
                  [percent]="contract.complete"
                  [biggerPercentText]="true"
                  percentWidth="big"
                  fillClass="bg-color_accent"
                  text="PAID"
                >
                </app-progress-bar-projectable>
              </td>
              <td></td>
            </tr>
            <tr class="content-row" *ngIf="user.isManager && !isLoading">
              <td (click)="goToDirectCosts.emit()" class="table-cell">Miscellaneous</td>
              <td class="table-cell"></td>
              <td class="table-cell"></td>
              <td class="table-cell"></td>
              <td class="table-cell" [ngClass]="{ negative: directCostTotals.total_committed < 0 }">
                <span
                  [pTooltip]="directCostTotals.total_committed | money: 'positiveComa'"
                  [tooltipStyleClass]="directCostTotals.total_committed < 0 ? 'negative' : ''"
                  tooltipPosition="bottom"
                >
                  {{
                    directCostTotals.total_committed === 0
                      ? '-'
                      : (directCostTotals.total_committed | money: 'shortSignSimpleSpace')
                  }}
                </span>
              </td>
              <td
                class="table-cell"
                [pTooltip]="directCostTotals.total_invoiced | money: 'positiveComa'"
                [tooltipStyleClass]="directCostTotals.total_invoiced < 0 ? 'negative' : ''"
                tooltipPosition="bottom"
              >
                {{
                  directCostTotals.total_invoiced === 0
                    ? '-'
                    : (directCostTotals.total_invoiced | money: 'shortSignSimpleSpace')
                }}
              </td>
              <td class="table-cell" [ngClass]="{ negative: directCostTotals.total_paid < 0 }">
                <span
                  [pTooltip]="directCostTotals.total_paid | money: 'positiveComa'"
                  [tooltipStyleClass]="directCostTotals.total_paid < 0 ? 'negative' : ''"
                  tooltipPosition="bottom"
                >
                  {{
                    directCostTotals.total_paid === 0
                      ? '-'
                      : (directCostTotals.total_paid | money: 'shortSignSimpleSpace')
                  }}
                </span>
              </td>
              <td class="table-cell complete-percent-cell">
                <app-progress-bar-projectable
                  [percent]="directCostTotals?.complete ?? 0"
                  [biggerPercentText]="true"
                  percentWidth="big"
                  fillClass="bg-color_accent"
                  text="PAID"
                ></app-progress-bar-projectable>
                <!--            <app-progress-bar [percent]="directCostTotals.complete"></app-progress-bar>-->
              </td>
              <td class="dummy"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-scrollbar>
  </div>
  <div class="total-wrapper">
    <table class="table" [ngClass]="{ contractor: !user.isManager }">
      <tbody>
        <tr>
          <td class="font-weight-bold table-cell totals">Totals</td>
          <td class="table-cell"></td>
          <td class="table-cell" [ngClass]="{ negative: totals.contract_value < 0 }">
            <span
              [pTooltip]="totals.contract_value | money: 'positiveComa'"
              [tooltipStyleClass]="totals.contract_value < 0 ? 'negative' : ''"
              tooltipPosition="top"
            >
              {{ totals.contract_value === 0 ? '-' : (totals.contract_value | money: 'shortSignSimpleSpace') }}
            </span>
          </td>
          <td class="table-cell" [ngClass]="{ negative: totals.change_order_value < 0 }">
            <span
              [pTooltip]="totals.change_order_value | money: 'positiveComa'"
              [tooltipStyleClass]="totals.change_order_value < 0 ? 'negative' : ''"
              tooltipPosition="top"
            >
              {{ totals.change_order_value === 0 ? '-' : (totals.change_order_value | money: 'shortSignSimpleSpace') }}
            </span>
          </td>
          <td class="table-cell" [ngClass]="{ negative: totals.total_committed < 0 }">
            <span
              [pTooltip]="totals.total_committed | money: 'positiveComa'"
              [tooltipStyleClass]="totals.total_committed < 0 ? 'negative' : ''"
              tooltipPosition="top"
            >
              {{ totals.total_committed === 0 ? '-' : (totals.total_committed | money: 'shortSignSimpleSpace') }}
            </span>
          </td>
          <td class="table-cell" [ngClass]="{ negative: totals.total_invoiced < 0 }">
            <span
              [pTooltip]="totals.total_invoiced | money: 'positiveComa'"
              [tooltipStyleClass]="totals.total_invoiced < 0 ? 'negative' : ''"
              tooltipPosition="top"
            >
              {{ totals.total_invoiced === 0 ? '-' : (totals.total_invoiced | money: 'shortSignSimpleSpace') }}
            </span>
          </td>

          <td class="table-cell" [ngClass]="{ negative: totals.total_paid < 0 }">
            <span
              [pTooltip]="totals.total_paid | money: 'positiveComa'"
              [tooltipStyleClass]="totals.total_paid < 0 ? 'negative' : ''"
              tooltipPosition="top"
            >
              {{ totals.total_paid === 0 ? '-' : (totals.total_paid | money: 'shortSignSimpleSpace') }}
            </span>
          </td>
          <td class="table-cell complete-percent-cell">
            <app-progress-bar-projectable
              [percent]="totals.complete"
              [biggerPercentText]="true"
              percentWidth="big"
              fillClass="bg-color_accent"
              text="PAID"
            >
            </app-progress-bar-projectable>
          </td>
          <td class="dummy"></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
