import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { setIsLoading, spendActionTypes } from './spend.actions';
import { catchError, debounceTime, map, repeat, switchMap, withLatestFrom } from 'rxjs/operators';
import { ProjectSpendService } from '../../services/project-spend.service';
import { AppState } from '../app-state';
import { Store } from '@ngrx/store';
import {
  getAllLineItemData,
  getForecastProjectTotalPerItem,
  getLineItemById,
  getNextOrder,
  getProjectStartDate,
  projectTotalPerItem,
  spendFeatureSelector,
} from './spend.selectors';
import { forkJoin, of, throwError } from 'rxjs';
import {
  defaultLineItem,
  defaultMonthlyData,
  DISTRIBUTION_TYPES,
  spendTypeKeys,
} from '../../framework/constants/spend.constants';
import moment from 'moment/moment';
import {
  IBudget,
  ICommittedItem,
  IDistributionResponse,
  IForecastBudget,
  ILineItem,
  ISpendDistribution,
  SPEND_ERROR_TYPES,
} from './spend.interfaces';
import cloneDeep from 'lodash/cloneDeep';
import { NotificationsService } from '../../services/notifications.service';
import { ProjectStateService } from '../../services/project-state.service';
import lodash from 'lodash';
import { FiscalService } from '../../services/fiscal.service';
import { SpendState } from './spend.reducer';

@Injectable()
export class SpendEffects {
  actionsWithHttpRequest = [
    spendActionTypes.loadSpends,
    spendActionTypes.saveToBackend,
    spendActionTypes.updateDistribution,
  ];

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private spendService: ProjectSpendService,
    private notif: NotificationsService,
    private projectStateService: ProjectStateService,
    private fiscalService: FiscalService,
  ) {}

  addLineItem = createEffect(() => {
    return this.actions$.pipe(
      ofType(spendActionTypes.addDefaultLineItem, spendActionTypes.addLineItemWithName),
      withLatestFrom(this.store.select(getProjectStartDate), this.store.select(getNextOrder)),
      map(([action, projectStartDate, order]) => {
        const lineItem = { ...defaultLineItem };
        lineItem.row_number = order;
        lineItem.start_date = projectStartDate ?? moment().format('YYYY-MM-DD');
        lineItem.budget = [];
        if (action.type === spendActionTypes.addLineItemWithName.type) {
          lineItem.name = action.name;
        }
        const year = this.projectStateService.getLineItemFiscalYear(lineItem.start_date);
        const budget: IBudget = {
          year,
          monthly_budget: { ...defaultMonthlyData },
        };
        lineItem.budget.push(budget);

        return spendActionTypes.addLineItem({ lineItem });
      }),
    );
  });

  loadSpends = createEffect(() => {
    return this.actions$.pipe(
      ofType(spendActionTypes.loadSpends),
      debounceTime(300),
      switchMap((action) =>
        this.spendService
          .getLineItemsByProjectId(action.projectId)
          .pipe(map((lineItems) => [lineItems, action])),
      ),
      map(([lineItems, action]) => {
        const { unsetLineItemIds, unsetCommittedItemIds } = this.getUnsetRowNumbers(lineItems);
        // make sure all line items and their committed line items are sorted and a new row number is assigned
        lineItems = this.sortLineItemsWithCommittedItems(lineItems);
        lineItems = this.addOriginalBudget(lineItems);

        return spendActionTypes.setLineItems({
          lineItems,
          projectId: action.projectId,
          modifiedItems: {
            lineIds: unsetLineItemIds,
            committedItemIds: unsetCommittedItemIds,
          },
        });
      }),
    );
  });

  updateDistribution$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spendActionTypes.updateDistribution, spendActionTypes.updateForecastDistribution),
      withLatestFrom(this.store.select(spendFeatureSelector)),
      switchMap(([action, state]) => this.handleDistributionUpdate(action, state)),
      map(([response, action, lineItemStore]) => {
        if (!response && action.distribution.distribution !== DISTRIBUTION_TYPES.MANUAL) {
          return spendActionTypes.cancel();
        }
        return this.mapToUpdateLineItem(response, action, lineItemStore);
      }),
      catchError(this.handleError),
    ),
  );

  deleteAllLineItems = createEffect(() => {
    return this.actions$.pipe(
      ofType(spendActionTypes.addProjectBudgetTemplateChange),
      withLatestFrom(
        this.store.select(getAllLineItemData),
        this.store.select(spendFeatureSelector),
      ),
      map(([action, items, state]) => {
        items.forEach((item) => {
          // if (!state.newLineItems.has(item.id)) {
          this.store.dispatch(spendActionTypes.deleteLineItem({ id: item.id }));
          // }
        });
        return spendActionTypes.cancel();
      }),
    );
  });

  saveToBackend = createEffect(() => {
    return this.actions$.pipe(
      ofType(spendActionTypes.saveToBackend),
      withLatestFrom(this.store.select(spendFeatureSelector)),
      map(([_, state]) => {
        const saveObservables = []; // network requests are collected here and are executed in parallel
        // error handling on every observable is needed because the forkJoin used later.
        // otherwise if an error happens it cancels out other requests too.
        const errorHandling = catchError((err) => {
          setTimeout(() => {
            if (err?.error?.message) {
              this.notif.showError(err?.error?.message);
              return;
            }

            if (err?.error?.start_date?.[0] === 'validation.after_or_equal') {
              this.notif.showError("Start date can't be before project start date!");
              return;
            }

            this.notif.showError('An error occurred during saving.');
          });

          return throwError(err);
        });

        for (const id of state.newLineItems) {
          const lineItem = { ...state.entities[id] };
          delete lineItem.id;
          lineItem.project_id = state.selectedProjectId;
          saveObservables.push(
            this.spendService.createLineItem$(lineItem).pipe(
              errorHandling,
              catchError((err) => {
                return of({
                  id,
                  errorType: SPEND_ERROR_TYPES.NEW,
                  error: true,
                });
              }),
            ),
          );
        }

        for (const id of state.deletedLineItems) {
          saveObservables.push(
            this.spendService.deleteLineItem(id).pipe(
              errorHandling,
              catchError((err) => {
                return of({
                  id,
                  errorType: SPEND_ERROR_TYPES.DELETED,
                  error: true,
                });
              }),
            ),
          );
        }

        for (const id of state.modifiedLineItems) {
          if (state.entities[id]) {
            saveObservables.push(
              this.spendService.updateLineItem(state.entities[id]).pipe(
                errorHandling,
                catchError((err) => {
                  return of({
                    id,
                    errorType: SPEND_ERROR_TYPES.MODIFIED,
                    error: true,
                  });
                }),
              ),
            );
          }
        }

        const modifiedItems = [];
        for (const id of state.modifiedCommittedItems) {
          Object.entries(state.entities).forEach(([_, entity]) => {
            entity.committed_items.forEach((item) => {
              if (item.id === id) {
                modifiedItems.push(item);
              }
            });
          });
        }

        modifiedItems.forEach((item) => {
          saveObservables.push(
            this.spendService.updateCommittedLineItem(item).pipe(
              errorHandling,
              catchError((err) => {
                return of({
                  id: item.id,
                  errorType: SPEND_ERROR_TYPES.FAILED_TO_UPDATE_COMMITTED_LINE,
                  error: true,
                });
              }),
            ),
          );
        });
        return saveObservables;
      }),
      switchMap((observables) => (observables?.length > 0 ? forkJoin([...observables]) : of([]))),
      map((responses: any[]) => {
        // items saved not successfully can be resaved
        const errors = responses.filter((response) => response?.error);
        return spendActionTypes.clearAfterSave({ errors });
      }),
    );
  });

  loadSpendItemSummary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(spendActionTypes.loadSpendLineItemSummary),
      switchMap((action) => {
        return this.spendService.getSpendLineItemSummary(action.lineItemId).pipe(
          map((summary) => {
            return spendActionTypes.spendLineItemSummaryLoaded({ summary });
          }),
        );
      }),
      catchError((_) => {
        this.notif.showError('An error occurred.');
        return of(spendActionTypes.cancel());
      }),
      repeat(),
    );
  });

  setIsLoading = createEffect(() =>
    this.actions$.pipe(
      ofType(...this.actionsWithHttpRequest),
      map((action) => {
        if (action.type === spendActionTypes.updateDistribution.type) {
          if (action.distribution.distribution === DISTRIBUTION_TYPES.MANUAL) {
            // this way won't overwrite current loading state
            return spendActionTypes.setIsLoading({ isLoading: undefined });
          }
        }
        return spendActionTypes.setIsLoading({ isLoading: true });
      }),
    ),
  );

  isUpdateRequestNeeded = (
    action: ReturnType<
      | typeof spendActionTypes.updateDistribution
      | typeof spendActionTypes.updateForecastDistribution
    >,
  ) => {
    let isNeeded = true;
    if (action.distribution.duration <= 0 || action.distribution.budget < 0) {
      isNeeded = false;
    }

    if (!isNeeded) {
      this.store.dispatch(setIsLoading({ isLoading: false }));
    }
    return isNeeded;
  };

  updateDistWithReq<T extends ILineItem | ICommittedItem>(
    lineItem: T,
    response: IDistributionResponse,
    action: { distribution: ISpendDistribution },
  ) {
    // all new years from request will be saved in budgetYears
    const budgetYears = response.budget.map((bud: IBudget | IForecastBudget) => bud.year);
    // delete not needed years but keep years with non default values (non zero defaults)
    lineItem.budget = lineItem.budget.filter((budget) => {
      return budgetYears.includes(budget.year) || this.hasNonZeroMonth(action, budget);
    }) as T['budget'];

    // old monthly_data values need to be zero for selected distribution
    lineItem.budget.forEach((bud) => {
      bud[action.distribution.field] = { ...defaultMonthlyData };
    });
    // then add/update the years
    budgetYears.forEach((year) => {
      const newBudgetPerYear = cloneDeep(response.budget.find((newBud) => newBud.year === year));
      const oldBudgetIndex = lineItem.budget.findIndex((bud) => bud.year === year);
      // add years from distribution years
      if (oldBudgetIndex < 0) {
        spendTypeKeys
          .filter((key) => key !== action.distribution.field)
          .forEach((key) => {
            newBudgetPerYear[key] = { ...defaultMonthlyData };
          });

        if (action.distribution.field === 'monthly_forecast') {
          (lineItem as ICommittedItem).budget.push(newBudgetPerYear as IForecastBudget);
        } else {
          (lineItem as ILineItem).budget.push(newBudgetPerYear as IBudget);
        }
      } else {
        const budgetTypeKey = action.distribution.field;
        lineItem.budget[oldBudgetIndex][budgetTypeKey] = newBudgetPerYear[budgetTypeKey];
      }
    });

    // return line item without losing previous years with values but also new distribution and years from request
    return lineItem;
  }

  hasNonZeroMonth(
    action: { distribution: ISpendDistribution },
    budget: IBudget | IForecastBudget,
  ): boolean {
    let hasValues = false;
    spendTypeKeys
      .filter((key): boolean => key !== action.distribution.field)
      .forEach((key): void => {
        if (!lodash.isEqual(budget[key], defaultMonthlyData) && !hasValues) {
          hasValues = true;
        }
      });
    return hasValues;
  }

  updateDistManual<T extends ILineItem | ICommittedItem>(
    lineItem: T,
    action:
      | ReturnType<typeof spendActionTypes.updateDistribution>
      | ReturnType<typeof spendActionTypes.updateForecastDistribution>,
  ): T {
    // because we had no request we need to add/remove years from budget if start_date/duration changed
    const FYStart = this.fiscalService.fiscalYearStart - 1;
    const budgetStart = moment(lineItem.start_date);

    const budgetStartFY = budgetStart.clone();
    if (FYStart) {
      budgetStartFY.add(12 - FYStart, 'months');
    }
    const budgetEndFY = budgetStartFY.clone().add(lineItem.duration - 1, 'months');

    // add years in duration if not present yet
    for (let year = budgetStartFY.year(); year <= budgetEndFY.year(); year++) {
      if (!lineItem.budget.find((bud) => bud.year === year)) {
        if (action.type === spendActionTypes.updateForecastDistribution.type) {
          (lineItem as ICommittedItem).budget.push({
            year,
            monthly_forecast: { ...defaultMonthlyData },
          });
        } else {
          (lineItem as ILineItem).budget.push({
            year,
            monthly_budget: { ...defaultMonthlyData },
          });
        }
      }
    }
    return lineItem;
  }

  getUnsetRowNumbers = (lineItems: ILineItem[]) => {
    const unsetLineItemIds = this.getUnsetLineItemIds(lineItems);
    const unsetCommittedItemIds = this.getUnsetCommittedItemIds(lineItems);

    return {
      unsetLineItemIds,
      unsetCommittedItemIds,
    };
  };

  private getUnsetLineItemIds(lineItems: ILineItem[]): number[] {
    const unsetLineItems = lineItems.filter((item) => item.row_number === 0);
    return unsetLineItems.length > 1 ? unsetLineItems.map((item) => item.id) : [];
  }

  private getUnsetCommittedItemIds(lineItems: ILineItem[]): number[] {
    const unsetCommittedItemIds = new Set<number>();

    // Check for unset committed items within each line item
    lineItems.forEach((item) => {
      const unsetCommittedItems = item.committed_items.filter(
        (commItem) => commItem.committed_row_number === 0,
      );

      if (unsetCommittedItems.length > 1) {
        item.committed_items.forEach((commItem) => unsetCommittedItemIds.add(commItem.id));
      }
    });

    // Check for unset committed items across all line items
    const allUnsetCommittedItems = lineItems
      .flatMap((item) => item.committed_items)
      .filter((commItem) => commItem.row_number === 0);

    if (allUnsetCommittedItems.length > 1) {
      lineItems
        .flatMap((item) => item.committed_items)
        .forEach((commItem) => unsetCommittedItemIds.add(commItem.id));
    }

    return Array.from(unsetCommittedItemIds);
  }

  /**
   * Sort line committed items by row number and committed row number.
   * Make sure row numbers start from the lowest number already present in the list as row or committed row number.
   * At the most end of the list will be placed all items with type 'forecast_modification' aka Anticipated Costs.
   * @param lineItems
   */
  sortLineItemsWithCommittedItems = (lineItems: ILineItem[]): ILineItem[] => {
    const minLineItemRowNumber = Math.min(...lineItems.map((item) => item.row_number));
    const sortedLineItems = lineItems.map((item, index) => {
      const minCommittedRowNumber = Math.min(
        ...item.committed_items.map((commItem) => commItem.committed_row_number),
      );
      return {
        ...item,
        row_number: index + minLineItemRowNumber,
        committed_items: item.committed_items
          .sort((a, b) => a.committed_row_number - b.committed_row_number)
          .sort((a, b) =>
            a.type === 'forecast_modification' ? 1 : b.type === 'forecast_modification' ? -1 : 0,
          )
          .map((commItem, commIndex) => {
            return {
              ...commItem,
              committed_row_number: commIndex + minCommittedRowNumber,
            };
          }),
      };
    });

    const committedItems = sortedLineItems.flatMap((item: ILineItem) => item.committed_items);
    committedItems.sort((a, b) => a.row_number - b.row_number);
    committedItems.sort((a, b) =>
      a.type === 'forecast_modification' ? 1 : b.type === 'forecast_modification' ? -1 : 0,
    );

    const minCommittedRowNumberAll = Math.min(
      ...committedItems.map((commItem) => commItem.row_number),
    );
    committedItems.forEach((item: ICommittedItem, index: number) => {
      item.row_number = index + minCommittedRowNumberAll;
    });

    return sortedLineItems;
  };

  private handleDistributionUpdate(
    action: ReturnType<
      | typeof spendActionTypes.updateDistribution
      | typeof spendActionTypes.updateForecastDistribution
    >,
    state: SpendState,
  ) {
    const itemId =
      action.type === spendActionTypes.updateForecastDistribution.type
        ? action.parentId
        : action.lineId;
    return of(action).pipe(
      switchMap((actionInner) => this.calculateDistribution(actionInner, state)),
      catchError(this.handleDistributionError),
      withLatestFrom(of(action), this.store.select(getLineItemById(itemId))),
    );
  }

  private calculateDistribution(
    action: ReturnType<
      | typeof spendActionTypes.updateDistribution
      | typeof spendActionTypes.updateForecastDistribution
    >,
    state: SpendState,
  ) {
    const isForecast = action.type === spendActionTypes.updateForecastDistribution.type;

    if (action.distribution.distribution === DISTRIBUTION_TYPES.MANUAL) {
      return of(null);
    }

    const isUpdateNeeded = this.isUpdateRequestNeeded(action);

    if (isUpdateNeeded) {
      const lineId = isForecast || !state.newLineItems.has(action.lineId) ? action.lineId : null;
      return this.spendService.calculateDistribution(action.distribution, lineId);
    }

    return throwError(action.distribution);
  }

  private handleDistributionError(err) {
    setTimeout(() => {
      if (err.duration === null) {
        this.notif.showError('Please provide a distribution duration');
      } else if (err.distribution !== 'manual') {
        this.notif.showError('Distribution update error.');
      }
    });
    return of(undefined);
  }

  private mapToUpdateLineItem(
    response: IDistributionResponse,
    action:
      | ReturnType<typeof spendActionTypes.updateDistribution>
      | ReturnType<typeof spendActionTypes.updateForecastDistribution>,
    lineItem: ILineItem,
  ) {
    let item: ILineItem | ICommittedItem;

    if (action.type === spendActionTypes.updateDistribution.type) {
      item = cloneDeep(lineItem);
    } else {
      item = cloneDeep(
        lineItem.committed_items.find((committedItem) => committedItem.id === action.lineId),
      );
    }

    item = this.updateBasicDistributionValues(item, action.distribution);

    if (response) {
      item = this.updateDistWithReq(item, response, action);
    } else if (action.distribution.distribution === DISTRIBUTION_TYPES.MANUAL) {
      item = this.updateDistManual(item, action);
    }

    if (action.type === spendActionTypes.updateDistribution.type) {
      return spendActionTypes.updateLineItem({ lineItem: item as ILineItem });
    }
    return spendActionTypes.updateCommittedLineItem({ committedLineItem: item as ICommittedItem });
  }

  private updateBasicDistributionValues(
    item: ILineItem | ICommittedItem,
    distribution: ISpendDistribution,
  ) {
    return {
      ...item,
      distribution: distribution.distribution,
      start_date: distribution.start_date,
      duration: distribution.duration,
    };
  }

  private handleError(err) {
    console.warn(err);
    return of(undefined);
  }

  private addOriginalBudget(lineItems: ILineItem[]) {
    return lineItems.map((lineItem) => {
      lineItem.original_budget_total = projectTotalPerItem(lineItem);
      lineItem.committed_items = lineItem.committed_items.map((committedItem) => {
        committedItem.original_budget_total = getForecastProjectTotalPerItem(committedItem);
        return committedItem;
      });
      return lineItem;
    });
  }
}
