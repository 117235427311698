import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortenFileNamePipe } from '../../../pipes/framework/shorten-file-name.pipe';
import { UploadDropDirective } from '../../../directives/upload-drop.directive';

export interface IFile extends File {
  id: number;
  name: string;
  size: number;
  type: string;
  deleted?: boolean;
}

interface IFileEvent {
  file: File | IFile;
  index: number;
}

/**
 * Default upload component mainly used for input file upload with default UI
 * It's role is simply to display the files and handle events to emit open file input window, remove file and file dropped
 */
@Component({
  selector: 'app-default-upload-ui',
  templateUrl: './default-upload-ui.component.html',
  styles: ['animate-shake { border: none !important; }'],
  standalone: true,
  imports: [CommonModule, ShortenFileNamePipe, UploadDropDirective],
})
export class DefaultUploadUiComponent {
  @Input() disableUpload = false;
  @Input() hideRemoveFileIcon = false;
  @Input() files: (File | IFile)[] = [];

  @Output() fileDropped = new EventEmitter<any>();
  @Output() openWindow = new EventEmitter<void>();
  @Output() removeFile = new EventEmitter<IFileEvent>();

  onFileDropped($event: any) {
    this.fileDropped.emit($event);
  }

  onOpenWindow() {
    this.openWindow.emit();
  }

  onRemoveFile(file: File | IFile, index: number) {
    this.removeFile.emit({ file, index });
  }
}
