import { Injectable } from '@angular/core';
import { RestRequestService } from '../restApi/rest-request.service';
import { REST_PROGRESS_ITEMS, REST_PROGRESS_ITEMS_CHECKLISTS } from '../restApi/RestRoutes';
import {
  IActivityProgressItem,
  IItemTrackingChecklistItem,
  IItemTrackingSidebar,
} from '../store/item-tracking/item-tracking.reducer';
import { Observable } from 'rxjs';

export type ProgressItemPayload = {
  title: string;
  project_id: number;
  start_date: string;
  end_date: string;
  checklist: ChecklistPayload;
  id?: number;
  note?: string;
};

export type ChecklistPayload = {
  name: string;
  items: Item[];
};

export type Item = {
  name: string;
  end_date: string;
  completed: boolean;
  order: number;
};

@Injectable({
  providedIn: 'root',
})
export class ProgressItemsService {
  constructor(private restService: RestRequestService) {}

  transformToProgressItemPayload(
    model: IItemTrackingSidebar,
    project_id: number,
  ): ProgressItemPayload {
    const payload = {
      ...model,
      checklist: {
        name: model.checklist.name,
        id: model.checklist.id,
        template_id: model.checklist.template_id,
        items: model.checklist.items.map((item: IItemTrackingChecklistItem) => {
          return {
            name: item.name,
            end_date: item.end_date,
            completed: !!item?.completed,
            note: item?.note ?? '',
            id: item?.id ?? null,
            order: item?.order ?? 0,
          };
        }),
      } as ChecklistPayload,
      project_id,
    };

    if (model.id) {
      payload.id = model.id;
    }

    return payload;
  }

  loadProgressItems(
    projectId: number,
    filter: { [p: string]: boolean } = {},
  ): Observable<IActivityProgressItem[]> {
    const progressParams = Object?.entries(filter)
      .filter(([key, value]) => value)
      .map(([key, _]) => `progress[]=${key}`)
      .join('&');
    return this.restService.getWithObservable(
      filter
        ? `${REST_PROGRESS_ITEMS}/projects/${projectId}?${progressParams}`
        : `${REST_PROGRESS_ITEMS}/projects/${projectId}`,
    );
  }

  createProgressItem(data: IItemTrackingSidebar, projectId: number) {
    const payload = this.transformToProgressItemPayload(data, projectId);
    return this.restService.postWithObservable(REST_PROGRESS_ITEMS, payload);
  }

  updateProgressItem(data: IItemTrackingSidebar, project_id: number) {
    const payload = this.transformToProgressItemPayload(data, project_id);
    return this.restService.putWithObservable(`${REST_PROGRESS_ITEMS}/${payload.id}`, payload);
  }

  loadAllChecklist(withTrash = false) {
    return this.restService.getWithObservable(
      `${REST_PROGRESS_ITEMS_CHECKLISTS}`,
      {},
      { withTrashed: withTrash ? 1 : 0 },
    );
  }

  deleteChecklist(id: number) {
    return this.restService.delWithObservable(`${REST_PROGRESS_ITEMS_CHECKLISTS}/${id}`);
  }

  deleteProgressItem(id: number) {
    return this.restService.delWithObservable(`${REST_PROGRESS_ITEMS}/${id}`);
  }
}
