import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import {
  Project,
  PROJECT_STATUS_ID,
  PROJECT_STATUS_KEY,
} from '../../../pages/webapp/projects/projects.interface';
import {
  getReportingFormInitialValues,
  IReportingPayload,
  REPORTING_SIDEBAR_TYPES,
  reportingBooleanFields,
  ReportingFormType,
} from './reporting.constants';
import { MatButton } from '@angular/material/button';
import { FloatingInputComponent } from '../../inputs/floating-input/floating-input.component';
import { ChecklistItemComponent } from '../../inputs/checklist-item/checklist-item.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { DropdownComponent } from '../../inputs/dropdown/dropdown.component';
import { BackButtonBoxComponent } from '../../back-button-box/back-button-box.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { ReportingBaseComponent } from './reporting-base.component';

@Component({
  selector: 'app-bar-reporting',
  templateUrl: './reporting-bar.component.html',
  styleUrls: ['./reporting-bar.component.scss'],
  providers: [NgForm],
  standalone: true,
  imports: [
    NgIf,
    BackButtonBoxComponent,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    DropdownComponent,
    NgScrollbar,
    ChecklistItemComponent,
    FloatingInputComponent,
    NgFor,
    MatButton,
  ],
})
export class ReportingBarComponent extends ReportingBaseComponent {
  readonly PROPERTY_PAGE: number = 1;
  readonly PROJECTS_PAGE: number = 2;
  readonly MAX_PAGE: number = 2;
  readonly reportingType = REPORTING_SIDEBAR_TYPES.FULL_REPORT;

  PROJECT_STATUS_ID = PROJECT_STATUS_ID;

  reportFields: FormGroup<ReportingFormType> = new FormGroup<ReportingFormType>({
    ...getReportingFormInitialValues(),
  });

  @Input() set data(value: { properties: any; projects: Project[] }) {
    if (value) {
      if (value.properties) {
        this.properties = this.sortProperties(Array(...value.properties));
      }
      if (value.projects) {
        const projects = value.projects.filter(
          (proj) =>
            proj.project_status.key !== PROJECT_STATUS_KEY.DELETED &&
            proj.project_status.key !== PROJECT_STATUS_KEY.ARCHIVED,
        );
        this.setYears(projects);
        this.projects = this.sortProjects([...projects]);
      }
    }
  }

  checkStatusPageValidation(): boolean {
    this.errors[0] = null;

    const isAnyFieldFilled = reportingBooleanFields.some((field) => {
      return this.reportFields.get(field).value;
    });

    if (!isAnyFieldFilled) {
      this.errors[0] = 'At least one report type is required.';
    }

    const statuses = this.reportFields.get('project_status_ids').value;
    if (statuses.length === 0) {
      this.errors[0] = 'At least one project status is required.';
    }

    return !this.errors[0];
  }

  checkOverallValidation() {
    let isValid = true; // if any call returns false, this will be false
    isValid = isValid && this.checkStatusPageValidation();
    isValid = isValid && this.checkPropertyValidation();
    isValid = isValid && this.checkProjectValidation();
    this.submitDisabled = !isValid;
  }

  incrementPage() {
    if (this.currentPage === this.MAX_PAGE) {
      return;
    }

    let canGoTrough = false;
    if (this.currentPage === 0) {
      canGoTrough = this.checkStatusPageValidation();
    } else if (this.currentPage === 1) {
      canGoTrough = this.checkPropertyValidation();
    } else if (this.currentPage === 2) {
      canGoTrough = this.checkProjectValidation();
    }

    if (canGoTrough) {
      this.currentPage++;
      this.checkOverallValidation();
      this.updateAllSelected();
    }

    // if (this.currentPage === this.PROJECTS_PAGE) {
    // this.checkAllProjectsSelected();
    // }
  }

  setYears(projects: Project[]) {
    const years = new Set<number>();
    projects.forEach((project) => {
      if (project.budget_years) {
        project.budget_years.forEach((year) => {
          years.add(year);
        });
      }
    });

    const yearArray: number[] = Array.from(years)
      .filter((year) => year > 0) // if the project doesn't have a budget FY, it will be 0
      .sort((a, b) => (a < b ? -1 : 1));
    this.years = yearArray.map((year) => ({ key: year, value: year }));

    const currentValueIncluded = yearArray.includes(this.reportFields.get('start_year').value);

    if (this.years.length > 0 && !currentValueIncluded) {
      // if the default value is not in the list, set the first year as default
      this.reportFields
        .get('start_year')
        .setValue(this.years?.[0]?.key ?? new Date().getFullYear());
      this.reportFields.get('end_year').setValue(this.years?.[0]?.key ?? new Date().getFullYear());
    }
  }

  yearsChanged(fieldChanged: string) {
    const endYear = this.reportFields.get('end_year').value;
    const startYear = this.reportFields.get('start_year').value;

    if (endYear < startYear) {
      // end year must be greater than start year
      // so change the opposite field
      if (fieldChanged === 'start_year') {
        this.reportFields.get('end_year').setValue(startYear);
      } else if (fieldChanged === 'end_year') {
        this.reportFields.get('start_year').setValue(endYear);
      }
    }
  }

  convertFormToPayload(): IReportingPayload {
    const formData = this.reportFields.getRawValue();
    const data: IReportingPayload = {
      ...formData,
      property_ids: this.reportFields.get('property_ids').value,
      project_ids: this.reportFields.get('project_ids').value,
      project_status_ids: this.reportFields.get('project_status_ids').value,
      include_invoice_log: formData.include_invoice_log,
      tmp_sp_ids: [], // will be empty for "Full report"
      sp_ids: [], // will be empty for "Full report"
    };

    return data;
  }

  /**
   * Toggle a field in the report form
   * @param fieldName - the name of the field
   *
   */
  toggleField(fieldName: keyof ReportingFormType) {
    if (!this.reportFields.get(fieldName)) {
      return;
    }
    const currentValue = this.reportFields.get(fieldName)?.value;
    this.reportFields.get(fieldName).setValue(!currentValue);
  }

  toggleStatus(statusValue: number) {
    this.toggleSpecificValues('project_status_ids', statusValue, this.allStatuses);
  }
}
